'use strict';

app.controller('StudentAssociationCommitteeCtrl', ['$scope', '$timeout', 'Ajax', 'Utils', function($scope, $timeout, Ajax, Utils) {
     $scope.addToCommittee = function(name){
        if(name === 'noAssociation'){
            var selected = $('#w7').yiiGridView('getSelectedRows');

            if(selected[0]){
                Ajax.post({
                    url: 'committee/add-student-to-committee',
                    params: {id: Utils.GET().id},
                    data: selected,
                    success: function(res){
                        $.pjax.reload({container:'#w7-container'});
//                        $timeout(function () {
//                            $.pjax.reload({container:'#w6-container'});
//                        });
                    }
                });
            }
        }else if(name === 'transmitted'){
            var selected = $('#w17').yiiGridView('getSelectedRows');

            if(selected[0]){
                Ajax.post({
                    url: 'committee/add-student-to-committee',
                    params: {id: Utils.GET().id},
                    data: selected,
                    success: function(res){
                        $.pjax.reload({container:'#w17-container'});
//                        $timeout(function () {
//                            $.pjax.reload({container:'#w6-container'});
//                        });
                    }
                });
            }
        }
     };  
     
     $scope.removeFromCommittee = function(){
        var selected = $('#w27').yiiGridView('getSelectedRows');

        if(selected[0]){
            Ajax.post({
                url: 'committee/remove-from-committee',
                params: {id: Utils.GET().id},
                data: selected,
                success: function(res){
                    $.pjax.reload({container:'#w27-container'});
//                    $timeout(function () {
//                        $.pjax.reload({container:'#w6-container'});
//                    });
                }
            });
        }
     };
}]);