'use strict';

app.controller('FormController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', '$rootScope', '$window',
    function($scope, $http, $filter, $modal, Ajax, Utils, $rootScope, $window) { 
    
    $scope.form = {};
    $scope.form.categories = [];
    $scope.form.filled_times = [];
    $scope.form.settings = {};
    $scope.form.settings.fieldsUpdate = {}
    $scope.form.settings.fieldsUpdate.data = [];
    $scope.forms = [];
    $scope.fields = [];
       
    $scope.init = function(firstTime){
        
        if (Utils.GET().id){
            Ajax.get({
                url: 'form/get-form',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.form = res.form;
                    $scope.originalForm  = angular.copy($scope.form);
                    if (!$scope.form.categories || $scope.form.categories.length == 0){
                        $scope.form.categories = [];
                        $scope.form.categories.push({name: 'כללי', rank: 1, new: false});
                    }
                    if (!$scope.form.settings || ($scope.form.settings && !$scope.form.settings.fieldsUpdate)){
                        $scope.form.settings = {};
                        $scope.form.settings.fieldsUpdate = {};
                        $scope.form.settings.fieldsUpdate.data = [];
                        $scope.addFieldUpdate();
                    }
                    $scope.fields           = res.fields;
                    $scope.fieldTypes       = res.fieldTypes;
                    $scope.titles           = res.titles;
                    $scope.modelMap         = res.modelMap;
                    $scope.originalTitles  = angular.copy($scope.titles);
                    if (firstTime) {
                        $scope.currentTitleId   = ( $scope.titles.length>0 ? $scope.titles[0].id : null );
                    }else{
                        $scope.currentTitleId   =  $scope.titles[$scope.titles.length-2].id;
                    }
                    $scope.form.settings.fieldsUpdate.data.forEach( (d, currIndex) => {
                        $scope.addWatchFieldUpdate(currIndex);
                    });
                },
            });
        }else{
            Ajax.get({
                url: 'form/get-forms',
                success: function(res){
                    $scope.forms = res.forms;
                },
            });
            if ($scope.form.categories.length==0){
                $scope.form.categories = [];
                $scope.form.categories.push({name: 'כללי', rank: 1, new: false});
            }
        }
        Ajax.get({
            url: 'form/get-objects',
            success: function(res){
                $scope.objects  = res.objects;
            },
        });
    };
    
    $scope.init(true);
    
    $scope.getCurrentTitleIndex = () => {
        for (var i = 0; i < $scope.titles.length; i++) {
            if ($scope.currentTitleId == $scope.titles[i].id){
                return i;
            }
        }
    };
    

    $scope.getFieldById = (fieldId) => {
        return $scope.fields.filter( function(i) {
            return i.id == fieldId;
        });
    };

    $scope.$watch('form.settings.ivrita.field', function(newValue, oldValue) {
        if (newValue){
            $scope.ivritaSelectedField = $filter('filter')($scope.fields, {id: newValue})[0];
        }
    });

    
    // $scope.$watch('form.settings.fieldsUpdate.targetObject', function(newValue, oldValue) {
    //     if (newValue){
    //         $scope.fuSelectedTargetField = $scope.modelMap[newValue];
    //     }
    // });

    $scope.saveForm = function(){
        if ($scope.form.categories.length == 0){
            $scope.form.categories.push({name: 'כללי', rank: 1, new: false});
        }
        
        // ** must do before save
        $scope.form.settings.fieldsUpdate.data.forEach( (d) => {
            if (d.selectedSourceField){
                delete d.selectedSourceField;
            }
        });
        // end must do before save

        Ajax.post({
            url: 'form/save-form',
            data: {
                form: $scope.form,
                fields: $scope.fields,
                titles: $scope.titles
            },
            success: function(res){
                if(window.location.href.includes('create')){
                    $scope.form = res.form;
                    Utils.redirect('/common/form/update?id=' + $scope.form.id);
                }
                $scope.init(false);
                $scope.inSaveProcess = false;
                // Utils.redirect('/common/form/update?id=' + $scope.form.id);
                // $scope.currentTitleId   =  $scope.titles[$scope.titles.length-2].id;
            }
        });
    };
    
    $scope.recalculatSubmissions = function(){
        if(!angular.equals($scope.originalForm.categories, $scope.form.categories) || !angular.equals($scope.originalTitles, $scope.titles)){
            if( !confirm('פעולה זו מחשבת מחדש את הציונים בכל הטפסים מסוג זה שהוגשו על ידי המשתתפים, פעולה זו יכולה להמשך מספר דקות. אתה בטוח שאתה רוצה להמשיך?') ){
                return;
            }
            Ajax.post({
                url: 'form/recalculate-submissions-rank',
                data: {form: $scope.form},
                success: function(res){
                    if(window.location.href.includes('create')){
                        $scope.form = res.form;
                        Utils.redirect('/common/form/update?id=' + $scope.form.id);
                    }
                    $scope.init(false);
                }
            });
        }else{
            alert('לא יתבצע חישוב ציונים, כי לא היה שינוי בקטגוריות או בשדות');
        }
    };

    $scope.setCurrentTitleId = function($index){
        if ($scope.titles[$index].id!='add'){
            $scope.currentTitleId = $scope.titles[$index].id;
        }else{
            $scope.currentTitleId = 'new'+$scope.titles.length;
            var order = $scope.fields.length +1;
            var ft = {value: 'title'};
            $scope.fields.push($scope.getNewField(ft,order,null));
            $scope.titles[$index].fields = [];
            $scope.titles[$index].fields.push($scope.getNewField(ft,order,null));
            $scope.titles[$index].fields[$scope.titles[$index].fields.length-1].field_text = 'שלב ' + $scope.titles.length;
            $scope.titles[$index].fields[$scope.titles[$index].fields.length-1].id = $scope.currentTitleId;
            $scope.titles.splice($scope.titles.length-1, 0, $scope.titles[$index].fields[$scope.titles[$index].fields.length-1]);
        }
    };

    $scope.removeForm = function($id){
		Ajax.post({
			url: 'form/remove-form',
            params: {id: $id},
            success: function(res){
				$scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
	};    
        
    $scope.openFormPopup = function(data){
        var fn = [];
        fn = {
            removeForm: $scope.removeForm
        };
        Popup.open('common/form', $scope.saveForm, data, fn);
    };
    
    $scope.addCategory = function(){
        $scope.form.categories.push({name: null, rank: null, new: true});
    }
    
    $scope.removeCategory = function($index){
        $scope.form.categories.splice($index,1);
    };

    $scope.addFieldUpdate = () => {
        $scope.form.settings.fieldsUpdate.data.push({
            sourceField: null,
            selectedSourceField: null,
            sourceFieldValue: null,
            condition: null,
            targetObject: null,
            targetField: null,
            targetFieldValue: null,
        });
        let currIndex = $scope.form.settings.fieldsUpdate.data.length-1;
        $scope.addWatchFieldUpdate(currIndex);
    };
    
    $scope.removeFieldUpdate = function($index){
        $scope.form.settings.fieldsUpdate.data.splice($index,1);
        let currIndex = $scope.form.settings.fieldsUpdate.data.length-1;
        $scope.$watch('form.settings.fieldsUpdate.data['+currIndex+'].sourceField', function () {});
    };


    $scope.addWatchFieldUpdate = (currIndex) => {
        $scope.$watch('form.settings.fieldsUpdate.data['+currIndex+'].sourceField', function(newValue, oldValue) {
            if (newValue){
                $scope.form.settings.fieldsUpdate.data[currIndex].selectedSourceField = $filter('filter')($scope.fields, {id: newValue})[0];
            }
        });
    };
    
    $scope.addFilledTime = function(){
        $scope.form.filled_times.push({name: $scope.newTime});
        $scope.newTime = "";
    };
    
    $scope.removeFilledTime = function($index){
        $scope.form.filled_times.splice($index,1);
    };
    

    $scope.addField = function(title,ft){
        var order = $scope.fields.length +1;
        title.fields.push($scope.getNewField(ft,order,null));
        $scope.fields.push($scope.getNewField(ft,order,null));
    };
    
    $scope.addChild = function(ft,f){
        var order = f.childs.length +1;
        f.childs.push($scope.getNewField(ft,order,f));
    }
    
    $scope.getNewField = function(ft,order,f){
        return {
            id: null,
            form_id: $scope.form.id,
            field_text: null,
            description: null,
            mandatory: null,
            rules: null,
            category: null,
            field_type: ft.value,
            order: order,
            visible: 1,
            answer_options: [],
            extendField: true,
            parent_id: f ? f.id : null,
            access: 'all',
            title_parent_id: ft.value=='title' ? null : $scope.currentTitleId,
            childs: [],
            fields: [],
            attributes: (ft.value=='radio')||(ft.value=='multiselect') ? {viewStyle: 'column'} : null,
            settings: {max_number_of_files: 1, fileFormat: {doc: 'true'}}
        }
    }
    
    $scope.getMaxOrder = function() {
        var maxOrder = $scope.fields[0].order;
        angular.forEach($scope.fields, function(item, key) {
            if(maxOrder < parseInt(item.order)) { 
                maxOrder = item.order;
            }
        });
        return maxOrder;
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d.replace(/-/g, '/'));
    };

    $scope.removeStep = function($index){
        $scope.titles[$index].delete = !$scope.titles[$index].delete;
    };

    $scope.hideStep = function($index){
        $scope.titles[$index].visible = $scope.titles[$index].visible == 1 ? 0 : 1;
    };

    $scope.isTitleHasVisibleFields = function($index){
        return $scope.titles[$index].fields.filter(function(i){
            return i.visible == 1;
        }).length > 0;
    };

    $scope.onDropComplete = function(index, obj, evt, type){
        
        var key =  type;
        
        var otherObj    = $scope.titles[index];
        if (obj.field_type!='title'){
            if (obj.title_parent_id == otherObj.id) {
                return;
            }

            var previosTitle = $scope.titles.filter(function(i){
                return i.id == obj.title_parent_id;
            })
            if (previosTitle.length>0){
                previosTitle[0].fields.splice(obj.order,1);

                obj.title_parent_id =  otherObj.id;
                obj.order = otherObj.fields.length;
                otherObj.fields.push(obj);
            }
          
            return;
        }
        var otherIndex  = $scope.titles.indexOf(obj);
                
        if (otherIndex < index){
            $scope.titles.splice(index+1, 0, obj);
            $scope.titles.splice(otherIndex, 1);
        }else{
            $scope.titles.splice(index, 0, obj);
            $scope.titles.splice(otherIndex+1, 1);
        }
    };

    $scope.onDropFieldsComplete = function(index, obj, evt, type){
        if (obj.parent_id||obj.field_type=='tittle'){
            return;
        }
        var key =  type;
        var field = $filter('filter')($scope.titles, {id: obj.title_parent_id})[0];
        if (!field){
            return;
        }
        var list = field.fields;
        var otherObj    = list[index];
        var otherIndex  = list.indexOf(obj);
        obj.order = index;

        if (otherIndex < index){
            list.splice(index+1, 0, obj);
            list.splice(otherIndex, 1);
        }else{
            list.splice(index, 0, obj);
            list.splice(otherIndex+1, 1);
        }
    };

    $scope.onDropChildsComplete = function(index, obj, evt, type){
        if (!obj.parent_id) {
            return;
        }

        var key =  type;
        var title = $filter('filter')($scope.titles, {id: obj.title_parent_id})[0];
        var field =  $filter('filter')(title.fields, {id: obj.parent_id})[0];
        
        var list = field.childs;
        var otherObj    = list[index];
        var otherIndex  = list.indexOf(obj);
        obj.order = index;

        if (otherIndex < index){
            list.splice(index+1, 0, obj);
            list.splice(otherIndex, 1);
        }else{
            list.splice(index, 0, obj);
            list.splice(otherIndex+1, 1);
        }
    };

    $scope.getAllSubmissions = function(){
        var filter = {
            yearId: $scope.yearId ? $scope.yearId : null ,
            filledTime: $scope.filledTime ? $scope.filledTime : null
        };
        Ajax.post({
            url: 'form/get-all-submissions',
            params: {
                id: Utils.GET().id,
                format: 'excel'
            },
            data: filter,
            notShowMessage: true,
            success: function(res){
                $scope.headers = res.headers;
                $scope.rows = res.rows;
            },
        });
    };

    $scope.exportToExcel = function(){
        Ajax.get({
            module: 'common',
            url: 'form/export-to-excel?id=' + Utils.GET().id,
            notShowMessage: true,
            success: function(res) { 
                // let csvContent = "data:text/csv;charset=utf-8,\uFEFF" + res.rows.map(e => e.join(",")).join("\n");
                // var encodedUri = encodeURI(csvContent);
                // var link = document.createElement("a");
                // link.setAttribute("href", encodedUri);
                // link.setAttribute("download", "report.csv");
                // document.body.appendChild(link); // Required for FF
                // link.click();
            }
        });
    };	
}]);