'use strict';

app.controller('EhpHomeController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    $scope.init = function(){
        Ajax.get({
            url: 'supplier/get-alert-details',
            params: {id: YT.user.user_id},
            success: function(res){
                $scope.alerts           = res.alerts;
                $scope.invoicesSummary  = res.invoicesSummary;
                $scope.requestsSummary  = res.requestsSummary;
            },
        });
        //}
    };
        
    $scope.init();
    
    $scope.setProjectId = function(id) {
        Ajax.post({
            module: 'common',
            url: 'default/set-project-id', 
            params: {id: id},
            success: function(res) { 
                Utils.redirect('/elhanefeshportal/supplier/home');
            },
            notShowMessage: true
        });
    };
}]);