app.directive('autocompleteaddress', ['Utils', '$timeout', function (Utils, $timeout) {
    return {
        require:'^ngModel',
        restrict:'A',
        scope: true,
        link:function (scope, elm, attrs, ctrl) {
            var autocomplete = {};
            var place = {};
            var options = {
                types: ['geocode'],
                //componentRestrictions: {country: 'il'}
            };
            
            scope.fillInAddress = function(){
                $timeout(function(){
                    var place = autocomplete.getPlace();
                    if (place){
                        scope.$emit('autocomplete:getplace', place);
                    }
                },100);
            };
            
            scope.init = function() {
                autocomplete = new google.maps.places.Autocomplete(elm.context, options);
                autocomplete.addListener('place_changed', scope.fillInAddress);
            }
            scope.init();
        }
    };
}]);


