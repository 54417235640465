'use strict';

var newStudyModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, organizations, lookupFieldStudy, userAcademicStatus, lookupYears, Ajax, Utils){
    
    $scope.study = {};
    
    $scope.organizations        = organizations;
    $scope.lookupFieldStudy     = lookupFieldStudy;
    $scope.userAcademicStatus   = userAcademicStatus;
    $scope.lookupYears          = lookupYears;
    
    $scope.ok = function () {
        if($scope.study){
            Ajax.post({
                url: 'student/study-user',
                params: {id: Utils.GET().id},
                data: $scope.study,
                //notShowMessage: true,
                success: function(res){
                    $modalInstance.close(res.data);
                },
                error: function(res){
                    $scope.popupAlerts.push({type: 'danger', msg: res});
                }
            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};

app.controller('AcademicStudyController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', function($scope, $http, $filter, $modal, Ajax, Utils) {
    
    $scope.academic = null;
    $scope.userStudent = null;
    
    $scope.user_id = Utils.GET().id;
    
    $scope.getAcademicStudy = function(){
        Ajax.get({
            url: 'student/academic-study',
            params: {id: Utils.GET().id},
            success: function(data){
                $scope.academic             = data.academic.academicStudyUsers;
                $scope.userStudent          = data.userStudent;
                
                $scope.academicEdit         = angular.copy($scope.academic);
                //$scope.academic[acaIndex].academicStudyYears[yearIndex].academicStudySemesters
                $scope.lookupYears          = data.picklists.lookupYears;
                $scope.userAcademicStatus   = data.picklists.userAcademicStatus;
                $scope.organizations        = data.picklists.organizations;
                $scope.diplomaOrganizations = data.picklists.diplomaOrganizations;
                $scope.lookupFieldStudy     = data.picklists.lookupFieldStudy;
                $scope.statusApproval       = data.picklists.statusApproval;
            }
        });
    };
    //$scope.getAcademicStudy();
    $scope.$watch('tabSelected', function(num) {
        if(num === 3 && !$scope.academic){
            $scope.getAcademicStudy();
        }
    });
    
    
    // Get
    $scope.getYearName = function(id){
        var f = $filter('filter')($scope.lookupYears, {id: id})[0];
        return f ? f.name_he : '';
    };
    
    $scope.getUserAcademicStatus = function(id){
        var f = $filter('filter')($scope.userAcademicStatus, {id: id})[0];
        return f ? f.name : ''; 
    };
    
    $scope.getFieldStudyName = function(id){
        var name = '';
        
        angular.forEach($scope.lookupFieldStudy, function(item, key) {
            if(item.id == id) { 
                name = item.name;
            }
        });
        
        return name;
    };
    
    $scope.getOrganizationName = function(id){
        var f = $filter('filter')($scope.organizations, {id: id})[0];
        return f ? f.name : '';
    };
    
    $scope.getStatusApproval = function(id){
        var f = $filter('filter')($scope.statusApproval, {id: id})[0];
        return f ? f.name : '';
    };
    
    // Action
    $scope.removeStudy = function(index){
        $scope.academic.splice(index, 1);
    };
    

    $scope.openAddStudyPopup = function(){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/add_study_popup.html',
            controller: newStudyModalInstanceCtrl,
            size: 'lg',
            resolve: {
                organizations: function () {
                    return $scope.organizations;
                },
                lookupFieldStudy: function(){
                    return $scope.lookupFieldStudy;
                },
                userAcademicStatus: function(){
                    return $scope.userAcademicStatus;
                },
                lookupYears: function(){
                    return $scope.lookupYears;
                }
            }
        });
        
        modalInstance.result.then(function (academicStadyUser) {
            $scope.getAcademicStudy();
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };

    $scope.cancel = function(){
        $scope.academic = angular.copy($scope.academicEdit);
    };
    
    $scope.editStudy = function(acaKey, aca){
        if( !angular.equals($scope.academicEdit, $scope.academic) ){
            Ajax.post({
                url:    'student/study-user', 
                params: {id: Utils.GET().id},
                data:   angular.extend({user_id: $scope.academic[acaKey].user_id}, $scope.academic[acaKey]),
                success: function(res){
                    $scope.academic[acaKey].id = res.data.id;
                    $scope.academicEdit = angular.copy($scope.academic);
                    aca.edit=false;
                }
            });
        }
    };
    
    $scope.addYear = function(index){        
        if(!$scope.academic[index].academicStudyYears){
            $scope.academic[index].academicStudyYears = [];
        }
        $scope.academic[index].academicStudyYears.push({year_name: '', school_year: "", edit: true});
    };
    
    $scope.saveYear = function(acaKey, yearKey, year){
        Ajax.post({
            url:        'student/year', 
            data:       angular.extend({academic_study_user_id: $scope.academic[acaKey].id}, $scope.academic[acaKey].academicStudyYears[yearKey]),
            success:    function(res){
                year.edit=false;
                $scope.getAcademicStudy();
            }
        });
    };
    
    $scope.removeYear = function(acaIndex, yearIndex){
        Ajax.post({
            url: 'student/delete-year',  
            params: {id: $scope.academic[acaIndex].academicStudyYears[yearIndex].id},
            success: function(res){
                $scope.academic[acaIndex].academicStudyYears.splice(yearIndex, 1);
                $scope.academicEdit = angular.copy($scope.academic);
            }
        });
    };

    $scope.cancelYear = function(acaIndex, yearIndex){
        $scope.academic[acaIndex].academicStudyYears.splice(yearIndex, 1);
    };
    

    // Semester
    
    $scope.addSemester = function(acaIndex, yearIndex){
        if( !$scope.academic[acaIndex].academicStudyYears[yearIndex].hasOwnProperty('academicStudySemesters') )  {
            $scope.academic[acaIndex].academicStudyYears[yearIndex].academicStudySemesters = [];
        }        
        $scope.academic[acaIndex].academicStudyYears[yearIndex].academicStudySemesters.push( {semester : "", review : 'no', edit: true, showGrades: false} );
    };
    
    
    $scope.saveSemester = function(acaIndex, yearIndex, semIndex){
        Ajax.post({
            url: 'student/semester',
            data: angular.extend({academic_study_year_id: $scope.academic[acaIndex].academicStudyYears[yearIndex].id}, $scope.academic[acaIndex].academicStudyYears[yearIndex].academicStudySemesters[semIndex]),
            success: function(res){
                $scope.getAcademicStudy();
            }
        });        
    };

    $scope.removeSemester = function(acaKey, yearKey, semKey){
        Ajax.post({
            url: 'student/delete-semester',
            params: {id: $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].id},
            success: function(res){
                $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters.splice(semKey, 1);
                $scope.academicEdit = angular.copy($scope.academic);
            }
        });
    };

    $scope.cancelSemester = function(acaKey, yearKey, semKey){
        $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters.splice(semKey, 1);
    };

    // Grade
    $scope.addGrade = function(acaKey, yearKey, semKey){
        $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].showGrades = true;
        
        if( !$scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].hasOwnProperty('academicStudySemesterGrades') )  {
            $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades = [];
        }
        
        $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades.push( {name: '', grade: '', academic_credit: '', status: '', course_number: '', course_cost: '', edit: true}  );
    };
    
    $scope.saveGrade = function(acaKey, yearKey, semKey, gradeKey){
        Ajax.post({
            url: 'student/grade',
            data: angular.extend({academic_study_semester_id: $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].id}, $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades[gradeKey]),
            success: function(res){
                $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades[gradeKey].id = res.data.id;
                $scope.academicEdit = angular.copy($scope.academic);
            }
        });
    };

    $scope.removeGrade = function(acaKey, yearKey, semKey, gKey){
        Ajax.post({
            url: 'student/delete-grade',
            params: {id: $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades[gKey].id},
            success: function(res){
                $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades.splice(gKey, 1);
                $scope.academicEdit = angular.copy($scope.academic);
            }
        });
    };
    
    $scope.cancelGrade = function(acaKey, yearKey, semKey, gKey){
        $scope.academic[acaKey].academicStudyYears[yearKey].academicStudySemesters[semKey].academicStudySemesterGrades.splice(gKey, 1);
    };
    
    $scope.setCurrentStudies = function(id){
        $scope.SaveCurrentStudiesDetails(id, null, null);
    };
    
    $scope.setCurrentYear = function(id, studies_id){
        $scope.SaveCurrentStudiesDetails(studies_id, id, null);
    };
    
    $scope.setCurrentSemester = function(id, year_id, studies_id){
        $scope.SaveCurrentStudiesDetails(studies_id, year_id, id);
    };
    
    $scope.SaveCurrentStudiesDetails = function(currentStudyId, currentYearId, currentSemesterId){
        Ajax.post({
            url: 'student/save-current-studies-details',
            data: $scope.userStudent,
            params: {
                //userId: $scope.userStudent.user_id,
                currentStudyId: currentStudyId,
                currentYearId: currentYearId,
                currentSemesterId: currentSemesterId
            },
            success: function(res){
                $scope.getAcademicStudy();
            }
        });          
    };
    
}]);
