'use strict';

app.controller('IhptServiceController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.ihptServices         = [];
    $scope.data                 = {};
    $scope.modalInstance = null;
    
    $scope.init = function(){
        Ajax.get({
            url: 'ihpt-service/get-ihpt-services',
            success: function(res){
                $scope.ihptServices  = res.ihptServices;
            },
        });
    };
    
    $scope.init();
           
        
    $scope.saveService = function(data){
        Ajax.post({
            url: 'ihpt-service/save-service',
            data: data ? data : $scope.ihptService,
            success: function(res){
                $scope.init();
                if ($scope.modalInstance){
                    $scope.modalInstance.close();
                }
            }
        });
    };
    
    $scope.openSaveServicePopup = function(data){
        var data = data ? data : {};
        var fn = [];
        fn = {
            saveService: $scope.saveService
        };
        $scope.modalInstance = Popup.open('adminsettings/addIhptService', $scope.saveService, data, fn);
    };
}]);
