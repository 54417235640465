'use strict';

app.directive('lbl', ['$timeout', '$compile', '$parse', 'Ajax', 'Utils', function ($timeout, $compile, $parse, Ajax, Utils) {
    return {
        restrict: 'AE',
        scope: {
            data: '=',
            prefix: '@',
            suffix: '@'
        },
        templateUrl: '/js/app/directives/lbl/tpl.html',
        link: function(scope, element, attrs) {
            scope.addClass          = attrs.addClass;
            scope.alternativeName   = attrs.alternativeName ? attrs.alternativeName : null;
        }
    };
}]);
