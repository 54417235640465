'use strict';

app.directive('fullCalendar', ['$rootScope', '$filter', 'Popup', 'Ajax', function ($rootScope, $filter, Popup, Ajax ) {
	return{
        restrict:	'AEC',
        scope:{
			calendarid: '='
		},
        transclude: false,
        templateUrl: '/js/app/directives/fullcalendar/tpl.html',
        link: (scope, element, attrs) => { 
			scope.authurl = attrs.authurl;
			
			scope.openPopup = function(data, revertFunc){
                let watch = [
                    {
                        myVar: 'data.allDay',
                        myFun: function(newValue, oldValue){
                            scope.hideTime(newValue);
                        }
                    }
                ];
                
                data = data ? data : {};
                scope.setEventListeners(revertFunc);
				Popup.open('calendar', scope.save, scope.prepareData(data), {remove_popupVar: scope.remove}, watch);
            };
			
			scope.setEventListeners = (revertFunc) => {
				$rootScope.$on('popup:cancel', () => {
					if( $.isFunction(revertFunc) ){
						revertFunc();
					}
					$('#calendar').fullCalendar('unselect');
				});				
			};
			
			scope.hideTime = (allday) => {
                if (allday) {
                    $( "[ng-model='displayTime']" ).hide();
                }else {
                    $( "[ng-model='displayTime']" ).show();
                }
            };
			
			scope.prepareData = (data) => {
				if( !angular.equals({}, data) ){
                    if (!data.end) {
                        data.end = data.start;
                    }
					data.calendar_id	= scope.calendarid;
					data.startDate		= $.fullCalendar.moment(data.start).format('YYYY-MM-DD') + ' ' + $.fullCalendar.moment(data.start).format('HH:mm:ss');
					data.endDate		= $.fullCalendar.moment(data.end).format('YYYY-MM-DD') + ' ' + $.fullCalendar.moment(data.end).format('HH:mm:ss');

					if(!data.end && data.allDay){
						data.endDate = $filter('date')(new Date(), 'YYYY-MM-DD');
					}

					if( data.end && !data.end.hasTime() ){
						data.allDay = true;
					}
				}else{
					var moment			= $('#calendar').fullCalendar('getDate');
					data.calendar_id	= scope.calendarid;
					data.startDate		= moment.format('YYYY-MM-DD') + ' ' + $.fullCalendar.moment(new Date()).format('HH:mm:ss');
					data.endDate		= moment.format('YYYY-MM-DD') + ' ' + $.fullCalendar.moment(new Date()).format('HH:mm:ss');
				}
				
				return data;
			};
			
			scope.refresh = () => {
				$('#calendar').fullCalendar('unselect');
				$('#calendar').fullCalendar( 'refetchEvents' );
			};
			
			scope.save = (popupScope, $modalInstance) => {
                Ajax.post({
					url: 'calendar/save',
					params: {id: popupScope.id ? popupScope.id : null},
					data:   popupScope,
					success: function(res){
						scope.refresh();
						$modalInstance.close();
					}
				});
			};
			
			scope.remove = (popupScope, $modalInstance) => {
				Ajax.post({
					url: 'calendar/remove',
					params: {id: popupScope.id},
					success: (res) => {
						scope.refresh();
						$modalInstance.close();
					}
				});
			};
			
			$('#calendar').fullCalendar({
				header: {
					left: 'prev,next today',
					center: 'title',
					right: 'month,agendaWeek,agendaDay,listWeek,'
				},
				defaultView:	'agendaDay',
				//minTime:        '08:00:00',
				scrollTime:		'06:00:00',
				contentHeight:	'auto',
				isRTL:			true,
				lang:			'he',
				navLinks:		true, // can click day/week names to navigate views
				editable:		true,
				eventLimit:		true, // allow "more" link when too many events
				locale:			'he',
				selectable:		true,
				selectHelper:	true,
				unselectAuto:	false,
				slotEventOverlap:false,

//					googleCalendarApiKey: 'AIzaSyDqKHITkvJUzfu1fC4Mmm4h0gh9zbcNVVg',
//					eventSources: [
//						{
//							url: '/dafchadash/calendar/get-events',
//							type: 'POST',
//						},
//						{
//							googleCalendarId: 'bender.moshe@gmail.com'
//						},
//					],

				events: {
					url: '/common/calendar/get-events',
					type: 'POST'
					//data: {calendarIds: scope.displayCalendarIds}
				},
				select: (start, end, jsEvent, view) => {
					scope.openPopup({
						start: start, 
						end: !end.hasTime() ? $.fullCalendar.moment(end).subtract(1, 'days') : end
					});
				},
				eventClick: (calEvent, jsEvent, view)  => {
					scope.openPopup(calEvent);

					return false;
				},
				eventResize: (calEvent, delta, revertFunc, jsEvent, ui, view) => { 
					scope.openPopup(calEvent, revertFunc);
				},
				eventDrop: (calEvent, delta, revertFunc, jsEvent, ui, view) => { 
					scope.openPopup(calEvent, revertFunc);
				}
				 /*eventRender: function(event, element) {
					 console.log(element);
//						element.qtip({
//							content: event.description
//						});
				}*/
			});
        }
    };
}]);