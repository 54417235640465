'use strict';

app.directive('committeeParticipants', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce, Popup) {
    return {
        restrict: 'AEC',
        scope: false,
        templateUrl: '/js/app/directives/committeeParticipants/committeeParticipants.html',
        link: function(scope, element, attrs) {
            
            scope.openSignaturePopup = function(data) {
                var fn = [];
                fn = {
                    sign: scope.sign
                };
                data.project_id = scope.committee.project_id;
                data.showCommitteeMessage = true;
                Popup.open('common/signature', {}, data, fn, {}, 'static', 'md');
            };

            scope.sign = function(user) {
                if (YT.mvc.module=='academy') {
                    scope.saveCommittee();
                }
                if (YT.mvc.module=='academy') {
                    if (scope.committee.lpi_id_committee_status != 47) {
                        scope.committee.lpi_id_committee_status = 47;
                    }
                    user.sign = 'yes';
                    scope.saveCommittee();
                }else{
                    Ajax.post({
                        module: 'common',
                        url: 'picklist/get-item-id-by-logic-id', 
                        params: {logicId: 15}, // 15 = ועדה חתומה
                        notShowMessage: true,
                        success: function(res) {
                            if (scope.committee.lpi_id_committee_status != res.data) {
                                //scope.committee.lpi_id_committee_status = res.data;
                            }
                            user.sign = 'yes';
                            scope.saveCommittee();
                        }
                    });
                }
            };

            scope.cancelSignature = (user) => {
                Ajax.post({
                    url: 'committee/cancel-signature', 
                    params: {userId: user.id, committeeId: scope.committee.id},
                    notShowMessage: true,
                    success: function(res) {
                        scope.saveCommittee();
                    }
                });
            };

            scope.getJSFormatDate = function(d) {
                return new Date(d);
            };

            scope.removeParticipants = function(user, index){
                if (confirm("פעולה זו תסיר את " + user.fname + " " + user.lname + " מהועדה, להמשיך?")){
                    Ajax.post({
                        module: YT.mvc.module,
                        url: 'committee/remove-participant',
                        params: {userId: user.id, committeeId: scope.committee.id},
                        success: function(res) {
                            scope.committee.participants.splice(index, 1);
                            scope.setAccountingReviewUser();
                        }
                    });
                }
            };
        }
    };
}]);