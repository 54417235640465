'use strict';

app.directive('editModelMap', ['Utils', 'Ajax', function (Utils, Ajax) {
    return{
        restrict: 'AEC',
       // replace: true,
        scope: false,
        transclude: false,
        templateUrl: '/js/app/partials/model/editMapTpl.html',
        link: function(scope, element, attrs) {
            if( Utils.GET().modelName ){
                Ajax.get({
                    module: 'common',
                    url: 'model/fields-map', 
                    params: {model: Utils.GET().modelName },
                    success: function(res) { 
                        scope.fieldsMap = res;
                    }
                });
            }

            scope.save = function(){
                Ajax.post({
                    module: 'common',
                    url: 'model/edit-fields-map', 
                    params: {model: Utils.GET().modelName },
                    data: {map: scope.fieldsMap},
                    success: function(res) { 
                        scope.fieldsMap = res.data.map;
                    }
                });
            };
            
        }
    };
}]);