'use strict';

app.directive('activityWorkplan', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            recordid:   '@',
            fieldname:  '=',
            logicid:    '@'
        },
        templateUrl: '/js/app/directives/activityWorkplan/activityWorkplan.html',
        link: function(scope, element, attrs) {
            scope.workplans = [];
            scope.activityPicklists = [];
            scope.current_user_id = YT.user.user_id;
            scope.milestoneTemplates = {};

            $timeout(function(){    
                scope.getRecordWorkplans();
                Ajax.get({
                    module: 'common',
                    url: 'activity-project/get-activity-project-types',
                    success: function(res){
                        scope.projectTypes = res.projectTypes;                        
                    }
                });
            },1000);
        
            Ajax.get({
                module: 'common',
                url: 'picklist/get-json-lookup',
                params: {settingName: 'MilestoneTemplates'},
                success: function(res){
                    scope.milestoneTemplates = res;
                }
            });

            scope.getRecordWorkplans = function() {
                Ajax.get({
                    module: 'common',
                    url: 'activity-milestone/get-record-workplans',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    success: function(res){
                        scope.workplans = res.workplans;                        
                    }
                });
            };

            

            scope.saveWorkplan = function(data, $modalInstance) {
                //data.order = scope.milestones.length;

                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/save-workplan',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    data: data,
                    success: function(res){
                        scope.getRecordWorkplans();
                        if ($modalInstance){
                            $modalInstance.close();
                        }
                    }
                });
            };

            scope.openWorkplanPopup = function($index,workplan){
                var data;
                if (workplan) {
                    data = workplan;
                }else{
                    data = $index!=undefined ? scope.workplans[$index] : {};
                }
                
                var fn = {
                    addRole: scope.addRole,
                    deleteWorkplan: scope.deleteWorkplan
                };
                
                data.projectTypes = scope.projectTypes; 
                Popup.open('common/activityWorkplan', scope.saveWorkplan, data, fn, {}, 'static');
            };


            scope.deleteWorkplan = function(data, $modalInstance){
                Ajax.post({
                    module: 'common',
                    url:    'activity-milestone/delete-workplan',
                    params: {id: data.id},
                    success: function(res){
                        scope.getRecordWorkplans();
                        $rootScope.$broadcast("popup:close", res);
                    }
                });
            };

            scope.createActivitiyProject = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/create-activity-project',
                    params: {milestoneIndex: $index, recordId: scope.recordid, logicId: scope.logicid, fieldName: scope.fieldname},
                    success: function(res){
                        scope.getRecordWorkplans();                    
                    },
                    successMessage: 'תוכנית העבודה נוספה בהצלחה'
                });
            };
        }
    };
}]);