'use strict';

app.directive('ehpatient', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
            objectid: '='
        },
        templateUrl: '/js/app/directives/ehPatient/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
                        
            scope.$on('ihpt:getIhptClientSuccess', function(e, res){
                scope.serviceException = res.serviceException;
            });
            
            scope.$on('ihpt:ihptClientChanged', function(e, res){
                scope.init();
            }); 
            
            scope.init = function(){
                Ajax.get({
                module: 'elhanefesh',
                url: 'patient/get-patient-details', 
                params: {id: scope.objectid},
                success: function(res) { 
                    scope.data = res.data;
                    if (scope.data.userPatient.professional_notes){
                        scope.data.userPatient.professional_notes = scope.data.userPatient.professional_notes.replace(/\n/g, "<br/>");
                    }
                    scope.$emit('ehpatient:getSuccess', 'view', res);
                        if(scope.data){
                            if(scope.data.birth_date){
                                    scope.data.age = Utils.getAge(scope.data.birth_date);
                            }
                            if (scope.data.tags)  {
                                    scope.tagsArr = scope.data.tags.split("|");
                            }
                        }
                    }
                });
            }
            
            scope.init();
            
            scope.save = function(){
                // this save function for saving tags in view mode
                Ajax.post({
                    module: 'elhanefesh',
                    url: 'patient/save', 
                    params: {id: scope.data.id},
                    data: scope.data,
                    success: function(res) { 
                        if(res.data.tags!=null){
                            scope.tagsArr = res.data.tags.split("|");
                            if(scope.tagsArr[scope.tagsArr.length-1]==''){
                                scope.tagsArr.splice(0,1);
                            }
                        }
                    }
                });
            };

            scope.openMailPopup = function(){
                mailMerge.openMailPopup(scope.data, 31); // 31 = patient logic id
            };
        }
    };
}]);
