'use strict';

app.controller('UserRegister', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    
    $scope.users        = [];
    $scope.projects     = [];
    $scope.userProjects = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'user-register/get-user-projects',
            success: function(res){
                $scope.users        = res.users;
                $scope.projects     = res.projects;
                $scope.userProjects = res.userProjects;
            },
        });
    };
    
    $scope.init();
    
    $scope.saveUserProject = function(uId, pId, isActive){
        Ajax.post({
            url: 'user-register/save-user-projects',
            params: {userId: uId, projectId: pId, isActive: isActive},
            success: function(res){
                
            },
        });
    }
    
       
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'user-register/create-new-user',
            data:   data,
            success: function(res){
                $scope.init();
                $scope.user = [];
                $modalInstance.close();
            }
        });
    };
    
    $scope.update = function(data, $modalInstance){
        Ajax.post({
            url: 'user-register/update-user',
            data:   data,
            success: function(res){
                $scope.init();
                $scope.user = [];
                $modalInstance.close();
            }
        });
    };
    
    $scope.setIsActive = function(id, isActive){
        Ajax.post({
            url: 'user-register/set-user-is-active',
            params: {id: id, isActive: isActive},
            success: function(res){
            }
        });
    };
        
    $scope.openUserRegisterPopup = function(user){
        var data = {};
        if (user){
           data.calendar    = user.calendar;
           data.id          = user.id;
           data.email       = user.username; 
           data.fname       = user.fname;  
           data.lname       = user.lname;  
           data.gender      = user.gender;  
           data.role_id     = user.role_id;  
        }
        var fn = {
            resetCalendarSyncData:  $scope.resetCalendarSyncData,
            syncGoogleCalendar:     $scope.syncGoogleCalendar
        }
        if (data.id){
            Popup.open('adminsettings/userRegister', $scope.update, data, fn);
        }else{
            Popup.open('adminsettings/userRegister', $scope.save, {});
        }
    };

    $scope.resetCalendarSyncData = function(caledar) {
        Ajax.post({
            url: 'user-register/reset-calendar-sync-data',
            data: caledar,
            success: function(res){
                //$modalInstance.close();
            }
        });
    }

    $scope.syncGoogleCalendar = function(googleCalendarId){
        Ajax.post({
            module: 'common',
            url: 'calendar/sync-google-calendar',
            params: {googleCalendarId: googleCalendarId},
            success: function(res){
                
            },
        });
    }
}]);