'use strict';

app.controller('committeeDiscussionController', ['$scope', '$rootScope', '$http', '$filter', '$modal', 'Ajax', 'Utils', 'Popup', function($scope, $rootScope, $http, $filter, $modal, Ajax, Utils, Popup) { 
    
    $scope.init = function(){
        $scope.orders = (Utils.getParameterByName('con')) ? JSON.parse(Utils.getParameterByName('con')) : [] ;
        $scope.orderName = 'lname';
    };
    
    $scope.init();
    
    $scope.apply = function(user_id){
        var id          = "?id=" + Utils.getParameterByName('id');
        var user_id     = "&user_id=" + user_id;
        var con         = $scope.orders.length > 0 ? "&con=" + JSON.stringify($scope.orders) : '';

        window.location.href = '/academy/committee/committee-discussion' + id + con;
    };
    
    $scope.goToNewStudent = function(direction){
        var new_user_id = (direction == 'next') ? $scope.nextUserId : $scope.prevUserId;
        var id          = "?id=" + Utils.getParameterByName('id');
        var user_id     = "&user_id=" + new_user_id;
        var con         = $scope.orders.length > 0 ? "&con=" + JSON.stringify($scope.orders) : '';

        window.location.href = '/academy/committee/committee-discussion' + id + user_id + con;
    };
    
    $scope.saveComments = function(mess){
        console.log(mess);
        alert(mess);
    };
    
    var fn = [];
    fn = {
        saveComments: function(requestId, userId, comment){
            Ajax.post({
                url:    'committee/save-comment',
                params: {requestId: requestId}, 
                data:   {comment: comment},
                success: function(res){
                    Utils.redirect('/academy/committee/committee-discussion?id=' + Utils.GET().id + '&userID=' + userId);
                }
            });
        }
    };
    
    $scope.ok = function(){
        
    };
    
    $scope.openCommentPopup = function(requestId, userId, nfc, nfcp){
        Popup.open('comment_popup_tpl', $scope.ok, {requestId: requestId, userId: userId, comment:{note_from_committee: nfc, note_from_committee_public: nfcp} }, fn);
    };
    
    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            Utils.redirect('/academy/committee/committee-discussion?id=' + Utils.GET().id + '&userID=' + data.userId);
        }
    });
    
    $scope.decision = function(status_id, request_id, user_id, nfc, nfcp){
        Ajax.post({
            url:    'committee/committee-decision',
            params: {request_id: request_id}, 
            data:   {lpi_id_status_commitee_decision: status_id},
            success: function(res){
                if (status_id == 41){
                    $scope.openCommentPopup(request_id, user_id, nfc, nfcp);
                    return;
                }
                Utils.redirect('/academy/committee/committee-discussion?id=' + Utils.GET().id + '&userID=' + user_id);
            }
        });
    };
    $scope.cancelDecision = function(request_id, user_id){
        Ajax.post({
            url:    'committee/cancel-decision',
            params: {request_id: request_id}, 
            success: function(res){
                Utils.redirect('/academy/committee/committee-discussion?id=' + Utils.GET().id + '&userID=' + user_id);
            }
        });
    };
    
    $scope.addOrder = function(orderName, orderType){
        var orderHeName = $( "#orderSelect option:selected" ).text();
              if (!$filter('filter')($scope.orders,{name:orderName})[0]){
                   $scope.orders.push({name: orderName, he_name: orderHeName, type: (orderType)?orderType:'ASC'});
               }
           };
    
    $scope.removeOrder = function(index){
        $scope.orders.splice(index, 1);
    };
}]);