'use strict';

app.controller('EhSupplierController', ['$scope', '$filter', 'Ajax', 'mailMerge', 'Utils', 'Popup', function($scope, $filter, Ajax, mailMerge, Utils, Popup) {
    $scope.data = {};
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/elhanefesh/supplier/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            $scope.getSupplierInvoices();
            $scope.getTherapistAttributes();
            $scope.getSupplierPricelist();
            $scope.getAttributesKeys();
            $scope.getRequestsCount();
            $scope.profileSrc   = res.data.profileSrc;
            
        }
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getForms = function(){
        Ajax.get({
            module: 'common',
            url: 'form/get-open-forms',
            success: function(res) {
                $scope.forms = res.forms;
            }
        });
        Ajax.get({
            module: 'common',
            url: 'form/get-user-submissions',
            params: {userId: Utils.GET().id},
            success: function(res) {
                $scope.formSubmissions  = res.formSubmissions;
            }
        });
    };

    $scope.getRequestsCount = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'supplier/get-requests-count', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.requestsCount    = res.requestsCount;
                $scope.groupsCount      = res.groupsCount;
                $scope.con              = res.con;
            }
        });
    };
    
    $scope.getAttributesKeys = function(){
        $scope.attributesKeys = [];
        Ajax.get({
            module: 'elhanefesh',
            url: 'therapist-attributes/get-attributes-keys', 
            success: function(res) { 
                $scope.attributesKeys = res.data;
            }
        });
    };
        
    $scope.getSupplierInvoices = function(){
        Ajax.get({
            module: 'elhanefesh',
            url: 'supplier/get-supplier-invoices', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.supplierInvoices = res.invoices;                
            }
        });
    };
    
    $scope.getTherapistAttributes = function(){
        Ajax.get({
            module: 'elhanefesh',
            url: 'supplier/get-therapist-attributes', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.therapistAttributes = res.attributes;
            }
        });
    };
    
    $scope.cancelAttribute = function(){
        //$scope.therapistAttributes.splice($index, 1);
    };
    
    $scope.removeAttribute = function($index){
        Ajax.post({
            module: 'elhanefesh',
            url: 'supplier/remove-therapist-attribute',
            params: {id: $scope.therapistAttributes[$index].id},
            success: function(res){
				$scope.getTherapistAttributes();
            }
        });
    };

    $scope.getValues = function(key){
        Ajax.get({
            module: 'elhanefesh',
            url: 'therapist-attributes/get-attributes-value-by-key', 
            params: {key: key},
            success: function(res) { 
                $scope.attributesValues = res.data;
            }
        });
    };

    $scope.saveAttribute = function(){
        var keyName = (!$( "#attr-key" ).val()) ? $( "#key-selected option:selected" ).text() : $( "#attr-key" ).val();
        var valName = (!$( "#attr-val" ).val()) ? $( "#val-selected option:selected" ).text() : $( "#attr-val" ).val();
        
        if((!keyName || keyName=='-בחר-') || (!valName || valName=='-בחר-')){
            alert('כדי להמשיך בשמירה, עליך לבחור מאפיין וערך');
            return;
        }
        
        if ($scope.therapistAttributes.length > 1){
            if ($scope.isAttributeExist(keyName,valName)){
                alert('מאפיין זה קיים כבר');
                return;
            }
        }
        var data = {};
        data.attr_key       = keyName;
        data.attr_value     = valName;
        Ajax.post({
            module: 'elhanefesh',
            url: 'supplier/save-therapist-attribute', 
            params: {userId: Utils.GET().id},
            data: data,
            success: function(res) {
                $scope.getTherapistAttributes();
                $scope.getAttributesKeys();
                //$modalInstance.close();
                $scope.showKeyInput     = false;
                $scope.showValueInput   = false;
                $scope.showAddAttribute = false;
                $( "#key-selected" ).val(null);
                $( "#val-selected" ).val(null);
            }
        });
    };
    
    $scope.getSupplierPricelist = function(){
        Ajax.get({
            module: 'elhanefesh',
            url: 'supplier/get-supplier-pricelist', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.supplierPricelist    = res.pricelist;
                $scope.supplierHmoPrice     = res.supplierHmoPrice;
            }
        });
    };
    
    $scope.cancelPricelist = function($index){
        $scope.supplierPricelist.splice($index, 1);
    };
    
    $scope.cancelSupplierHmoPrice = function($index){
        if ($scope.supplierHmoPrice[$index].id)
            return;
        $scope.supplierHmoPrice.splice($index, 1);
    };
    
    $scope.removePricelist = function($index){
        Ajax.post({
			module: 'elhanefesh',
            url: 'supplier/remove-supplier-pricelist',
            params: {id: $scope.supplierPricelist[$index].id},
            success: function(res){
				$scope.getSupplierPricelist();
            }
        });
    };
    
    $scope.addPricelist = function(){
      $scope.supplierPricelist.push( {program_id: '', organization_id: '', treatment_cost: '', edit: true}  );  
    };
    
    $scope.addSupplierHmoPrice = function(){
      $scope.supplierHmoPrice.push( {treatment_cost: '', hmo_cost: '', patient_cost: '', edit: true, is_active: '1'}  );  
    };
    
    $scope.savePricelist = function($index){
        if ($scope.supplierPricelist.length > 1){
            if ($scope.isPricelistExist()){
                alert('מחירון זה קיים כבר');
                return;
            }
        }
        Ajax.post({
            module: 'elhanefesh',
            url: 'supplier/save-supplier-pricelist', 
            params: {userId: Utils.GET().id},
            data: $scope.supplierPricelist[$index],
            success: function(res) {
                $scope.getSupplierPricelist();
            }
        });
    };
    
    $scope.saveSupplierHmoPrice = function($index){
        if ($scope.supplierHmoPrice.length > 1){
            if ($scope.isSupplierHmoPriceExist()){
                alert('הסדר זה קיים כבר');
                return;
            }
        }
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'supplier/save-supplier-hmo-price', 
            params: {userId: Utils.GET().id},
            data: $scope.supplierHmoPrice[$index],
            success: function(res) {
                $scope.getSupplierPricelist();
            }
        });
    };
    
    $scope.getTreatmentsLength = function(arr){
        return arr.length;
    };
    
    $scope.isPricelistExist = function(){
        var tType = $scope.supplierPricelist[$scope.supplierPricelist.length-1].program_id;
        var orgId = $scope.supplierPricelist[$scope.supplierPricelist.length-1].organization_id;
        if (orgId == ""){
            if ($filter('filter')($scope.supplierPricelist,{program_id:tType,organization_id:null}).length > 0){
                return true;
            }
        }else{
            if ($filter('filter')($scope.supplierPricelist,{program_id:tType,organization_id:orgId}).length > 1){
                return true;
            }
        }
        return false;
    };
    
    $scope.isSupplierHmoPriceExist = function(){
        var tType = $scope.supplierHmoPrice[$scope.supplierHmoPrice.length-1].program_id;
        var hmoId = $scope.supplierHmoPrice[$scope.supplierHmoPrice.length-1].lpi_id_hmo;
        
        if ($filter('filter')($scope.supplierHmoPrice,{program_id:tType,lpi_id_hmo:hmoId}).length > 1){
            return true;
        }

        return false;
    };
    
    $scope.isAttributeExist = function(key,val){
        return $filter('filter')($scope.therapistAttributes,{attr_key:key,attr_value:val}).length > 0;
    };
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data, 30); // 30 = supplier logic id
    };
	
	$scope.createUserLogin = function(){
		if( !$scope.data.userSupplier || !$scope.data.userSupplier.email){
			alert('חובה להזין כתובת אימייל לפני יצירת פרטי לוגין');
			return;
		}
		
        Ajax.post({
			module: 'elhanefesh',
            url: 'supplier/create-user-object-login', 
            params: {user_id: Utils.GET().id, email: $scope.data.userSupplier.email},
            success: function(res) { 
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.resetPassword = function(){
        if(!confirm('האם אתה בטוח שברצונך לאפס את הסיסמה?')){
            return;
        }
        
        Ajax.post({
            url: 'supplier/reset-password',
            data: $scope.data,
            success: function(res){
                // do something
            },
            error: function(res){
                //alert('res');
            }
        });
    };
    
}]);