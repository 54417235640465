'use strict';

var paymentModalInstanceController = function($scope, $filter, $modalInstance, $modal, Ajax, Utils, payment, mod){
    
    $scope.payment  = payment;
    
    if(mod !== 'edit') {
        $scope.$watch('payment.expected_date', function(newValue, oldValue) {
            if(newValue){
                $scope.payment.actual_date = newValue;
            }
        });
    }
    
    Ajax.get({
        url: 'payment/get-payment-picklists',
        success: function(res){
            if(!$scope.payment.lpi_id_accounting_status){
                $scope.payment.lpi_id_accounting_status = 57;
            }
            
            $scope.picklists = res.picklists;
        }
    });
    
    $scope.ok = function () {
        Ajax.post({
           url: 'payment/save',
           params: {id: $scope.payment.id ? $scope.payment.id : null},
           data: $scope.payment,
           success: function(res){
               $modalInstance.close(res);
           }
        });
        
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    
    $scope.setActualAmount = function()
    {
        $scope.payment.actual_amount = $scope.payment.expected_amount;
    };
    $scope.setActualDate = function()
    {
        $scope.payment.actual_date = $scope.payment.expected_date;
    };
};


app.directive('paymentGrid', ['$modal', '$filter', 'Ajax', 'Utils',  function ($modal, $filter, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: false,
        transclude: false,
        templateUrl: function(elem, attrs) {
            return '/js/app/partials/' + attrs.templateurl + '.html' + window.YT.project.version;
        },
        link: function(scope, element, attrs) {
            scope.$on("paymentGrid:load", function(e, msg) {
                scope.init();
            });
            
            scope.init = function(){
                Ajax.get({
                    url: 'payment/' + attrs.geturl,
                    params: {id: Utils.GET().id},
                    success: function(res){
                        scope.payments = res.payments;
                        if(res.picklists){
                            scope.picklists.templatePicklists = res.picklists.paymnetTemplates;
                        }
                    }
                });
            };
            
            scope.getBudgetBalance = function(budget_id, amount){
                if(!amount || !budget_id){
                    return '';
                }
                
                var payments = $filter('filter')(scope.payments, {budget_request_id: budget_id});
                var total = 0;

                if(!payments){
                    return amount;
                }

                $.each(payments, function(k, v) {
                    total += parseFloat(v.actual_amount);
                });

                return amount - total;
            };
            
            scope.checkPermission = function(budgetRequest){                                
                
                if(!budgetRequest.request || !budgetRequest.request.commitee || budgetRequest.request.commitee.lpi_id_committee_status != 47){
                    return true;
                }
                
                if(budgetRequest.request.commitee.lpi_id_committee_status == 47 && (scope.globals.user.role == 'finance' || scope.globals.user.role == 'admin')){
                    return true;
                }
                
                return false;
            };
            
            scope.addPayment = function(br){
                var payment = {
                    budgetRequest: br,
                    budget_request_id: br.id,
                    user_id_content:   scope.globals.user.user_id,
                    date_content:      $filter('date')(new Date(), 'yyyy-MM-dd'),
                    lpi_id_content_status: 52,
                    lpi_id_accounting_status: 57,
                    funding_percent: 100.0,
                    payment_reciver: 'beneficiary',
                    payment_type: 'wiretransfer'
                };
                
                scope.openPaymentPopup(payment, 'add');
            };
            
            scope.editPayment = function(payment_id){
                var payment = $filter('filter')(scope.payments, {id: payment_id})[0];
                scope.openPaymentPopup(payment, 'edit');
            };
            
            scope.getPopupTemplateName = function(payment){
                
                if(scope.globals.user.role == 'admin'){ // fulle edit for admin
                    return 'fullEdit';
                }
                
                if( !payment.budgetRequest.request.commitee || payment.budgetRequest.request.commitee.lpi_id_committee_status != 47){ // before commitee decision
                    return 'fullEdit';
                }
                
                if( payment.budgetRequest.request.commitee.lpi_id_committee_status == 47 && scope.globals.user.role == 'finance'){ //finance after commitee 
                    return 'afterCommitteeAccounting';
                }
                
                if( payment.budgetRequest.request.commitee.lpi_id_committee_status == 47 && (scope.globals.user.role == 'contentManager' || scope.globals.user.role == 'content') ){ //content after commitee 
                    return (payment.lpi_id_accounting_status == 57 || !payment.lpi_id_accounting_status) ? 'afterCommitteeContent' : 'view'; // paid or not paid
                }
                
                return 'view';
            };
            
            scope.openPaymentPopup = function(payment, mod){
                var modalInstance = $modal.open({
                    templateUrl: '/js/app/partials/paymentPopup/' + (mod === 'edit' ? scope.getPopupTemplateName(payment) : 'fullEdit') + '.html',
                    controller: paymentModalInstanceController,
                    size: 'lg',
                    resolve: {
                        payment: function () {
                            return payment;
                        },
                        mod: function(){
                            return mod;
                        }
                    }
                });

                modalInstance.result.then(function (res) {
                    if ( !angular.isUndefined(scope.getBudgetTab) ) {
                        scope.getBudgetTab();
                    }else{
                        scope.$broadcast("paymentTab:refresh");
                    }
                    
                }, function () {});
            };
            
            scope.deletePayment = function(id){
                Ajax.post({
                    url: 'payment/delete-payment',
                    params: {id: id, requestId: Utils.GET().id},
                    notShowMessage: true,
                    success: function(res){
                        Utils.showNoty('התשלום נמחק בהצלחה');
                        scope.init();
                    }
                });
            };
            
            scope.createPayments = function(){
                if (!scope.payment_template_id){
                    Utils.showNoty('עליך לבחור תבנית תשלומים לפני פעולה זו.', 'error', 3000);
                }else{
                    Ajax.post({
                        url: 'payment-template/create-payments-for-budget',
                        notShowMessage: true,
                        params: {templateId: scope.payment_template_id, requestId: Utils.GET().id},
                        success: function(res){
                            // check if any payment was created, if yes show success message
                            if (res.counter_created_payments>0){
                                Utils.showNoty('הנתונים נשמרו בהצלחה');
                            }
                            
                            if (res.message !== 'success'){
                                Utils.showNoty(res.message, 'error', 4000);
                            }
                            scope.init();
                        }
                    });
                }
            };
        }
    };
}]);