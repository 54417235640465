'use strict';

app.controller('FormResultViewController', ['$scope', '$filter',  'Ajax', 'Utils', '$rootScope', function($scope, $filter, Ajax, Utils, $rootScope) { 
       
    $scope.init = function() {
        Ajax.get({
            module: 'common',
            url: 'form/get-result-views', 
            params: {
                recordId: Utils.GET().recordId,
                fieldName: Utils.GET().fieldName,
                formResultViewId: Utils.GET().formResultViewId
            },
            
            success: function(res) {
                $scope.result = res.resultView;
                //document.dispatchEvent(window.globalEve);
            }
        });
     };

     $scope.init();

     $scope.getInt = function(str){
        return parseInt(str);
     };
}]);