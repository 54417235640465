'use strict';

app.directive('activityComments', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: { 
			activityId: '@'
		},
        templateUrl: '/js/app/directives/activityComments/activityCommentsTpl.html',
        link: function(scope, element, attrs) {
            scope.activity = {};
            scope.comments = {};
            
            scope.initComments = function(){ 
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-activity-comments',
                    params: {id: scope.activityId},
                    success: function(res){
                       scope.activity = res.activity;
                       scope.comments = res.activity.mergingCommentsTracking;
                    }
                });
            };
            
            scope.initComments();
            
            scope.$on('activity:showComments'+scope.activityId, function(event, res) {
                scope.initComments();
            });
            
            scope.saveComment = function(){
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity-comment',
                    params: {id: scope.activityId},
                    data:   scope.activityComment,
                    success: function(res){
                        scope.activityComment = {};
                        scope.comments = res.current_activity.mergingCommentsTracking;
                    }
                });
            };
            
            scope.getDate = function(d){
                moment.locale('he');
                return moment(d).calendar().includes('יום')||moment(d).calendar().includes('אתמול') ? ' ' + moment(d).calendar() : ' ב ' + moment(d).calendar();
            }
        }
    };
}]);