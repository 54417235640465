'use strict';

app.directive('generalSearch', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: true,
        templateUrl: '/js/app/directives/generalSearch/generalSearchTpl.html',
        link: function(scope, element, attrs) {
            scope.grids = [];
            scope.gridId = null;
            scope.newItem = null;

            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'settings/warnings-list', 
                    params: {id: 9},
                    success: function(res) {
                        if (res[YT.user.project_id]){
                            scope.grids = res[YT.user.project_id];
                            scope.setSearchDetailsIfExist();
                        }
                    }
                });
            };
            
            scope.init();
            
            scope.setSearchDetailsIfExist = function(){
                scope.generalSearchText = Utils.GET().sft;
                
                angular.forEach(scope.grids, function(item, key) {
                    if(item.gridId == Utils.GET().id) { 
                        scope.gridId = item.gridId;
                    }
                });
                
                if(window.location.href.includes('list/view') && window.location.href.includes('sft')){
                    scope.gridId = Utils.GET().id;
                }
                
                if (!scope.gridId){
                    scope.gridId = scope.grids[0].gridId;
                }
            };
            
            scope.keyUp = function($event){
                if ($event.which == 13){ // only if "Enter" key was pressed
                    scope.search();
                }
            }
            
            scope.search = function(){
                if (scope.gridId){
                    if (scope.generalSearchText){
                        Utils.redirect('/common/list/view?id=' + scope.gridId + '&sft=' + scope.generalSearchText);
                    }else if(Utils.GET().sft){ // 
                        Utils.redirect('/common/list/view?id=' + scope.gridId);
                    }
                }
            };
            
            scope.$watch('gridId', function(newValue, oldValue) {
//                if (oldValue && oldValue != newValue && Utils.GET().id != scope.gridId) {
//                    scope.generalSearchText = '';
//                }else if(Utils.GET().id == scope.gridId){
//                    scope.generalSearchText = Utils.GET().sft;
//                }
            });

            scope.$watch('newItemLink', function(newValue, oldValue) {
               if (newValue) {
                    Utils.redirect(newValue);
               }
            });
        }
    };
}]);