'use strict';

app.controller('YtcrmReportController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    
    $scope.getUsersReport = () => {
        Ajax.post({
            module: 'ytcrm',
            url: 'report/get-report-details',
            data: {filterDates: $scope.filterDates},
            success: function(res) { 
                $scope.usersReport = res.data;
            }
        });
    };

    $scope.init = function(){
        $scope.getUsersReport();
    };
        
    $scope.init();
}]);