'use strict';

app.directive('timeLineEditDoc', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            fc: '@' // file conneciton data
        },
        templateUrl: '/js/app/directives/timeLineEditDoc/timeLineEditDocTpl.html',
        link: function(scope, element, attrs) {
            scope.fileData = angular.fromJson(scope.fc);
            
            scope.newRecordId       = null;
            scope.newObjectLogicId  = null;
            //scope.fileData      = {access_action: 'private', is_required: 'no'};
            scope.recordid = Utils.GET().id;
            scope.logicid = 54;
            
            scope.$on("docs:refresh", function(e, msg) {
                scope.init();
            });
            
            scope.$watch('recordid', function(newData) {
                if(newData){
                    scope.init();
                }
            });
            
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.init = function(){
                // if object id not include with directive, set it do default 1 = student(not in use, I send logicid everywhere)
                scope.logicid = scope.logicid || 1;
                scope.newRecordId       = null;
                scope.newObjectLogicId  = null;
                scope.projectId        = YT.user.project_id;
            };
            
            scope.addNewFileClick = function(){
                $timeout(function(){
                    $("#uploadNewFile").trigger('click');
                    $("#uploadNewFile").value = null;
                });
            };
            
            scope.save = function(){
                var file    = $("#uploadNewFile")[0].files[0];

                if( file ){ // user upload file
                    var isProfilePic    = false;
                    var fd              = new FormData();
                    
                    fd.append('file', file);
                    
                    if( scope.currentIndex !== null ){
                        scope.fileData.file_connection_id = scope.docs[scope.currentIndex].id;
                    }
                    if(scope.currentIndex !== null && scope.docs[scope.currentIndex].friendly_name == 'תמונת פספורט'){
                        scope.fileData.isProfilePic = true;
                        isProfilePic = true;
                        
                        var ext = $("#uploadNewFile").val().match(/\.(.+)$/)[1];

                        if ( !ext.match(/(?:gif|GIF|jpg|JPG|jpeg|JPEG|png|PNG|bmp|BMP)$/) ) {
                            alert('פספורט חייב להיות מסוג תמונה');
                            return;
                        }
                    }
                    
                    if( !scope.fileData.friendly_name || scope.fileData.friendly_name == ''){
                        scope.fileData.friendly_name = file.name.replace(/\.[^.$]+$/, '');
                    }
                    
                    fd.append('fileData',  JSON.stringify(scope.fileData));

                    
                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload?id=' + scope.recordid + '&logicId=' + scope.logicid,
                        fd: fd,
                        success: function(res){
                            $("#uploadNewFile").val(''); // clear the file input
                            
                            scope.docs = res.docs;
                            scope.showList();
                            $rootScope.$broadcast('item:fileupload',true);
                            if(isProfilePic){
                                scope.$parent.$broadcast("docs:changeProfilePic");
                            }
                        }
                    });
                }else if( scope.fileData.id ){ // user update file connection
                    
                    if(!scope.fileData.friendly_name){
                        alert('אנא בחר שם קובץ');
                        return;
                    }
                    
                    Ajax.post({
                        module: 'common',
                        url:    'file/update-file-connection',
                        params: {id: scope.fileData.id, logicId: scope.logicid},
                        data:   scope.fileData,
                        success: function(res){
                            scope.docs = res.docs;
                            //$rootScope.$broadcast('item:fileupdated',scope.fileData.created_at);
                            scope.editDocMode = false;
                        }
                    });
                }
            };
    
            scope.deleteFile = function(doc){
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-file',
                    params: {file_connection_id: doc.id},
                    success: function(res){
                        scope.docs = res.docs;
                    },
                    successMessage: 'הקובץ נמחק בהצלחה'
                });
            };
                        
            scope.deleteRecord = function(index){
                if(scope.docs[index].friendly_name === 'תמונת פספורט'){
                    return;
                }
                
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-record',
                    params: {fileConnectionId: scope.docs[index].id},
                    success: function(res){
                        scope.docs = res.docs;
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };
            
            scope.getJSFormatDate = function(d){
                return Utils.getJSFormatDate(d);
            };
            
                                    
            scope.addNewFileConnection = function(newRecordId, newObjectLogicId) {
                if (!newRecordId || !newObjectLogicId) {
                    alert('על מנת להוסיף קישור לקובץ, עליך לבחור אובייקט ויעד לקישור')
                    return;
                }
                
                if (newRecordId != scope.recordid || newObjectLogicId != scope.logicid) {
                    Ajax.post({
                        module: 'common',
                        url:    'file/add-file-connection',
                        params: {recordId: newRecordId, logicId: newObjectLogicId},
                        data:   scope.fileData,
                        success: function(res){
                            scope.init();
                            //scope.showList();
                        }
                    });
                }else {
                    alert('קישור המסמך קיים כבר');
                }
            };
            
            scope.deleteFileConnection = function(fileConnection){
//                if(fileConnection.friendly_name === 'תמונת פספורט'){
//                    return;
//                }
//                
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-file-connection',
                    params: {fileConnectionId: fileConnection.id},
                    success: function(res){
                        scope.init();
                        //scope.showList();                        
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };
        }
    };
}]);