app.directive('ngFileModel', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var model = $parse(attrs.ngFileModel);
            var isMultiple = attrs.multiple;
            var modelSetter = model.assign;
            var values = [];
            element.bind('change', function () {
                
                angular.forEach(element[0].files, function (item) {
                    var value = {
                       // File Name 
                        name: item.name,
                        //File Size 
                        size: item.size,
                        //File URL to view 
                        url: URL.createObjectURL(item),
                        // File Input Value 
                        _file: item
                    };
                    values.push(value);
                });
                scope.$apply(function () {
                    if (isMultiple) {
                        modelSetter(scope, values);
                    } else {
                        modelSetter(scope, values[0]);
                    }
                });
            });
            scope.myClick = function(){
                $timeout(function() {
                $("#myFile").trigger('click');
                }, 0);
            }
            
            scope.removeFile = function($index){
                scope.data.emailTemplate.newFiles.splice($index, 1);
            };
        }
    };
}]);