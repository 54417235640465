'use strict';

app.controller('MainController', ['$scope', 'Ajax', 'Xnavigation', 'PageContentOnresize','PortalActiveNavigation', function($scope, Ajax, Xnavigation, PageContentOnresize, PortalActiveNavigation) {
    angular.element(document).ready(function () {  

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    
            ga('create', 'UA-25945516-8', 'auto');
            ga('send', 'pageview');

        $scope.$watch('globals', function(newValue) {
            if($scope.globals.mvc.module == 'academyportal'){
                $scope.profileSrc = $scope.globals.user.profileSrc;
                PortalActiveNavigation.run();
            }else if(newValue && $scope.globals.mvc.module !=='academyportal'){
                Xnavigation.run();
                PageContentOnresize.run();
            }


            $("input[type=text]").keydown(function( event ) {
                $scope.preventCurlyBraces(event);
            });
            $("textarea").keydown(function( event ) {
                $scope.preventCurlyBraces(event);
            });
            if (YT.user.project_id) {
                Ajax.turnOnValidation();
            }
            
            
        });

        $scope.getJSFormatDate = function(d) {
            return new Date(d.replace(/-/g, '/'));
        };


        $scope.preventCurlyBraces = function(event){
            if ( event.shiftKey && (event.key === '{' || event.key === '}') ) {
                event.preventDefault();
            }
        };
    });   
}]);

