'use strict';

app.directive('treatmentSymptoms', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
            mode: '=mode',
            type: '=type'
        },
        templateUrl: '/js/app/directives/treatmentSymptoms/treatmentSymptoms.html',
        link: function(scope, element, attrs) {
            scope.failedToSave = false;
            scope.noGoals = false;
            scope.newSymptom = {};

            scope.init = function() {
                scope.data = angular.fromJson(scope.data);
                scope.role = YT.user.role;
                Ajax.get({
                    module: 'common',
                    url: 'request/get-symptom-categories', 
                    success: function(res) { 
                        scope.symptomCategories = res.symptomCategories;
                    }
                });
                scope.data.personalSymptoms.forEach( (personalSymp) => {
                    let symptom = scope.data.symptoms.filter( (symp) => {return symp.description == personalSymp.name});
                    if (symptom.length > 0){
                        personalSymp.ischecked = 'true';
                        personalSymp.id = symptom[0].id;
                    }
                });
            }
            
            scope.init();

            
        
            scope.addSymptom = function(newSymptom){
                if (scope.data.request){
                    newSymptom.request_id = scope.data.request.id;     
                }else{
                    newSymptom.request_id = scope.data.id;
                }
                
                scope.data.symptoms.push(JSON.parse(JSON.stringify(newSymptom)));
                newSymptom = {};
            };
        
            scope.deleteSymptom = function($index, dbDelete){
                if(YT.user.role == 'admin' && dbDelete && confirm("הצילו! מוחקים אותי! להמשיך? בטוח?") ){
                    Ajax.post({
                        module: 'common',
                        url: 'request/delete-symptom',
                        data: scope.data.symptoms[$index],
                        success: function(res) { 
                            //
                        }
                    });
                }
                
                if (scope.data.symptoms[$index].id){
                    scope.data.deletedSymptoms.push(scope.data.symptoms[$index]);
                }
                scope.data.symptoms.splice($index,1);
            };

            scope.clickPersonalSymptom = (item) => {
                item.ischecked = item.ischecked == 'false' ? 'true' : 'false';
                if (item.ischecked == 'true') {
                    let newSymptom = {description: item.name, lpi_id_type: '953'};
                    scope.addSymptom(newSymptom);
                } else {
                    let index = scope.data.symptoms.findIndex(s => {
                        return s.description === item.name;
                    });
                    scope.deleteSymptom(index);
                }
            };
        }
    };
}]);