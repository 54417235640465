'use strict';

app.controller('EhOrganizationController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    $scope.tagsArr = [];
    $scope.communityTags = [];
    $scope.data = {};
    
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            if ($scope.data.community_association)  {
                $scope.communityTags = $scope.data.community_association.split(",");
            }
            $scope.getPatientsCount();
            $scope.getBudgetReviewLines();
            $scope.getQuota();
            $scope.getProfessionalDiscussions();
        }
        $scope.tagsToArr();
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod == 'add'){
            Utils.redirect('/elhanefesh/organization/view?id=' + res.data.id);
            $scope.tagsToArr();
        //}
    });

    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_house_num = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
        
    $scope.$watch('selected_community', function(newValue, oldValue) {
        if (newValue) {
            var newValueText = $( "#org-community-list option:selected" ).text();
            if( !$scope.isCommunityTagExist(newValueText) && newValueText != '-בחר-' ) {
                $scope.communityTags.push(newValueText);
                $scope.data.community_association = $scope.communityTags.join();
            }
        }
    });
    
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
    
    $scope.removeTag = function($index){
        $scope.communityTags.splice($index, 1);
        $scope.data.community_association = $scope.communityTags.join();
    };
    
    $scope.isCommunityTagExist = function(valueName){
        if ($filter('filter')($scope.communityTags,valueName)[0]){
            return true;
        }
        return false;
    };
    
    $scope.getPatientsCount = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'organization/get-number-of-patients', 
            params: {id: Utils.GET().id, orgId: $scope.data.id},
            success: function(res){
                $scope.patientsCount    = res.patientsCount;
                $scope.activeRequests   = res.activeRequests;
                $scope.activeGroups     = res.activeGroups;
                $scope.con              = res.con;
            }
        });
    };
    
    $scope.getBudgetReviewLines = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'organization/get-budget-review-lines', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.budgetReviewLines = res.budgetReviewLines;
            }
        });
    };

    $scope.getQuota = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'organization/get-quota', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.quota = res.quota;
                $scope.quota.forEach((e) => {
                    e.taken_amount = e.taken_amount | 0.00;
                    e.usage = e.usage | 0.00;
                    e.total = parseFloat(e.usage + e.taken_amount);
                });
            }
        });
    };

    $scope.getProfessionalDiscussions = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'organization/get-professional-discussions',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.professionalDiscussions = res.professionalDiscussions;
            }
        });
    };

    $scope.saveQuota = function(data, $modalInstance){
        Ajax.post({
            module: 'elhanefesh',
            url:    'organization/save-quota', 
            data: data,
            success: function(res){
                $scope.getQuota();
                if ($modalInstance){
                    $modalInstance.close();
                }
            }
        });
    };

    $scope.openQuotaPopup = (data) => {
        if (!data){
            data = {organization_id: Utils.GET().id};
        }
        Popup.open('elhanefesh/addQuota', $scope.saveQuota, (data) ? data : {}, {});
    };

    $scope.saveDiscussion = function(data, $modalInstance){
        Ajax.post({
            module: 'elhanefesh',
            url:    'organization/save-professional-discussion', 
            data: data,
            success: function(res){
                $scope.getProfessionalDiscussions();
                if ($modalInstance){
                    $modalInstance.close();
                }
            }
        });
    };

    $scope.openDiscussionPopup = (data) => {
        if (!data){
            data = {organization_id: Utils.GET().id};
        }
        if (!data.discussion_date){
            data.discussion_date = $scope.todayDateSqlFormat();
        }
        Popup.open('elhanefesh/addProfessionalDiscussion', $scope.saveDiscussion, (data) ? data : {}, {});
    };
    
    $scope.todayDateSqlFormat = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    };
}]);