'use strict';

app.directive('womanCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl:'/js/app/directives/WomanCard/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
            
            scope.$on('ihpt:getIhptClientSuccess', function(e, res){
                scope.serviceException = res.serviceException;
            });
            
            scope.$on('ihpt:ihptClientChanged', function(e, res){
                scope.init();
            }); 
            
            scope.init = function(){
                Ajax.get({
                    module: 'dafchadash',
                    url: 'woman/get-woman-details',
                    params: {id: scope.objectid},
                    success: function(res) { 
                        scope.obj = res.data;
                        scope.$emit('woman:getSuccess', 'view', res);

                        if(scope.obj){
                            if(scope.obj.birth_date){
                                scope.obj.age = Utils.getAge(scope.obj.birth_date);
                            }
                            if (scope.obj.tags)  {
                                scope.tagsArr = scope.obj.tags.split("|");
                            }
                        }
                    }
                });
            };
            scope.init();
            
            scope.$on('woman:measureUpdate', function(e){
                scope.init();
            });
            
            scope.openMailPopup = function(){
                mailMerge.openMailPopup(scope.obj, 38); // 30 = woman
            };
		}
    };
}]);
