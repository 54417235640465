'use strict';

app.controller('EhBudgetReviewController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    
    $scope.data = {};
    $scope.newLine = {};
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            
            $scope.getBudgetReviewLines();
        }
    });
    
    $scope.getBudgetReviewLines = function(){
        Ajax.get({
            module: 'common',
            url:    'budget-review/get-budget-review-lines', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.budgetReviewLines    = res.budgetReviewLines;
                $(".knob").knob();
            }
        });
    };
    
//    $scope.saveLine = function(){
//        Ajax.get({
//            module: 'common',
//            url:    'budget-review/get-budget-review-lines', 
//            params: {id: Utils.GET().id},
//            success: function(res){
//                $scope.budgetReviewLines    = res.budgetReviewLines;
//                $(".knob").knob();
//            }
//        });
//    };
    
    $scope.saveLine = function($index){
        Ajax.post({
            module: 'common',
            url: 'budget-review-line/save', 
            data: $scope.budgetReviewLines[$index],
            success: function(res) {
                $scope.getBudgetReviewLines();
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.cancelNewLine = function(){
        $scope.newLine = {};
    }
    
    $scope.saveNewLine = function(){
//        if ($scope.supplierPricelist.length > 1){
//            if ($scope.isPricelistExist()){
//                alert('מחירון זה קיים כבר');
//                return;
//            }
//        }
        $scope.newLine.budget_review_id = Utils.GET().id;
        Ajax.post({
            module: 'common',
            url: 'budget-review-line/save', 
            data: $scope.newLine,
            success: function(res) {
                $scope.getBudgetReviewLines();
                $scope.$broadcast("cru:refresh");
                $scope.newLine = {};
            }
        });
    };
    
}]);