'use strict';

app.controller('YtcrmActivityProjectController', ['$rootScope', '$scope', '$filter', 'Ajax', 'Utils', 'Popup', '$sce', function($rootScope, $scope, $filter, Ajax, Utils, Popup, $sce) {
    
    $scope.tagsArr = [];
    $scope.data = {};
    $scope.showLoadFrame = true;

    $scope.projectlist = function(){
        Ajax.get({
            url: 'activity-project/get-activity-project-list',
            params: {activityProjectId: Utils.GET().id},
            success: function(res){
                $scope.projectlist = res.list;
                $scope.projectArchiveList = res.archiveList;
            }
        });
    }

    $scope.$on('cru:getSuccess', function(e, mod, res){
        //$scope.tagsToArr();
        for (var i = 0; $scope.picklists.allUsers && i < $scope.picklists.allUsers.length; i++) {
            $scope.$watch('picklists.allUsers[' + i + '].lpi_id_title', function (newValue, oldValue) {
                let currentIndex = this.exp.substring(
                    this.exp.lastIndexOf("[") + 1, 
                    this.exp.lastIndexOf("]")
                );
                if (newValue && newValue!=oldValue){
                    alert($scope.picklists.allUsers[currentIndex].lpi_id_title);
                }
            });
        }
        $scope.data.is_active = $scope.data.is_active ? $scope.data.is_active : 1;
        if (Utils.GET().id){
            $scope.getEvents();
        }
    });
    
    $scope.getEvents = function(){
        Ajax.get({
            module: 'common',
            url: 'calendar/get-activity-project-events',
            params: {activityProjectId: Utils.GET().id},
            success: function(res){
                $scope.data.calendarEvents = res.calendarEvents;
                $scope.showLoadFrame = false;
            }
        });
    }

    $scope.$on('cru:postSuccess', function(e, mod, res){
//        if(window.location.href.includes('view')){
//            $scope.tagsToArr();
//            return;
//        }
        Utils.redirect('/ytcrm/activity-project/view?id=' + res.data.id);
    });
    
    $scope.addMember = function(){
      $scope.data.members.push( {user:null, lpi_id_title: '', edit: true}  );  
    };
    
    $scope.cancelMember = function($index){
        if ($scope.data.members && $scope.data.members[$index].id)
            return;
        $scope.data.members.splice($index, 1);
    };
    
    $scope.saveMember = function($index){
        if ($scope.data.members.length > 1){
            if ($scope.isMemberExist($scope.data.members[$index].user.id)){
                alert('משתתף זה קיים');
                return;
            }
        }
        Ajax.post({
            module: 'ytcrm',
            url: 'activity-project/save-member', 
            data: {
                user_id:                $scope.data.members[$index].user.id,
                activity_project_id:    Utils.GET().id,
                lpi_id_title:           $scope.data.members[$index].lpi_id_title
            },
            success: function(res) {
                $scope.getMembers();
            }
        });
    };
    
    $scope.getMembers = function(){
        Ajax.get({
            url: 'activity-project/get-members',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.data.members = res.members;
            }
        });
    };
    
    $scope.isMemberExist = function(userId){
        if ($filter('filter')($scope.data.members,{user_id:userId}).length > 0){
            return true;
        }
        return false;
    };
    
    $scope.removeMember = function($index){
        if ($scope.data.members.length == 1){
            alert('אי אפשר למחוק את החבר היחיד בקבוצה');
            return;
        }
        Ajax.post({
			module: 'ytcrm',
            url: 'activity-project/remove-member',
            params: {id: $scope.data.members[$index].id},
            success: function(res){
				$scope.getMembers();
            }
        });
    };

    // START test           
    $scope.saveSummaryCalendarEvent = function(data, $modalInstance){
        if (!data.summary){
            alert('אין אפשרות להמשיך ללא הכנסת תיעוד פגישה');
            return;
        }
        Ajax.post({
            module: 'common',
            url: 'calendar/save-calendar-event-summary', 
            data: data,
            success: function(res) {
                data = res.data;
                $scope.saveEventStatus(data, 115);
                $modalInstance.close();
                if ($scope.calendarEventModalInstance){
                    $scope.calendarEventModalInstance.close();
                }
                $scope.getEventDetailsById(data);
                if (data.toArray.length>0){
                    $scope.openSendSummaryConfirm(data);
                }
            }
        });
    };

    $scope.openSummaryPopup = function(data, $index) {
        $scope.calendarEventIndex = $index;
        data.summary = data.activity ? data.activity.content : null;
        var fn = {
            getJSFormatDate:        $scope.getJSFormatDate,
            removeAttendee:         $scope.removeAttendee,
            unremoveAttendee:       $scope.unremoveAttendee,
            getEventDetailsById:    $scope.getEventDetailsById
        };
        Popup.open('ytcrm/calendarEventSummary', $scope.saveSummaryCalendarEvent, (data) ? data : {}, fn, {});
    };

    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };

    $scope.removeAttendee = function(data, $index, $modalInstance){
        if (data.attendees.length > $index) {
            data.attendees[$index].removed = true;
        }
        if (data.activity && data.activity.allConnections && data.activity.allConnections[$index].id) {
            Ajax.post({
                module: 'common',
                url: 'activity/delete-connection',
                params: {id: data.activity.allConnections[$index].id},
                success: function(res){
                    data.activity.allConnections.splice($index,1);
                    $scope.getEventDetailsById(data);
                },
                successMessage: 'הקישור נמחק בהצלחה'
            });
        }
        if (data.attendees.length > $index) {
            data.attendees[$index].activityConnection = null;
        }
    };
    
    $scope.unremoveAttendee = function(data, $index, $modalInstance){
        delete data.attendees[$index].removed;
    };

    $scope.getEventDetailsById = function(ce) {
        if (!ce && !ce.start){
            return;
        }
        var keyMonth    = ce.start.replace(/-/g, ''); // remove all '-' dashs from date string
        keyMonth        = keyMonth.substring(0,6); // get the year and month only like '201811'
        //var itemIndex = $scope.getIndexByMonthNumber(keyMonth);
        
        // Ajax.get({
        //     module: 'common',
        //     url: 'calendar/get-event-details-by-id', 
        //     params: {id: ce.id},
        //     success: function(res) {
        //         $rootScope.$broadcast('parent:getEventDetailsById',res.calendarEvent);
        //         res.calendarEvent.type = 'event';
        //         res.calendarEvent.fullView = true;
        //         res.calendarEvent.currentIndex = ce.currentIndex;
        //         res.calendarEvent.showSummaryBtn = true;
        //         $scope.data.userItems[itemIndex]['itemsList'][ce.currentIndex] = res.calendarEvent;
        //     }
        // });
        
    };

    $scope.saveEventStatus = function(event, logicId){
        Ajax.get({
            module: 'common',
            url: 'calendar/set-calendar-event-status', 
            params: {eventId: event.id, logicId: logicId},
            success: function(res) {
                $scope.getEventDetailsById(event);
            }
        });
    };

    $scope.sendSummaryEmail = function(data, $modalInstance){
        Ajax.post({
            module: 'common',
            url: 'calendar/send-summary-email', 
            data: data,
            success: function(res) {
                $scope.saveEventStatus(data,124);
                $modalInstance.close();
            }
        });
    };

    $scope.openSendSummaryConfirm = function(data) {
        Popup.open('ytcrm/sendSummaryMailConfirm', $scope.sendSummaryEmail, data);
    };

    $scope.trustAsHtml = function(bindHtml){
        if (bindHtml){
            return $sce.trustAsHtml(bindHtml);
        }
    };

}]);
