'use strict';

app.directive('viewData', ['$timeout','Ajax', 'Utils', 'PageContentOnresize', function ($timeout, Ajax, Utils, PageContentOnresize) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: false,
        transclude: false,
        templateUrl: function(elem, attrs) {
            return '/js/app/partials/' + attrs.templateurl + '.html'  + window.YT.project.version;
        },
        link: function(scope, element, attrs) { 
            scope.getData = function(){
                var param = {
                    url: attrs.geturl, 
                    params: {id: attrs.userid},
                    success: function(res) { 
                        scope.data         = res.data;
                        if(res.picklists){
                            scope.picklists = res.picklists;
                        }

                        scope.$emit('viewData:getSuccess', res);
                    },
                    error: function(res){
                        console.log(res);
                    }
                };
                
                if(attrs.module){
                    param.module = attrs.module;
                }
                
                Ajax.get(param);
            };
            
            if(attrs.run){
                scope.getData();
            }
            
            scope.$on("viewData:refresh", function(e, msg) {
                scope.getData();
            });
            
            scope.$evalAsync(function() {
                PageContentOnresize.run();
                $timeout(function () {
                    scope.$emit('viewData:finished');
                });
            });
        }
    };
}]);