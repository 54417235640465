'use strict';

var path = '/../js/app/directives/datatableCustom';

app.directive('datatableCustom', ['$timeout', '$compile', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $compile, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngDataName',
        restrict: 'AE',
        replace: false,
        scope: true,
        templateUrl: function(elem, attrs) {
            if (attrs.tplName) {
                return path + '/' + attrs.tplName + '.html';  
            }
            return path + '/tpl.html';
        },
        link: function(scope, element, attrs) {
			scope.ngDataName        = attrs.ngDataName;
			scope.ngDataTitle       = attrs.ngDataTitle;
			scope.ngFilter          = Utils.GET().filter;
			scope.currentTable      = [];
			scope.tableId           = null;
			scope.ngFilter          = Utils.GET().filter;

			scope.init = function(){

				scope.tableId = attrs.ngDataName+'_table';
				Ajax.get({
					module: YT.mvc.module,
					url: attrs.ngDataName + '/' + 'get-columns-data', 
					//params: {therapistId: $scope.globals.user.user_id},
					success: function(res) {
						scope.columns       = res.columns;
						scope.setRenderColumns();
						scope.orderDefault  = res.orderDefault;
						scope.drawDatatable();
						scope.searchData    = res.searchData;
					}
				});
			};

			scope.drawDatatable = function(){
                scope.currentTable =  $('#'+scope.tableId).DataTable( {
					responsive: true,
					drawCallback: function( settings ) {
						// after tabel was draw
					},
					language: {
						decimal:        "",
						emptyTable:     "לא נמצאו נתונים בטבלה",
						info:           "מציג _START_ עד _END_ מתוך _TOTAL_ תוצאות",
						infoEmpty:      "",
//                            infoFiltered:   "(סך הכל _MAX_ תוצאות)",
						infoFiltered:   "",
						infoPostFix:    "",
						thousands:      ",",
						lengthMenu:     "הצג _MENU_ תוצאות",
						loadingRecords: "טוען...",
						processing:     "<div style='position:fixed; top: 10%;right: 50%; background: #028da2; color:white; width: 200px; height: 50px; padding: 10px;'>עוד רגע...</div>",
						search:         "",
						searchPlaceholder: "חיפוש...",
						zeroRecords:    "לא נמצאו נתונים מתאימים",
						paginate: {
							first:      "ראשון",
							last:       "אחרון",
							next:       "הבא",
							previous:   "הקודם"
						},
						aria: {
							sortAscending:  ": הפעל מיון בסדר עולה",
							sortDescending: ": הפעל מיון בסדר יורד"
						}
					},
					order: [ scope.orderDefault ],
					pageLength: attrs.pageLength ? attrs.pageLength : 10,
					orderCellsTop:  true,
					processing:     true,
					serverSide:     true,
					ajax:           '../' + attrs.ngDataName + '/get-data' + ( (Utils.GET().filter) ? ('?filter=' + Utils.GET().filter) :  ''),
					columns:        scope.columns
				} );
			};

			scope.init();

			scope.$on('datattable:search', function(e, index, value){
				if (index){
					scope.currentTable.column(index + ':visible').search( value ).draw();
				}
			});

			scope.clearSearch = function(){
				// this two lines clear the search text from view
				$('#' + scope.tableId + ' thead input').val('');
				$('#' + scope.tableId + ' thead select').val(null);
				// draw the table after clear search
				scope.currentTable.search( '' ).columns().search( '' ).draw();
			};

			scope.setRenderColumns = function(){
				// set the render of columns if there is render on the column
				for (var i = 0; i < scope.columns.length; i++){
					if (scope.columns[i].render) {
						scope.columns[i] = {
							data:   scope.columns[i].data,
							title:  scope.columns[i].title,
							render: function(data, type, row, meta){
								if(type === 'display'){
									return '<a href="' + data.link + '">' + data.data + '</a>';
								}
								return data;
							}
						};
					}
				}
			};
        }
    };
}]);
