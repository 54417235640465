'use strict';

app.directive('relatedList',['$timeout', 'Ajax', 'Utils', function($timeout, Ajax, Utils){
        return {
            restrict: 'AEC',
            templateUrl: function(elem, attrs) {
                return '/js/app/partials/' + attrs.listname + '.html';
            }
        };
}]);


