'use strict';

app.controller('ActivityProjectType', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.activityProjectTypes = [];
    $scope.objects  = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'activity-project-type/get-activity-project-types',
            success: function(res){
                $scope.activityProjectTypes = res.activityProjectTypes;
                if ($scope.selectedType){
                    let type = $scope.activityProjectTypes.filter( i => i.id == $scope.selectedType.id)[0];
                    $scope.selectedType.roles = type.roles;
                }
                $scope.objects  = res.objects;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        data = data ? data : $scope.selectedType;
        Ajax.post({
            url: 'activity-project-type/save',
            data:   data,
            success: function(res){
                //$scope.selectedType.roles = res.roles;
                $scope.init();
                if ($modalInstance) {
                    $modalInstance.close();
                }
                // $scope.setSelectedType($scope.activityProjectTypes[$scope.activityProjectTypes.length-1]);
            }
        });
    };

    $scope.removeActivityProjectType = function($id){
        Ajax.post({
            url: 'activity-project-type/remove-activity-project-type',
            params: {id: $id},
            success: function(res){
                $scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
    };    
        
    $scope.openActivityProjectTypePopup = function(data){
        data = data ? data : {};
        var fn = [];
        fn = {
            removeActivityProjectType: $scope.removeActivityProjectType
        };
        
        Popup.open('adminsettings/activityProjectType', $scope.save, data, fn);
    };

    $scope.setSelectedType = function(type){

        //*** start */
        if (!type.settings){
            type.settings = {};
            if(!type.settings.categories){
                type.settings.categories = [];
                type.settings.categories.push({name: 'כללי', new: false});
            }
            if (!type.roles){
                type.roles = {};
            }
            
        }else{
            if (!type.roles){
                type.roles = [];
            }
        }
        //*** end */
        $scope.selectedType = type;
    }

    $scope.addCategory = function(){
        $scope.selectedType.settings.categories.push({name: null, rank: null, new: true});
    }
    
    $scope.removeCategory = function($index){
        $scope.selectedType.settings.categories.splice($index,1);
    };

    $scope.addRole = function(){
        $scope.selectedType.roles.push({name: null, object_id: null});
    }
    
    $scope.removeRole = function($index){
        if ($scope.selectedType.roles[$index].id) {
            if (!$scope.selectedType.rolesToDelete){
                $scope.selectedType.rolesToDelete = [];
            }
            $scope.selectedType.rolesToDelete.push($scope.selectedType.roles[$index]);
        }
        $scope.selectedType.roles.splice($index,1);
    };
}]);