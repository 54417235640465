'use strict';

app.controller('SettingsFormResultViewController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.formResultViewList       = [];
    $scope.formResultView           = {};
    $scope.formResultView.forms    = {};
    
    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.init();
        }
    });  
    
    $scope.init = function(){
        Ajax.get({
            url: 'form-result-view/get-form-result-view-list',
            success: function(res){
                $scope.formResultViewList   = res.formResultViewList;
                $scope.objectsList          = res.objectsList;
                $scope.formsList            = res.formsList;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        angular.forEach(data.forms, function(item, key) {
            delete item.edit;
        });
        Ajax.post({
            module: 'adminsettings',
            url: 'form-result-view/save',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.removeResultView = function($id){
		Ajax.post({
			url: 'form-result-view/remove',
            params: {id: $id},
            success: function(res){
				$scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
	};    
        
    $scope.openFormResultViewPopup = function(data){
        var fn = [];
        var data = data || {};
        data.objectsList    = [];
        data.formsList      = [];
        data.objectsList    = $scope.objectsList;
        data.formsList      = $scope.formsList;
        fn = {
            removeGroup:    $scope.removeGroup,
            addObject:      $scope.addObject,
            addForm:        $scope.addForm,
            removeForm:     $scope.removeForm
        };
        Popup.open('adminsettings/formResultView', $scope.save, data, fn);
    };

    $scope.addForm = function(data){
        if (!data.forms) {
            data.forms = [];
        }
        if ($filter('filter')(data.forms, {id: data.newForm.id}).length > 0){
            alert('טופס קיים בתצוגה, בחר טופס אחר');
            return;
        }
        
        data.forms.push(data.newForm);
    };

    $scope.removeForm = ($index,data) => {
        data.forms.splice($index,1);
    }
}]);
