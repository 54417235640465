'use strict';

app.controller('cr21OrganizationController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    $scope.data = {};
    $scope.communityTags = [];
        
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            if ($scope.data.community_association)  {
                $scope.communityTags = $scope.data.community_association.split(",");
            }
        }
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod == 'add'){
            Utils.redirect('/cr21/organization/view?id=' + res.data.id);
        //}
    });
    
    $scope.$watch('selected_community', function(newValue, oldValue) {
        if (newValue) {
            var newValueText = $( "#org-community-list option:selected" ).text();
            if( !$scope.isCommunityTagExist(newValueText) && newValueText != '-בחר-' ) {
                $scope.communityTags.push(newValueText);
                $scope.data.community_association = $scope.communityTags.join();
            }
        }
    });

    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number   = '';
        var route           = '';
        var locality        = '';
        var country         = '';
        var area            = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'administrative_area_level_1') {
                area = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_house_num = street_number;
        $scope.data.adrs_street     =  route;
        $scope.data.adrs_city       =  locality;
        $scope.data.adrs_country    =  country;
        //$scope.data.adrs_area        = area ? area.split(' ')[1] : '';
    });    

    $scope.removeTag = function($index){
        $scope.communityTags.splice($index, 1);
        $scope.data.community_association = $scope.communityTags.join();
    };
    
    $scope.isCommunityTagExist = function(valueName){
        if ($filter('filter')($scope.communityTags,valueName)[0]){
            return true;
        }
        return false;
    };
    
}]);