'use strict';

app.controller('PaymentListController', ['$scope', 'Ajax', function($scope, Ajax){
    $scope.applyActions = function(action){
        var ids = $('#w7').yiiGridView('getSelectedRows');
        if(ids.length > 0 && $scope[action]){
            Ajax.post({
                url: 'payment/apply-actions',
                data: {ids: ids, fieldName: action, fieldValue: $scope[action]},
                success: function(res){
                    $.pjax.reload({container:'#w7-container'});
                }
            });
        }
    };
}]);