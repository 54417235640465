'use strict';

app.directive('orders', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            fieldname: '='
        },
        templateUrl: '/js/app/directives/orders/tpl.html',
        link: function(scope, element, attrs) {       
            
            scope.init = function(){
                Ajax.get({
                    module: 'ytcrm',
                    url: 'order/get-record-orders',
                    params: {id: attrs.recordid, fieldName: attrs.fieldname},
                    success: function(res) { 
                        scope.orders = res.orders;
                    }
                });
            };            
            
            scope.init();
            
            scope.openOrderCard =  (orderId) => {
                // $rootScope.$broadcast('order:showOrderCard', order);
                Utils.redirect('../order/view?id=' + orderId)
            };
        }
    };
}]);