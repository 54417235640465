app.directive('quickBrSave', ['$parse', 'Ajax', 'Utils', 'Popup', function ($parse, Ajax, Utils, Popup) {
    return {
        restrict: 'AEC',
        scope: { 
			brid: '@'
		},
        templateUrl: '/js/app/directives/quickBrSave/quickBrSaveTpl.html',
        link:function (scope, elm, attrs) {
            scope.budgetRequest = {};
            scope.copyDataBeforeEdit = {};
                        
            scope.init = function(){
                
                Ajax.get({
                    module: 'elhanefesh',
                    url: 'request/get-budget-request', 
                    params: {id: attrs.brid},
                    //data: data,
                    success: function(res) {
                        scope.budgetRequest = res.budgetRequest;
                        scope.copyDataBeforeEdit = angular.copy(scope.budgetRequest);
                    }
                });
                
                scope.showEdit = false;
                scope.globals = YT;
            }
            scope.init();
            
            scope.$on('quickBrSave:savedByPopup', function(e, mod, id){
                if (scope.budgetRequest.id = id){
                    scope.init();
                }
            });
            
            scope.saveBudgetRequest = function(){
                if(angular.equals(scope.copyDataBeforeEdit, scope.budgetRequest)){
                    scope.showEdit = false;
                    return; // to avoid from multiple saving
                }

                Ajax.post({
                    module: 'elhanefesh',
                    url: 'request/save-budget-request', 
                    params: {id: Utils.GET().id, budgetRequestId: scope.budgetRequest.id},
                    data: scope.budgetRequest,
                    success: function(res) {
                        scope.budgetRequest = res.data;
                        scope.init();
                        scope.$emit('quickBrSave:postSuccess', 'view', res);
                    }
                });
            };
            
            scope.cancelChanges = function(){
                scope.budgetRequest = angular.copy(scope.copyDataBeforeEdit);
                scope.showEdit = false
            }
            
            scope.addFeedbackFileClick = function(elemID){
                $('#'+elemID+scope.budgetRequest.id).trigger('click');
            };
                                    
            scope.uploadFile = function(elem,uploadedObjLogicId){
                //var file_connection_id  = this.doc.id;
                var file = $("#"+elem.id)[0].files[0];

                if( file ){
                    var fd      = new FormData();

                    var param = {};
                    param.upload_source = '(תוכנה)';
                    param.is_sensitive = 1;

                    fd.append('file', file);
                    fd.append('fileData', JSON.stringify(param));
        
                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload-budget-request-file?id=' + scope.budgetRequest.id + '&uploadedObjLogicId=' + uploadedObjLogicId,
                        fd: fd,
                        success: function(res){
                            scope.budgetRequest = res.data;
                            scope.init();
                        }
                    });
                }
            };

            scope.goToPreviewUrl = function(d) {
                var data = {};
                data.file = d;
                Ajax.post({
                    module: 'common',
                    url: 'file/get-preview-url',
                    data: data,
                    success: function(res){
                        window.open('https://drive.google.com/gview?url='+res.previewUrl,'_blank');
                    }
                });
            };

            scope.openViewFormPopup = function(fs,$index) {
                var data = {};
                
                Ajax.get({
                    module: 'common',
                    url: 'form/get-form-answer', 
                    params: {
                        formId: fs.form_id,
                        recordId: scope.budgetRequest.id,
                        fieldName: 'budget_request_id'
                    },
                    success: function(res) {
                        data.form                   = res.form;
                        data.titles                 = res.titles;
                        data.submissions            = res.submissions;
                        data.categories             = res.categories;
                        data.currentSubmissionId    = fs.id;
                        Popup.open('common/formView', null, data, {});
                    }
                });
            };
        }
    };    
}]);


