'use strict';

app.directive('ngMsgConfirm', ['$modal',
    function($modal){
        return {
            restrict: 'AEC',
            link: function (scope, element, attr) {
                var modalInstance	= null;
                var clickAction		= attr.ngMsgConfirm;

                scope.openPopup = function(){
                    modalInstance = $modal.open({
                        templateUrl: '/js/app/directives/confirm/tpl.html',
                        controller: function($scope, $modalInstance, type, title, message){
                            $scope.type		= type		|| 'danger';
                            $scope.title	= title		|| 'אישור לפני פעולה';
                            $scope.message	= message	|| "האם אתה בטוח שברצונך לבצע את הפעולה";

                            $scope.ok = function(){
                                $modalInstance.close();
                            };

                            $scope.cancel = function(){
                                $modalInstance.dismiss('cancel');
                            };
                        },
                        size: 'lg',
                        windowClass: 'extra-large-modal',
                        resolve: {
                            type: function ()		{ return attr.type; },
                            title: function ()		{ return attr.title; },
                            message: function ()	{ return attr.message; }
                        }
                    });	
                };

                element.bind('click',function (event) {
                    scope.openPopup();

                    modalInstance.result.then(
                            function () { scope.$eval(clickAction); }, 
                            function () {}
                    );
                });
            }
        };
}]);
