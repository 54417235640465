'use strict';

app.controller('FileGroup', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.fileGroups = [];

    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.init();
        }
    });
    
    
    
    $scope.init = function(){
        Ajax.get({
            url: 'file-group/get-file-groups',
            success: function(res){
                $scope.fileGroups = res.fileGroups;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'file-group/save-group',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.removeGroup = function($id){
		Ajax.post({
			url: 'file-group/remove-group',
            params: {id: $id},
            success: function(res){
				$scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
	};    
        
    $scope.openFileGroupePopup = function(data){
        var fn = [];
        fn = {
            removeGroup: $scope.removeGroup
        };
        Popup.open('adminsettings/fileGroup', $scope.save, data, fn);
    };
}]);
