'use strict';

app.controller('Tag', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tags = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'tag/get-tags',
            success: function(res){
                $scope.tags     = res.tags;
                $scope.objects  = res.objects;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        if (data.id) { // if update mode
            Ajax.post({
                url: 'tag/is-tag-in-use',
                params: {id: data.id},
                success: function(res){
                    if (res.data > 0) {
                        if ( !confirm('תגית זו נמצאת בשימוש, במידה ותבצע בה שינויים הדבר יגרום להסרתה מהמקומות בהם היא נמצאת בשימוש') ) {
                            return;
                        }
                    }
                    Ajax.post({
                        url: 'tag/save-tag',
                        data:   data,
                        success: function(res){
                            $scope.init();
                            $modalInstance.close();
                        }
                    });
                },
                notShowMessage: true,
            });
        }else { // create new tag mode
            Ajax.post({
                url: 'tag/save-tag',
                data:   data,
                success: function(res){
                    $scope.init();
                    $modalInstance.close();
                }
            });
        }
    };

    $scope.removeTag = function($id){
        Ajax.post({
            url: 'tag/is-tag-in-use',
            params: {id: $id},
            success: function(res){
                if (res.data > 0) {
                    if ( !confirm('תגית זו נמצאת בשימוש, במידה ותחליט למחוק אותה הדבר יגרום להסרתה מהמקומות בהם היא נמצאת בשימוש') ) {
                        return;
                    }
                }
                Ajax.post({
                    url: 'tag/remove-tag',
                    params: {id: $id},
                    success: function(res){
                        $scope.init();
                        $rootScope.$broadcast("popup:close", res);
                    }
                });
            },
            notShowMessage: true,
        });
    };    
        
    $scope.openTagPopup = function(data){
        data = data ? data : {};
        var fn = [];
        fn = {
            removeTag: $scope.removeTag
        };
        data.objects = $scope.objects;
        Popup.open('adminsettings/tag', $scope.save, data, fn);
    };
}]);