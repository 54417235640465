'use strict';

app.controller('PortalActiveStudiesYearsController', ['$scope', 'Ajax',  function($scope, Ajax){
        
    $scope.$on("fileViewUpload:uploadSuccess", function(e, file_connection_id) {
        $scope.$broadcast("viewData:refresh");
    });
    
    $scope.$on("fileViewUpload:deleteSuccess", function(e, res){
        $scope.$broadcast("viewData:refresh");
    });
    
    $scope.submitYearFile = function(doc){
        Ajax.post({
            url: 'active/submit-year-file',
            params: {file_connection_id: doc.id}, 
            success: function(res){
                $scope.data = res.data;
            }
        });
    };    
}]);