'use strict';



app.controller('EhcpLoginManageController', ['$scope', 'Ajax', 'Utils', 'Popup', function($scope, Ajax, Utils, Popup){
    $scope.pass = {};
    $scope.showPasswordStrength = false;
    
    $scope.init = function(){
        Ajax.get({
            module: 'contactehportal',
            url: 'login-manage/get-use-agreement-text', 
            success: function(res) { 
                $scope.useAgreementText = res.useAgreementText;
            }
        });
    };
    $scope.init();
    $scope.changePassword = function(){
        if(!$scope.pass.password || !$scope.pass.new_password || !$scope.pass.retype_new_password){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        if($scope.pass.new_password !== $scope.pass.retype_new_password){
            alert('סיסמה חדשה אינה תואמת בשני השדות');
            return;
        }
        
        if ($scope.pass.new_password.length < 8){
            alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
            return;
        }
        
        if (!$scope.isValidPass($scope.pass.new_password)){
            alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
            return;
        }
        
        if (!$scope.isStrengthPass($scope.pass.new_password)){
            alert('הסיסמה צריכה לעמוד בדרישות הבאות: להכיל לפחות אות אחת ומספר אחד ולהכיל לפחות אות אחת גדולה');
            return;
        }
        
        Ajax.post({
            url: 'login-manage/change-password',
            data: $scope.pass, 
            notShowMessage: true,
            success: function(res){
                $scope.pass = {};
                Utils.showNoty('הסיסמה נשמרה בהצלחה');
                Utils.redirect('/contactehportal/default/login');
            },
            error: function(res){
                alert(res);
            }
        });
    };
    
    $scope.resetPassword = function(){
        if(!$scope.data.email){
            alert('אנא מלא את כל השדות');
            return;
        }
                
        grecaptcha.execute('6LczCSwfAAAAAGJdz8tzwgp7sFiQ1LZ8r3TO2FRZ',{action: 'ytForgotPassword'}).then(function(token) {
            $scope.data.token = token;
            Ajax.post({
                module: 'contactehportal',
                url: 'login-manage/reset-password',
                data: $scope.data, 
                notShowMessage: true,
                success: function(res){
                    if (res.data){
                        console.log(res.data);
                        alert(res.data);
                    }else{
                        Utils.redirect('/contactehportal/login-manage/success');
                    }
                    
                },
                error: function(res){
                    //alert('res');
                }
            });
        });
    };
    
    $scope.saveUseAgreement = function(){
        $scope.data.i_agree_use_ehportal                = $scope.data.i_agree_use_ehportal ? 1 : 0;
        $scope.data.is_treatment_description_availabel  = $scope.data.is_treatment_description_availabel ? 1 : 0;
        Ajax.post({
            module: 'contactehportal',
            url: 'login-manage/save-use-agreement',
            data: $scope.data, 
            notShowMessage: true,
            success: function(res){
                Utils.redirect('/contactehportal/default/login');
            },
        });
    };
    
    $scope.saveSecurityRules = function(){
        Ajax.post({
            url: 'login-manage/save-security-rules',
            data: $scope.data, 
            notShowMessage: true,
            success: function(res){
                Utils.redirect('/contactehportal/default/login');
            },
        });
    };

    $scope.isValidPass = function(str) {
        return /^\w+$/.test(str);
    }
    
    $scope.isStrengthPass = function(str) {
        return $scope.pass.new_password.match(/[A-z]/) && $scope.pass.new_password.match(/[A-Z]/) && $scope.pass.new_password.match(/\d/);
    }
    
    $scope.passwordStrength = function(){
        $scope.showPasswordStrength = true;
        if(!$scope.pass.new_password){
            $scope.validLength          = '';
            $scope.validLeastOneLetter  = '';
            $scope.validLeastOneCapital = '';
            $scope.validLeastOneNumber  = '';
            return;
        }
        if ( $scope.pass.new_password.length < 8 ) {
            $scope.validLength = 'invalid_pass';
        } else {
            $scope.validLength = 'valid_pass';
        }
        //validate letter
        if ( $scope.pass.new_password.match(/[A-z]/) ) {
            $scope.validLeastOneLetter = 'valid_pass';
        } else {
            $scope.validLeastOneLetter = 'invalid_pass';
        }

        //validate capital letter
        if ( $scope.pass.new_password.match(/[A-Z]/) ) {
            $scope.validLeastOneCapital = 'valid_pass';
        } else {
            $scope.validLeastOneCapital = 'invalid_pass';
        }

        //validate number
        if ( $scope.pass.new_password.match(/\d/) ) {
            $scope.validLeastOneNumber = 'valid_pass';
        } else {
            $scope.validLeastOneNumber = 'invalid_pass';
        }
    };
}]);