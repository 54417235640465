'use strict';

app.directive('datepicklists', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        restrict: 'AEC',
        require:  '^ngModel',
        scope: true,
        templateUrl: '/js/app/partials/datepicklistsTpl.html',
        link: function(scope, element, attrs) {
            var ngModelGetter = $parse(attrs['ngModel']);
            var currentYear = new Date().getFullYear();
            
            scope.years = $.map($(Array((currentYear)-1931)), function (val, i) { return currentYear - i; });
            scope.months = $.map($(Array(12)), function (val, i) { return i + 1; });
            scope.days = $.map($(Array(31)), function (val, i) { return i + 1; });
            scope.limitDays = 31;
            scope.displaymode = attrs.displaymode;
            
            scope.onchange = function(){
                if (scope.displaymode == 'years'){
                    ngModelGetter.assign(scope, ((scope.selectedYear) ? scope.selectedYear : null));
                    return;
                }
                scope.setDaysByMonth();
                if (scope.selectedYear && scope.selectedMonth && scope.selectedDays) {
                    var d = scope.selectedYear + "-" + scope.selectedMonth + "-" + scope.selectedDays;
                    ngModelGetter.assign(scope, d);
                }else{
                    ngModelGetter.assign(scope, null);
                }
            }
            
            scope.setDaysByMonth = function(){
                switch(scope.selectedMonth) {
                    // april june september november
                    case '4': case '6': case '9': case '11':
                        if(scope.limitDays != 30){
                            scope.limitDays = 30;
                            if (scope.selectedDays > scope.limitDays){
                                scope.selectedDays = undefined;
                            }
                        }
                        break;
                    // january may july august october december
                    case '1': case '3': case '5': case '7': case '8': case '10': case '12':
                        if(scope.limitDays != 31){
                            scope.limitDays = 31;
                        }
                        break;
                    // february
                    case '2':
                        var leapYear = false;
                        if (scope.selectedYear){
                            var year = scope.selectedYear;
                            leapYear = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
                            if (!leapYear && scope.selectedDays > 28){
                                scope.selectedDays = undefined;
                            }else if (leapYear && scope.selectedDays > 29){
                                scope.selectedDays = undefined;
                            }
                        }    
                        scope.limitDays = leapYear ? 29 : 28;
                        break;
                    default:
                        scope.limitDays = 31;
                }
            }
            
            scope.$watch(ngModelGetter, function(newValue, oldValue) {
                if (newValue){
                    if (scope.displaymode == 'years'){
                        scope.selectedYear = newValue;
                        return;
                    }
                    var dateArr = newValue.split("-");
                    scope.selectedYear  = (parseInt(dateArr[0]) == 0) ? undefined : parseInt(dateArr[0]).toString() ;
                    scope.selectedMonth = (parseInt(dateArr[1]) == 0) ? undefined : parseInt(dateArr[1]).toString() ;
                    scope.selectedDays  = (parseInt(dateArr[2]) == 0) ? undefined : parseInt(dateArr[2]).toString() ;
                    scope.setDaysByMonth();
                }
            });
        }
    };
}]);