'use strict';



app.controller('ActivePortalPersonalProfileController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){
    $scope.pass = {};    
    $scope.changePassword = function(){
        if(!$scope.pass.password || !$scope.pass.new_password || !$scope.pass.retype_new_password){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        if($scope.pass.new_password !== $scope.pass.retype_new_password){
            alert('סיסמה חדשה אינה תואמת בשני השדות');
            return;
        }
        
        if ($scope.pass.new_password.length < 8){
            alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
            return;
        }
        
        if (!$scope.isValidPass($scope.pass.new_password)){
            alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
            return;
        }
        
         Ajax.post({
            url: 'login-manage/change-password',
            data: $scope.pass, 
            notShowMessage: true,
            success: function(res){
                $scope.pass = {};
                Utils.showNoty('הסיסמה נשמרה בהצלחה');
            },
            error: function(res){
                alert(res);
            }
        });
    };
    
    $scope.isValidPass = function(str) {
        return /^\w+$/.test(str);
    }
}]);