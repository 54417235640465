'use strict';

app.directive('activityMilestone', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            recordid:   '@',
            fieldname:  '=',
            logicid:    '@',
            workplan:    '=?'
        },
        templateUrl: '/js/app/directives/activityMilestone/activityMilestone.html',
        link: function(scope, element, attrs) {
            scope.activityProject = [];
            scope.milestones = [];
            scope.activityPicklists = [];
            scope.current_user_id = YT.user.user_id;
            scope.milestoneTemplates = {};
            scope.activitiesForSave = [];
            scope.filter            = null;

            scope.init = () => {
                scope.getRecordMilestones();
            };


            $timeout(function(){    
                scope.init();                
            },1000);
        
            Ajax.get({
                module: 'common',
                url: 'picklist/get-json-lookup',
                params: {settingName: 'MilestoneTemplates'},
                success: function(res){
                    scope.milestoneTemplates = res;
                }
            });

            scope.getRecordMilestones = function() {
                Ajax.get({
                    module: 'common',
                    url: 'activity-milestone/get-record-milestones',
                    params: {
                        recordId: scope.recordid,
                        fieldName: scope.fieldname
                    },
                    data: scope.filter,
                    success: function(res){
                        scope.milestones = res.milestones;
                        angular.forEach(scope.milestones, function(m) {
                            m.unit_sum                  = m.unit_sum | 0;
                            m.actual_activities_count   = m.actual_activities_count | 0;
                            m.unit_utilization          = m.unit_utilization | 0;
                        });
                        scope.activityPicklists = res.activityPicklists;
                    }
                });
            };

            scope.$on("activityFilter:filterdata", function(e, res) {
                scope.filter = res;
                scope.init();
            });

            scope.$on("activityProject:changeId", function(e, res) {
                scope.recordid = res;
                scope.init();
            });
            

            scope.saveMilestone = function(data, $modalInstance) {
                data.order = scope.milestones.length;
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/save',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    data: data,
                    success: function(res){
                        scope.getRecordMilestones();
                        if ($modalInstance){
                            $modalInstance.close();
                        }
                    }
                });
            };

            scope.reorderMilestone = function(data, $modalInstance) {
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/save',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    data: data,
                    notShowMessage: true,
                    success: function(res){
                        scope.getRecordMilestones();
                        if ($modalInstance){
                            $modalInstance.close();
                        }
                    }
                });
            };

            scope.openMilestonePopup = function($index){
                var data = $index!=undefined ? scope.milestones[$index] : {};
                data.milestoneTemplates = scope.milestoneTemplates;
                data.activityProject = scope.activityProject;
                var fn = {
                    addActivitiesToMilestone: scope.addActivitiesToMilestone,
                    deleteMilestone: scope.deleteMilestone
                };
                Ajax.get({
                    module: 'common',
                    url: 'activity-milestone/get-current-activity-project',
                    params: {recordId: scope.recordid},
                    success: function(res){
                        scope.activityProject = res.activityProject;
                        data.activityProjectType = scope.activityProject.activityProjectType;
                        Ajax.get({
                            module: 'common',
                            url: 'form/get-open-forms',
                            params: {objectId: scope.logicid, availableInActivities: 1},
                            success: function(res) {
                                data.forms= res.forms;
                                Popup.open('common/activityMilestone', scope.saveMilestone, data, fn, {}, 'static');
                            }
                        });
                    }
                });
            };

            scope.openHelpPopup = function(){
                
                Popup.open('adminsettings/helpMilestone', {}, {}, {}, {});
            };

            

            scope.saveActivity = function(data,$modalInstance,$event){  
                scope.saveChangedActivities();               

                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/save-activity',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    data: data,
                    success: function(res){                        
                        scope.getRecordMilestones();
                        if ($modalInstance) {
                            $modalInstance.close();
                            
                        }
                    }
                });
            };

            scope.saveChangedActivities = function(){
                if (scope.activitiesForSave.length == 0) {
                    return;
                }
                scope.activitiesForSave.forEach(function(item) {
                    Ajax.post({
                        module: 'common',
                        url: 'activity-milestone/save-activity',
                        params: {recordId: scope.recordid, fieldName: scope.fieldname},
                        data: item,
                        notShowMessage: true,
                        success: function(res){                        
                        }
                    });
                });
                scope.activitiesForSave = [];
            };
            
            scope.reorderActivity = function(data,$modalInstance,$event){                
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/save-activity',
                    params: {recordId: scope.recordid, fieldName: scope.fieldname},
                    data: data,
                    notShowMessage: true,
                    success: function(res){                        
                        scope.getRecordMilestones();
                        if ($modalInstance) {
                            $modalInstance.close();
                        }
                    }
                });
            };


            scope.createActivitiyMilestone = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/create-activity-milestone',
                    params: {milestoneIndex: $index, recordId: scope.recordid, logicId: scope.logicid, fieldName: scope.fieldname},
                    success: function(res){
                        scope.getRecordMilestones();                    
                    },
                    successMessage: 'תוכנית העבודה נוספה בהצלחה'
                });
            };

            scope.addActivitiesToMilestone = function($id,$index,$modalInstance){
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/add-activities-to-milestone',
                    params: {milestoneId: $id, milestoneIndex: $index, recordId: scope.recordid, logicId: scope.logicid, fieldName: scope.fieldname},
                    success: function(res){
                        scope.getRecordMilestones();
                        $rootScope.$broadcast("popup:close", res);               
                    },
                    successMessage: 'תוכנית העבודה נוספה בהצלחה'
                });
            };

            scope.saveComment = function(data){
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity-comment',
                    params: {id: data.id},
                    data:   data.activityComment,
                    success: function(res){
                        data.activityComment = {};
                        data.mergingCommentsTracking = res.current_activity.mergingCommentsTracking;
                    }
                });
            };

            scope.deleteActivity = function(data, $modalInstance){
                Ajax.post({
                    module: 'common',
                    url:    'activity-milestone/delete-activity',
                    params: {id: data.id},
                    success: function(res){
                        scope.getRecordMilestones();
                        $rootScope.$broadcast("popup:close", res);
                    }
                });
            };

            scope.openActivityPopup = function(data,parentIndex){
                //var data = $index ? scope.milestones[$index] : {};
                data.parentIndex = parentIndex;
                var fn = {
                    setIsDone: scope.setIsDone,
                    setIsIrelevant: scope.setIsIrelevant,
                    saveComment: scope.saveComment,
                    getJSFormatDate: scope.getJSFormatDate,
                    deleteActivity: scope.deleteActivity,
                    feedbackScrollDown: scope.feedbackScrollDown,
                    createSubmission: scope.createSubmission,
                    openViewHtmlForm: scope.openViewHtmlForm
                };
                data.activityPicklists = scope.activityPicklists;
                data.unit_type = scope.milestones[parentIndex].unit_type;
                data.parentFormId = scope.milestones[parentIndex].form_id;
                Popup.open('common/activity', scope.saveActivity, data, fn, {}, 'static');
            };

            scope.createSubmission = (id) => {
                Ajax.post({
                    module: 'common',
                    url: 'activity-milestone/create-activity-submission',
                    params: {id: id},
                    notShowMessage: true,
                    success: function(res) {
                        Utils.redirect('/common/form-portal?token=' + res.token, true);
                    }
                });
            };

            scope.openViewHtmlForm = (activity) => {
                var data = {};
                Ajax.get({
                    module: 'common',
                    url: 'form/get-form-fields', 
                    params: {
                        id: activity.parentFormId,
                        formSubmissionId: activity.form_submission_id
                    },
                    success: function(res) {
                        data.form = res.form;
                        data.titles = res.titles;
                        data.fieldname = 'budget_request_id';
                        Popup.open('common/formViewHtml', null, data, {}, {});
                    }
                });
            };

            scope.feedbackScrollDown = function(){
               $("#activity-feedback-section").scrollTop($("#activity-feedback-section")[0].scrollHeight);
            }

            scope.setIsDone = function(a){
                if (a.done_date){
                   a.done_date = null;
                }else{
                    a.done_date = new Date();
                    scope.checkIfProgressEnd(a);
                }
            };

            scope.quickSetIsDone = function(a){
                if (!a.done_date){
                    a.done_date = new Date();
                }else{
                    a.done_date = null;
                }
                scope.saveActivity(a);
            };            

            scope.checkIfProgressEnd = function(a){
                if (a.progress_value){
                    if (confirm("אנחנו רואים שהמשימה הזו מביאה את היעד " + scope.milestones[a.parentIndex].name + " ל " + a.progress_value + " אחוזי ביצוע, האם לסמן את המשימות שערך ההתקדמות שלהם נמוך מ " + a.progress_value + " אחוזים ונשארו פתוחות כלא רלוונטיות")){
                        scope.activitiesForSave = [];
                        scope.milestones[a.parentIndex].activities.forEach(function(item) {
                            if(!item.done_date && item.id != a.id && item.irelevant != "1") { 
                                if ( a.progress_value == "100" || // if 100 set even null progress_value to irelevant else only <= from current progress value
                                    (item.progress_value && parseInt(item.progress_value) <= parseInt(a.progress_value)) ) {
                                    item.irelevant = 1;
                                    item.done_date = new Date();
                                    scope.activitiesForSave.push(item);
                                }
                                //scope.saveActivity(item);
                            }
                        });
                    }
                }
            };

            scope.setIsIrelevant = function(a){
                if (a.irelevant){
                   a.irelevant = null;
                }else{
                    a.irelevant = 1;
                    if (!a.done_date){
                        a.done_date = new Date();
                    }
                }
            };

            scope.isCurrentYear = function(dueDate) {
                if (dueDate){
                    var d = new Date();
                    dueDate =  new Date(dueDate.replace(/-/g, '/'));
                    return dueDate.getFullYear() == d.getFullYear();
                }
            };

            
            scope.isPastDate = function(dueDate) {
                if (dueDate){
                    var d = new Date();
                    dueDate =  new Date(dueDate.replace(/-/g, '/'));
                    return dueDate < d;
                }
                return false;
            };

            scope.getJSFormatDate = function(d) {
                return new Date(d);
            };

            scope.keyUp = function($event,data){
                if ($event.which == 13){ // only if "Enter" key was pressed
                    scope.saveActivity(data);
                }
            }

            scope.onDropTaskComplete = function(index, obj, evt, tasks){
                
                var otherObj    = tasks[index];
                var otherIndex  = tasks.indexOf(obj);
                
                if (otherIndex < 0){
                    return;
                }
        
                if (otherIndex < index){
                    tasks.splice(index+1, 0, obj);
                    tasks.splice(otherIndex, 1);
                }else{
                    tasks.splice(index, 0, obj);
                    tasks.splice(otherIndex+1, 1);
                }

                var orderIndex = 0;
                tasks.forEach(function (item, index, object) {
                    item.order = orderIndex;
                    if (item.id) {
                        scope.reorderActivity(item);
                    }
                    orderIndex++;
                });

                // $('#'+obj.id+'reorder_btn').css('zIndex',20);
                // $('#'+otherObj.id+'reorder_btn').css('zIndex',20);

            };
        
            scope.onDropMilestoneComplete = function(index, obj, evt){
                
                var milestones = scope.milestones;
                var otherObj    = milestones[index];
                if (obj.activity_milestone_id) {
                    if (obj.activity_milestone_id == otherObj.id) {
                        return;
                    }
                    obj.activity_milestone_id =  otherObj.id;
                    obj.order = otherObj.activities.length;
                    scope.saveActivity(obj);
                    return;
                }
                var otherIndex  = milestones.indexOf(obj);
                
                if (otherIndex < 0){
                    return;
                }
        
                if (otherIndex < index){
                    milestones.splice(index+1, 0, obj);
                    milestones.splice(otherIndex, 1);
                }else{
                    milestones.splice(index, 0, obj);
                    milestones.splice(otherIndex+1, 1);
                }

                var orderIndex = 0;
                milestones.forEach(function (item, index, object) {
                    item.order = orderIndex;
                    if (item.id) {
                        scope.reorderMilestone(item);
                    }
                    orderIndex++;
                });
            };

            scope.deleteMilestone = function(data, $modalInstance){
                Ajax.post({
                    module: 'common',
                    url:    'activity-milestone/delete-milestone',
                    params: {id: data.id},
                    success: function(res){
                        scope.getRecordMilestones();
                        $rootScope.$broadcast("popup:close", res);
                    }
                });
            };

            scope.removeActToAnotherMilestone = function(activity,newMilestone){
                activity.activity_milestone_id = newMilestone.id;
                scope.saveActivity(activity);
            }

        }
    };
}]);