'use strict';

app.controller('SpNewCommitteeController', ['$rootScope', '$scope', '$http', '$filter', '$modal', '$log', 'Ajax', 'Utils', 'Popup',
    function($rootScope, $scope, $http, $filter, $modal, $log, Ajax, Utils, Popup) {

    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            //Utils.redirect('/common/list/view?id=' + ( (YT.user.project_id==5) ? 303 : 354 ) );
            history.go(-1);
        }
    });
    
    $scope.save  = function() {
        Ajax.post({
            url: 'committee/save-committee',
            data: $scope.committee,
            success: function(res){
                Utils.redirect('/singleparent/committee/update?id='+res.data.id);
            }
        });
    };
    
    $scope.openNewCommitteePopup = function(oc){
        $scope.users = {};
        Ajax.get({
            url: 'committee/get-committee-users',
            success: function(res){
                $scope.users = res.users;
                $scope.openPopup();
            }
        });
        
    };
    
    $scope.openPopup = function(){
        Popup.setOption('users', $scope.users);
        $scope.committee = {};
        $scope.committee = {selectedUser: []};
        var fn = [];
        fn = {
            removeParticipants: function(user_id, index){
                $scope.committee.selectedUser.splice(index, 1);
            },
            addUser: function(id){
                var user = $filter('filter')($scope.users, {id: id})[0];
    
                if ( !$filter('filter')($scope.committee.selectedUser, {id: user.id})[0] ){ // chack if exsits
                    $scope.committee.selectedUser.push(user);
                }
            }
        };
        
        Popup.open('singleparent/newCommittee', $scope.save, $scope.committee, fn, []);
    };
    
    $scope.openNewCommitteePopup();
    
}]);