'use strict';

app.directive('timeLineNewDoc', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: true,
        templateUrl: '/js/app/directives/timeLineNewDoc/timeLineNewDocTpl.html',
        link: function(scope, element, attrs) {
            scope.fileData      = {access_action: 'private', is_required: 'no'};
            scope.recordid = Utils.GET().id;
            scope.logicid = 54;
            scope.docsGroups = {};
            
            scope.initNewDoc = function(){
                Ajax.get({
                    module: 'common',
                    url: 'picklist/get-lookup',
                    params: {model: 'FileGroup', field: 'name_he'},
                    success: function(res){
                        scope.docsGroups = res;
                    }
                });
            };
            
            scope.initNewDoc();
            
            scope.addNewFileClick = function(){
                $timeout(function(){
                    $("#uploadNewFile").value = null;
                    $("#uploadNewFile").trigger('click');
                });
            };
            
            scope.save = function(){
                var file    = $("#uploadNewFile")[0].files[0];

                if( file ){ // user upload file
                    var fd              = new FormData();
                    fd.append('file', file);
                    scope.fileData.friendly_name = file.name.replace(/\.[^.$]+$/, '');
                    fd.append('fileData',  JSON.stringify(scope.fileData));

                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload?id=' + scope.recordid + '&logicId=' + scope.logicid,
                        fd: fd,
                        success: function(res){
                            $("#uploadNewFile").val(''); // clear the file input
                            
                            $rootScope.$broadcast('item:fileupload',true);
                        }
                    });
                }
            };
            
            scope.createDocsByFileGroupId = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'file/create-file-connections-file-group-id',
                    params: {fileGroupId: scope.docsGroups[$index].id, recordId: scope.recordid, logicId: scope.logicid},
                    success: function(res){
                        scope.docs = res.docs;
                        scope.showList();                        
                    },
                    successMessage: 'קבוצת המסמכים נוספה בהצלחה'
                });
            };
        }
    };
}]);