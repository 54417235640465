'use strict';



app.controller('AcaLoginManageController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){
    $scope.pass = {};

    $scope.init = function(){
        // Ajax.get({
        //     url: 'login-manage/get-use-agreement-text', 
        //     success: function(res) { 
        //         $scope.useAgreementText         = res.useAgreementText;
        //     }
        // });
    };
    $scope.init();

    $scope.changePassword = function(){
        if(!$scope.pass.password || !$scope.pass.new_password || !$scope.pass.retype_new_password){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        if( $scope.pass.password == $scope.pass.new_password ){
            alert('הסיסמה החדשה צריכה להיות שונה מהסיסמה הקיימת');
            return;
        }

        if($scope.pass.new_password !== $scope.pass.retype_new_password){
            alert('סיסמה חדשה אינה תואמת בשני השדות');
            return;
        }
        
        if ($scope.pass.new_password.length < 8){
            alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
            return;
        }
        
        if (!$scope.isValidPass($scope.pass.new_password)){
            alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
            return;
        }
              
        Ajax.post({
            url: 'login-manage/change-password',
            data: $scope.pass, 
            notShowMessage: true,
            success: function(res){
                $scope.pass = {};
                Utils.showNoty('הסיסמה נשמרה בהצלחה');
                Utils.redirect('/academyportal/default/login');
            },
            error: function(res){
                alert(res);
            }
        });
    };
    
    $scope.resetPassword = function(){
        if(!$scope.data.tz || !$scope.data.email){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        grecaptcha.execute('6LczCSwfAAAAAGJdz8tzwgp7sFiQ1LZ8r3TO2FRZ',{action: 'ytForgotPassword'}).then(function(token) {
            $scope.data.token = token;
            Ajax.post({
                url: 'login-manage/reset-password',
                data: $scope.data, 
                notShowMessage: true,
                success: function(res){
                    if (res.data){
                        console.log(res.data);
                        alert(res.data);
                    }else{
                        Utils.redirect('/academyportal/login-manage/success');
                    }
                    
                },
                error: function(res){
                    //alert('res');
                }
            });
        });
    };
    
    $scope.isValidPass = function(str) {
        return /^\w+$/.test(str);
    }

    $scope.saveUseAgreement = function(){
        $scope.data.i_agree_use_ehportal                = $scope.data.i_agree_use_ehportal ? 1 : 0;
        Ajax.post({
            url: 'login-manage/save-use-agreement',
            data: $scope.data, 
            notShowMessage: true,
            success: function(res){
                Utils.redirect('/academyportal/default/login');
            },
        });
    };

}]);