'use strict';

app.controller('LookupPicklist', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.lookupPicklists = [];

    $scope.init = function(){
        Ajax.get({
            url: 'lookup-picklist/get-lookup-picklists',
            success: function(res){
                $scope.lookupPicklists = res.lookupPicklists;
            },
        });
    };
    
    $scope.init();
}]);
