'use strict';

app.controller('ProductController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.products = [];
    // $scope.form.categories = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'product/get-products',
            success: function(res){
                $scope.products = res.products;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'product/save',
            data:   data,
            success: function(res){
                $scope.init();
                if ($modalInstance) {
                    $modalInstance.close();
                }
                // $scope.setSelectedType($scope.products[$scope.products.length-1]);
            }
        });
    };

    $scope.removeProduct = function($id){
        Ajax.post({
            url: 'product/remove-product',
            params: {id: $id},
            success: function(res){
                $scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
    };    
        
    $scope.openProductPopup = function(data){
        data = data ? data : {};
        var fn = [];
        fn = {
            removeProduct: $scope.removeProduct
        };
        
        Popup.open('adminsettings/product', $scope.save, data, fn);
    };
    
}]);