'use strict';

app.directive('fileViewUpload', ['$timeout', 'Ajax', '$filter', function ($timeout, Ajax, $filter) {
    return {
        restrict: 'AEC',
        scope: true,
        templateUrl: '/js/app/partials/fileViewUploadTpl.html',        
        link: function(scope, element, attrs) {
            scope.fileCon = attrs.filecon ? JSON.parse(attrs.filecon) : null;
            
            scope.uploadFile = function() {       
                var file_connection_id  = this.fileCon.id;
                var file                = $("#portal_upload_" + file_connection_id)[0].files[0];

                if( file ){
                    var fd     = new FormData();
                    var param = {};
                    
                    param.file_connection_id = file_connection_id;
                    if(this.fileCon.friendly_name == 'תמונת פספורט'){
                        param.isProfilePic = true;
                    }

                    fd.append('file', file);
                    fd.append('fileData', JSON.stringify(param));

                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload?id=' + attrs.userid + '&logicId=1',
                        fd: fd,
                        success: function(res){
                            scope.$apply(function() {
                                scope.$emit('fileViewUpload:uploadSuccess', file_connection_id);
                            });
                        }
                    });
                }
            };
    
            scope.deleteFile = function(){
                var params = {file_connection_id: scope.fileCon.id};
                if(scope.fileCon.friendly_name == 'תמונת פספורט'){
                    params = angular.extend(params, {isProfilePic: true});
                }

                Ajax.post({
                    module: 'common',
                    url: 'file/delete-file',
                    params: params,
                    success: function(res){
                        scope.$emit('fileViewUpload:deleteSuccess', res);
                    },
                    successMessage: 'הקובץ נמחק בהצלחה'
                });
            };
        }
    };
}]);