app.directive('massiveUpdates', ['$filter', '$location', '$window', 'Ajax', 'Utils', '$timeout',  function ($filter, $location, $window, Ajax, Utils, $timeout) {
    return {
        restrict: 'AEC',
        scope: false,
        transclude: false,
        templateUrl: '/js/app/partials/list/massiveUpdatesTpl.html',
        link: function(scope, element, attrs) {  
            scope.loadMassiveUpdatesData        = true;
            scope.selectedMassiveUpdateFields   = [];
            scope.massUpdate = [];
            
            
            scope.init = function(){
               scope.showMassiveUpdatesBox  = false;
            };
            scope.init();
            
            scope.showBox = function(){
                if(!scope.loadMassiveUpdatesData){
                    scope.showMassiveUpdatesBox = !scope.showMassiveUpdatesBox;
                    return;
                }

                Ajax.get({
                    url: 'list/get-massive-updates', 
                    params: {id: Utils.GET().id},
                    success: function(res) { 
                        scope.selectedMassiveUpdateFields = res.systemList.massive_updates ? res.systemList.massive_updates : [];
                        
                        scope.loadMassiveUpdatesData    = false;
                        scope.showMassiveUpdatesBox     = true;
                    }
                });
            };
            
            scope.chekPermition = function(item){
                return YT.user.role_id <= item.role;
            };
            
            scope.applyMassUpdate = function(){
                var ids = $('input[name=checkboxlist]:checked').map(function() {
                    return this.value;
                }).get();
                
                if(ids.length == 0){
                    alert('אנא בחר רשומות לפני לחיצה על החל');
                    return;
                }
                
                Ajax.post({
                    url: 'list/apply-mass-update',
                    params: {id: Utils.GET().id},
                    data: {massUpdate: scope.massUpdate, ids: ids},
                    success: function(res){
                        Utils.refresh();
                    }
                });
            };
        }
    };
}]);