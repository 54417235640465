'use strict';

var commentPopupCtrl = function($scope, $modalInstance, $modal, $filter, Ajax, request_id, public_note, private_note){
    $scope.comment = {
        note_from_committee_public: public_note,
        note_from_committee: private_note
    };
      
    $scope.ok = function () {
        Ajax.post({
            url:    'committee/committee-note',
            params: {request_id: request_id}, 
            data:   $scope.comment,
            success: function(res){
                $modalInstance.close($scope.comment);
            }
        });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};

app.directive('studentDiscussed', function($compile, $http, $modal, $log, $timeout, $filter, Ajax, PageContentOnresize){
    return {
        restrict: 'AEC',
        replace: false,
        scope: true,
        templateUrl: '/js/app/partials/student_discussedTpl.html',
        link: function(scope, elem, attrs) {
            scope.expend = false;
            
            scope.getAge = function(birth_date){
                var birthYear = $filter('date')(birth_date, 'yyyy');
                var currentYear = $filter('date')(new Date(), 'yyyy');

                return currentYear - birthYear;   
            };

            scope.showExpend = function(e){
                scope.expend = true;
            };
            
            scope.hideExpend = function(){
                scope.expend = false;
            };
            
            scope.openCommentPopup = function(i){
                
                var modalInstance = $modal.open({
                    templateUrl: '/js/app/partials/comment_popup_tpl.html',
                    controller: commentPopupCtrl,
                    size: 'lg',
                    resolve: {
                        public_note: function () {
                            return scope.stu.requests[i].note_from_committee_public;
                        },
                        private_note: function () {
                            return scope.stu.requests[i].note_from_committee;
                        },
                        request_id : function(){
                            return scope.stu.requests[i].id;
                        }
                    }
                });

                modalInstance.result.then(function (comment) {
                    scope.stu.requests[i].note_from_committee_public    = comment.note_from_committee_public;
                    scope.stu.requests[i].note_from_committee           = comment.note_from_committee;
                   // $log.info(scope.stu);
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            };

            scope.decision = function(status_id, request_id, index){
                Ajax.post({
                    url:    'committee/committee-decision',
                    params: {request_id: request_id}, 
                    data:   {lpi_id_status_commitee_decision: status_id},
                    success: function(res){
                        if(status_id == 42){
                            scope.openCommentPopup(index);
                            scope.init();
                            return;
                        }
                        
                        $timeout(function () {
                            scope.stu.requests[index].lpi_id_status_commitee_decision = res.data;
                            scope.$emit('ngRepeatFinished');
                            if(status_id == 40){
                                scope.openCommentPopup(index);
                            }
                        });
                    }
                });
            };
            
            scope.cancelDecision = function(request_id, index){
                Ajax.post({
                    url:    'committee/cancel-decision',
                    params: {request_id: request_id}, 
                    success: function(res){
                        scope.stu.requests[index].lpi_id_status_commitee_decision = res.data;
                        $timeout(function () {
                            scope.$emit('ngRepeatFinished');
                        });
                    }
                });
            };
            
            if (scope.$last === true) {
                PageContentOnresize.run();
                $timeout(function () {
                    scope.$emit('ngRepeatFinished');
                });
            }
            
        }
    };
});