'use strict';

app.controller('PortalDocCtrl', ['$scope', '$http', 'Ajax', 'Utils', function($scope, $http, Ajax, Utils){
 
    $scope.fileSelected = {};
 
    Ajax.get({
        module: 'academyportal',
        url:    'initial/get-student-files',
        success: function(res){
            $scope.studentFiles = res.studentFiles;
        }
    });
 
 
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
 
    $scope.uploadNoneFileConnectionFile = function(){
        var file    = $("#uploadNewFile")[0].files[0];
        var fd      = new FormData();
        var sf      = $scope.studentFiles[$scope.fileSelected];

        var param = {};
        sf.group_line_id = sf.id;
        sf.friendly_name = sf.name;
        sf.is_sensitive = 1;

        fd.append('file', file);
        fd.append('fileData', JSON.stringify( sf ));
        
        Ajax.jqueryUploadFile({
            module: 'common',
            url: 'file/upload?id=' + $scope.globals.user.user_id + '&logicId=1',
            fd: fd,
            success: function(res){
                $("#uploadNewFile").replaceWith( $("#uploadNewFile").clone() ); 
                $scope.fileSelected = "";
                $scope.getData();
            }
        });
    };
 
    $scope.uploadFile = function() {    
        var file_connection_id  = this.doc.id;
        var file                = $("#portal_upload_" + file_connection_id)[0].files[0];
        
        if( file ){
            
            var ext = $("#portal_upload_" + file_connection_id).val().match(/\.(.+)$/)[1];
            
            if ( !ext.match(/(?:gif|GIF|jpg|JPG|jpeg|JPEG|png|PNG|bmp|BMP)$/) && this.doc.friendly_name == 'תמונת פספורט') {
                alert('פספורט חייב להיות מסוג תמונה');
                return;
            }
            
            var fd      = new FormData();

            var param = {};
            param.file_connection_id = file_connection_id;
            param.is_sensitive = 1;
            
            if(this.doc.friendly_name == 'תמונת פספורט'){
                param['isProfilePic'] = true;
            }

            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
            

            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload?id=' + $scope.globals.user.user_id + '&logicId=1',
                fd: fd,
                success: function(res){
                    if( param['isProfilePic'] ){
                        Utils.refresh();
                    }else{
                        $scope.getData();
                    }
                }
            });
        }
    };
    
    $scope.deleteFile = function(doc){
        var params = {file_connection_id: doc.id};
        if( doc.friendly_name == 'תמונת פספורט'){
            params = angular.extend(params, {isProfilePic: true});
        }
        
        Ajax.post({
            module: 'common',
            url: 'file/delete-file',
            params: params,
            success: function(res){
                if( params.isProfilePic ){
                    Utils.refresh();
                }else{
                    $scope.getData();
                }
            },
            successMessage: 'הקובץ נמחק בהצלחה'
        });
    };

}]);