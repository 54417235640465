'use strict';

app.directive('orgDetailsCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl:'/js/app/directives/orgDetailsCard/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
            scope.communityTags = [];

            scope.init = function(){
                Ajax.get({
                    module: 'ytcrm',
                    url: 'organization/get-organization-details',
                    params: {id: scope.objectid},
                    success: function(res) { 
                        scope.data = res.data;
                        scope.$emit('organization:getSuccess', 'view', res);
                        // if (mod == 'edit'){
                            if (scope.data.community_association)  {
                                scope.communityTags = scope.data.community_association.split(",");
                            }
                        // }
                        scope.tagsToArr();

                    }
                });
            };
            scope.init();
            
            scope.save = function(){
                // this save function for saving tags in view mode
                Ajax.post({
                    module: 'ytcrm',
                    url: 'organization/save', 
                    params: {id: scope.data.id},
                    data: scope.data,
                    success: function(res) { 
                        if(res.data.tags!=null){
                            scope.tagsArr = res.data.tags.split("|");
                            if(scope.tagsArr[scope.tagsArr.length-1]==''){
                                scope.tagsArr.splice(0,1);
                            }
                        }
                    }
                });
            };
            
            scope.openMailPopup = function(br){
                if (!br){
                    mailMerge.openMailPopup(scope.data, 82); // 82 = family logic id
                }else{
                    mailMerge.openMailPopup(br,92); // 92 = BudgetRequest logic id
                }
            };

            scope.tagsToArr = function(){
                if (scope.data.tags!=null)  {
                    scope.tagsArr = scope.data.tags.split("|");
                    if(scope.tagsArr[scope.tagsArr.length-1]==''){
                        scope.tagsArr.splice(0,1);
                    }
                }
            };
            
            scope.removeTag = function($index){
                scope.communityTags.splice($index, 1);
                scope.data.community_association = scope.communityTags.join();
            };
            
            scope.isCommunityTagExist = function(valueName){
                if ($filter('filter')(scope.communityTags,valueName)[0]){
                    return true;
                }
                return false;
            };
		}
    };
}]);
