'use strict';

app.controller('GridController', ['$timeout', '$rootScope', '$scope', '$filter', 'Ajax', 'Utils', '$location', '$window', '$modal', 'Popup', 'mailMerge', 
function($timeout, $rootScope, $scope, $filter, Ajax, Utils, $location, $window, $modal, Popup, mailMerge) {
    $scope.asignmentId = null;
	    
    $timeout(function(){
        $("#checkAll").change(function () {
            $("input[name=checkboxlist]").prop('checked', $(this).prop("checked"));
            $scope.showSelectIdsCount ();
        });
    }, 1000);

    $scope.showSelectIdsCount = (timeout) => {
        if (timeout) {
            $timeout(function(){
                $scope.setSelectIdsCount();
            }, 200);
        }else{
            $scope.setSelectIdsCount();
        }
    };

    $scope.setSelectIdsCount = (timeout) => {
        var ids = [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });
        $scope.selectedIdsCount = ids.length;
    };

    $scope.export = function(id, type){
        var sort    = Utils.getParameterByName('sort') ? "&sort=" + Utils.getParameterByName('sort')    : "";
        var sft     = Utils.getParameterByName('sft')  ? "&sft=" + Utils.getParameterByName('sft')      : "";
        var con     = Utils.getParameterByName('con')  ? "&con=" + Utils.getParameterByName('con')      : "";
        var node    = Utils.getParameterByName('node') ? "&node=" + Utils.getParameterByName('node')    : "";

        if(type == 'pdf'){
            $window.open("/common/list/export?id=" + id + "&type=" + type + sort + sft + con + node, '_blank');
        }else{
            //$window.location.href = "/common/list/export?id=" + id + "&type=" + type + sort + sft + con + node;
            Ajax.get({
				module: 'common',
				url: 'list/export?id=' + id + '&type=' + type + sort + sft + con + node, 
				notShowMessage: true,
				success: function(res) { 
					let csvContent = "data:text/csv;charset=utf-8,\uFEFF" + res.rows.map(e => e.join(",")).join("\n");
                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "report.csv");
                    document.body.appendChild(link); // Required for FF
                    link.click();
				}
			});

        }
    }
    	
    $scope.getIdsSelected = function(){
        var ids = [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        if( !ids.length ){
            alert('אנא בחר רשומות');
            return false;
        }

        return ids;
    };
	
    $scope.openAddToPopup = function(addToName, logicId){
        if( !$scope.getIdsSelected() ){
                return false;
        }

        var controllerName = addToName + 'PopupController';
        
        if (addToName == 'sendcustomemail'){
            var ids = [];
            $("input[name=checkboxlist]:checked").each(function() {
                ids.push( $(this).val() );
            });

            var url     = new URL(window.location.href);
            var listId  = url.searchParams.get("id");

            mailMerge.openMailPopup(ids, logicId, true);
            return; // must return in this point!!!
        }
        
        var modalInstance = $modal.open({
                templateUrl: '/js/app/partials/addTo/' + addToName + '.html'  + window.YT.project.version,
                controller: controllerName,
                size: 'lg'
        });

        modalInstance.result.then(function () {
            Utils.refresh();
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    };
     
    $scope.openHelpPopup = function(description){
        var data = {};
        data.description = description;
        Popup.open('gridHelp', {}, data);
    };
}]);

var setWizard = function(){
    setTimeout(function(){
        $(".wizard").smartWizard({
			noForwardJumping: true,
			includeFinishButton: false,
			labelNext: '',
			labelPrevious: ''
		});

		$(".wizard").smartWizard('hideMessage');
    });    
};


var yearPopupController = function($scope, $modalInstance, $modal, $filter, Ajax,){
	setWizard();
    
	$scope.yearsPicklist	= null;
	$scope.isCurrentYear	= false;
	$scope.yearId			= null;
	$scope.schoolYear		= null;
	$scope.count			= null;
	$scope.showNext			= true;
	
	Ajax.get({
		module: 'common',
		url: 'add-to/get-lookup-years', 
		success: function(res) { 
			$scope.yearsPicklist = res.lookupYears;
		}
	});
	
	$scope.next = function(){
		$scope.showPrev = true;
		if( $(".wizard").smartWizard('currentStep') == 1){
			if( !$scope.yearId ){
				alert('חובה לבחור את השנה שברצונך להוסיף');
				return false;
			}
			
			$(".wizard").smartWizard('goForward');
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/year-effect', 
				params: {yearId: $scope.yearId, isCurrentYear: $scope.isCurrentYear ? 1 : 0},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
				}
			});
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
	
	$scope.addYear = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/year', 
            params: {yearId: $scope.yearId, schoolYear: $scope.schoolYear, isCurrentYear: $scope.isCurrentYear ? 1 : 0},
            data: {ids: ids},
            success: function(res) {
                $scope.actionSummary = res.data.actionSummary;
                $scope.showPrev      = false;
                $scope.showNext     = false;
                $(".wizard").smartWizard('goForward');
				//$modalInstance.close();
            }
        });
    };
    
    $scope.close = function(){
		$modalInstance.close();
	};
};

var semesterPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.isCurrentSemester = false;
	$scope.semester = null;
	$scope.count = null;
	$scope.showNext = true;
	
	$scope.next = function(){
		
		if( $(".wizard").smartWizard('currentStep') == 1){
			if( !$scope.semester ){
				alert('חובה לבחור את הסמסטר שברצונך להוסיף');
				return;
			}
			
			$(".wizard").smartWizard('goForward');
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/semester-effect', 
				params: {semester: $scope.semester},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
				}
			});
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
	
	$scope.addSemester = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        Ajax.post({
            module: 'common',
            url: 'add-to/semester', 
            params: {semester: $scope.semester, isCurrentSemester: $scope.isCurrentSemester ? 1 : 0},
            data: {ids: ids},
            success: function(res) {
                $modalInstance.close();
            }
        });
    };
};

var eligibilityPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.count	= null;
	$scope.showNext	= true;
	
	
	$scope.init = function(){
		var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        Ajax.post({
            module: 'common',
            url: 'add-to/eligibility-effect', 
            data: {ids: ids},
            success: function(res) { 
				$scope.count = res.count;
            }
        });
		//$scope.count = ids.length;
	};
	$scope.init();
	
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 2 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 2 ? false : true;
	};
	
	$scope.runEligibilityValidation = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/eligibility', 
            data: {ids: ids},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
};

var assignmentPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
    
	$scope.asignmentId = null;
	$scope.asignmentCount = null;
	
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');

		if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.asignmentId ){
				alert('חובה להזין מספר מטלה');
				$(".wizard").smartWizard('goToStep', 1);
				return;
			}
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/asignment-effect', 
				params: {asignment_id: $scope.asignmentId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.asignmentCount = res.asignmentCount;
				}
			});
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToAsignment = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/asignment', 
            params: {asignment_id: $scope.asignmentId},
            data: {ids: ids},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
};

var sendemailPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
    setWizard();
        
	$scope.templatetId = null;
	$scope.sendemailCount = null;
	
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');

		if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.templateId ){
				alert('חובה לבחור תבנית מייל');
				$(".wizard").smartWizard('goToStep', 1);
				return;
			}
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/sendemail-effect', 
				params: {templateId: $scope.templateId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.sendemailCount = res.sendemailCount;
				}
			});
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToSendemail = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/sendemail', 
            params: {templateId: $scope.templateId, logicId: 19},
            data: {ids: ids},
            success: function(res) { 
                $modalInstance.close();
            }
        });
    };
};


var sendemailbudgetrequestPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.templatetId = null;
	$scope.sendemailCount = null;
	
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');

		if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.templateId ){
				alert('חובה לבחור תבנית מייל');
				$(".wizard").smartWizard('goToStep', 1);
				return;
			}
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/sendemail-effect', 
				params: {templateId: $scope.templateId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.sendemailCount = res.sendemailCount;
				}
			});
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToSendemail = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/sendemail-budget-request', 
            params: {templateId: $scope.templateId, logicId: 20},
            data: {ids: ids},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
}

var senddecisionemailPopupController = function($scope, $modalInstance, $modal, $filter, Ajax, Utils){

	setWizard();
	
    //$scope.templatetId = null;
    $scope.approvedCount = 0;
    $scope.rejectedCount = 0;
    $scope.summaryApprovedCount = null;
    $scope.summaryRejectedCount = null;
	
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });

    Ajax.post({
        module: 'common',
        url: 'add-to/send-decision-email-effect', 
        params: {systemListId: Utils.GET().id},
        data: {ids: ids},
        notShowMessage: true,
        success: function(res) { 
            $scope.approvedCount = res.approvedCount;
            $scope.rejectedCount = res.rejectedCount;
        }
    });
    
    $scope.next = function(){
            $(".wizard").smartWizard('goForward');

            if( $(".wizard").smartWizard('currentStep') == 1){


            }
    };
		
    $scope.prev = function(){
            $(".wizard").smartWizard('goBackward');
    };
	
    $scope.close = function(){
	$modalInstance.close();
    };
    
    $scope.addToSendemail = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
                ids.push( $(this).val() );
        });
        $(".wizard").smartWizard('goForward');
        Ajax.post({
            module: 'common',
            url: 'add-to/send-decision-email', 
            params: {systemListId: Utils.GET().id},
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) {
                $scope.summaryApprovedCount = res.summaryApprovedCount;
                $scope.summaryRejectedCount = res.summaryRejectedCount;
                $(".wizard").smartWizard('goForward');
                //$modalInstance.close();
            }
        });
    };
}

var sendemailinvoicePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	//$scope.templatetId = null;
	$scope.count = null;
    
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });

    Ajax.post({
        module: 'common',
        url: 'add-to/send-email-invoice-effect', 
        data: {ids: ids},
        notShowMessage: true,
        success: function(res) { 
            $scope.count = res.count;
        }
    });
    
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');

		if( $(".wizard").smartWizard('currentStep') == 1){
			
			
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
    $scope.close = function(){
		$modalInstance.close();
	};
    
	$scope.addToSendemail = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/send-email-invoice', 
            params: {logicId: 35},
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) {
                $scope.count = res.count;
                $(".wizard").smartWizard('goForward');
                //$modalInstance.close();
            }
        });
    };
}

var sendemailsupplierPopupController = function($scope, $modalInstance, $modal, $filter, Ajax, ){
	setWizard();
	
	$scope.templatetId = null;
	$scope.sendemailCount = null;
	
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');

		if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.templateId ){
				alert('חובה לבחור תבנית מייל');
				$(".wizard").smartWizard('goToStep', 1);
				return;
			}
			
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});

			Ajax.post({
				module: 'common',
				url: 'add-to/sendemail-effect', 
				params: {templateId: $scope.templateId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.sendemailCount = res.sendemailCount;
				}
			});
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToSendemail = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/sendemail-supplier', 
            params: {templateId: $scope.templateId, logicId: 30},
            data: {ids: ids},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
};

var committeePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.committeesPicklist	= null;
	$scope.committeeId			= null;
    $scope.decisionSelected     = null;    
	$scope.count                = null;
    $scope.approvedCount        = 0;
    $scope.rejectedCount        = 0;
    $scope.unknownCount         = 0;
	$scope.showNext             = true;
    $scope.showPrev             = true;
	
    $scope.budgetName                   = null;
    $scope.framework                    = null;
    $scope.availableBudget              = null;
    $scope.sumCurrentBudgetRequests     = null;
    $scope.balanceAfterCommittee        = null;
    $scope.budgetReviewByOrganization   = null;
    
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });
	Ajax.post({
		module: 'common',
		url: 'add-to/get-committees-picklist',
        params: {objectId: 20},
        data: {ids: ids},
		success: function(res) { 
			$scope.committeesPicklist       = res.committeesPicklist;
            $scope.committeeId              = res.committeeId;
            $scope.ihptExceptionPatients    = res.ihptExceptionPatients;
            if ($scope.ihptExceptionPatients.length == 0){
                $(".wizard").smartWizard('goForward');
            }
		}
	});
	
	$scope.next = function(){
        if( $(".wizard").smartWizard('currentStep') == 2){
            if( !$scope.committeeId ){
				alert('חובה לבחור את הועדה');
				return false;
			}
			$(".wizard").smartWizard('goForward');
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});
            Ajax.post({
				module: 'common',
				url: 'add-to/committee-effect', 
				params: {committeeId: $scope.committeeId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
                }
			});
		}else if( $(".wizard").smartWizard('currentStep') == 3){
			if( !$scope.decisionSelected ){
				alert('חובה לבחור החלטה');
				return false;
			}
			
			$(".wizard").smartWizard('goForward');
			
            if ($scope.decisionSelected == 1){
                var ids		= [];
                $("input[name=checkboxlist]:checked").each(function() {
                    ids.push( $(this).val() );
                });

                Ajax.post({
                    module: 'common',
                    url: 'add-to/committee-decision-effect', 
                    params: {committeeId: $scope.committeeId},
                    data: {ids: ids},
                    notShowMessage: true,
                    success: function(res) { 
                        $scope.approvedCount = res.approvedCount;
                        $scope.rejectedCount = res.rejectedCount;
                        $scope.unknownCount  = res.unknownCount;
                    }
                });
            }else{
                if ($scope.decisionSelected == 2){
                    $scope.approvedCount        = $scope.count;
                    $scope.rejectedCount        = 0;
                    $scope.unknownCount         = 0;
                }else if ($scope.decisionSelected == 3){
                    $scope.rejectedCount        = $scope.count;
                    $scope.approvedCount        = 0;
                    $scope.unknownCount         = 0;
                }
            }
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 4 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 4 ? false : true;
	};
	
    $scope.close = function(){
		$modalInstance.close();
	};
    
	$scope.addCommittee = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/committee', 
            params: {committeeId: $scope.committeeId, decision: $scope.decisionSelected},
            data: {ids: ids},
            success: function(res) { 
                //$modalInstance.close();
                $scope.approvedCount = res.approvedCount;
                $scope.rejectedCount = res.rejectedCount;
                $scope.unknownCount  = res.unknownCount;
                $scope.showPrev      = false;
                $scope.showNext     = false;
                
                $(".wizard").smartWizard('goForward');
                
            }
        });
    };
};

var assignmentdonePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.asignmentCount           = null;
    $scope.autoEventCreationCount   = null;
	
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/assignmentdone-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.asignmentCount = res.count;
                $scope.autoEventCreationCount = res.countAutoEventCreation;
            }
        });
    }
    $scope.getEfectInfo();
    
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');
    	if( $(".wizard").smartWizard('currentStep') == 1){
            // not necessary
		}
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToAsignmentdone = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/assignmentdone', 
            data: {ids: ids},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
};

var treatmentdecisionPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.successCount           = null;
    $scope.warningsCount   = null;
	
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-decision-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.successCount         = res.successCount;
                $scope.warningsCount        = res.warningsCount;
                $scope.failedCount          = res.failedCount;
                $scope.seriousFailedCount   = res.seriousFailedCount;
            }
        });
    }
    
    $scope.getWarningDetails = function(){
        $(".wizard").smartWizard('goForward');
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });
        
        Ajax.post({
            module: 'common',
            url: 'add-to/get-warning-details', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.warningsDetailsCount = res.warningsDetailsCount;
            }
        });
    }
    
    $scope.getEfectInfo();
    
	$scope.next = function(){
		if( $(".wizard").smartWizard('currentStep') == 1){
            if (!$scope.rank){
                alert('עליך לבחור את אחת מהאפשרויות לפני המעבר לשלב הבא');
                return;
            }
            
		    if($scope.rank==1){
                $scope.getWarningDetails();
            }
            $(".wizard").smartWizard('goForward');
		}
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
	};
	
	$scope.addToTreatmentDecision = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-decision', 
            data: {ids: ids},
            params: {rank: $scope.rank},
            success: function(res) { 
				$modalInstance.close();
            }
        });
    };
};

var invoicecreationPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
    $scope.showNext         = true;
	$scope.showPrev         = true;
	$scope.treatmentsCount  = null;
    $scope.invoicesCount = null;
    $scope.newInvoicesCount = null;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/invoice-creation-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.treatmentsCount  = res.treatmentsCount;
                $scope.invoicesCount    = res.invoicesCount;
                if($scope.treatmentsCount == 0){
                    $scope.showPrev     = false;
                    $scope.showNext     = false;
                }
            }
        });
    }
    
    $scope.getEfectInfo();
    
	$scope.next = function(){
        
        if( $(".wizard").smartWizard('currentStep') == 1){
            if ($scope.treatmentsCount == 0){
                alert('לא נמצאו מפגשים הממתינים ליצירת חשבונית');
                $scope.showPrev     = false;
                $scope.showNext     = false;
                return;
            }
        }
        
        $(".wizard").smartWizard('goForward');
        
		$scope.showNext = $(".wizard").smartWizard('currentStep') > 1 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
        if( $(".wizard").smartWizard('currentStep') == 1){
            $scope.showNext = true;
        }
	};
	
	$scope.addToInvoiceCreation = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/invoice-creation', 
            data: {ids: ids},
            params: {rank: $scope.rank},
            success: function(res) { 
                $scope.newInvoicesCount = res.newInvoicesCount;
                $(".wizard").smartWizard('goForward');
                $scope.showPrev     = false;
                $scope.showNext     = false;
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var treatmentGroupLinesInvoiceCreationPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
    $scope.showNext         = true;
	$scope.showPrev         = true;
	$scope.treatmentsCount  = null;
    $scope.invoicesCount = null;
    $scope.newInvoicesCount = null;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-group-lines-invoice-creation-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.treatmentsCount  = res.treatmentsCount;
                $scope.invoicesCount    = res.invoicesCount;
                if($scope.treatmentsCount == 0){
                    $scope.showPrev     = false;
                    $scope.showNext     = false;
                }
            }
        });
    }
    
    $scope.getEfectInfo();
    
	$scope.next = function(){
        
        if( $(".wizard").smartWizard('currentStep') == 1){
            if ($scope.treatmentsCount == 0){
                alert('לא נמצאו מפגשים הממתינים ליצירת חשבונית');
                $scope.showPrev     = false;
                $scope.showNext     = false;
                return;
            }
        }
        
        $(".wizard").smartWizard('goForward');
        
		$scope.showNext = $(".wizard").smartWizard('currentStep') > 1 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
        if( $(".wizard").smartWizard('currentStep') == 1){
            $scope.showNext = true;
        }
	};
	
	$scope.addToInvoiceCreation = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-group-lines-invoice-creation', 
            data: {ids: ids},
            params: {rank: $scope.rank},
            success: function(res) { 
                $scope.newInvoicesCount = res.newInvoicesCount;
                $(".wizard").smartWizard('goForward');
                $scope.showPrev     = false;
                $scope.showNext     = false;
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var treatmentreviewPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
    $scope.showNext         = true;
	$scope.showPrev         = false;
    $scope.showClose        = false;
	$scope.treatmentsCount  = null;
    
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-review-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.treatmentsCount  = res.treatmentsCount;
                if($scope.treatmentsCount == 0){
                    $scope.showNext     = false;
                    $scope.showClose    = true;
                }
            }
        });
    }
    
    $scope.getEfectInfo();
    
	$scope.next = function(){
        
        if( $(".wizard").smartWizard('currentStep') == 1){
            if ($scope.treatmentsCount == 0){
                alert('לא נמצאו מפגשים בסטטוס תהליך');
                $scope.showPrev     = false;
                $scope.showNext     = false;
                $scope.showClose    = true;
                return;
            }
            $scope.showPrev     = true;
        }
        
        $(".wizard").smartWizard('goForward');
        
		$scope.showNext = $(".wizard").smartWizard('currentStep') > 1 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
        if( $(".wizard").smartWizard('currentStep') == 1){
            $scope.showNext = true;
            $scope.showPrev = false;
        }
    };
	
	$scope.addToTreatmentReview = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/treatment-review', 
            data: {ids: ids},
            params: {rank: $scope.rank},
            success: function(res) { 
                $scope.treatmentsCount = res.treatmentsCount;
                $(".wizard").smartWizard('goForward');
                $scope.showPrev     = false;
                $scope.showNext     = false;
                $scope.showClose    = true;
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var kdmsenddecisionemailPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){

	setWizard();
	
    //$scope.templatetId = null;
    $scope.approvedCount = 0;
    $scope.summaryApprovedCount = null;
    $scope.summaryRejectedCount = null;
	
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });

    Ajax.post({
        module: 'common',
        url: 'add-to/kdm-send-decision-email-effect', 
        //params: {templateId: $scope.templateId},
        data: {ids: ids},
        notShowMessage: true,
        success: function(res) { 
            $scope.approvedCount = res.approvedCount;
        }
    });
    
    $scope.next = function(){
            $(".wizard").smartWizard('goForward');

            if( $(".wizard").smartWizard('currentStep') == 1){


            }
    };
		
    $scope.prev = function(){
            $(".wizard").smartWizard('goBackward');
    };
	
    $scope.close = function(){
	$modalInstance.close();
    };
    
    $scope.addToSendemail = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
                ids.push( $(this).val() );
        });
        $(".wizard").smartWizard('goForward');
        Ajax.post({
            module: 'common',
            url: 'add-to/kdm-send-decision-email', 
            params: {logicId: 92},
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) {
                $scope.summaryApprovedCount = res.summaryApprovedCount;
                $(".wizard").smartWizard('goForward');
                //$modalInstance.close();
            }
        });
    };
};


var sendcustomemailPopupController = function($scope, $modalInstance, $modal, $filter, Ajax, mailMerge){
    setWizard();
    
//    var ids = [];
//    $("input[name=checkboxlist]:checked").each(function() {
//            ids.push( $(this).val() );
//    });
//    
//    var url = new URL(window.location.href);
//    var c = url.searchParams.get("c");
//        
//    mailMerge.openMailPopup(ids,19,true); // 3 = request(logic_id) in academy
};

var formPopupController = function($scope, $modalInstance, $modal, $filter, Ajax, Utils){
	setWizard();
	
	$scope.usersCounter = null;
	$scope.forms        = null;
    $scope.formId       = null;
    $scope.addToAll = 0;
    $scope.showNext         = true;
    $scope.showPrev         = false;
    $scope.showClose        = true;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/form-effect',
            params: {
                formId:     $scope.formId,
                filledTime: $scope.filledTime,
                yearId:     $scope.yearId
            },
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.usersCount   = res.usersCount;
                $scope.selectedIdsCount = ids.length;
            }
        });
    }

    $scope.getPicklists = function(){
        Ajax.get({
            module: 'common',
            url: 'add-to/get-form-picklists',
            params: {systemListId: Utils.GET().id},
            notShowMessage: true,
            success: function(res) { 
                $scope.forms        = res.forms;
                $scope.filledTimes  = res.filledTimes;
            }
        });
    }
    
    $scope.getPicklists();
    
	$scope.next = function(){
    	if( $(".wizard").smartWizard('currentStep') == 1){
            $scope.getEfectInfo();
		}
        $(".wizard").smartWizard('goForward');
        $scope.setButtonShow();
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
        $scope.setButtonShow();
	};
	
    $scope.setButtonShow = () => {
        let currentStep = $(".wizard").smartWizard('currentStep');
        $scope.showNext = currentStep == 1 ? true : false;
        $scope.showPrev = currentStep == 2 ? true : false;
        $scope.showClose = (currentStep == 1 || currentStep === 3) ? true : false;
    };

	$scope.addToForm = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/form',
            params: {
                formId: $scope.formId,
                addToAll: $scope.addToAll,
                filledTime: $scope.filledTime,
                yearId: $scope.yearId
            },
            data: {ids: ids},
            success: function(res) { 
				$scope.next();
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var closeRequestsPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.requestsCount    = null;
	$scope.forms            = null;
    $scope.formId           = null;
    $scope.showNext         = true;
    $scope.showPrev         = false;
    $scope.showClose        = false;
    $scope.createNewBudget  = 0;
    $scope.notUtiliziedReqStatus = null;
    $scope.utiliziedReqStatus = null;
    $scope.closedBudgetRequestCounter = 0;
    $scope.continuationBudgetsCounter = 0;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/close-requests-effect', 
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.requestsCount            = res.requestsCount;
                $scope.notUtiliziedReqStatus    = res.notUtiliziedReqStatus;
                $scope.utiliziedReqStatus       = res.utiliziedReqStatus;
            }
        });
    }
    $scope.getEfectInfo();
    
	$scope.next = function(){
		$(".wizard").smartWizard('goForward');
    	if( $(".wizard").smartWizard('currentStep') == 1){
            // not necessary
        }
        $scope.showNext = $(".wizard").smartWizard('currentStep') === 1 ? true : false;
        $scope.showPrev = $(".wizard").smartWizard('currentStep') === 2 ? true : false;
        $scope.showClose = $(".wizard").smartWizard('currentStep') === 3 ? true : false;
    };
		
	$scope.prev = function(){
        $(".wizard").smartWizard('goBackward');
        $scope.showNext = $(".wizard").smartWizard('currentStep') === 1 ? true : false;
        $scope.showPrev = $(".wizard").smartWizard('currentStep') === 2 ? true : false;
        $scope.showClose = $(".wizard").smartWizard('currentStep') === 3 ? true : false;
	};
	
	$scope.closeRequests = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/close-requests',
            params: {
                createNewBudget: $scope.createNewBudget,
                notUtiliziedReqStatus: $scope.notUtiliziedReqStatus,
                utiliziedReqStatus: $scope.utiliziedReqStatus,
            },
            data: {ids: ids},
            success: function(res) {
                $scope.closedBudgetRequestCounter = res.closedBudgetRequestCounter;
                $scope.continuationBudgetsCounter = res.continuationBudgetsCounter;
                $scope.next();
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var activityDonePopupController = function($scope, $modalInstance, $modal, $filter, Ajax, Utils){
	setWizard();
	
	$scope.usersCounter = null;
	$scope.forms        = null;
    $scope.formId       = null;
    $scope.addToAll = 0;
    $scope.showNext         = true;
    $scope.showPrev         = false;
    $scope.showClose        = true;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });

        Ajax.post({
            module: 'common',
            url: 'add-to/activity-done-effect',
            data: {ids: ids},
            notShowMessage: true,
            success: function(res) { 
                $scope.effectCount  = res.effectCount;
                $scope.originalCount  = res.originalCount;
            }
        });
    }

    $scope.getEfectInfo();

	$scope.addToActivityDone= function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/activity-done',
            data: {ids: ids},
            success: function(res) { 
				$scope.done = true;
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};

var activityProjectPopupController = function($scope, $modalInstance, $modal, $filter, Ajax, Utils){
	setWizard();
	
	
    $scope.templateId       = null;
    $scope.showNext         = true;
    $scope.showPrev         = false;
    $scope.showClose        = true;
    $scope.selectedIdsCount = 0;
    
    $scope.getEfectInfo = function(){
        var ids		= [];
        $("input[name=checkboxlist]:checked").each(function() {
            ids.push( $(this).val() );
        });
        $scope.selectedIdsCount = ids.length;
    }

    $scope.getPicklists = function(){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-json-lookup',
            params: {settingName: 'MilestoneTemplates'},
            success: function(res){
                $scope.milestoneTemplates = res;
            }
        });
    }
    
    $scope.getPicklists();
    
	$scope.next = function(){
    	if( $(".wizard").smartWizard('currentStep') == 1){
            $scope.getEfectInfo();
		}
        $(".wizard").smartWizard('goForward');
        $scope.setButtonShow();
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
        $scope.setButtonShow();
	};
	
    $scope.setButtonShow = () => {
        let currentStep = $(".wizard").smartWizard('currentStep');
        $scope.showNext = currentStep == 1 ? true : false;
        $scope.showPrev = currentStep == 2 ? true : false;
        $scope.showClose = (currentStep == 1 || currentStep === 3) ? true : false;
    };

	$scope.addToActivityProject = function(){
        var listId = Utils.GET().id;
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/activity-project',
            params: {
                templateId: $scope.templateId,
                listId: listId
            },
            data: {ids: ids},
            success: function(res) { 
				$scope.next();
            }
        });
    };
    
    $scope.close = function(){
        $modalInstance.close();
    };
};


var orderToCommitteePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.committeesPicklist	= null;
	$scope.committeeId			= null;
    $scope.decisionSelected     = null;    
	$scope.count                = null;
    $scope.approvedCount        = 0;
    $scope.rejectedCount        = 0;
    $scope.unknownCount         = 0;
	$scope.showNext             = true;
    $scope.showPrev             = true;
	
    $scope.budgetName                   = null;
    $scope.framework                    = null;
    $scope.availableBudget              = null;
    $scope.sumCurrentBudgetRequests     = null;
    $scope.balanceAfterCommittee        = null;
    $scope.budgetReviewByOrganization   = null;
    
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });
	Ajax.get({
		module: 'common',
		url: 'add-to/get-committees-picklist', 
        data: {ids: ids},
		success: function(res) { 
			$scope.committeesPicklist       = res.committeesPicklist;
            $scope.committeeId              = res.committeeId;
            $scope.ihptExceptionPatients    = res.ihptExceptionPatients;
            // if ($scope.ihptExceptionPatients.length == 0){
            //     $(".wizard").smartWizard('goForward');
            // }
		}
	});
	
	$scope.next = function(){
        if( $(".wizard").smartWizard('currentStep') == 1){
            if( !$scope.committeeId ){
				alert('חובה לבחור את הועדה');
				return false;
			}
			$(".wizard").smartWizard('goForward');
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});
            Ajax.post({
				module: 'common',
				url: 'add-to/order-to-committee-effect', 
				params: {committeeId: $scope.committeeId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
                }
			});
		}else if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.decisionSelected ){
				alert('חובה לבחור החלטה');
				return false;
			}
			
			$(".wizard").smartWizard('goForward');
			
            // if ($scope.decisionSelected == 1){
            //     var ids		= [];
            //     $("input[name=checkboxlist]:checked").each(function() {
            //         ids.push( $(this).val() );
            //     });

            //     Ajax.post({
            //         module: 'common',
            //         url: 'add-to/committee-decision-effect', 
            //         params: {committeeId: $scope.committeeId},
            //         data: {ids: ids},
            //         notShowMessage: true,
            //         success: function(res) { 
            //             $scope.approvedCount = res.approvedCount;
            //             $scope.rejectedCount = res.rejectedCount;
            //             $scope.unknownCount  = res.unknownCount;
            //         }
            //     });
            // }else{
                if ($scope.decisionSelected == 2){
                    $scope.approvedCount        = $scope.count;
                    $scope.rejectedCount        = 0;
                    $scope.unknownCount         = 0;
                }else if ($scope.decisionSelected == 3){
                    $scope.rejectedCount        = $scope.count;
                    $scope.approvedCount        = 0;
                    $scope.unknownCount         = 0;
                }
            // }
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
	
    $scope.close = function(){
		$modalInstance.close();
	};
    
	$scope.addCommittee = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/order-to-committee', 
            params: {committeeId: $scope.committeeId, decision: $scope.decisionSelected},
            data: {ids: ids},
            success: function(res) { 
                //$modalInstance.close();
                $scope.approvedCount = res.approvedCount;
                $scope.rejectedCount = res.rejectedCount;
                $scope.unknownCount  = res.unknownCount;
                $scope.showPrev      = false;
                $scope.showNext     = false;
                
                $(".wizard").smartWizard('goForward');
                
            }
        });
    };
};

var groupsCommitteePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.committeesPicklist	= null;
	$scope.committeeId			= null;
    $scope.decisionSelected     = null;    
	$scope.count                = null;
    $scope.approvedCount        = 0;
    $scope.rejectedCount        = 0;
    $scope.unknownCount         = 0;
	$scope.showNext             = true;
    $scope.showPrev             = true;
	
    $scope.budgetName                   = null;
    $scope.framework                    = null;
    $scope.availableBudget              = null;
    $scope.sumCurrentBudgetRequests     = null;
    $scope.balanceAfterCommittee        = null;
    $scope.budgetReviewByOrganization   = null;
    
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });
	Ajax.get({
		module: 'common',
		url: 'add-to/get-committees-picklist', 
        params: {objectId: 171},
        data: {ids: ids},
		success: function(res) { 
			$scope.committeesPicklist       = res.committeesPicklist;
            $scope.committeeId              = res.committeeId;
            //$scope.ihptExceptionPatients    = res.ihptExceptionPatients;
            // if ($scope.ihptExceptionPatients.length == 0){
            //     $(".wizard").smartWizard('goForward');
            // }
		}
	});
	
	$scope.next = function(){
        if( $(".wizard").smartWizard('currentStep') == 1){
            if( !$scope.committeeId ){
				alert('חובה לבחור את הועדה');
				return false;
			}
			$(".wizard").smartWizard('goForward');
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});
            Ajax.post({
				module: 'common',
				url: 'add-to/groups-committee-effect', 
				params: {committeeId: $scope.committeeId},
				data: {ids: ids},
				notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
                }
			});
		}else if( $(".wizard").smartWizard('currentStep') == 2){
			if( !$scope.decisionSelected ){
				alert('חובה לבחור החלטה');
				return false;
			}
			
			$(".wizard").smartWizard('goForward');
			
            
            if ($scope.decisionSelected == 2){
                $scope.approvedCount        = $scope.count;
                $scope.rejectedCount        = 0;
                $scope.unknownCount         = 0;
            }else if ($scope.decisionSelected == 3){
                $scope.rejectedCount        = $scope.count;
                $scope.approvedCount        = 0;
                $scope.unknownCount         = 0;
            }
            // }
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 3 ? false : true;
	};
	
    $scope.close = function(){
		$modalInstance.close();
	};
    
	$scope.addCommittee = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/groups-committee', 
            params: {committeeId: $scope.committeeId, decision: $scope.decisionSelected},
            data: {ids: ids},
            success: function(res) { 
                //$modalInstance.close();
                $scope.approvedCount = res.approvedCount;
                $scope.rejectedCount = res.rejectedCount;
                $scope.unknownCount  = res.unknownCount;
                $scope.showPrev      = false;
                $scope.showNext     = false;
                
                $(".wizard").smartWizard('goForward');
                
            }
        });
    };
};

var changeCommitteePopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
	
	$scope.committeesPicklist	= null;
	$scope.committeeId			= null;
    $scope.count                = null;
    $scope.count        = 0;
    $scope.showNext             = true;
    $scope.showPrev             = true;
	
    
    var ids		= [];

    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });

	Ajax.post({
		module: 'common',
		url: 'add-to/get-committees-picklist',
        params: {objectId: 20},
        data: {ids: ids},
        notShowMessage: true,
		success: function(res) { 
			$scope.committeesPicklist       = res.committeesPicklist;
            $scope.committeeId              = res.committeeId;
        }
	});
	
	$scope.next = function(){
        if( $(".wizard").smartWizard('currentStep') == 1){
            if( !$scope.committeeId && !confirm('לא נבחרה ועדה התקציבים יוצאו מהועדה אליה הם שייכים וסטטוס הועדה שלהם יוחזר למוכן לועדה, להמשיך?')){
				return;
			}
			$(".wizard").smartWizard('goForward');
			var ids		= [];
			$("input[name=checkboxlist]:checked").each(function() {
				ids.push( $(this).val() );
			});
            Ajax.post({
				module: 'common',
				url: 'add-to/change-committee-effect', 
				data: {ids: ids},
                notShowMessage: true,
				success: function(res) { 
					$scope.count = res.count;
                }
			});
		}else{
			$(".wizard").smartWizard('goForward');
		}
		
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 2 ? false : true;
	};
		
	$scope.prev = function(){
		$(".wizard").smartWizard('goBackward');
		$scope.showNext = $(".wizard").smartWizard('currentStep') === 2 ? false : true;
	};
	
    $scope.close = function(){
		$modalInstance.close();
	};
    
	$scope.addCommittee = function(){
        var ids		= [];
		$("input[name=checkboxlist]:checked").each(function() {
			ids.push( $(this).val() );
		});
        
        Ajax.post({
            module: 'common',
            url: 'add-to/change-committee', 
            params: {committeeId: $scope.committeeId ? $scope.committeeId : null},
            data: {ids: ids},
            success: function(res) { 
                //$modalInstance.close();
                $scope.count = res.count;
                $scope.showPrev      = false;
                $scope.showNext     = false;
                
                $(".wizard").smartWizard('goForward');
                
            }
        });
    };
};

var showPrivateQuotasPopupController = function($scope, $modalInstance, $modal, $filter, Ajax){
	setWizard();
		    
    var ids		= [];
    $("input[name=checkboxlist]:checked").each(function() {
        ids.push( $(this).val() );
    });

	Ajax.post({
		module: 'common',
		url: 'add-to/show-private-quotas',
        data: {ids: ids},
        notShowMessage: true,
		success: function(res) { 
			$scope.orgs = res.orgs;
        }
	});
	
    $scope.quotaTypes = {}; 
    $scope.quotaTypes['patient'] = 'מוטבים';
    // quotaTypes['group'] = 'קבוצות';
    $scope.quotaTypes['budget private'] = 'תקציב פרטני';
    // quotaTypes['budget group'] = 'תקציב קבוצות';
    $scope.quotaTypes['treatment'] = 'מפגשים פרטני';
    // quotaTypes['treatment group'] = 'מפגשים קבוצתיים';

    $scope.close = function(){
		$modalInstance.close();
	};  
	
};