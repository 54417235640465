'use strict';

app.directive('formExternal', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
            moveStep: '=fn1',
            isFieldDisplay: '=fn2'
        },
        templateUrl: '/js/app/directives/formExternal/formExternal.html',
        link: function(scope, element, attrs) {
            scope.$on('ytForm:languageDetailsSet', function(event, res) {
                scope.userMessages = window.ytUserMessages;
                scope.language = window.ytFormLanguage;
            });

            $timeout(function(){
                //Ajax.turnOnValidation();
                if (scope.data) {
                    scope.data = angular.fromJson(scope.data);
                    
                    if(scope.data.titles && scope.data.currentStep && scope.data.titles[scope.data.currentStep].id){
                        $('#tab-name-'+scope.data.titles[scope.data.currentStep].id).trigger('click');
                    }
                    if (scope.data.formSubmission && scope.data.formSubmission.ivrita_gender){
                        scope.setIvritaGender(scope.data.formSubmission.ivrita_gender);
                    }
                    if (scope.data.settings && scope.data.settings.ivrita && scope.data.settings.ivrita.field) {
                        $( '[id*="'+ scope.data.settings.ivrita.field +'"]' ).change(function() {
                            if ($(this).val() === scope.data.settings.ivrita.valueMale ){
                                scope.setIvritaGender('male');
                            }else{
                                if ( $(this).val() === scope.data.settings.ivrita.valueFemale ) {
                                    scope.setIvritaGender('female');
                                }
                            }
                        });

                        $( '.field-' + scope.data.settings.ivrita.field + ' option' ).click(function() {
                            if ($(this).val() === scope.data.settings.ivrita.valueMale ){
                                scope.setIvritaGender('male');
                            }else{
                                if ( $(this).val() === scope.data.settings.ivrita.valueFemale ) {
                                    scope.setIvritaGender('female');
                                }
                            }
                        });
                    }
                }
                scope.userMessages = window.ytUserMessages;
                scope.language = window.ytFormLanguage;
            },1000);
            
            scope.$on("form:signed", function(e, res) {

                scope.data.formSubmission.signature             = res.signature;
                scope.data.formSubmission.signature_file_name   = res.signature_file_name;
                scope.data.formSubmission.sign_time             = res.sign_time;
                scope.data.formSubmission.sign_ipaddress        = res.sign_ipaddress;
                scope.data.formSubmission.sign_user_agent       = res.sign_user_agent;
                  
                scope.signNow = false;
            });

            scope.$on("form:willsignlater", function(e, res) {
                scope.signNow = false;
            });

            scope.setIvritaGender =  function(gender) {
                scope.data.formSubmission.ivrita_gender = gender;
                $( "#set-" + gender ).addClass("active");
                
                var ivrita = new Ivrita(document.querySelector('.content'), Ivrita.FEMALE);

                if (gender == 'male'){
                    $( "#set-female" ).removeClass("active");
                    ivrita.setMode(Ivrita.MALE); // Possible options: Ivrita.MALE, Ivrita.FEMALE, Ivrita.NEUTRAL, Ivrita.ORIGNAL
                }else{
                    $( "#set-male" ).removeClass("active");
                    ivrita.setMode(Ivrita.FEMALE); // Possible options: Ivrita.MALE, Ivrita.FEMALE, Ivrita.NEUTRAL, Ivrita.ORIGNAL
                }
            };

            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };
            
            scope.saveFormSubmission = function(submitted){
                if (Utils.GET().id && !Utils.GET().token) {
                    if (submitted && scope.data.currentStep < scope.data.titles.length - 1){
                        scope.data.currentStep++;
                    }
                    scope.savingProcess=false;
                    return;
                }

                scope.data.validationFailed = false;
                
                var isValid = scope.checkValidate();
                if (!isValid) {
                    scope.data.validationFailed = true;
                    if (submitted) { // only if submitted stop saving
                        scope.savingProcess=false;
                        return;
                    }
                } 
                if (submitted && scope.data.currentStep < scope.data.titles.length - 1){
                    scope.data.formSubmission.last_step = parseInt(parseInt(scope.data.currentStep)+1);
                }else{
                    scope.data.formSubmission.last_step = scope.data.currentStep;
                }
                Ajax.post({
                    module: 'common',
                    url: 'form-portal/save-form-submission',
                    params: {submitted: (submitted && scope.data.currentStep == scope.data.titles.length - 1) ? true : null},
                    data: {
                        form: scope.data,
                        token: Utils.GET().token    
                    },
                    notShowMessage: true,
                    success: function(res) {
                        scope.savingProcess=false;
                        if (submitted && scope.data.currentStep == scope.data.titles.length - 1) {
                            Utils.redirect('/common/form-portal/thank-you-page-submitted?token='+scope.data.formSubmission.token);
                        }else if (submitted && scope.data.currentStep < scope.data.titles.length - 1){
                            scope.data.currentStep++;
                        }
                        $rootScope.$broadcast("formexternal:saved", null);
                        document.documentElement.scrollTop = 0;
                    }
                });
            };
            
            scope.checkValidate = function() {

                var step = null;

                if (scope.data.currentStep < scope.data.titles.length - 1) {
                    step = scope.data.currentStep;
                }

                var isValid = true;
                
                angular.forEach(scope.data.titles[scope.data.currentStep].fields, function(field, key) {
                    // if not step check all steps if step check only current step
                    if ( (step!=null && field.title_parent_id==scope.data.titles[step].id) || step==null ) {
                        if(field.mandatory=='1'&&field.visible=='1'&&scope.isFieldDisplay(field)) {
                            if ((!field.myanswer||field.myanswer=='')) {
                                field.failed_class = 'is-invalid';
                                isValid = false;
                                if (field.field_type=='multiselect') {
                                    angular.forEach(field.answer_options, function(option, key) {
                                        if (option.ischecked){
                                            field.failed_class = '';
                                            isValid = true;
                                        }
                                    });
                                }
                            }else if (field.field_type == 'checkbox' && field.myanswer=='0') {
                                field.failed_class = 'is-invalid';
                                isValid = false;
                            }else{
                                field.failed_class = '';
                            }
                        }
                        angular.forEach(field.arrayChilds, function(child, key) {
                            var childRes = true;
                            angular.forEach(child.fields, function(childField, key) {
                                if(childField.mandatory=='1'&&field.visible=='1'&&childField.visible=='1'&&scope.isFieldDisplay(field)) {
                                    if(!childField.myanswer||childField.myanswer=='') {
                                        field.failed_class = 'is-invalid';
                                        childField.failed_class = 'is-invalid';
                                        isValid = false;
                                        childRes = false;
                                        if (childField.field_type=='multiselect') {
                                            angular.forEach(childField.answer_options, function(option, key) {
                                                if (option.ischecked){
                                                    field.failed_class = '';
                                                    childField.failed_class = '';
                                                    isValid = true;
                                                    childRes = true;
                                                }
                                            });
                                        }
                                    }else if (childField.field_type == 'checkbox' && childField.myanswer=='0') {
                                        field.failed_class = 'is-invalid';
                                        isValid = false;
                                    }else{
                                        childField.failed_class = '';
                                    }
                                }
                            });
                        });
                    }
                });
                
                return isValid;
            };
            
            scope.showMyPanelBody = function(e,mode){
                
                $(".card-body").addClass( "hidden" );
                // $(".card-body").hide();
                // $(".repeater-clickable").show();
                $(".repeater-clickable").removeClass("hidden");
                if (mode == 'open') {
                    // e.delegateTarget.nextElementSibling.style.display = 'block';
                    e.delegateTarget.nextElementSibling.classList.remove("hidden");
                    // e.delegateTarget.style.display = 'none';
                     e.delegateTarget.classList.add("hidden");
                }
            };

            scope.getCurrentStep = function(){
                return parseInt(parseInt(scope.data.currentStep)+1);
            };

            scope.validateLoopField = (f) => {
                scope.$parent.validateLoopField(f);
            };
        }
    };
}]);