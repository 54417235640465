'use strict';

app.controller('cr21ContactController', ['$scope', 'Ajax', 'Utils', 'Popup', function($scope, Ajax, Utils, Popup) {
    $scope.data = {};    
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/cr21/contact/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number   = '';
        var route           = '';
        var locality        = '';
        var country         = '';
        var area            = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'administrative_area_level_1') {
                area = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street     =  route;
        $scope.data.adrs_city       =  locality;
        $scope.data.adrs_country    =  country;
        //$scope.data.adrs_area        = area ? area.split(' ')[1] : '';
    });
    
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
}]);