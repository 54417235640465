'use strict';

app.controller('WorkStatusController', ['$rootScope', '$scope', '$modal', 'Ajax', 'Utils', 'Popup',
	function($rootScope, $scope, $modal, Ajax, Utils, Popup) {
	
	$scope.works		= null;
	$scope.currentYearId	= null;
	$scope.editWorkStatus = null;
    
         // Yechiel - I add this event listner, need to see if it's OK
        $rootScope.$on('popup:cancel', function(e,data){
            if(data){// if data, it's edit mode
                $scope.init();
            }
        });
        
        
	$scope.init = function(){
            if( window.YT.mvc.module === 'academyportal' ){
                $scope.user_id	= window.YT.user.user_id;

                Ajax.get({
                    module: 'common',
                    url: 'student/get-current-acdemic-year',
                    success: function(res){
                        $scope.currentYearId = res.currentAcdemicYear.value;
                    }
                });
            } else {
                $scope.user_id	= Utils.GET().id;
            }
		
            Ajax.get({
                    module: 'common',
                    url: 'student/get-works',
                    params: {id:  $scope.user_id},
                    success: function(res){
                        $scope.works = res.works;
                    }
            });
	};
	$scope.init();
	
	$scope.save = function(data, $modalInstance){
            
        data.user_id = $scope.user_id;
        if(data.still_work === 1){
            data.year_end = null;
        }

        if($scope.currentYearId){
            data.lookup_academic_year_id = $scope.currentYearId;
        }


        // to avoid from multyple saving - this code must be after all the data changing above
        if(!data.id && $scope.editWorkStatus && angular.equals($scope.editWorkStatus, data)){
            return; // to avoid from multiple saving
        }

        $scope.editWorkStatus = angular.copy(data);

        Ajax.post({
            module: 'common',
            url: 'student/save-work-status',
            data:   data,
            params: data.id ? {workId: data.id} : {},
            success: function(res){
                $scope.init();
                $modalInstance.close($scope.data);
            },
        });
	};
	
	$scope.openPopup = function(data){
        if(data && data.percentage_job){
                data.percentage_job = parseInt(data.percentage_job);
        }

        if(data && data.employees_number){
                data.employees_number = parseInt(data.employees_number);
        }

        if(!data){
            data = {};
            //data.is_odd_jobs = null;
            //data.looking_job = null;
        }

        var watch = [];
        watch = [
            {
                myVar: 'data.still_work',
                myFun: function(newValue, oldValue){
                    if(newValue && newValue == 237 && data && data.year_end){
                        data.year_end = null;
                    }
                }
            },
            {
                myVar: 'data.looking_job',
                myFun: function(newValue, oldValue){
                    if(newValue){
                        Ajax.post({
                            module: 'common',
                            url: 'student/get-piclistitem',
                            notShowMessage: true,
                            params: {piclistItemId: newValue},
                            success: function(res){
                                if(res.data.logic_id == 2){
                                    data.showWhyNotLookingJob = true;
                                }else{
                                    data.showWhyNotLookingJob = false;
                                }
                            },
                        });
                    }else{
                        data.showWhyNotLookingJob = false;
                    }
                }
            },
            {
                myVar: 'data.is_odd_jobs',
                myFun: function(newValue, oldValue){
                    if(newValue){
                        Ajax.post({
                            module: 'common',
                            url: 'student/get-piclistitem',
                            notShowMessage: true,
                            params: {piclistItemId: newValue},
                            success: function(res){
                                if(res.data.logic_id == 1){
                                    data.showWhatOddJobs = true;
                                }else{
                                    data.showWhatOddJobs = false;
                                }
                            },
                        });
                    }else{
                        data.showWhatOddJobs = false;
                    }
                }
            }
        ];

        // to avoid from multyple saving
        $scope.editWorkStatus = null;
        Popup.open('workStatus', $scope.save, data, {}, watch);
	};
		
	$scope.remove = function(id){
		Ajax.post({
			module: 'common',
            url: 'student/remove-work',
            params: {workId: id},
            success: function(res){
				$scope.init();
            }
        });
	};
    
    $scope.getJSFormatDate = function(d){
        return new Date(d);
    };
}]);
