'use strict';

var addFiles2EventModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, $timeout, currentConnection, Ajax, Utils/*, users*/){
    $scope.currentConnection = currentConnection;
    
    $scope.data = {};
    $scope.user_id = $scope.globals.user.user_id;
    $scope.newFileIns = {};
    
    $scope.init = function(){
        $scope.recordid = $scope.currentConnection.id;
        $scope.objectid = 12;
        Ajax.get({
            module: 'common',
            url: 'student/get-portal-student-docs',
            params: {id: $scope.recordid, object_id: $scope.objectid},
            success: function(res){
                $scope.data = res.data;
            }
        });
    };
    
    $scope.init();
    
    $scope.addNewFileClick = function(){
        $timeout(function(){
            $('#uploadNewFile').trigger('click');
        });
    };
 
    $scope.uploadNoneFileConnectionFile = function(){
        var file    = $("#uploadNewFile")[0].files[0];
        var fd      = new FormData();
        
        var sf      = {};
        var param   = {};
        
        sf.group_line_id = 1;
        sf.friendly_name = ($scope.newFileIns.fileSelectedName) ? $scope.newFileIns.fileSelectedName : file.name;
        
        
        fd.append('file', file);
        fd.append('fileData', JSON.stringify( sf ));
        
        Ajax.jqueryUploadFile({
            module: 'common',
            url: 'file/upload-asignment-file?id=' + $scope.recordid + '&user_id=' + $scope.globals.user.user_id,
            fd: fd,
            success: function(res){
                $("#uploadNewFile").replaceWith( $("#uploadNewFile").clone() ); 
                $scope.init();
                $scope.fileSelectedName = "";
                //$scope.getData();
                //Utils.refresh();
            }
        });
    };
 
    $scope.uploadFile = function() {
        var file_connection_id  = this.doc.id;
        var file                = $("#portal_upload_" + file_connection_id)[0].files[0];
        
        if( file ){
            
            var ext = $("#portal_upload_" + file_connection_id).val().match(/\.(.+)$/)[1];
                        
            var fd      = new FormData();

            var param = {};
            param['file_connection_id'] = file_connection_id;
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
            

            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-asignment-file?id=' + $scope.recordid + '&user_id=' + $scope.globals.user.user_id,
                fd: fd,
                success: function(res){
                   // $scope.getData();
                   $scope.init();
                }
            });
        }
    };
   
    $scope.deleteRecord = function(doc){
        
        Ajax.post({
            module: 'common',
            url: 'file/remove-file-from-asignment',
            params: {fileConnectionId: doc.id},
            success: function(res){
                $scope.init();
            },
            successMessage: 'הרשומה נמחקה בהצלחה'
        });
    };
    
    
    $scope.close = function () {
        $modalInstance.close('OK');
    };
};

var eventRegistrationModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, asignmentConnection, Ajax, Utils/*, users*/){
    $scope.currentAsignment = asignmentConnection.asignment;
    $scope.isAcademyProg = false;
    $scope.rbIndex;    
    
    $scope.getTimeForDisplay    = Utils.getTimeForDisplay;
    $scope.getDateForDisplay    = Utils.getDateForDisplay;
    $scope.getWeekDayForDisplay = Utils.getWeekDayForDisplay;
    
    $scope.ok = function(eventId) {
        //console.log($scope.currentAsignment);
        //var eventId = ($scope.currentAsignment.selectedEventIndex) ? $scope.currentAsignment.events[$scope.currentAsignment.selectedEventIndex].id : -1;
        if (eventId != -1) {
            Ajax.post({
                module: 'common',
                url: 'student/reg-student-to-event',
                params: {asignmentConnectionId: asignmentConnection.id, asignmentId: $scope.currentAsignment.id, eventId: eventId},
                data: $scope.currentAsignment,
                success: function(res){
                    $modalInstance.close(res.data);
                    Utils.refresh();
                }
            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
   
   $scope.getDateForDisplay = function(iDate){
        var date4dislay = Utils.getDateForDisplay(iDate);
        return date4dislay;
    };
    
    $scope.getTimeForDisplay = function(iDate){
        var time4dislay = Utils.getTimeForDisplay(iDate);
        return time4dislay;
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d.replace(/-/g, '/'));
    };
};
app.controller('AsignmentRegistrationController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', function($scope, $http, $filter, $modal, Ajax, Utils) { 
    $scope.openEditAsignmentRegistrationPopup = function($event, $asignmentConnection){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/reg_asignment_popup.html' + window.YT.project.version,
            controller: eventRegistrationModalInstanceCtrl,
            size: 'lg',
            resolve: {
                asignmentConnection: function () {
                    return $asignmentConnection;
                }
            }
        });
    };
	
	$scope.submitFiles = function(asignmentConnectionId){
		Ajax.post({
            module: 'common',
            url: 'student/submit-files',
            params: {asignmentConnectionId: asignmentConnectionId},
            success: function(res){
                Utils.refresh();
            }
        });
	};
    
    $scope.openAddFiles2EventPopup = function($event, $currentEvent){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/add_files2Event_popup.html',
            controller: addFiles2EventModalInstanceCtrl,
            size: 'lg',
            resolve: {
                currentConnection: function () {
                    return $currentEvent.asignmentConnections[0];
                }
            }
        });
        modalInstance.result.then(function (res) {
            // in case of ok button pressed
            Utils.redirect('/academyportal/active/asignments');
        }, function (res) {
            // in case of cancel button pressed
        });
    };
    
    $scope.closeAsignmentConnection = function(currentEvent){
        Ajax.post({
            module: 'common',
            url: 'student/close-asignment-connection',
            params: {id: currentEvent.asignmentConnections[0].id, user_id: YT.user.user_id},
            //data: $scope.currentAsignment,
            success: function(res){
                Utils.redirect('/academyportal/active/asignments');
            }
        });
    };
    $scope.getDateForDisplay = function(iDate){
        var date4dislay = Utils.getDateForDisplay(iDate);
        return date4dislay;
    };
    
    $scope.getTimeForDisplay = function(iDate){
        var time4dislay = Utils.getTimeForDisplay(iDate);
        return time4dislay;
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d.replace(/-/g, '/'));
    };
}]);