'use strict';

app.controller('EhpTreatmentGroupController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tIsSelected              = [];
    $scope.treatmentIsSelected      = [];
    $scope.requestsIdsToAdd         = [];
    $scope.showMembers              = [];
    $scope.treatmentGroup           = {};
    $scope.treatmentGroup.therapist = {};
    $scope.disabledSaveBtn          = false;
    
        
    $scope.init = function(mod){
        $scope.mod = mod;
        $scope.currentDate = new Date();
        $scope.currentDate.setDate(new Date().getDate()+1);
        $scope.fromDate = new Date('2000-01-01');
        
        if (Utils.GET().id){
            Ajax.get({
                module: 'elhanefeshportal',
                url: 'treatment-group/get-treatment-group-details',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.treatmentGroup = res.treatmentGroup;
                },
            });
            $scope.getRequests();
            $scope.getTreatmentGroupLines();
        }
    };
    
    $scope.$watch('treatmentGroup.program_id', function(newValue, oldValue) {
        if(newValue && newValue != oldValue && $scope.treatmentGroup.user_id_therapist){
            $scope.getRequests();
        }
    });
    
    $scope.$watch('treatmentGroup.user_id_therapist', function(newValue, oldValue) {
        if(newValue && newValue != oldValue && $scope.treatmentGroup.program_id){
            $scope.getRequests();
        }
    });
    
    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.save = function(){
        
        // empty the id's array before save
        $scope.requestsIdsToAdd = [];
        $("input[name=requestCheck]:checked").each(function() {
            $scope.requestsIdsToAdd.push( $(this).val() );
        });
        
        Ajax.post({
            url: 'treatment-group/save',
            params: {id: Utils.GET().id},
            data: {
                treatmentGroup: $scope.treatmentGroup,
                requestsIdsToAdd: $scope.requestsIdsToAdd
            },
            success: function(res){
                $scope.treatmentGroup = res.data;
                if ( $scope.mod == 'add' ){
                    Utils.redirect('/elhanefesh/treatment-group/update?id=' + $scope.treatmentGroup.id);
                }
            },
        });
    };
    
    $scope.getRequests = function(){
        if ($scope.treatmentGroup.user_id_therapist && $scope.treatmentGroup.program_id){
            Ajax.get({
                module: 'elhanefeshportal',
                url: 'treatment-group/get-treatment-group-requests',
                params: {
                    therapistId: $scope.treatmentGroup.user_id_therapist,
                    programId: $scope.treatmentGroup.program_id,
                    treatmentGroupId: $scope.treatmentGroup.id ? $scope.treatmentGroup.id : null,
                },
                success: function(res){
                    $scope.requests         = res.requests;
                    $scope.groupRequests    = res.groupRequests;
                    $scope.picklists        = res.picklists;
                },
            });
        }
    };
    
    $scope.getTreatmentGroupLines = function(){
        if (Utils.GET().id){
            Ajax.get({
                module: 'elhanefeshportal',
                url: 'treatment-group/get-treatment-group-lines',
                params: {treatmentGroupId: Utils.GET().id},
                success: function(res){
                    $scope.treatmentGroupLines = res.treatmentGroupLines;
                },
            });
        }
    };
    
    $scope.addRequestToGroup = function($index){
        if(!$scope.treatmentGroup.counter_request_id){
            $scope.treatmentGroup.counter_request_id=0;
        }
        if ($scope.tIsSelected[$index] == true){
            $scope.treatmentGroup.counter_request_id++;
        }else{
            $scope.treatmentGroup.counter_request_id--;
        }
    };
    
    $scope.getShowFilter = function(t,supplierIdForFilter,brIdForFilter){
        if (!supplierIdForFilter && !brIdForFilter){ // if no filter choosen
            return true;
        }
        
        if (!supplierIdForFilter && brIdForFilter){
            return t.budget_request_id == brIdForFilter;
        }
        if (supplierIdForFilter && !brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter;
        }
        if (supplierIdForFilter && brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter && t.budget_request_id == brIdForFilter;
        }
    };
    
    $scope.checkAll = function(checked){
        $scope.treatmentGroup.counter_request_id     = Number(0);
        $("input[name=requestCheck]").each(function(i) {
             $(this).prop('checked', checked);
             $scope.tIsSelected[i] = checked;
             if (checked == true){
                 $scope.addRequestToGroup(i);
             }
         });
    };
    
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param['upload_source'] = '(תוכנה)';
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
//            var obj = {};
//            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-treatment-group-file?id=' + $scope.invoice.id,
                fd: fd,
                success: function(res){
                   $scope.init('edit');
                }
            });
        }
    };
    
    $scope.$watch('newTreatmentGroupLine.treatment_date', function(newValue, oldValue) {
        if(newValue && newValue != oldValue){
            if ($scope.treatmentGroup.group_type == 'private'){
                $scope.getTreatmentMembers();
            }
        }
    });
    
    $scope.getTreatmentMembers = function(){
        //if ($scope.treatmentGroupLine.treatment_date){
            Ajax.get({
                module: 'elhanefeshportal',
                url: 'treatment-group-line/get-treatment-members',
                params: {
                    treatmentGroupId: $scope.treatmentGroup.id,
                    treatmentDate: $scope.newTreatmentGroupLine.treatment_date
                },
                success: function(res){
                    $scope.members = res.members;
                    for(let i=0;i<$scope.members.length;i++){
                        if (!$scope.members[i].request.lpi_id_absence_reason){
                            $scope.treatmentIsSelected[i] = true;
                        }
                    }
                },
            });
        //}
    };
    
    $scope.validateLine = (data) => {
        if (!data.treatment_date){
            $scope.disabledSaveBtn=false;
            alert('לא ניתן לשמור מפגש ללא תאריך');
            return false;
        }

        
        if (!data.fromHour || !data.fromMinute) {
            $scope.disabledSaveBtn=false;
            alert('לא ניתן לשמור מפגש ללא שעת מפגש');
            return false;
        }else{
            data.from_time = data.fromHour + ':' + data.fromMinute;
        }

        if (!data.duration_time) {
            $scope.disabledSaveBtn=false;
            alert('לא ניתן לשמור מפגש ללא משך מפגש');
            return false;
        }else{
            // בינתיים אין אפשרות לבחור משך פעילות והברירת מחדל שעה
            if (!data.duration_time){
                data.duration_time = 60;
            }
            let toTimeMinutes = (parseInt(data.fromHour)*60) + parseInt(data.fromMinute) + parseInt(data.duration_time);
            let toHour = Math.floor(toTimeMinutes/60);
            let toMinute = Math.floor(toTimeMinutes%60);
            if (toHour<10){toHour = '0' + toHour;}
            if (toMinute<10){toMinute = '0' + toMinute;}
            data.to_time = toHour + ':' + toMinute;
        }
        if (!data.location){
            $scope.disabledSaveBtn=false;
            alert('לא ניתן לשמור מפגש ללא מיקום מפגש');
            return false;
        }
        if ($scope.treatmentGroup.group_type == 'group') {
            if (!data.number_of_participants){
                $scope.disabledSaveBtn=false;
                alert('לא ניתן לשמור מפגש ללא מספר משתתפים');
                return false;
            }
        }
        return true;
    };

    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.saveNewTreatmentGroupLine = function(data){
        if (!$scope.validateLine($scope.newTreatmentGroupLine)) {
            return;
        }
        
        if ($scope.members && $scope.members.length > 0) {
            $scope.members.forEach((member, index) => {
                if($scope.treatmentIsSelected[index] != true && !member.lpi_id_treatment_absence_reason){
                    $scope.disabledSaveBtn=false;
                    let participantName = member.request.user.fname + '. ' + member.request.user.lname;
                    alert(participantName + ' לא סומנה כנוכחת ללא סיבת העדרות, לאחר בחירת סיבת העדרות ניתן להמשיך בשמירה');
                    throw new Error(participantName + ' לא סומנה כנוכחת ללא סיבת העדרות, לאחר בחירת סיבת העדרות ניתן להמשיך בשמירה');
                }
            });
        }

        $scope.disabledSaveBtn=true; //  to avoid double click disable save button 
        
        $scope.newTreatmentGroupLine.treatment_group_id = $scope.treatmentGroup.id;
        Ajax.post({
            url: 'treatment-group-line/save',
            data: {
                treatmentGroupLine: data ? data : $scope.newTreatmentGroupLine,
                members: $scope.members,
                treatmentGroupId: $scope.treatmentGroup.id
            },
            success: function(res){
                Utils.refresh();
                $scope.disabledSaveBtn=false;
            }
        });
    };
    
    $scope.cancel = function(){
        $scope.newGroupLine             = false;
        $scope.newTreatmentGroupLine    = {};
        $scope.members                  = {};
        // empty the datepicker
        $(".display-date").val('');
    };
    
    
    $scope.getTreatmentGroupLineDetails = function($index, refresh){
        if ( refresh || ($scope.treatmentGroupLines[$index].showMembers && !$scope.treatmentGroupLines[$index].members) ) {
            Ajax.get({
                module: 'elhanefeshportal',
                url: 'treatment-group-line/get-treatment-group-line-details',
                params: {id: $scope.treatmentGroupLines[$index].id},
                success: function(res){
                    $scope.treatmentGroupLines[$index] = res.treatmentGroupLine;
                    $scope.treatmentGroupLines[$index].members = res.members;
                    for(let i=0;i<$scope.treatmentGroupLines[$index].members.length;i++){
                        if (!$scope.treatmentGroupLines[$index].members[i].lpi_id_treatment_absence_reason){
                            $scope.treatmentGroupLines[$index].members[i].is_selected = true;
                            $scope.treatmentGroupLines[$index].members[i].lpi_id_treatment_absence_reason = null;
                        }
                    }
                    if ($scope.treatmentGroup.group_type == 'private'){
                        $scope.treatmentGroupLines[$index].showMembers = true;
                    }
                },
            });
        }
    };
    
    $scope.saveTreatment = function(data, $modalInstance){
        if(!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)){
            return; // to avoid from multiple saving
        }
        
        $scope.editTreatmentData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'request/save-treatment', 
            params: {budgetRequestId: data.budget_request_id, treatmentId: data.id},
            data: data,
            success: function(res) {
                $scope.getTreatmentGroupLineDetails(data.index, true);
                $modalInstance.close();
            }
        });
    };
    
    $scope.removeTreatment = function(id){
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'treatment-group/remove-treatment-group-line',
            params: {id: id},
            success: function(res) {
                Utils.refresh();

            }
        });
    };
    
    $scope.updateTreatmentGroupLine = function(data){
        if (!$scope.validateLine(data)) {
            return;
        }
        Ajax.post({
            url: 'treatment-group-line/update-line',
            data: data,
            success: function(res){
                Utils.refresh();
            }
        });
    };

    $scope.openTreatmentGroupLinePopup = function(data){
        
        var fn = [];
        fn = {
            removeTreatment: $scope.removeTreatment,
            getJSFormatDate: (d) => {
                return new Date(d);
            }
        };
        
        if (data.from_time && data.from_time.includes(':')) {
            data.fromHour = data.from_time.split(':')[0];
            data.fromMinute = data.from_time.split(':')[1];
        }

        Popup.open('elhanefeshPortal/addTreatmentGroupLine', $scope.updateTreatmentGroupLine, data, fn);
    };
    
    $scope.init();

}]);