'use strict';

app.directive('digitalFeedback', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
            mode: '=mode'
        },
        templateUrl: '/js/app/directives/digitalFeedback/digitalFeedback.html',
        link: function(scope, element, attrs) {
            scope.failedToSave = false;
            
            scope.init = function() {
                scope.data = angular.fromJson(scope.data);
                
                Ajax.get({
                    module: 'common',
                    url: 'request/get-symptom-categories', 
                    success: function(res) { 
                        scope.symptomCategories = res.symptomCategories;
                    }
                });
                Ajax.get({
                    module: 'common',
                    url: 'request/get-symptoms', 
                    params: {reqId: scope.data.request.id, brId: scope.data.id},
                    success: function(res) { 
                        scope.data.symptoms = res.symptoms;
                    }
                });

                Ajax.get({
                    module: 'common',
                    url: 'request/get-goals', 
                    params: {reqId: scope.data.request.id, brId: scope.data.id},
                    success: function(res) { 
                        scope.data.goals = res.goals;
                        // set original rate for know if to show goal or not
                        scope.data.goals.forEach(function(i){
                            if (i.update && i.update.rate) {
                                i.update.originalRate = i.update.rate
                            }    
                        });
                    }
                });

                Ajax.get({
                    module: 'common',
                    url: 'picklist/get',
                    params: {name: 'ehStatusContentReason'},
                    success: function(res) { 
                        scope.contentReasonPicklist = res;
                    }
                });

                Ajax.get({
                    module: 'common',
                    url: 'picklist/get',
                    params: {name: 'ehContinuationRecommendation'},
                    success: function(res) { 
                        scope.recommendationPicklist = res;
                    }
                });
            }
            
            scope.init();

            scope.saveDigitalFeedback = function(data, $modalInstance){
                if (scope.mode=='view') {
                    $modalInstance.close();
                    return;
                }
                scope.failedToSave = false;
                
                $('#planSaveBtn').prop('disabled', true);
                
                $rootScope.$on('ajax:notValid', function(e,data){
                    $('#planSaveBtn').prop('disabled', false); //  if form not valid enable button again
                    scope.failedToSave = true;
                });
        
                if (!scope.validateData(data)){
                    $('#planSaveBtn').prop('disabled', false);
                    return;
                }
        
                Ajax.post({
                    module: 'common',
                    url: 'request/save-digital-feedback', 
                    data: data,
                    success: function(res) {
                        data = res.data;
                        //scope.getRequestDetails();
                        $rootScope.$broadcast("digitalData:saved");
                        $modalInstance.close();
                    }
                });
            };
        
            scope.validateData = (data) => {
                var isValidate = true;
                $( ".mandatory__field" ).each(function( index ) {
                    let inputValue = undefined;
                    if ($( this ).find('radio-buttons').length > 0){
                        inputValue = $( this ).find('radio-buttons').find('input[type="radio"]:checked').val();
                    }else if($( this ).find('textarea').length > 0) {
                        inputValue = $( this ).find('textarea').val();
                    }else if($( this ).find('input[type="text"]').length > 0){
                        inputValue = $( this ).find('input').val();
                    }else if($( this ).find('input[type="checkbox"]').length > 0){
                        $( this ).find('input[type="checkbox"]').each(function( index ) {
                            if ($( this )[0].checked){
                                inputValue = true;
                                return;
                            }
                        });
                    }else if ($( this ).find('select').length > 0){
                        inputValue = $( this ).find('select').val();
                    }
                    
                    
                    if (inputValue==undefined || inputValue==''){
                        if ($(this).siblings('.card__item__label').length > 0) {
                            $(this).siblings('.card__item__label').addClass( "failed" );
                        }else if($(this).siblings('.symptom_label').length > 0){
                            $(this).siblings('.symptom_label').addClass( "failed" );
                        }else if ($(this).prev().prop('nodeName')=='TD'){
                            $(this).addClass( "failed" );
                        }
                        isValidate = false;
                    }else{
                        if ($(this).siblings('.card__item__label').length > 0) {
                            $(this).siblings('.card__item__label').removeClass( "failed" );
                        }else if($(this).siblings('.symptom_label').length > 0){
                            $(this).siblings('.symptom_label').removeClass( "failed" );
                        }else if ($(this).prev().prop('nodeName')=='TD'){
                            $(this).removeClass( "failed" );
                        }
                    }
                });
                scope.failedToSave = !isValidate;
                
                return isValidate && !scope.noGoals;
            };
        
            scope.addGoal = function(newGoal){
                scope.data.goals.push({
                    goal_details: newGoal.goal_details,
                    budget_request_id: scope.data.id,
                    request_id: scope.data.request.id,
                    source: 'משוב לשובר ' + scope.data.id
                });
                newGoal = {};
            };
        
            scope.deleteGoal = function($index,data){
                if (data.goals[$index].id){
                    data.deletedGoals.push(data.goals[$index]);
                }
                data.goals.splice($index,1);
            };
        
            scope.OpenFeedbackPopup = function(req){
                var data = req;
                data.deletedGoals     = [];
                data.deletedSymptoms  = [];
                
                data.symptomLevel = [
                    {id: '0', name: 'אין קושי'},
                    {id: '1', name: 'נמוך'},
                    {id: '2', name: 'בינוני'},
                    {id: '3', name: 'גבוה'},
                    {id: '4', name: 'גבוה מאוד'},
                ]
                
                data.personalSymptoms = [];
                data.personalSymptoms.push(
                    {ischecked: 'false', name: 'כעסים'},
                    {ischecked: 'false', name: 'חרדות'},
                    {ischecked: 'false', name: 'עצבות'},
                    {ischecked: 'false', name: 'תסכול'},
                    {ischecked: 'false', name: 'קושי במתן אמון'},
                    {ischecked: 'false', name: 'אילמות סלקטיבית'},
                    {ischecked: 'false', name: 'ניתוקים'},
                    {ischecked: 'false', name: 'פגיעה עצמית'},
                    {ischecked: 'false', name: 'הפרעות אכילה'},
                    {ischecked: 'false', name: 'חוסר שליטה ביציאות'},
                    {ischecked: 'false', name: 'הרטבת לילה'},
                    {ischecked: 'false', name: 'קשיי שינה'},
                    {ischecked: 'false', name: 'גמגום'},
                    {ischecked: 'false', name: 'כפייתיות'},
                );

                data.futureTreatmentTypes = [];
                data.futureTreatmentTypes.push(
                    {ischecked: 'false', name: 'אבחון פסיכו-דידקטי'},
                    {ischecked: 'false', name: 'אבחון בריאותי'},
                    {ischecked: 'false', name: 'אבחון נוירולוגי'},
                    {ischecked: 'false', name: 'הוראה מתקנת'},
                    {ischecked: 'false', name: 'אבחון פסיכיאטרי'},
                    {ischecked: 'false', name: 'תרפיה'},
                    {ischecked: 'false', name: 'לא נדרשת התערבות נוספת'},
                    {ischecked: 'false', name: 'אחר', other: '1'},
                );
                if (data.future_treatment_types) {
                    var tempArray = data.future_treatment_types.split("|");
                    angular.forEach(data.futureTreatmentTypes, function(item, key) {
                        item.ischecked = 'false';
                        if( tempArray.includes(item.name) ) { 
                            item.ischecked = 'true';
                        }
                    });
                }

                data.moreFactorsContacts = [];
                data.moreFactorsContacts.push(
                    {ischecked: 'false', name: 'אין'},
                    {ischecked: 'false', name: 'עם ההורים'},
                    {ischecked: 'false', name: 'עם צוות המוסד'},
                    {ischecked: 'false', name: 'אחר'},
                );
                if (data.more_factors_contacts) {
                    let tempArray = data.more_factors_contacts.split("|");
                    angular.forEach(data.moreFactorsContacts, function(item, key) {
                        item.ischecked = 'false';
                        if( tempArray.includes(item.name) ) { 
                            item.ischecked = 'true';
                        }
                    });
                }


                data.symptomCategory = scope.symptomCategories;
                if (data.symptoms.length == 0){
                    data.symptoms = [];
                    data.symptoms.push(
                        // קשיים ביחסיו עם דמויות סמכות ובלמידה
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'התנגדות לכללי המסגרת הלימודית', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'חוסר הצלחה בלימוד מסיבות רגשיות', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'קשר לקוי עם ההורים', request_id: data.id, level: null},
                        // קשיים ביחסיו עם קבוצת השווים
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'התנהגות אלימה בחברה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'מופנמות יתר בחברה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'יצירת קשרים לא בריאים', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'דחיה על ידי החברה', request_id: data.id, level: null},
                        // קשיים אישיים
                        {lpi_id_type: data.symptomCategory[2]['id'], description: 'מחשבות הפוגעות בתפקוד', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[2]['id'], description: 'תסמינים הפוגעים בתפקוד (קושי להירדם, הרטבות לילה, פחדים מוגזמים, גמגום)', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[2]['id'], description: 'התנהגויות הפוגעות בתפקוד (התנהגות כפייתית, פגיעה עצמית, הפרעות אכילה)', request_id: data.id, level: null},
                        // קשיים לימודיים
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בקריאה וכתיבה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בהישגים לימודיים', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בכישרון לימודי', request_id: data.id, level: null},
                        // אחר
                    );
                    
                }
                if (!data.goals){
                    data.goals = [];
                }
                
                var fn = {
                    addSymptom: scope.addSymptom,
                    addGoal: scope.addGoal,
                    deleteGoal: scope.deleteGoal,
                    isFailedToSave: scope.isFailedToSave,
                    isMoreFactorsContacts: scope.isMoreFactorsContacts
                };
                
                data.contentReasonPicklist = scope.contentReasonPicklist;
                data.contentReasonPicklistForView = {};
                scope.contentReasonPicklist.forEach(function(i){
                    data.contentReasonPicklistForView[i.id]  = {id: i.id, name: i.name};
                });

                data.recommendationPicklist = scope.recommendationPicklist;
                data.recommendationPicklistForView = {};
                scope.recommendationPicklist.forEach(function(i){
                    data.recommendationPicklistForView[i.id]  = {id: i.id, name: i.name};
                });
                if (data.digital_feedback_submited_time) {
                    data.digital_feedback_submited_time = new Date(data.digital_feedback_submited_time);
                }
                if (scope.mode == 'view'){
                    if (data.submited_time) {
                        data.submited_time = scope.getJSFormatDate(data.requestMoreDetails.submited_time);
                    }
                    data.rate = [];
                    data.rate[-1] = 'נסיגה';
                    data.rate[0] = 'ללא שינוי';
                    data.rate[1] = 'שיפור מועט';
                    data.rate[2] = 'שיפור ניכר';
                    data.rate[3] = 'יעד הושלם';
                    data.rate[4] = 'לא רלוונטי';

                    data.measureRate = [];
                    data.measureRate[-2] = 'נסיגה משמעותית';
                    data.measureRate[-1] = 'נסיגה';
                    data.measureRate[0] = 'ללא שינוי';
                    data.measureRate[1] = 'התקדמות קלה';
                    data.measureRate[2] = 'התקדמות משמעותית';

                    data.patientInvolvement = ['בכלל לא','מעטה','חלקית','מלאה'];
                    
                    Popup.open('common/digitalFeedbackView', scope.saveTherapeuticPlan, data, fn, {});
                }else{
                    Popup.open('common/digitalFeedback', scope.saveDigitalFeedback, data, fn, {}, 'static');
                }
            };
        
            scope.isFailedToSave = () => {
                return scope.failedToSave;
            };       
            
            scope.isMoreFactorsContacts = (moreFactorsContacts) => {
                return moreFactorsContacts.filter((f)=>{
                    return f.ischecked=='true' && f.name != 'אין';
                }).length > 0;
            };
        }
    };
}]);