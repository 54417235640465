'use strict';

var studentReg2EventModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, asignmentConnection, isAcademyProg, Ajax, Utils/*, users*/){
    $scope.currentAsignment = asignmentConnection.asignment;
    $scope.isAcademyProg = isAcademyProg;
    $scope.rbIndex;    
    
    $scope.getTimeForDisplay = Utils.getTimeForDisplay;
    $scope.getDateForDisplay = Utils.getDateForDisplay;
    $scope.getWeekDayForDisplay = Utils.getWeekDayForDisplay;
    
    $scope.ok = function(eventId) {
        //var eventId = ($scope.currentAsignment.selectedEventIndex) ? $scope.currentAsignment.events[$scope.currentAsignment.selectedEventIndex].id : -1;
        if (eventId != -1) {
            Ajax.post({
                module: 'common',
                url: 'student/reg-student-to-event',
                //params: {asignmentId: $scope.currentAsignment.id, eventId: eventId, userId: Utils.GET().id},
                params: {asignmentConnectionId: asignmentConnection.id, asignmentId: $scope.currentAsignment.id, eventId: eventId, userId: Utils.GET().id, regFrom: 'academy'},
                //data: $scope.currentAsignment,
                success: function(res){
                    $modalInstance.close('reg');
                }
            });
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    
    $scope.cancelRegistration = function () {
        Ajax.post({
            module: 'common',
            url: 'student/cancel-registration',
            params: {asignmentId: $scope.currentAsignment.id, userId: Utils.GET().id},
            //data: $scope.currentAsignment,
            success: function(res){
                //asignmentConnection.event_id =null;
                $modalInstance.close('cancel reg');
            }
        });
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
   
};

app.directive('asignments', ['$modal', '$timeout', 'Ajax', 'Utils', function ($modal, $timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: false,
        templateUrl: '/js/app/partials/asignmentsTpl.html',
        link: function(scope, element, attrs) {
            scope.showAsignmentsList    = true;
            scope.currentIndex          = null;
            scope.asignmentData         = {};
            scope.updateMode            = false;
            scope.currentDate           = new  Date();
            scope.user_id               = Utils.GET().id;
            
            scope.initAsignment = function(){  
                Ajax.get({
                    module: 'common',
                    url: 'student/get-asignment-connections',
                    params: {id: Utils.GET().id},
                    success: function(res){
                        scope.asignmentsConnections = res.asignmentsConnections;
                        scope.statusesPicklist = res.statusesPicklist;
                        scope.submitStatusesPicklist = res.submitStatusesPicklist;
						
						//console.log(scope.asignmentsConnections);
                    }
                });
            };
            
            scope.initAsignment();
            
            
            scope.deleteAsignmentConnection = function(asignmentConnectionId){
                Ajax.post({
                    module: 'common',
                    url: 'student/delete-asignment-connection',
                    params: {asignment_connection_id: asignmentConnectionId},
                    success: function(res){
                        scope.showAsignmentsList = true;
                        scope.updateMode = false;
                        scope.initAsignment();
                    }
                });
            };
            
			
			
			
			
			scope.getFileCount = function(ac){
				var count = 0;
				angular.forEach(ac.filesConnections, function(item) {
					if( item.file ){
						count++;
					}
				});
				
				return count;
			};
			
            scope.goToAsignmentsList = function(){
                scope.asignmentData          = {};
                scope.asignmentComment       = {};
                //scope.initAsignment();
                scope.showAsignmentsList    = true;
                scope.updateMode            = false;
                scope.currentIndex          = null;
            };
            
            scope.addNewAsignment = function(){                
                scope.showAsignmentsList = false;
                scope.updateMode = false;
                scope.currentIndex = null;
                scope.asignmentData   = {};
                scope.asignmentData.user_id = YT.user.user_id;
                var today = new Date();
                var iMonth = today.getMonth()+1;
                scope.asignmentData.due_date = new Date();
            };
            
            scope.showAsignmentUpdateForm = function(index){
                scope.showAsignmentsList = false;
                scope.updateMode = true;
                scope.currentIndex = index;
                scope.asignmentData  = angular.copy(scope.asignmentsConnections[scope.currentIndex]);
            
            };
            
            scope.saveAsignmentConnection = function () {
                Ajax.post({
                    module: 'common',
                    url: 'student/save-asignment-connection',
                    params: {id: scope.asignmentData.id, user_id: scope.user_id},
                    data: scope.asignmentData,
                    success: function(res){
                        scope.showAsignmentsList = true;
                        scope.updateMode = false;
                        scope.initAsignment();
                    }
                });
            };
            
            scope.addStudentFileConnection = function(id, file_id){
                Ajax.post({
                    module: 'common',
                    url: 'student/add-file-from-asignment-to-student',
                    params: {id: id, user_id: Utils.GET().id, file_id: file_id},
                    success: function(res){
                        
                    }
                });
            };

            scope.deleteAsignmentFile = function(id){
                if (!confirm('האם אתה בטוח שברצונך להסיר את המסמך ?')) {
                    return;
                }
                Ajax.post({
                    module: 'common',
                    url: 'file/remove-file-from-asignment',
                    params: {fileConnectionId: id},
                    success: function(res){
                       //$scope.init(); 
                    },
                    successMessage: 'הקובץ נמחק בהצלחה'
                });
            };
            
            scope.openEditAsignmentRegistrationPopup = function($event){
                var modalInstance = $modal.open({
                    templateUrl: '/js/app/partials/reg_asignment_popup.html' + window.YT.project.version,
                    controller: studentReg2EventModalInstanceCtrl,
                    size: 'lg',
                    resolve: {
                        asignmentConnection: function () {
                            return scope.asignmentData;
                        },
                        isAcademyProg: function () {
                            return true;
                        }
                    }
                });
                modalInstance.result.then(function (res) {
                    if (res == 'cancel reg'){
                        scope.initAsignment();
                    } else if (res == 'reg'){
                        scope.initAsignment();
                    }
                    scope.showAsignmentsList = true;
                }, function () {});
            };
        }
    };
}]);