'use strict';

app.directive('itemConnection', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            itemid: '@',
            modelname:       '='
        },
        templateUrl: '/js/app/directives/itemConnection/itemConnectionTpl.html',
        link: function(scope, element, attrs) {
            scope.current_user_id       = YT.user.user_id;
            scope.newRecordId           = null;
            scope.newObjectLogicId      = null;
            scope.allConnections        = {};
            
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.getAllConnections = function(){
                Ajax.get({
                    module: 'common',
                    url: attrs.modelname + '/get-all-connections',
                    params: {id: scope.itemid, recordId: Utils.GET().id},
                    success: function(res){
                        scope.allConnections = res.allConnections;
                    }
                });
            }
            
            scope.initAddConnection = function(){ 
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-objects',
                    params: {},
                    success: function(res){
                        scope.objects   = res.objects;
                    }
                });
                scope.getAllConnections();
            };
            
            scope.initAddConnection();
                       
                                    
            scope.addNewConnection = function(newRecordId, newObjectLogicId) {
                if (!newRecordId || !newObjectLogicId) {
                    alert('על מנת להוסיף קישור, עליך לבחור אובייקט ויעד לקישור')
                    return;
                }
                
                Ajax.post({
                    module: 'common',
                    url:    attrs.modelname + '/add-new-connection',
                    params: {id: attrs.itemid, recordId: newRecordId, logicId: newObjectLogicId},
                    success: function(res){
                        scope.showaddconnection = false;
                        scope.getAllConnections();
                    }
                });
                scope.newRecordId         = null;
                scope.newObjectLogicId    = null
            };
            
            scope.deleteConnection = function(id){
                Ajax.post({
                    module: 'common',
                    url: attrs.modelname + '/delete-connection',
                    params: {id: id},
                    success: function(res){
                        scope.getAllConnections();                    
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };
        }
    };
}]);