'use strict';

app.directive('treatmentsTimeLine', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            patientid: '@'
        },
        templateUrl: '/js/app/directives/treatmentsTimeLine/tpl.html',
        link: function(scope, element, attrs) {       
            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'patient/get-treatments-time-line',
                    params: {patientId: scope.patientid},
                    success: function(res){
                        scope.treatmentsPerWeekNumber  = res.treatmentsPerWeekNumber;
                    }
                });
            };
            
            scope.$watch('patientid', function(newValue, oldValue) {
                if (newValue){
                    scope.init();
                }
            });
        }
    };
}]);