'use strict';

app.controller('FormPortalController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', '$rootScope',
    function($scope, $http, $filter, $modal, Ajax, Utils, $rootScope) { 
    
    $scope.data = {};
    $scope.data.currentStep = 0;
    $scope.data.controllerName = 'formportal';
    $scope.userMessages = {
        en: {
            step: 'Step',
            submitButton: 'Send',
            submitWithoutSaving: 'Save & Send later',
            nextButton: 'Next',
            backButton: 'Back',
            fieldRequire: "Required field",
            missingFields: 'Required fields mark in red*',
            sign: 'Signature',
            resign: 'Change signature',
            cleanSign: 'Clear signature',
            confirmSign: 'Confirm signature',
            laterSign: "I'll sign later",
            plsSign: 'Please sign',
            uploadingFile: 'Uploading... This might take a while',
            browseFile: 'Browse',
            plsSelect: 'Select',
            info: 'Info',
            fillCorectly: 'Please fill corectly',
            date: 'Date',
            year: 'Year',
            month: 'Month',
            day: 'Day',
            fileUploadedSuccess: 'File uploaded success',
            fileUploadAgain: 'Remove file and upload again',
        },
        he: {
            step: 'שלב',
            submitButton: 'הגשה',
            submitWithoutSaving: 'שמור ללא הגשה',
            nextButton: 'המשך',
            backButton: 'חזרה לשלב הקודם',
            fieldRequire: 'זהו שדה חובה',
            missingFields: 'השדות המסומנים באדום הינם שדות חובה יש למלא אותם',
            sign: 'חתימה',
            resign: 'חתימה מחדש',
            cleanSign: 'נקה חתימה',
            confirmSign: 'אישור חתימה',
            laterSign: "אחתום מאוחר יותר",
            plsSign: 'לא זוהתה חתימה',
            uploadingFile: 'מעלה קובץ... הפעולה עשויה לקחת כמה דקות',
            browseFile: 'בחירת קובץ',
            plsSelect: 'נא לבחור',
            info: 'עזרה',
            fillCorectly: 'נא למלא באופן תקין',
            date: 'תאריך',
            year: 'שנה',
            month: 'חודש',
            day: 'יום',
            fileUploadedSuccess: 'הקובץ התקבל בהצלחה',
            fileUploadAgain: 'הסרת הקובץ והעלאה מחדש',
        }
    };        
    
    $scope.init = function(f,fs,$index){   
        var id = Utils.GET().id ? Utils.GET().id :  null;
        var token = Utils.GET().token ? Utils.GET().token :  null;
        Ajax.get({
            module: 'common',
            url: 'form-portal/get-form-fields', 
            params: {
                id: id,
                formSubmissionId: null,
                token: token
            },
            success: function(res) {
                $scope.data = res.form;
                $scope.data.formSubmission = res.formSubmission;
                $scope.data.titles = res.titles;
                for (var i = 0; i < $scope.data.titles.length; i++){
                    $scope.data.titles[i].fields.forEach((f) => {
                        if (f.field_type == 'number'){
                            f.tempNumber = Number(f.myanswer);
                        }else if (f.arrayChilds){
                            f.arrayChilds.forEach((child) => {
                                child.fields.forEach((fc) => {
                                    if (fc.field_type == 'number'){
                                        fc.tempNumber = Number(fc.myanswer);
                                    }
                                });
                            });
                        }
                    });
                    if(!$scope.isFieldDisplay($scope.data.titles[i])){
                        $scope.data.titles.splice(i,1);
                        i--;
                    }
                }
                $scope.data.currentStep = (res.formSubmission&&res.formSubmission.last_step) ? res.formSubmission.last_step : 0;
                $scope.validateAllLoopFields();

                window.ytUserMessages = $scope.userMessages;
                window.ytFormLanguage = $scope.data.settings.language ? $scope.data.settings.language : 'he';
                $rootScope.$broadcast("ytForm:languageDetailsSet", null);
            }
        });
    };
    
    $scope.init();

    $scope.$on('formexternal:saved', function(event, res) {
        $scope.init();
    });

    $scope.setCurrentStep = function($index) {
        $scope.data.currentStep = $index;
        document.documentElement.scrollTop = 0;
    };


    $scope.isFieldDisplay = (f) => {
        if (f.dependent_fields && f.dependent_fields.length > 0) {
            // this check use to be on all form fields not only on current step
            let allFields = [];
            $scope.data.titles.forEach( (t) => {
                allFields = allFields.concat(t.fields)
            });
            var res = 0;
            f.dependent_fields.forEach(function(df) {
                let dependentField = allFields.filter( function(i) {
                    return df.fieldId == i.id;
                });
    
                // if (!f.dependent_field.fieldId || !f.dependent_field.answerOption || !f.dependent_field.operator){
                //     return true;
                // }
                if (dependentField.length > 0){
                    if (dependentField[0].field_type == 'multiselect'){
                        let trueAnswer = dependentField[0].answer_options.filter( function(ans) {
                            if (ans.text && ans.ischecked && df.operator && df.answerOption) {
                                return eval("'"+ans.text.replace(/'/g, "\\'")+"'" + df.operator + "'"+df.answerOption.replace(/'/g, "\\'")+"'");
                            }
                        });
                        // check if no answer on multiselct field and there is need to be answer
                        if (trueAnswer.length == 0 && df.answerOption){
                            res++;
                        }
                    }else if (dependentField[0].myanswer && df.operator && df.answerOption) {
                        if ( ! (eval("'"+dependentField[0].myanswer.replace(/'/g, "\\'")+"'" + df.operator + "'"+df.answerOption.replace(/'/g, "\\'")+"'")) ){
                            res++;
                        }
                    }else if (!dependentField[0].myanswer || dependentField[0].myanswer == ''){
                        res++;
                    }
                }
            })
            
            return f.dependent_condition == 'and' ? res == 0 :  res < f.dependent_fields.length;
        }
        return true;
    };


    $scope.validateLoopField = (f) => {
        angular.forEach(f.arrayChilds, function(c, key) {
            c.isValid = true;
            angular.forEach(c.fields, function(childField, key) {
                if(childField.visible=='1') {
                    if(!childField.myanswer||childField.myanswer=='') {
                        c.isValid = false;
                        if (childField.field_type=='multiselect') {
                            angular.forEach(childField.answer_options, function(option, key) {
                                if (option.ischecked == 'true'){
                                    c.isValid = true;
                                }
                            });
                        }
                    }else if (childField.field_type == 'checkbox' && childField.myanswer=='0') {
                        c.isValid = false;
                    }
                }
            });
        });
    };

    $scope.validateAllLoopFields = () => {
        $scope.data.titles[$scope.data.currentStep].fields.filter( (titleField) => {
                return titleField.field_type == 'repeater'
            }).forEach( (f) => {
                $scope.validateLoopField(f);
        });
    };

}]);