'use strict';

app.controller('EmailTemplateController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', 'mailMerge', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup, mailMerge) {
    $scope.emailTemplates = [];

    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.init();
        }
    });
    
    
    
    $scope.init = function(){
        Ajax.get({
            url: 'email-template/get-email-templates',
            success: function(res){
                $scope.emailTemplates = res.emailTemplates;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'email-template/save-email-template',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.removeGroup = function($id){
		Ajax.post({
			url: 'email-template/remove-email-template',
            params: {id: $id},
            success: function(res){
				$scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
	};    
        
    $scope.openMailPopup = function(template){
        mailMerge.openMailPopup(template, template.object.logic_id); // 29 = contact logic id
    };
}]);
