'use strict';

app.directive('datetimepicker', ['$filter', '$timeout', function ($filter, $timeout) {
	return{
        require: '?ngModel',
        restrict: 'AEC',
        scope: {
            model: '=ngModel'
        },
		templateUrl: '/js/app/directives/datetimepicker/tpl.html',
        link: function(scope, elem, attrs) { 
            let displayFormat	= 'dd/MM/yyyy';
            let mysqlFormat		= 'yyyy-MM-dd';
            let viewMode		= attrs.viewmode === 'birthday' ? 'years' : 'days';
            scope.displayTime	= null;
            scope.showtime		= attrs.showtime ? true : false;
            scope.sDate         = null;
            scope.sTime         = null;
            
            scope.lastEventType = null;
            
            
            scope.split = () => {
                let arr = [];
                if( scope.model ){
                    if (typeof(scope.model) == 'string') {
                        arr = scope.model.split(" ");
                        scope.sDate = arr[0];
                        if(arr[1]){
                            scope.sTime = arr[1].substr(0, 5);
                        }
                    }
                }
            };

            scope.$watch('model', (newValue, oldValue) => {    
                //if( (typeof newValue !== 'undefined') && newValue !== oldValue ){
                if( (typeof newValue !== 'undefined') ){
                    elem.find('.display-date').val( newValue ? $filter('date')(new Date(newValue), displayFormat) : scope.remove() );
                    scope.setDateTimePicker( newValue ? $filter('date')(new Date(newValue), displayFormat) : scope.remove()  );
					
                    if(scope.showtime){
                        scope.split();
                        scope.displayTime = scope.sTime;
                    }
                    
                    if (!scope.lastEventType) {
                        // hide the datetime picker after date was chosen
                        elem.find('.mydatetimepicker').hide();
                        // set foucus out from date input
                        elem.find('.mydatetimepicker').prev('input').blur();
                        
                    }
                    scope.lastEventType = null;
                }
            }, true);
            
            elem.find('.mydatetimepicker').prev('input').on( "keypress", function( event ) {
                scope.lastEventType = 'keypress';
            });
            
            scope.setDateToModel = (date) => {
                if( !date ){
                    return scope.remove();
                }
                
                scope.split();

                scope.model = $filter('date')(date, mysqlFormat);							

                if( scope.sTime ){
                    scope.model += " " + scope.sTime;
                }
                
                return scope.model;
            };
			
            scope.setTimeToModel = (time) => {
                if( !scope.model ){
                    return scope.model;
                }

                scope.split();
                
                if(!time){
                    return (scope.model = scope.sDate);
                }

                return scope.model = (scope.sDate + " " + time);
            };
			
            scope.setDateTimePicker = (date) => {
				if(elem.find('.mydatetimepicker').data("DateTimePicker")){
					elem.find('.mydatetimepicker').data("DateTimePicker").date( date );
				}
            };
            
            scope.remove = () => {
				scope.model = null;
				elem.find('.display-date').val(null);
				elem.find('.display-time').val(null);
				return scope.model;
            };

            scope.checkMinMax = (date) => {				
                if(attrs.linkedtoid){
					let elemTo = angular.element( $('#' + attrs.linkedtoid) );
                    if (elemTo.find('.display-date').val()) {
                        elem.find('.mydatetimepicker').data("DateTimePicker").maxDate(elemTo.find('.display-date').val());
                    }
                    elemTo.find('.mydatetimepicker').data("DateTimePicker").minDate(date);
                }else if(attrs.linkedfromid){				
					let elemFrom = angular.element( $('#' + attrs.linkedfromid) );
                    if (elemFrom.find('.display-date').val()) {
                        elem.find('.mydatetimepicker').data("DateTimePicker").minDate(elemFrom.find('.display-date').val());
                    }
                    elemFrom.find('.mydatetimepicker').data("DateTimePicker").maxDate(date);
                }
            };
			
			elem.find('.mydatetimepicker').datetimepicker({
				format:             false,
				locale:             'he',
				inline:             true,
				showClear:          true,
				showTodayButton:    true,
				viewMode:           viewMode,
				useCurrent:	attrs.linkedfromid || attrs.linkedtoid ? false : true,
				icons: {
					previous:	'glyphicon glyphicon-chevron-right', 
					next:		'glyphicon glyphicon-chevron-left'
				}
			}).on('dp.change', (event) => {
				$timeout( () => {
					scope.$apply(() => {
						if(!event.date){
							scope.checkMinMax(false);
							return scope.remove();
						}

						let t = scope.setDateToModel( event.date.toJSON() );
						scope.checkMinMax(event.date);
						return t;
					});         
				});
			});

			elem.find('.display-date').bind('blur', (e) => {
				elem.find('.mydatetimepicker').data("DateTimePicker").hide();
				elem.find('.mydatetimepicker').hide();
			});

			elem.find('.display-date').bind('focus ', (e) => {					
				elem.find('.mydatetimepicker').data("DateTimePicker").viewMode(viewMode);
				if( scope.model ){
					scope.setDateTimePicker( new Date(scope.model) );
				}					

				elem.find('.mydatetimepicker').data("DateTimePicker").show();
				elem.find('.mydatetimepicker').show();
			});

			elem.find('.display-date').mask("00/00/0000", {
				placeholder: "__/__/____",
				onKeyPress: (cep, event, currentField, options) => {
					if(cep.length === 10){
						scope.setDateTimePicker(cep);
					}
				}
			});

			if(scope.showtime){
				elem.find('.display-time').timepicker({ 
					'timeFormat':		'H:i',
					'scrollDefault':	'now'	
				}).on('changeTime', function() {
					scope.setTimeToModel( $(this).val() );
				});
				elem.find('.display-time').mask("00:00", {
					onKeyPress: (cep, event, currentField, options) => {
						if(cep.length === 5 || cep === ''){
							scope.setTimeToModel(cep);
						}
					}
				});
			}
        }
    };
}]);

