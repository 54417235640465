'use strict';

app.directive('userActivities', ['$interval', 'Ajax', 'Utils', function ($interval, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: false,
        templateUrl: '/js/app/partials/userActivitiesTpl.html',
        link: function(scope, element, attrs) {       
            scope.tasks = null;
            scope.picklists = {};
            
            scope.initActivitySummaryController = function(){
                Ajax.get({
                    module: 'common',
                    notShowPageLoadingFrame: true,
                    url: 'activity/get-user-activities-count',
                    success: function(res){
                        scope.activitiesCount = res.activities_count;
                    }
                });
            };
            scope.initActivitySummaryController();
            
            $interval(function() {
                scope.initActivitySummaryController();
            }, 300000);
            
            scope.$on("activitiesCount:newCount", function(e, count) {
                scope.activitiesCount = count;
            });
        }
    };
}]);