'use strict';

app.controller('YtcrmDefaultController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    //$scope.ordersReport = {};
    $scope.yearId = null;

    $scope.init = function(){
        Ajax.get({
            module: 'common',
            url: 'settings/get-current-academic-year',
            success: function(res) {
                $scope.yearId = res.currentAcademicYear.value;
                $scope.getReport();
            }
        });
        
        Ajax.get({
            module: 'common',
            url: 'calendar/get-auth-url', 
            success: function(res) { 
                $scope.authUrl = res.authUrl;
            }
        });
        
    };
        
    $scope.init();

    $scope.getReport = () => {
        Ajax.get({
            module: 'ytcrm',
            url:    'default/get-orders-report', 
            params: {yearId: $scope.yearId},
            success: function(res){
                $scope.orderStautses    = res.orderStautses;
                $scope.activeStautses   = res.activeStautses;
                $scope.orderTypes       = res.orderTypes;
            }
        });
    };

    $scope.adminone = () => {
        Ajax.get({
            module: 'ytcrm',
            url:    'default/adminone', 
            success: function(res){
                
            }
        });
    };


    $scope.$watch('yearId', function(newValue, oldValue) {
        if (oldValue && newValue && newValue != oldValue){
            $scope.getReport();
        }
    });
}]);