'use strict';

app.directive('ckEditor', [function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0]);

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

//            ck.editorConfig = function() {
                ck.config.toolbarGroups = [
                    { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'paragraph' ] },
                    { name: 'tools', groups: [ 'Maximize' ] },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name:'insert', groups:['mode']},
                    { name: 'links' },
                    
                    { name: 'styles', groups: [ 'styles' ] },
                    { name: 'colors', groups: [ 'colors' ] },
                ];
                ck.config.removeButtons = 'Flash,Language,Strike,CopyFormatting,Indent,Outdent,Smiley,HorizontalRule,SpecialChar,PageBreak,Iframe,Subscript,Superscript,ShowBlocks,Anchor';
                ck.config.autoParagraph = false;
                ck.config.allowedContent = true;
                 
//            };
//            ck.editorConfig();
            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}]);

app.directive('ckEditorBasicly', [function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0]);

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

//            ck.editorConfig = function() {
                ck.config.toolbarGroups = [
                    { name: 'paragraph', groups: [ 'list', 'align', 'basicstyles' ,'colors' ] },
                ];
                ck.config.removeButtons = 'Flash,Language,Strike,CopyFormatting,Indent,Outdent,Smiley,HorizontalRule,SpecialChar,PageBreak,Iframe,Subscript,Superscript,ShowBlocks,Anchor,Source,Table,Maximize';
                
//            ck.editorConfig();
            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}]);

app.directive('ckEditorStandard', [function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0]);

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

//            ck.editorConfig = function() {
                ck.config.toolbarGroups = [
                    { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'paragraph' ] },
                    { name: 'tools', groups: [ 'Maximize' ] },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name:'insert', groups:['mode']},
                    
                    { name: 'styles', groups: [ 'styles' ] },
                    { name: 'colors', groups: [ 'colors' ] },
                ];
                ck.config.removeButtons = 'Flash,Language,Strike,CopyFormatting,Indent,Outdent,Smiley,HorizontalRule,SpecialChar,PageBreak,Iframe,Subscript,Superscript,ShowBlocks,Anchor,Source,Table,Maximize';
                
//            ck.editorConfig();
            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}]);

app.directive('ckEditorMail', [function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0]);

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

//            ck.editorConfig = function() {
                ck.config.toolbarGroups = [
                    { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'paragraph' ] },
                    { name: 'tools', groups: [ 'Maximize' ] },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name:'insert', groups:['mode']},
                    { name: 'links' },
                    
                    { name: 'styles', groups: [ 'styles' ] },
                    { name: 'colors', groups: [ 'colors' ] },
                ];
                ck.config.removeButtons = 'Flash,Language,Strike,CopyFormatting,Indent,Outdent,Smiley,HorizontalRule,SpecialChar,PageBreak,Iframe,Subscript,Superscript,ShowBlocks,Anchor';
                ck.config.autoParagraph = false;
                ck.config.allowedContent = true;
                 
//            };
//            ck.editorConfig();
            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}]);

app.directive('ckEditorReadOnly', [function () {
    return {
        require: '?ngModel',
        link: function ($scope, elm, attr, ngModel) {

            var ck = CKEDITOR.replace(elm[0]);

            ck.on('pasteState', function () {
                $scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            });

//            ck.editorConfig = function() {
                ck.config.toolbarGroups = [
                    { name: 'paragraph', groups: [ 'list', 'indent', 'align', 'bidi', 'paragraph' ] },
                    { name: 'tools', groups: [ 'Maximize' ] },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                    { name:'insert', groups:['mode']},
                    { name: 'links' },
                    
                    { name: 'styles', groups: [ 'styles' ] },
                    { name: 'colors', groups: [ 'colors' ] },
                ];
                ck.config.removeButtons = 'Flash,Language,Strike,CopyFormatting,Indent,Outdent,Smiley,HorizontalRule,SpecialChar,PageBreak,Iframe,Subscript,Superscript,ShowBlocks,Anchor';
                ck.config.autoParagraph = false;
                ck.config.allowedContent = true;
                ck.config.readOnly = true; 
//            };
//            ck.editorConfig();
            ngModel.$render = function (value) {
                ck.setData(ngModel.$modelValue);
            };
        }
    };
}]);
