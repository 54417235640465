'use strict';

app.directive('timeLineTaskBlock', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        scope: { 
			ac: '@'
		},
        templateUrl: '/js/app/directives/timeLineTaskBlock/timeLineTaskBlockTpl.html',
        link: function(scope, element, attrs) {
            $timeout(function(){
                scope.ac = angular.fromJson(scope.ac);
                scope.getJSFormatDate = function(d) {
                    return new Date(d.replace(/-/g, '/'));
                };
                scope.$on('activity:editsuccess'+scope.ac.activity.id, function(event, res) {
                    scope.ac.activity.subject       = res.subject;
                    scope.ac.activity.due_date      = res.due_date;
                    scope.ac.activity.user          = res.user;
                    scope.ac.activity.user_id       = res.user_id;
                    scope.ac.activity.content       = res.content;
                    scope.editTaskMode = res.lpiIdType.logic_id != 112; // 112 = משימה
                    scope.editNoteMode = res.lpiIdType.logic_id == 112;
                });
            },1000);
            
            scope.currentConnection     = {};
            scope.activityComment       = {};
            scope.currentDate           = new Date();
            scope.newRecordId           = null;
            scope.newObjectLogicId      = null;

            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.setIsDone = function(){
                if (scope.ac.activity.done_date){
                    scope.ac.activity.done_date = null;
                }else{
                    let d = new Date();
                    scope.ac.activity.done_date = d.getYear() + '-' + d.getMonth()+1 + '-' + d.getDay();
                }
                scope.saveActivity();
            };
//
            scope.saveComment = function(){
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity-comment',
                    params: {id: scope.ac.activity.id},
                    data:   scope.activityComment,
                    success: function(res){
                        scope.activityComment = {};
                        scope.ac.activity.mergingCommentsTracking = res.current_activity.mergingCommentsTracking;
                    }
                });
            };
//            
            scope.saveActivity = function(){
                var params;
                if (scope.ac.activity.id){
                    params =  {logicId: 54, id: scope.ac.activity.id};
                }else{
                    params = {logicId: 54, id: null, recordID: Utils.GET().id};
                }
                                
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity',
                    params: params,
                    data:   scope.ac.activity,
                    success: function(res){
                        $rootScope.$broadcast('activity:showComments'+res.activity.id,res.activity);
                    }
                });
            };
        }
    };
}]);