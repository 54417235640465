'use strict';

app.controller('OrganizationsCtrl', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils) {
    $scope.init = function(type){
        Ajax.get({
            url:        'organization/get-organiztion', 
            params:     type === 'edit' ?  {id: Utils.GET().id} : {},
            success: function(res){
                $scope.organization = res.organization;
                $scope.picklists    = res.picklists;
            }
        });
    };
    
    $scope.save = function(){
        Ajax.post({
            url: 'organization/save-organiztion', 
            data: $scope.organization
        });
    };
}]);