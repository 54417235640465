'use strict';

var editEventModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, currentEvent, eventStatuses, Ajax/*, users*/){
    
    //console.log(currentEvent);
    $scope.currentEvent = currentEvent;
    $scope.eventStatuses = eventStatuses;
    $scope.ok = function() {
        Ajax.post({
            url: 'event/save-event',
            params: {id: $scope.currentEvent.id},
            data: $scope.currentEvent,
            success: function(res){
                $modalInstance.close(res.data);
            }
        });
    };
    
    $scope.deleteEvent = function(){
        if( confirm('הנך עומד למחוק את האירוע. האם אתה בטוח שברצונך לעשות זאת?') ){
            Ajax.post({
                url: 'asignment/delete-event',
                params: {event_id: currentEvent.id},
                notShowMessage: true,
                success: function(res){
                    $modalInstance.close(res);
                }
            });
        }
    };
	
	$scope.duplicationEvent = function(){
		Ajax.post({
            url: 'event/duplication-event',
            data: $scope.currentEvent,
            success: function(res){
                $modalInstance.close(res.data);
            }
        });
	};
    
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};

app.controller('AsignmentController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', function($scope, $http, $filter, $modal, Ajax, Utils) { 
    
    $scope.mod;
    $scope.userId = null;
    
    $scope.init = function(mod) {
        $scope.mod = mod;
        $scope.asignment = {};
        $scope.asignment.events = {};
        $scope.newEvent = {};
        $scope.newEvent.lpi_id_reg_status = 173;
        $scope.newEvent.lpi_id_is_files_require=175;
        $scope.newEvent.lpi_id_is_files_review_require=177;

        Ajax.get({
            url: 'asignment/asignment-details',
            params: {id: Utils.GET().id},
            success: function(data){
                if (Utils.GET().id) {
                    $scope.asignment                = data.asignment;
                    $scope.asignmentCounterParams   = data.asignmentCounterParams;
                }
                $scope.asignmentPicklists = data.picklists;
            }
        });
    };
    
    //$scope.init('add');
	
    $scope.sendInvitation = function(){
            if( !$scope.userId ){
                    alert('חובה להזין זיהוי סטודנט');
                    return;
            }

            Ajax.post({
                    url: 'asignment/send-invitation',
                    params: {id: Utils.GET().id, userId: $scope.userId},
                    success: function(res){
                            //Utils.refresh();
                    }
            });
    };
	
	
    $scope.saveAsignment = function(){
        if ($scope.mod === 'add'){
            Ajax.post({
                url: 'asignment/save-asignment',
                data: $scope.asignment,
                success: function(res){
                    $scope.asignment = res.data.asignment;
                    Utils.redirect('/academy/asignment/view?id=' + $scope.asignment.id);
                }
            });
        }
        else if ($scope.mod === 'edit'){
            Ajax.post({
                url: 'asignment/edit-asignment',
                params: {id: (Utils.GET().id)},
                data: $scope.asignment,
                success: function(res){
                    $scope.asignment = res.data.asignment;
                    Utils.redirect('/academy/asignment/view?id=' + $scope.asignment.id);
                }
            });
        }
    };
    
    $scope.saveEvent = function(){
        Ajax.post({
             url: 'asignment/save-asignment-event',
             params: {id: (Utils.GET().id)},
             data: $scope.newEvent,
             success: function(res){
                 $scope.init();
             }
         });
    };
    
    $scope.deleteAsignment = function(){
        if( confirm('הנך עומד למחוק את המטלה. האם אתה בטוח שברצונך לעשות זאת?') ){
            Ajax.post({
                url: 'asignment/delete',
                params: {id: (Utils.GET().id)},
                notShowMessage: true,
                success: function(res){
                    Utils.showNoty(res.errMessage, 'error', 7000);
                }
            });
        }
    };
     
    $scope.openEditEventPopup = function($event, $currentEvent){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/edit_event_popup.html',
            controller: editEventModalInstanceCtrl,
            size: 'lg',
            resolve: {
                currentEvent: function () {
                    return $currentEvent;
                },
                eventStatuses: function () {
                    return $scope.asignmentPicklists.eventStatuses;
                }
            }
        });
        modalInstance.result.then(function (res) {
            $scope.init();
            //Utils.showNoty(res.errMessage, 'success', 7000);
            if (res != undefined && res.resMessage === 'success'){
                Utils.showNoty('האירוע הוסר בהצלחה');
            }else if (res != undefined ){
                Utils.showNoty('אין אפשרות למחוק אירוע, אליו רשומים משתתפים', 'error', 5000);
            }
        }, function (res) {
            // in case of cancel button pressed
        });
    };
    
    $scope.getDateForDisplay = function(iDate){
        var date4dislay = Utils.getDateForDisplay(iDate);
        return date4dislay;
    };
    
    $scope.getTimeForDisplay = function(iDate){
        var time4dislay = Utils.getTimeForDisplay(iDate);
        return time4dislay;
    };
}]);