'use strict';

app.controller('StudentController', ['$scope', '$http', '$filter', 'Ajax', 'mailMerge', 'Utils', 
	function($scope, $http, $filter, Ajax, mailMerge, Utils) {
        
    $scope.tabSelected = 1;
    $scope.mod;
    
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        if( $(e.target).attr('aria-controls') === 'study_general'){
            $scope.$apply(function() { $scope.selectTab(2); });
        }else if( $(e.target).attr('aria-controls') === 'academic_study' && !$scope.academic){
            $scope.$apply(function() { $scope.selectTab(3); });
        }else if( $(e.target).attr('aria-controls') === 'all_requests' ){
            $scope.$apply(function() { $scope.selectTab(4); });
        }else if( $(e.target).attr('aria-controls') === 'payments' ){
            $scope.$apply(function() { $scope.selectTab(5); });
        }
        //console.log(e.relatedTarget); // previous active tab
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (!res.data){
            return;
        }
        $scope.profileSrc   = res.data.profileSrc;
		$scope.feedBack		= $scope.checkIncome();
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(mod === 'add'){
            Utils.redirect('/academy/student/update?id=' + res.data);
        }
		$scope.feedBack = $scope.checkIncome();
        
    });
	   
	
	$scope.checkIncome = function(){
		var nefashot = ($scope.data.marital_status == 'single' ? 1 : 2) + (($scope.data.children_number) ? parseInt($scope.data.children_number) : 0);
		
		if (nefashot === 1 && $scope.data.userStudent.income_total > 4002) {
			return false;
		}else if (nefashot === 2 && $scope.data.userStudent.income_total > 6402) {
			return false;
		}else if (nefashot === 3 && $scope.data.userStudent.income_total > 8482) {
			return false;
		}else if (nefashot === 4 && $scope.data.userStudent.income_total > 10242) {
			return false;
		}else if (nefashot === 5 && $scope.data.userStudent.income_total > 12003) {
			return false;
		}else if (nefashot === 6 && $scope.data.userStudent.income_total > 13603) {
			return false;
		}else if (nefashot === 7 && $scope.data.userStudent.income_total > 15203) {
			return false;
		}else if (nefashot === 8 && $scope.data.userStudent.income_total > 16643) {
			return false;
		}else if (nefashot > 9 && $scope.data.userStudent.income_total > 17923) {
			return false;
		}
		
		return true;
	};		
			
		
    $scope.budgetSummary    = null;
    $scope.payments         = null;
    $scope.user_id          = Utils.GET().id;
    
    $scope.showPassword = false;
    $scope.getUserPassword = function(){
        Ajax.get({
            module: 'common',
            url: 'student/get-user-password', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.showPassword = true;
                $scope.password = res.data;
            }
        });
    };
    
    $scope.createUserLogin = function(){
        Ajax.post({
            module: 'common',
            url: 'student/create-user-login', 
            params: {id: Utils.GET().id, email: $scope.data.userStudent.email},
            success: function(res) { 
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    
    $scope.initPaymentTab = function(){
        $scope.$broadcast("paymentGrid:load");
            
        Ajax.get({
            url: 'student/get-budget-summary', 
            params: {id: Utils.GET().id},
            success: function(data) { 
                $scope.budgetSummary = data.budgetSummary;
            }
        });
    };
    
    $scope.$on('paymentTab:refresh', function(e, mod, res){
        $scope.initPaymentTab();
    });
    
    $scope.$watch('tabSelected', function(num) {
        if( num === 5 && (!$scope.budgetSummary || !$scope.payments) ){
            $scope.initPaymentTab();
        }
    });
    
    $scope.selectTab = function(num){
       $scope.tabSelected = num;
    };
    
    
    $scope.$on("activitiesUserCount:newCount", function(e, res) {
        $scope.userActivitiesCount();
    });
    
    // get student's activities that they are not done, and date past already
    $scope.userActivitiesCount = function(){
        Ajax.get({
            module: 'common',
            url: 'activity/get-activities-count-by-record-id',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.studentActivitiesCount = res.activities_count;
            }
        });
    };
        
    $scope.userActivitiesCount();
    
    $scope.getJSFormatDate = function(d){
        return new Date(d);
    };
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,1); // 1 = student
    };
}]);
