'use strict';

app.controller('YtcrmOrderController', ['$rootScope', '$scope', 'Ajax', '$filter', 'Utils', 'Popup', 'mailMerge', function($rootScope, $scope, Ajax, $filter, Utils, Popup, mailMerge) {
    $scope.data             = {};
    $scope.data.orderLines  = [];
	$scope.tagsArr          = [];
        
    $scope.$on('cru:getSuccess', function(e, mod, res){
        $scope.data = res.data ? res.data : {};
        if(mod == 'add'){
            $scope.data.organization_id = Utils.GET().organization_id;
        }
		$scope.tagsToArr();
        $scope.addWatchToLines();

        if (!$scope.data.year_id) {
            Ajax.get({
                module: 'common',
                url: 'settings/get-current-academic-year',
                success: function(res) {
                    $scope.data.year_id = res.currentAcademicYear.value;
                }
            });
        }
    });
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(mod == 'add'){
            $scope.data.organization_id = Utils.GET().organization_id;
        }else{
            $scope.tagsToArr();
            //$scope.editTags=!$scope.editTags;
        }
            
        if(!window.location.href.includes('view')){
            Utils.redirect('/ytcrm/order/view?id=' + res.data.id);
        }
    });

    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,149); // 149 = order(logic_id) in ytcrm
    };

    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };

    $scope.addLine = function(newLine){
        $scope.data.orderLines.push(newLine);
        $scope.newLine = {};
        $scope.saveOrder();
    }
    
    $scope.removeLine = function($index){
        let $orderLineId = $scope.data.orderLines[$index].id;
        $scope.data.orderLines.splice($index,1);
        $scope.data.items = $scope.totalQuantity();
        Ajax.post({
            url: 'order/remove-order-line',
            params: {id: $orderLineId},
            success: function(res){
                
            },
        });
    };

    $scope.totalQuantity = () => {
        let totalQuantity = 0.0;
        $scope.data.orderLines.forEach( (ol) => {
            totalQuantity += parseFloat(ol.quantity);
        });
        return totalQuantity;
    };

    $scope.saveLine = (data) => {
        Ajax.post({
            url: 'order/save-order-line',
            data: data,
            success: function(res){
                //$scope.data = res.data;
            },
        });
    };

    $scope.saveOrder = () => {
        $scope.data.items = $scope.totalQuantity();
        if (!$scope.data.organization_id){
            $scope.data.organization_id = Utils.GET().id;
        }
        Ajax.post({
            url: 'order/save',
            data: $scope.data,
            success: function(res){
                if(window.location.href.includes('create')){
                    Utils.redirect('/ytcrm/order/update?id=' + res.data.id);
                }
                $scope.data = res.data;
                $scope.addWatchToLines();
            },
        });
    };
    
    $scope.$watch('newLine.product_id', function(newValue) {
        if (newValue){
            Ajax.get({
                module: 'common',
                url: 'product/get-product',
                params: {id: newValue},
                success: function(res){
                    $scope.newLine.porduct_id = res.product.id;
                    $scope.newLine.name = res.product.name;
                    $scope.newLine.description = res.product.description;
                    $scope.newLine.price = res.product.price;

                    $scope.newLine.quantity = 1;

                    $scope.addLine($scope.newLine);
                },
            });
        }
    });

    $scope.addWatchToLines = () => {
        $scope.data.orderLines.forEach( (line, index) => {
            $scope.$watch('data.orderLines['+index+'].quantity', function(newValue) {
                $scope.setPrice(newValue,line);
                $scope.data.items = $scope.totalQuantity();
            });
            $scope.$watch('data.orderLines['+index+'].price', function(newValue) {
                $scope.setPrice(newValue,line);
            });
            $scope.$watch('data.orderLines['+index+'].total_price', function(newValue) {
                $scope.setTotalPrice(newValue);
            });
        })
    };
    
    $scope.$watch('newLine.quantity', function(newValue) {
        $scope.setPrice(newValue,$scope.newLine);
        $scope.data.items = $scope.totalQuantity();
    });
    $scope.$watch('newLine.price', function(newValue) {
        $scope.setPrice(newValue,$scope.newLine);
    });

    $scope.setPrice = (newValue, line) => {
        if (newValue){
            line.total_price = parseFloat(parseFloat(line.quantity)*parseFloat(line.price));
            line.total_price_include_vat = line.vat ? line.total_price * parseFloat(1+(line.vat/100)) : line.total_price;
        }
    };

    $scope.setTotalPrice = (newValue) => {
        if (newValue) {
            let totalDue = 0.00;
            $scope.data.orderLines.forEach((l)=>{
                totalDue += parseFloat(l.total_price);
            });
            $scope.data.amount_total = totalDue;
        }
    };
}]);