app.directive('listSearch', ['$filter', '$location', '$window', 'Ajax', 'Utils', '$timeout',  function ($filter, $location, $window, Ajax, Utils, $timeout) {
    return{
        restrict: 'AEC',
        //replace: true,
        scope: false,
        transclude: false,
        templateUrl: '/js/app/partials/list/listSearchTpl.html',
        link: function(scope, element, attrs) {  
			scope.loading		= false;
            scope.fieldsMap     = [];
            scope.objects       = [];
            scope.selectedWhere = [];
            scope.fieldsSelected = {};
            
            scope.init = function(){
                var con     = Utils.getParameterByName('con');
                var node    = Utils.getParameterByName('node');
                var sft     = Utils.getParameterByName('sft');
                
                try{
                    scope.selectedWhere = con ? JSON.parse(con) : [];
                }catch(e){
                    scope.selectedWhere = [];
                }
                
                scope.searchFreeText    = sft ? sft : '';
                scope.data              = {node: node ? node : ''};
                scope.loadData          = true;
                scope.showSearchBox     = false;
            };
            scope.init();
            
            scope.getFieldsMap = function(model){
                Ajax.get({
                    module: 'common',
                    url: 'model/fields-map', 
                    params: {model: model},
                    success: function(res) { 
                        scope.fieldsMap = res;
						scope.loading = false;
                    }
                });
            };
            
            scope.loadObject = function(item){
				if( scope.loading ){
					return;
				}
				
				scope.loading = true;
                scope.objects.push(item);   
                scope.getFieldsMap(item.modelClass);
            };
            
            scope.back = function(){
				if( scope.loading || scope.objects.length <= 1){
					return;
				}
				
				scope.loading = true;
				
                scope.objects.pop();
                scope.getFieldsMap(scope.objects[scope.objects.length-1].modelClass);
            };
            
            scope.add = function(item, objects, type, label){
                scope.fieldsSelected = angular.copy(item);
                
                if(objects.length > 1){   
                    var objStr      = Utils.replaceObejctToStringOrm(objects);

                    scope.fieldsSelected.joinWith       = scope.fieldsSelected.joinWith ? (objStr.joinWith + "." + scope.fieldsSelected.joinWith) : objStr.joinWith;
                    scope.fieldsSelected.objStrJoinWith = objStr.joinWith;

                    scope.fieldsSelected.listLabel  = objStr.listLabel ? (objStr.listLabel + "." + label) : label;
                }else{
                    scope.fieldsSelected.listLabel = objects[0].label + "." + label;
                }
                
                scope.fieldsSelected.userLabel = label;

                if( !angular.equals({}, scope.fieldsSelected) ){
                    var key = "selected" + Utils.capitalizeFirstLetter(type);
                    scope[key].push( angular.copy(scope.fieldsSelected) );
                }
            };
            
            scope.remove = function(item, type){
                var key = "selected" + Utils.capitalizeFirstLetter(type);
                scope[key].splice(scope[key].indexOf(item), 1);     
            };
            
            scope.showSearch = function(){
                if(!scope.loadData){
                    scope.showSearchBox = !scope.showSearchBox;
                    return;
                }

                scope.objects.push({modelClass: attrs.model, joinWith: false, label: attrs.nameyachid});
                scope.getFieldsMap(scope.objects[0].modelClass);

                scope.loadData      = false;
                scope.showSearchBox = true;
            };
            
            $timeout(function(){
                if(scope.selectedWhere.length > 0){
                    scope.showSearch();
                }
            }, 1000);
            
            
            scope.search = function(){
                var id      = "?id=" + Utils.getParameterByName('id');
                var sort    = Utils.getParameterByName('sort') ? "&sort=" + Utils.getParameterByName('sort') : "";
                
                var sft     = scope.searchFreeText              ? "&sft=" + scope.searchFreeText : '';
                var con     = scope.selectedWhere.length > 0    ? "&con=" + JSON.stringify(scope.selectedWhere) : '';
                var node    = scope.data.node && scope.selectedWhere.length > 1 ? "&node=" + scope.data.node    : '';
                
                $window.location.href = $location.absUrl().match(new RegExp("[^?]+")) + id + sort + sft + con + node;
            };
        }
    };
}]);