'use strict';

app.controller('MenuController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', function($scope, $http, $filter, $modal, Ajax, Utils) { 
    
    $scope.initMenu = function(){
        $scope.menu = {};
        $scope.newItem = {};
        $scope.ids4delete = [];
        $scope.expend = [];
        Ajax.get({
            url: 'menu/menu-details',
            params: {id: Utils.GET().id},
            success: function(data){
                $scope.menu      = data.menu;
                $scope.picklists = data.picklists;
            }
        });
    };
    
    $scope.initMenu();
    
    $scope.addNewItem = function(){
        $scope.menu.menuItems.push($scope.newItem);
        $scope.newItem = {};
    };
    
    $scope.deleteMenuItem = function(index){
        // only if it's existing item at DB add it to the delete items array
        if ($scope.menu.menuItems[index]['id']){
            $scope.ids4delete.push($scope.menu.menuItems[index]['id']);
        }
        $scope.menu.menuItems.splice(index, 1);
        $scope.expend.splice(index, 1);
    };
    
    $scope.saveMenu = function(){
        Ajax.post({
            url: 'menu/edit-menu-item',
            params: {id: Utils.GET().id},
            data: {menu: $scope.menu, ids4delete: $scope.ids4delete},
            success: function(res){
                $scope.initMenu();
            },
            error: function(data){}
        });
    };
    
    $scope.showExpend = function(index){
        $scope.expend[index] = true;
    };

    $scope.hideExpend = function(index){
        $scope.expend[index] = false;
    };
}]);