'use strict';

app.controller('EhContactController', ['$scope', 'Ajax', 'Utils', 'Popup', 'mailMerge', function($scope, Ajax, Utils, Popup, mailMerge) {
    $scope.data = {};
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod=='edit'){
            $scope.getPatientsCount();
        }
    });
	
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/elhanefesh/contact/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });
    
    $scope.getPatientsCount = function(){
        Ajax.get({
            module: 'elhanefesh',
            url:    'contact/get-patients-count', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.patientsCount    = res.patientsCount;
                $scope.con              = res.con;
            }
        });
    };
    
    $scope.getForms = function(){
        Ajax.get({
            module: 'common',
            url: 'form/get-open-forms',
            success: function(res) {
                $scope.forms = res.forms;
            }
        });
        Ajax.get({
            module: 'common',
            url: 'form/get-user-submissions',
            params: {userId: Utils.GET().id},
            success: function(res) {
                $scope.formSubmissions  = res.formSubmissions;
            }
        });
    };
        
    $scope.createUserLogin = function(){
        if( !$scope.data.userContact || !$scope.data.userContact.email){
                alert('חובה להזין כתובת אימייל לפני יצירת פרטי לוגין');
                return;
        }
		
        Ajax.post({
            module: 'elhanefesh',
            url: 'contact/create-user-object-login', 
            params: {user_id: Utils.GET().id, email: $scope.data.userContact.email},
            success: function(res) { 
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data, 29); // 29 = contact logic id
    };
    
    $scope.resetPassword = function(){
        if(!confirm('האם אתה בטוח שברצונך לאפס את הסיסמה?')){
            return;
        }
        
        Ajax.post({
            url: 'contact/reset-password',
            data: $scope.data,
            success: function(res){
                // do something
            },
            error: function(res){
                //alert('res');
            }
        });
    };

    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
}]);