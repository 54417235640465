'use strict';

app.controller('AcademySettings', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.academySettings = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'settings/get-academy-settings',
            success: function(res){
                $scope.academySettings = res.data;
            },
        });
    };
    
    $scope.init();
    
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'settings/save-academy-settings',
            data:   $scope.academySettings,
            success: function(res){
                $scope.academySettings = res.data;
            }
        });
    };    
}]);