'use strict';

app.controller('InvolvementReportController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    $scope.data = {};
    
    $scope.init = function() {
        Ajax.get({
            module: 'common',
            url: 'involvement-report/get-data',
            success: function(res){
                //console.log(res);
                $scope.data = res.data;
            }
        });
    };
    
    $scope.init();
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d.replace(/-/g, '/'));
    };
}]);