'use strict';

app.directive('ihpt', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            logicid: '='
        },
        templateUrl: '/js/app/directives/ihpt/ihptTpl.html',
        link: function(scope, element, attrs) {
            scope.userRole = null;
            
            scope.$on('woman:budgetRequestsUpdate', function(e){
                scope.init();
            });
            
            scope.$on('family:budgetRequestsUpdate', function(e){
                scope.init();
            });
            
            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'ihpt/get-ihpt-details',
                    params: {recordId: scope.recordid, logicId: scope.logicid},
                    success: function(res){
                        scope.record                = res.record;
                        scope.ihptClient            = res.ihptClient;
                        scope.ihptServices          = res.ihptServices;
                        scope.totalServicesAmount   = res.totalServicesAmount;
                        scope.userRole              = YT.user.role;
                        
                        $rootScope.$broadcast('ihpt:getIhptClientSuccess', res);
                    }
                });
            };              
            
            scope.init(); 
            
            scope.saveIhpt = function(){
                Ajax.post({
                    module: 'common',
                    url: 'ihpt/save-ihpt',
                    data: scope.record,
                    params: {logicId: scope.logicid},
                    success: function(res){
                        scope.init();
                        $rootScope.$broadcast('ihpt:ihptClientChanged', res);
                    }
                });
            };
        }
    };
}]);