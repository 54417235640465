'use strict';

app.controller('OrganizationQuotaController', ['$scope', '$filter', '$timeout', 'Ajax', 'Utils', 'Popup', function($scope, $filter, $timeout, Ajax, Utils, Popup) {
    $scope.data = {};
    $scope.newLine = {};
    $scope.year = {};
    $scope.selectedYear = null;

    $scope.quotaTypes = {};
    $scope.quotaTypes['patient'] = 'מוטבים';
    $scope.quotaTypes['treatment'] = 'מפגשים פרטני';
    $scope.quotaTypes['budget private'] = 'תקציב פרטני';
    $scope.quotaTypes['group'] = 'קבוצות';
    $scope.quotaTypes['treatment group'] = 'מפגשים קבוצתיים';
    $scope.quotaTypes['budget group'] = 'תקציב קבוצות';

    Ajax.get({
        module: 'common',
        url:	'picklist/get', 
        params: {name: 'organizationInnerStatus'},
        success: function(res){
            $scope.orgStatuses = res;
        }
    });

    Ajax.get({
        module: 'common',
        url:	'picklist/get', 
        params: {name: 'ehCustomerTypes'},
        success: function(res){
            $scope.customerTypes = res;
        }
    });


    $scope.init = () => {
        $scope.getQuotas();
    };
    
    $scope.getQuotas = function(){
        Ajax.post({
            module: 'common',
            url:    'organization-quota/get-all-quotas', 
            params: {yearId: $scope.selectedYear ? $scope.selectedYear : null},
            data: {
                filterOrgStatuses: $scope.filterOrgStatuses.length>0 ? $scope.filterOrgStatuses : null,
                filterCustomerType: $scope.filterCustomerType.length>0 ? $scope.filterCustomerType : null
            },
            notShowMessage: true,
            success: function(res){
                $scope.orgs    = res.orgs;
                $scope.setQuotaSummary();
                //if (!$scope.selectedYear){
                    $scope.year = res.year;
                    $scope.selectedYear = $scope.year.id;
                //}
            }
        });
    };

    // $scope.init();
    
    $scope.uploadQuotas = () =>{
        // organiztion statuses
        var selectedOrgStatus = document.querySelector('#organizationInnerStatus').nextSibling.querySelectorAll('li');
        $scope.filterOrgStatuses = [];
        selectedOrgStatus.forEach( (li) => {
            if (li.title){
                var id = $scope.orgStatuses.filter((status)=>{return status.name == li.title})[0].id;
                $scope.filterOrgStatuses.push(id);
            }
        });
        
        // customer types
        var selectedCustomerType = document.querySelector('#ehCustomerTypes').nextSibling.querySelectorAll('li');
        $scope.filterCustomerType = [];
        selectedCustomerType.forEach( (li) => {
            if (li.title){
                var id = $scope.customerTypes.filter((type)=>{return type.name == li.title})[0].id;
                $scope.filterCustomerType.push(id);
            }
        });

        $scope.init();
    }

    $scope.$watch('selectedYear', function(newValue, oldValue) {
        if (oldValue && newValue && (oldValue!=newValue)){
            $scope.init();
        }
    });

    $scope.$watchCollection('orgs | filter:{ name: orgNameSearchText}', function(filteredItems) {
        if (filteredItems && $scope.orgs){
            $scope.setQuotaSummary();
        }
    });

    $scope.setQuotaSummary = () => {
        let types = ['patient', 'treatment', 'budget private', 'group', 'treatment group', 'budget group'];
        let sumTypes = ['quota', 'usage', 'balance'];
        let tdElements = [];
        $timeout(function(){
            // Get all td elements
            for (let i = 0; i < types.length; i++){
                for (let x = 0; x < sumTypes.length; x++){
                    tdElements.push(document.querySelectorAll('td.'+types[i].replace(' ','-')+'-'+sumTypes[x]));
                }
            }

        
        // Initialize sum variable
        let sum = [];
        
        // Iterate over tdElements and accumulate values
        tdElements.forEach ( (elementTd, index) => {
            sum[index] = 0;
            elementTd.forEach(td => {
                // Parse the text content of each <td> element as an integer and add it to the sum
                if (td.textContent && parseInt(td.textContent.trim())){
                    sum[index] += parseInt(td.textContent.replace(/,/g, '').trim());
                }else{
                    sum[index] += 0;
                }
            });
            if (elementTd.length>0) {
                document.querySelector('.summary-'+elementTd[0].className).textContent = sum[index];
            }
        });
        
        // Display the summary
        }, 1000);
    };

    $scope.saveQuota = function(editedQuota, orgIndex, quotaIndex){
        let data = $scope.orgs[orgIndex].quotas.filter( (q) => {
            if (editedQuota.id){
                return q.id == editedQuota.id;
            }else{
                return q.organization_id == editedQuota.organization_id &&
                    q.year_id == editedQuota.year_id &&
                    q.type == editedQuota.type;
            }
        })[0];
        data.quota = editedQuota.sum;
        Ajax.post({
            module: 'common',
            url:    'organization-quota/save-quota', 
            data: data,
            success: function(res){
                //$scope.getQuota();
                if (!$scope.orgs[orgIndex].allQuotas[quotaIndex].id){
                    $scope.orgs[orgIndex].allQuotas[quotaIndex].id = res.data.id;
                }
                $scope.orgs[orgIndex].allQuotas[quotaIndex].sum = res.data.quota;
                $scope.setQuotaSummary();
            }
        });
    };
}]);