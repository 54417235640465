'use strict';

app.directive('activityFilter', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            activityprojectid: '@',
            typename:  '=',
        },
        templateUrl: '/js/app/directives/activityFilter/activityFilter.html',
        link: function(scope, element, attrs) {
            scope.data = {};
            scope.data.from_date = null;
            scope.data.to_date = null;
            scope.data.user_id = null;
            scope.data.creator_user_id = null;
            scope.data.lpi_id_type = null;
            
            scope.init = () =>{
                scope.data.activityProjectId = scope.activityprojectid;
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-activity-picklists',
                    params: {projectId: scope.activityprojectid},
                    success: function(res){
                        scope.users = [];
                        scope.users.push({id: YT.user.user_id, name: 'אני'},{id: -1, name: 'כולם'});
                        res.picklists.usersEmployees.forEach(u => {
                            scope.users.push({id: u.user.id, name: u.user.fname + ' ' + u.user.lname});
                        });
                        scope.picklists = res.picklists;
                    }
                });
            };

            scope.init();

            scope.filter = () =>{
                $rootScope.$broadcast("activityFilter:filterdata", scope.data);
            };
        }
    };
}]);