'use strict';

app.directive('activities', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: {
            logicid: "@",
            recordid: "@",
            sonobjectid: "@",
            recordids: "@",
        },
        templateUrl: '/js/app/directives/activities/activitiesTpl.html',
        link: function(scope, element, attrs) {
            scope.showActivitiesList    = true;
            scope.currentIndex          = null;
            scope.activityData          = {};
            scope.currentConnection     = {};
            scope.activityComment       = {};
            scope.updateMode            = false;
            scope.currentDate           = new Date();
            scope.current_user_id       = YT.user.user_id;
            scope.newRecordId           = null;
            scope.newObjectLogicId      = null;
            scope.activitiesGroups      = {};
            
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.initActivity = function(){ 
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-record-activities',
                    params: {id: attrs.recordid, logicId: attrs.logicid, recordIds: attrs.recordids ? attrs.recordids : null, sonLogicId: attrs.sonlogicid ? attrs.sonlogicid : null },
                    success: function(res){
                        scope.activities_connections = res.activities_connections;
                        scope.activityPicklists = res.picklists;
                        scope.objects   = res.objects;
                        if (scope.currentIndex != null) {
                            scope.activityData  = angular.copy(scope.activities_connections[scope.currentIndex].activity);
                        }
                    }
                });
                Ajax.get({
                    module: 'common',
                    url: 'picklist/get-json-lookup',
                    params: {settingName: 'ActivitiesTemplates'},
                    success: function(res){
                        scope.activitiesGroups = res;
                    }
                });
            };
                        
            scope.$watch('recordid', function(newValue, oldValue) {
                if (newValue){
                    scope.initActivity();
                }
            });

            scope.$watch('recordids', function(newValue) {
                if(newValue){
                    scope.initActivity();
                }
            });

            scope.showList = function(){
                scope.activityData          = {};
                scope.currentConnection     = {}
                scope.activityComment       = {};
                scope.initActivity();
                scope.showActivitiesList    = true;
                scope.updateMode            = false;
                scope.currentIndex          = null;
            };
            
            scope.getDate = function(iDate){
                var today = new Date();
                var commentDate = new Date(iDate);
                if (iDate == null || commentDate == 'Invalid Date'){
                    return 'Invalid Date';
                }
                var strCommentDate4Compare = commentDate.getDate().toString()+commentDate.getMonth().toString()+commentDate.getFullYear().toString();
                var strToday4Compare = today.getDate().toString()+today.getMonth().toString()+today.getFullYear().toString();
                if (strCommentDate4Compare === strToday4Compare){
                    var hour = commentDate.getHours()<10?"0"+commentDate.getHours():commentDate.getHours();
                    var minute = commentDate.getMinutes()<10?"0"+commentDate.getMinutes():commentDate.getMinutes();
                    var secound = commentDate.getSeconds()<10?"0"+commentDate.getSeconds():commentDate.getSeconds();
                    return "היום ב " + hour + ":" + minute + ":" + secound;
                }
                var iMonth = commentDate.getMonth()+1;
                var day = commentDate.getDate()<10?"0"+commentDate.getDate():commentDate.getDate();
                var month = iMonth<10?"0"+iMonth:iMonth;
                return  day + "/" + month + "/" + commentDate.getFullYear();
            };
            
            scope.getTime = function(iDate){
                var commentDate = new Date(iDate);
                if (iDate == null || commentDate == 'Invalid Date'){
                    return 'Invalid Time';
                }
                var hour = commentDate.getHours()<10?"0"+commentDate.getHours().toString():commentDate.getHours().toString();
                var minute = commentDate.getMinutes()<10?"0"+commentDate.getMinutes().toString():commentDate.getMinutes().toString();
                var secound = commentDate.getSeconds()<10?"0"+commentDate.getSeconds().toString():commentDate.getSeconds().toString();
                return hour + ":" + minute + ":" + secound;
            };
            
            scope.addNewActivity = function(){                
                scope.showActivitiesList    = false;
                scope.updateMode            = false;
                scope.currentIndex          = null;
                scope.activityData          = {};
                scope.currentConnection     = {};
                scope.activityData.user_id  = YT.user.user_id;
                var today                   = new Date();
                var iMonth                  = today.getMonth()+1;
                scope.activityData.due_date  = new  Date();//today.getDay() + "-" + iMonth + "-" + today.getYear();//"2015-08-18";
            };
            
            scope.showUpdateForm = function(index){
                scope.showActivitiesList    = false;
                scope.updateMode            = true;
                scope.currentIndex          = index;
                scope.activityData          = angular.copy(scope.activities_connections[scope.currentIndex].activity);
                scope.currentConnection     = angular.copy(scope.activities_connections[scope.currentIndex]);
            };
            
            scope.setIsDone = function(){
                if (scope.activityData.done_date){
                   scope.activityData.done_date = null;
                }else{
                    scope.activityData.done_date = new Date();
                }
            };

            scope.saveComment = function(){
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity-comment',
                    params: {id: scope.activityData.id},
                    data:   scope.activityComment,
                    success: function(res){
                        scope.activityComment = {};
                        scope.activityData.mergingCommentsTracking = res.current_activity.mergingCommentsTracking;
                    }
                });
            };
            
            scope.saveActivity = function(){
                var params;
                if (scope.activityData.id){
                    params =  {logicId: attrs.logicid, id: scope.activityData.id};
                }else{
                    params = {logicId: attrs.logicid, id: null, recordID: scope.recordid};
                }
                                
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity',
                    params: params,
                    data:   scope.activityData,
                    success: function(res){
                        scope.initActivity();
                        scope.showList();
                        
                        scope.$emit("activitiesCount:newCount", res.activities_count);
                        scope.$emit("activitiesUserCount:newCount");
                    }
                });
            };
            
            scope.addNewActivityConnection = function(newRecordId, newObjectLogicId) {
                if (!newRecordId || !newObjectLogicId) {
                    alert('על מנת להוסיף קישור למשימה, עליך לבחור אובייקט ויעד לקישור')
                    return;
                }
                
                if (newRecordId != scope.currentConnection.record_id || newObjectLogicId != scope.currentConnection.object_id) {
                    Ajax.post({
                        module: 'common',
                        url:    'activity/add-new-connection',
                        params: {id: scope.activityData.id, recordId: newRecordId, logicId: newObjectLogicId},
                        data:   scope.fileData,
                        success: function(res){
                            scope.initActivity();
                            //scope.showList();
                        }
                    });
                }else {
                    alert('קישור המשימה קיים כבר');
                }
            };
            
            scope.deleteActivityConnection = function(id){
                Ajax.post({
                    module: 'common',
                    url: 'activity/delete-connection',
                    params: {id: id},
                    success: function(res){
                        scope.initActivity();
                        //scope.showList();                        
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };
            
            scope.createActivitiesByGroupName = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'activity/create-activities-by-group',
                    params: {groupIndex: $index, recordId: scope.recordid, logicId: attrs.logicid},
                    success: function(res){
                        scope.showList();                        
                    },
                    successMessage: 'קבוצת המשימות נוספה בהצלחה'
                });
            };
        }
    };
}]);