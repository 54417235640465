'use strict';

app.controller('LoginController', ['$scope', '$filter', 'Ajax', ($scope, $filter, Ajax) => {
    const isTokenExist = {exist:'no'};

    window.addEventListener('DOMContentLoaded', (event) => {
        $(".btn-lg").attr("disabled", false);
    });

    $('#login-form').submit((event) =>{
        $(".btn-lg").attr("disabled", true);
        event.preventDefault();
        grecaptcha.ready(() => {
            if (isTokenExist.exist == 'no'){
                isTokenExist.exist='yes';
                grecaptcha.execute('6LczCSwfAAAAAGJdz8tzwgp7sFiQ1LZ8r3TO2FRZ',{action: 'ytLoginSubmit'}).then((token) => {
                    $('#login-form').prepend('<input type="hidden" name="token" value="' + token + '">');
                    $('#login-form').unbind('submit').submit();
                });
            }
        });
    });    
}]);