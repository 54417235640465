app.directive('measureGeneralView', ['$parse', function ($parse) {
    return {
        require:'?ngModel',
        restrict: 'AEC',
        scope: {
            ngModel: '='
        },
        templateUrl: '/js/app/partials/measureGeneralViewTpl.html',
        link:function (scope, elm, attrs, ngModel) {}
    };
}]);


