'use strict';

app.directive('formInternal', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
        },
        templateUrl: '/js/app/directives/formInternal/formInternal.html',
        link: function(scope, element, attrs) {
            $timeout(function(){
                scope.data = angular.fromJson(scope.data);
                if (!scope.data.formSubmission.filled_time && scope.data.filled_times.length == 1) {
                    scope.data.formSubmission.filled_time = scope.data.filled_times[0].name;
                }
                scope.getCurrentAcademicYear();
                scope.globals = $rootScope.globals;
            },1000);
            
            scope.getCurrentAcademicYear = function() {
                if (scope.data.formSubmission.year_id){
                    return;
                }
                Ajax.get({
                    module: 'common',
                    url: 'settings/get-current-academic-year',
                    success: function(res) {
                        scope.data.formSubmission.year_id = res.currentAcademicYear.value;
                    }
                });
            };
            
            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };
            
            $rootScope.$on('ajax:notValid', function(e,data){
                scope.disabledSaveBtn = false; //  if form not valid enable button again
            });

            scope.saveFormSubmission = function(){
                scope.disabledSaveBtn = true; //  to avoid double click disable save button 
                Ajax.post({
                    module: 'common',
                    url: 'form/save-form-submission',
                    data: {
                        recordId: Utils.GET().id,
                        fieldName: scope.data.fieldname,
                        form: scope.data
                    },
                    success: function(res) {
                        scope.disabledSaveBtn = false;
                        $rootScope.$broadcast('formSubmission:submited',null);
                        $rootScope.$broadcast('popup:close',null);  
                    }
                });
            };
            
            scope.calculateRank = function(){
                Ajax.post({
                    module: 'common',
                    url: 'form/calculate-rank',
                    data: {form: scope.data},
                    success: function(res) {
                        scope.data.categories = res.data;
                        $rootScope.$broadcast('formSubmission:submited',null);
                        $rootScope.$broadcast('popup:close',null);  
                    }
                });
            };

            scope.saveAndStayOpen = function(id){
                if (id) {
                    scope.linkShow = !scope.linkShow;
                    return;
                }
                
                Ajax.post({
                    module: 'common',
                    url: 'form/save-form-submission',
                    data: {
                        recordId: Utils.GET().id,
                        fieldName: scope.data.fieldname,
                        form: scope.data
                    },
                    success: function(res) {
                        scope.data.formSubmission = res.data;
                        scope.linkShow = true;
                    }
                });
            };

            scope.cancel = function() {
                $rootScope.$broadcast('popup:close',null);
            };
            
            scope.copyTokenLink = function() {
                /* Get the text field */
                var copyText = document.getElementById("token-link");

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/

                /* Copy the text inside the text field */
                document.execCommand("copy");

            };
            
            scope.getSubmissionStatusHeb = function(status) {
                if (status == 'new') {
                    return 'חדש';
                } else if (status == 'נפתח') {
                    return 'נפתח';
                } else if (status == 'in process') {
                    return 'בתהליך';
                } else if (status == 'submitted') {
                    return 'בוצעה שמירה';
                }
            }; 

            scope.getJSFormatDate = function(d) {
                return new Date(d.replace(/-/g, '/'));
            };      
        }
    };
}]);