'use strict';

app.directive('organizationContact', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            currentLogicid: '=',
            listLogicid: '='
        },
        templateUrl: '/js/app/directives/organizationContact/tpl.html',
        link: function(scope, element, attrs) {       
            scope.list                      = true;
            scope.currentObjName            = null;
            scope.currentIndex              = null;
            scope.data                      = {};
            scope.logicid                   = attrs.listLogicid;
            scope.statusListName            = attrs.statusListName;
            scope.roleListName              = attrs.roleListName;
            scope.projceId                  = YT.user.project_id;
            scope.data.defaultLpiIdStatus   = null;
            
            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'organization-contact/get-list', 
                    params: {id: attrs.recordid, currentLogicId: attrs.currentLogicid, listLogicId: attrs.listLogicid},
                    success: function(res) { 
                        scope.organizationContactList   = res.organizationContactList;
                        scope.listObject                = res.listObject;
                    }
                });
            };            
            
            scope.init();
            
            scope.showList = function(){
                scope.data = {};
                scope.list = true;
            };

            scope.hideList = function(){
                if (scope.data.lpi_id_status){
                    scope.defaultLpiIdStatus = scope.data.lpi_id_status; 
                }
                scope.data.lpi_id_status = scope.defaultLpiIdStatus;
                //scope.data = {};
                scope.list = false;
            };
            
            scope.showEdit = function(e, index){
                if (e){
                    e.stopImmediatePropagation();
                }
                scope.currentIndex  = index;
                scope.data          = angular.copy(scope.organizationContactList[index]);
                scope.list          = false;
            };
            
            scope.goToData = function(data){
                var model   = scope.listObject.model=='Organization' ? 'organization' : 'contact';
                var id      = scope.listObject.model=='Organization' ? data.organization_id : data.user_id;
                Utils.redirect('/'+YT.mvc.module+'/'+model+'/view?id='+id);
            };
            
            scope.mouseMiddleClick = function(e,index){
                // this funciton open the data page in new tab when user press the mouse middle button
                if (e && e.which != 2){ // 2 = mouse middle click
                    return;    
                }
                var model   = scope.listObject.model=='Organization' ? 'organization' : 'contact';
                var id      = scope.listObject.model=='Organization' ? scope.organizationContactList[index].organization_id : scope.organizationContactList[index].user_id;
                window.open('/'+YT.mvc.module+'/'+model+'/view?id='+id, '_blank');
            };
            
            scope.createNew = function(e){
                if (e){
                    e.stopImmediatePropagation();
                }
                var model   = scope.listObject.model=='Organization' ? 'organization' : 'contact';
                Utils.redirect('/'+YT.mvc.module+'/'+model+'/create');
            };
            
            scope.save = function(){
                Ajax.post({
                    module: 'common',
                    url: 'organization-contact/save', 
                    params: {id: attrs.recordid, currentLogicId: attrs.currentLogicid, listLogicId: attrs.listLogicid},
                    data: scope.data,
                    success: function(res) { 
                        scope.init();
                        scope.showList();
                    }
                });
            };
            
            scope.delete = function(id){
                Ajax.post({
                    module: 'common',
                    url: 'organization-contact/delete', 
                    params: {id: id},
                    success: function(res) { 
                        scope.init();
                        scope.showList();
                    }
                });
            };
        }
    };
}]);