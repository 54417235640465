'use strict';

app.controller('EhpSupplierController', ['$scope', '$filter', 'Ajax', 'mailMerge', 'Utils', 'Popup', function($scope, $filter, Ajax, mailMerge, Utils, Popup) {
    
    $scope.init = function(){
        Ajax.get({
            module: 'elhanefeshportal',
            url: 'supplier/get-supplier-details', 
            success: function(res) { 
                $scope.supplier                 = res.data;
                $scope.useAgreementText         = res.useAgreementText;
                $scope.extendUseAgreementText   = res.extendUseAgreementText;
            }
        });
        $scope.getTherapistAttributes();
        $scope.getSupplierPricelist();
    };
    
    $scope.save = function(){
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'supplier/save',
            data: $scope.supplier,
            success: function(res) { 
                $scope.supplier = res.data;
                //Utils.refresh();
            }
        });
    };
       
    $scope.getTherapistAttributes = function(){
        Ajax.get({
            module: 'elhanefeshportal',
            url: 'supplier/get-therapist-attributes', 
            //params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.therapistAttributes = res.attributes;
            }
        });
    };
    
    $scope.getSupplierPricelist = function(){
        Ajax.get({
            module: 'elhanefeshportal',
            url: 'supplier/get-supplier-pricelist', 
            //params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.supplierPricelist = res.pricelist;
            }
        });
    };
    
  
    
    $scope.getTreatmentsLength = function(arr){
        return arr.length;
    };
    
    $scope.pass = {};    
    $scope.changePassword = function(){
        if(!$scope.pass.password || !$scope.pass.new_password || !$scope.pass.retype_new_password){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        if($scope.pass.new_password !== $scope.pass.retype_new_password){
            alert('סיסמה חדשה אינה תואמת בשני השדות');
            return;
        }
        
        if ($scope.pass.new_password.length < 8){
            alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
            return;
        }
        
        if (!$scope.isValidPass($scope.pass.new_password)){
            alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
            return;
        }
        
         Ajax.post({
            url: 'supplier/change-password',
            data: $scope.pass, 
            notShowMessage: true,
            success: function(res){
                $scope.pass = {};
                Utils.showNoty('הסיסמה נשמרה בהצלחה');
            },
            error: function(res){
                alert(res);
            }
        });
    };
    
    $scope.isValidPass = function(str) {
        return /^\w+$/.test(str);
    }  
    
    $scope.init(); 
    
    $scope.openUseAgreementPopup = function(){
        Popup.open('useAgreement', {}, $scope.useAgreementText);
    };
    
    $scope.openExtendUseAgreementPopup = function(){
        Popup.open('useAgreement', {}, $scope.extendUseAgreementText);
    };
}]);