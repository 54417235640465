'use strict';

app.directive('sel', ['$filter','Ajax', '$timeout', function ($filter, Ajax, $timeout) {
    return{
        require: '?ngModel',
        restrict: 'AE',
		replace: false,
		scope: {
			ngModel :	'=',
			collection: '=?',
			picklist :	'@',
			model:		'=?',
			field:		'=?',
            exceptid:    '@'
		},
        templateUrl: '/js/app/directives/sel/tpl.html',
        link: (scope, elem, attrs, ngModel) => {
            angular.element(elem).ready(() => {
                function getParams(){
					return attrs.model && attrs.field ? 
							{model: attrs.model, field: attrs.field} : {name: attrs.picklist, useProjectId: attrs.useProjectId, exceptId: attrs.exceptid};
				};
                
                if( !attrs.collection ){
                    Ajax.get({
                        module: 'common',
                        url:	attrs.url ? attrs.url : 'picklist/get', 
                        params: getParams(),
                        success: function(res) { 
                            scope.collection = res;
                            // set default value as selected
                            if (!scope.ngModel){
                                var defaultValue = $filter('filter')(scope.collection,{is_default:1});
                                if (defaultValue.length > 0 && defaultValue[0].is_default == 1){
                                    scope.ngModel = defaultValue[0].id;
                                }
                            }
                        }
                    });
                }
            });
        }
    };
}]);

