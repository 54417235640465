'use strict';

app.directive('lookup', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngModel',
        restrict: 'AE',
        replace: false,
        scope: {
            ngModel     :	'=',
            ngData      :   '=',
            ngModelName :   '='
        },
        templateUrl: '/js/app/directives/lookup/tpl.html',
        link: function(scope, element, attrs) {
			element.find('select').select2({
				ajax: {
					url: function (params) {
						var logicId = attrs.ngLogicId;

						if (logicId) {
							return "/common/lookup/lookup?logicId=" + logicId + "&searchString=" + params.term + ( (attrs.ngDefaultWhere) ? "&defaultWhere=" + attrs.ngDefaultWhere : '' );
						}
						alert('אין אפשרות לבצע חיפוש ללא בחירת אובייקט');
						return;
					},
					dataType: 'json',
					delay: 250,
					processResults: function (data) {
						return {
							results: data.items
						};
					},
					cache: true
				},
				minimumInputLength: 2,
				width: attrs.ngWidth ? attrs.ngWidth + '%' : 'resolve',
				placeholder: "הזן ערך...", 
				allowClear: true, // allowclear work only if placeholder set
				language: {
					/*noResults: function() {
						return "לא נמצאו נתונים התואמים את החיפוש " + ( (attrs.ngModelName) ? '<a href="' + '/' + YT.mvc.module + '/' + attrs.ngModelName + '/' + 'create' + '">לחץ ליצירת חדש</a>' : '' );
					},*/
                    noResults: function() {
						return "לא נמצאו נתונים התואמים את החיפוש ";
					},
					 inputTooShort: function(args) {
						var remainingChars = args.minimum - args.input.length;
						return "עליך להזין עוד " + remainingChars + " תוים";
					},
					searching: function () {
						return 'מחפש…';
					},
					errorLoading: function () {
						return 'אירעה שגיאה בעת החיפוש';
					},
			   },
			   escapeMarkup: function (markup) {
					return markup;
				}
			});
        }
    };
}]);