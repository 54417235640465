app.directive('dropDownOperators', ['Ajax', 'Utils', '$timeout',  
	function (Ajax, Utils, $timeout) {
    return{
        restrict: 'AE',
        scope: {
            sw: '=?'
        },
        transclude: false,
        templateUrl: '/js/app/partials/dropDownOperatorsTpl.html',
        link: function(scope, element, attrs) { 

            if(scope.sw.type === 'lookup'){
				
                var params = {lookup_id: scope.sw.typeId ? scope.sw.typeId : 'null'};
                
                if(scope.sw.typeTbl){
                    params.model = scope.sw.typeTbl;
                }
                
                if(scope.sw.where){
                    params.where = scope.sw.where;
                }

                Ajax.get({
                    url: 'list/get-lookup', 
                    params: params,
                    success: function(res) { 
                        scope.picklist = res.picklist;
                    }
                });
            }
			else if(scope.sw.type === 'enum'){
                scope.picklist = scope.sw.typeValues;
            }else if(scope.sw.type === 'tag'){
				//var params = {lookup_id: scope.sw.typeId ? scope.sw.typeId : 'null'};
                
                Ajax.get({
                    url: 'list/get-tags', 
                    //params: params,
                    success: function(res) { 
                        scope.picklist = res.picklist;
                    }
                });
			}
        }  
    };
}]);

app.directive('dropDownValues', ['Ajax', 'Utils', '$timeout',  function (Ajax, Utils, $timeout) {
    return{
        restrict: 'AEC',
        scope: {
            sw: '='
        },
        transclude: false,
        templateUrl:'/js/app/partials/dropDownValuesTpl.html',
        link: function(scope, element, attrs) { 
            if(scope.sw.type === 'lookup'){
                var params = {lookup_id: scope.sw.typeId ? scope.sw.typeId : 'null'};
                
                if(scope.sw.typeTbl){
                    params.model = scope.sw.typeTbl;
                }
                
                
                
                if(scope.sw.where){
                    params.where = scope.sw.where;
                }

                Ajax.get({
                    url: 'list/get-lookup', 
                    params: params,
                    success: function(res) { 
                        scope.picklist = res.picklist;
                    }
                });
            }else if(scope.sw.type === 'enum'){
                scope.picklist = scope.sw.typeValues;
            }
        }  
    };
}]);