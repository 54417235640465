'use strict';

// changeStatusPopupController use in both controller - AcademicYearController & AcademicSemesterController
var changeStatusPopupController = function($scope, $modalInstance, $modal, Ajax, selected, controllerName){
    $scope.data = {
        status: null
    };
    
    Ajax.get({
        url: 'academic-study-year/get-file-check-status',
        success: function(res){
            $scope.picklist = res.picklist;
        }
    });
    
    
    $scope.ok = function(){
        if( !$scope.data.status ){
            return;
        }
        
        Ajax.post({
            url: controllerName  + '/set-file-status',
            data:   {ids: selected},
            params: {status_id: $scope.data.status},
            success: function(res){
                $modalInstance.close(res);
            }
        });
    };
    
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};
