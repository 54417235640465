'use strict';

app.directive('hintExistData', ['$parse', '$filter', '$compile', '$timeout', 'Ajax', function ($parse, $filter, $compile, $timeout, Ajax) {
    return{
        require: '?ngModel',
        restrict: 'AE',
        scope:      true,
        link: function(scope, elem, attrs) {
            if(!attrs.logicId || !attrs.modelName){
                return;
            }
            scope.field         = attrs.ngModel;
            scope.logicId       = attrs.logicId;
            scope.modelName     = attrs.modelName;
            scope.items         = {};
            scope.dirCSS        = '';
            
            if (elem[0].style['direction'] == 'ltr'){
                scope.dirCSS = 'pull-left';
            }
            
            elem[0].addEventListener('input', (e)=>{
                scope.lookForExistData(e.target.value);
            });


            String.prototype.replaceAll = function(searchStr, replaceStr) {
                var str = this;
                // no match exists in string?
                if(str.indexOf(searchStr) === -1) {
                    // return string
                    return str;
                }
                // replace and remove first match, and do another recursirve search/replace
                return (str.replace(searchStr, replaceStr)).replaceAll(searchStr, replaceStr);
            }
            scope.id = scope.field.replaceAll('.', '-');
            
            var contentTr = angular.element(
                '<div id="' + scope.id + '" class="customl pac-container" style="position: absolute; display : none; width:95%">'+
                    '<div ng-repeat="item in items">'+
                        '<div class="pac-item" ng-click="showme()"><a class="{{dirCSS}}" name="hint-exist-data" href="/{{globals.mvc.module}}/{{modelName}}/view?id={{item.id}}" target="_blank">{{item.text}}</a></div>'+
                    '</div>'+
                '</div>'
            );
            contentTr.insertAfter(elem);
            $compile(contentTr)(scope);
            
            // *** START ***
            // *** close the hint div when click on another place on page
            document.body.addEventListener('click', function(e){
                scope.lostFocus(e.toElement);
            }); 
            // *** close the hint div when use tab to go to next input
            $('form').find(':input').focus(function(){
                scope.lostFocus(this);
            });
            scope.lostFocus = function(currElem){
                if (currElem) {
                
                    if ( currElem.name == 'hint-exist-data' || currElem.className == 'pac-item' ){
                        return;
                    }
                    var currentElemAttr = currElem.getAttribute('ng-model');
                    if(currentElemAttr && $('#' + currentElemAttr.replaceAll('.', '-')).css('display') == 'block'){
                        return;
                    }
                }
                $('.customl').hide();
            }
            // *** END ***
            
            scope.lookForExistData = (value) => {
                if (value.length > 1){
                    Ajax.get({
                        module: 'common',
                        url:    'lookup/lookup',
                        params: {logicId: scope.logicId, searchString: value},
                        success: function(res){
                            scope.items    = res.items;
                            if (scope.items && scope.items.length > 0) {
                                $('#' + scope.id).show();
                            }
                        },
                        notShowPageLoadingFrame: true
                    });
                }else{
                    $('#' + scope.id).hide();
                }
            };
        }
    };
}]);