'use strict';

app.directive('ihptServices', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            clientid: '='
        },
        templateUrl: '/js/app/directives/ihptServices/ihptServicesTpl.html',
        link: function(scope, element, attrs) {
            
            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'ihpt/get-ihpt-services',
                    params: {clientId: scope.clientid},
                    success: function(res){
                        scope.ihptServices  = res.ihptServices;
                    }
                });
            };              
            
            scope.saveService = function(){
                Ajax.post({
                    module: 'common',
                    url: 'ihpt/save-ihpt',
                    data: scope.record,
                    params: {logicId: scope.logicid},
                    success: function(res){
                        scope.init();
                    }
                });
            };
        }
    };
}]);