app.directive('autosave', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    return{
        require:	'?ngModel',
        restrict:	'A',
		replace: false,
		scope: {
			model: '=ngModel',
            savefn: '&'
        },
        link: function(scope, elem, attrs) { 			
			let timeout		= undefined;
			let hasNewValue = false;
			scope.$watch('model', (newValue, oldValue) => {
				if( (typeof oldValue !== 'undefined') && (typeof newValue !== 'undefined')  && newValue !== oldValue ){
					hasNewValue = true;
					if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(scope.runSaveFunction, 1000);
				}
			}, true);
			
			scope.runSaveFunction = () => {
				if(hasNewValue){
					scope.savefn();
					hasNewValue = false;
				}
			};
		}
	};
}]);