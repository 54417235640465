'use strict';

app.controller('YtcrmContactController', ['$rootScope', '$scope', 'Ajax', 'Utils', 'Popup', '$filter', '$sce', function($rootScope, $scope, Ajax, Utils, Popup, $filter, $sce,) {
    $scope.tagsArr              = [];    
    $scope.data                 = {};
    $scope.data.userContact     = {};
    $scope.data.userItems       = [];
    $scope.filterDate           = null;
    $scope.back                 = null;
    $scope.showLoadFrame        = false;
    $scope.showNoDataFrame      = false;
    
    $scope.init = function(){
        if (Utils.GET().id){
            Ajax.get({
                module: 'ytcrm',
                url: 'contact/get-contact-details',
                params: {id: Utils.GET().id},
                success: function(res) { 
                    $scope.data = res.data;
                    $scope.getUserItems('anydirection');
                    $scope.tagsToArr();
                    if (Utils.GET().email) {
                        $scope.data.userContact.email = Utils.GET().email;
                    }
                }
            });
        }
        if (Utils.GET().email) {
            $scope.data.userContact.email = Utils.GET().email;
        }
    };
	
    $scope.init();
    
    // for update mode
    $scope.$on('cru:postSuccess', function(e, mod, res){
        Utils.redirect('/ytcrm/contact/view?id=' + res.data.id);
    });
    
    $scope.$on('item:createdsuccess', function(e, res){
        $scope.filterDate = res.due_date;
        $scope.getUserItems('anydirection',true);
    });
    
    $scope.$on('item:fileupload', function(e, res){
        var currentDate = new Date();
        var year=currentDate.getFullYear(),month=currentDate.getMonth()+1,day=1;
        $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
        $scope.getUserItems('anydirection',true);
    });
    
    $scope.$on('activity:groupadded', function(e, res){
        $scope.filterDate = null;
        $scope.getUserItems('anydirection',true);
    });
    
    $scope.$on('item:fileupdated', function(e, res){
        var resDate = res.filterDate.replace(/-/g, ''); // remove all '-' dashs from date string
        var year=res.substring(0,4),month=res.substring(4,2),day=1;
        $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
        $scope.getUserItems('anydirection',true);
    });
    
    $scope.save = function(){
        Ajax.post({
            module: 'ytcrm',
            url: 'contact/save',
            params: {id: Utils.GET().id},
            data: $scope.data,
            success: function(res){
				$scope.data = res.data;
                if(window.location.href.includes('view')){
                    //$scope.getUserItems(0);
                    $scope.tagsToArr();
                    return;
                }
                Utils.redirect('/ytcrm/contact/view?id=' + res.data.id);
            },
           
        });
    };
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });
    
    
    $scope.getUserItems = function(direction,updateItems=false){
        //var offset = offset ? offset : 0;
        if (!$scope.filterDate){
            var currentDate = new Date();
            var year=currentDate.getFullYear(),month=currentDate.getMonth()+1,day=1;
            $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
        }
        $scope.showLoadFrame = true;
        Ajax.get({
            module: 'common',
            url: 'calendar/get-auth-url', 
            success: function(res) { 
                $scope.authUrl = res.authUrl;
                if($scope.data.userContact && $scope.data.userContact.email){
                    Ajax.get({
                        module: 'common',
                        url: 'calendar/get-items-by-email',
                        params: {userId: Utils.GET().id, email: $scope.data.userContact.email, filterDate: $scope.filterDate, direction: direction},
                        success: function(res) {
                            if ($scope.data.userItems && $scope.data.userItems.length > 0){
                                if (updateItems){
                                    var keyMonth    = $scope.filterDate.replace(/-/g, ''); // remove all '-' dashs from date string
                                    keyMonth        = keyMonth.substring(0,6); // get the year and month only like '201811'
                                    var itemIndex = $scope.getIndexByMonthNumber(keyMonth);
                                    if (itemIndex == -1){
                                        if (parseInt(keyMonth) > parseInt($scope.data.userItems[0].monthNumber)){
                                            $scope.data.userItems = res.items.concat($scope.data.userItems);
                                        }else if (parseInt(keyMonth) < parseInt($scope.data.userItems[$scope.data.userItems.length-1].monthNumber)){
                                            $scope.data.userItems = $scope.data.userItems.concat(res.items);
                                        }else{
                                            var spliceIndex = $scope.getIndexForSplice(keyMonth);
                                             $scope.data.userItems.splice(spliceIndex,0,res.items[0]);
                                        }
                                    }else{
                                        $scope.data.userItems[itemIndex] = res.items[0];
                                    }
                                }else{
                                    if ($scope.back == true) {
                                        $scope.data.userItems = $scope.data.userItems.concat(res.items);
                                        if (res.items[0] && $scope.getTotalItems() < 15){
                                            $scope.goMonthBack();
                                        }
                                    }else{
                                        $scope.data.userItems = res.items.concat($scope.data.userItems);
                                    }
                                }
                            }else{
                                $scope.data.userItems = res.items;
                                if (res.totalCount < 15){
                                    $scope.goMonthBack();
                                }
                            }
                            $scope.data.itemsCount          = res.itemsCount;
                            $scope.data.calendarAuthLevel   = res.calendarAuthLevel;
                            $scope.disableForwardBtn        = false;
                            $scope.disableBackBtn       = false;   
                            $scope.showLoadFrame = false;
                            $scope.showNoDataFrame = $scope.data.userItems.length == 0;
                        },
                        notShowMessage: true
                    });
                }else{
                    $scope.showLoadFrame = false;
                }
            }
        });
    };
        
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
    
    $scope.removeContact = function($id){
        Ajax.post({
            module: 'ytcrm',
            url: 'contact/remove',
            params: {id: $id},
            success: function(res){
				Utils.redirect('/ytcrm');
            },
            successMessage: 'הרשומה נמחקה בהצלחה'
        });
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
    
    $scope.openCalendarEventPopup = function(data,$index){
        $scope.calendarEventIndex = $index;
        var fn = {
            getJSFormatDate:    $scope.getJSFormatDate,
            openSummaryPopup:   $scope.openSummaryPopup
        };
        $scope.calendarEventModalInstance = Popup.open('calendar/event', {}, data, fn);
    };
    
    $scope.trustAsHtml = function(bindHtml){
        if (bindHtml){
            return $sce.trustAsHtml(bindHtml);
        }
    };

    // START test           
    $scope.saveSummaryCalendarEvent = function(data, $modalInstance){
        if (!data.summary){
            alert('אין אפשרות להמשיך ללא הכנסת תיעוד פגישה');
            return;
        }
        Ajax.post({
            module: 'common',
            url: 'calendar/save-calendar-event-summary', 
            data: data,
            success: function(res) {
                data = res.data;
                $scope.saveEventStatus(data, 115);
                $modalInstance.close();
                if ($scope.calendarEventModalInstance){
                    $scope.calendarEventModalInstance.close();
                }
                $scope.getEventDetailsById(data);
                if (data.toArray.length>0){
                    $scope.openSendSummaryConfirm(data);
                }
            }
        });
    };
    
    $scope.getEventDetailsById = function(ce) {
        if (!ce && !ce.start){
            return;
        }
        var keyMonth    = ce.start.replace(/-/g, ''); // remove all '-' dashs from date string
        keyMonth        = keyMonth.substring(0,6); // get the year and month only like '201811'
        var itemIndex = $scope.getIndexByMonthNumber(keyMonth);
        
        Ajax.get({
            module: 'common',
            url: 'calendar/get-event-details-by-id', 
            params: {id: ce.id},
            success: function(res) {
                $rootScope.$broadcast('parent:getEventDetailsById',res.calendarEvent);
                res.calendarEvent.type = 'event';
                res.calendarEvent.fullView = true;
                res.calendarEvent.currentIndex = ce.currentIndex;
                res.calendarEvent.showSummaryBtn = true;
                $scope.data.userItems[itemIndex]['itemsList'][ce.currentIndex] = res.calendarEvent;
            }
        });
        
    };
    
    $scope.getTotalItems = function(){
        var totalItems = 0;
        angular.forEach($scope.data.userItems, function(item, key) {
            totalItems += item.itemsList.length;
        });
        return totalItems;
    }
    
    $scope.getIndexByMonthNumber = function(monthNumber){
        var index=0;
        var keepgoing = true;
        angular.forEach($scope.data.userItems, function(item, key) {
            if(keepgoing && item.monthNumber == monthNumber) { 
                keepgoing = false;
            }else if (keepgoing){
                index++;
            }
        });
        return keepgoing ? -1 : index;
    };
    
    $scope.getIndexForSplice = function(monthNumber){
        var index=0;
        var keepgoing = true;
        angular.forEach($scope.data.userItems, function(item, key) {
            if(keepgoing && parseInt(monthNumber) > item.monthNumber) { 
                keepgoing = false;
            }else if (keepgoing){
                index++;
            }
        });
        return keepgoing ? -1 : index;
    };
    
    $scope.openSummaryPopup = function(data, $index) {
        $scope.calendarEventIndex = $index;
        data.summary = data.activity ? data.activity.content : null;
        var fn = {
            getJSFormatDate:        $scope.getJSFormatDate,
            removeAttendee:         $scope.removeAttendee,
            unremoveAttendee:       $scope.unremoveAttendee,
            getEventDetailsById:    $scope.getEventDetailsById
        };
        Popup.open('ytcrm/calendarEventSummary', $scope.saveSummaryCalendarEvent, (data) ? data : {}, fn, {});
    };
    
    
    $scope.sendSummaryEmail = function(data, $modalInstance){
        Ajax.post({
            module: 'common',
            url: 'calendar/send-summary-email', 
            data: data,
            success: function(res) {
                $scope.saveEventStatus(data,124);
                $modalInstance.close();
            }
        });
    };
    
    $scope.openSendSummaryConfirm = function(data) {
        Popup.open('ytcrm/sendSummaryMailConfirm', $scope.sendSummaryEmail, data);
    };
    
    $scope.removeAttendee = function(data, $index, $modalInstance){
        if (data.attendees.length > $index) {
            data.attendees[$index].removed = true;
        }
        if (data.activity && data.activity.allConnections && data.activity.allConnections[$index].id) {
            Ajax.post({
                module: 'common',
                url: 'activity/delete-connection',
                params: {id: data.activity.allConnections[$index].id},
                success: function(res){
                    data.activity.allConnections.splice($index,1);
                    $scope.getEventDetailsById(data);
                },
                successMessage: 'הקישור נמחק בהצלחה'
            });
        }
        if (data.attendees.length > $index) {
            data.attendees[$index].activityConnection = null;
        }
    };
    
    $scope.unremoveAttendee = function(data, $index, $modalInstance){
        delete data.attendees[$index].removed;
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
    
    $scope.saveEventStatus = function(event, logicId){
        Ajax.get({
            module: 'common',
            url: 'calendar/set-calendar-event-status', 
            params: {eventId: event.id, logicId: logicId},
            success: function(res) {
                $scope.getEventDetailsById(event);
            }
        });
    };
    
    $scope.goMonthForward = function(){
        var monthNumber = $scope.data.userItems[0].monthNumber;
        var jsDate = new Date($scope.data.userItems[0].monthNumber.substring(0,4), $scope.data.userItems[0].monthNumber.substring(4,6)-1, 1); // day of month always 1st day to avoid day that not exist like 2018-02-30 etc...
        jsDate.setMonth(jsDate.getMonth() + 1);
        var year=jsDate.getFullYear(),month=jsDate.getMonth()+1,day=jsDate.getDate();
        $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-01');
        $scope.futureFilterDate = $scope.filterDate;
        $scope.back = false;
        $scope.getUserItems('forward');
    };
    
    $scope.goMonthBack = function(){
        if ($scope.data.userItems.length==0){
            return;
        }
        var monthNumber = $scope.data.userItems[$scope.data.userItems.length-1].monthNumber;
        var jsDate = new Date(monthNumber.substring(0,4), monthNumber.substring(4,6)-1, 1); // day of month always 1st day to avoid day that not exist like 2018-02-30 etc...
        jsDate.setMonth(jsDate.getMonth() - 1);
        var year=jsDate.getFullYear(),month=jsDate.getMonth()+1,day=jsDate.getDate();
        $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-01');
        $scope.pastFilterDate = $scope.filterDate;
        $scope.back = true;
        $scope.getUserItems('back');
    };
    // END test           
}]);