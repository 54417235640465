'use strict';

app.directive('radioButtons', ['$filter','Ajax', '$timeout', function ($filter, Ajax, $timeout) {
    return{
        require: '?ngModel',
        restrict: 'AE',
		//replace: false,
		scope: {
            ngModel :	    '=',
            collection:     '=?',
            fieldname:           '@',
            selectedColor:  '@'
        },
        templateUrl: '/js/app/directives/radioButtons/radioButtons.html',
        link: (scope, elem, attrs, ngModel) => {
            $timeout(function(){    
                scope.fieldname = attrs.fieldname;
                scope.selectedColor = attrs.selectedColor;
            },1000);

            scope.lclick = function(v){
                scope.ngModel = v;
            }
            
        }
    };
}]);

