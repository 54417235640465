'use strict';

app.controller('EhRequestController', ['$rootScope', '$scope', '$filter', 'Ajax', 'mailMerge', 'Utils', 'Popup', function($rootScope, $scope, $filter, Ajax, mailMerge, Utils, Popup) {
    $scope.data = {};
    $scope.treatments = {};
    $scope.editBudgetData = null;
    $scope.editTreatmentData = null;
    $scope.isInit = true;
    
    // Yechiel - I add this event listner, need to see if it's OK
    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.getRequestListsDetails();
        }
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        $scope.mod = mod;
        if(mod == 'add'){
            $scope.data.user_id = Utils.GET().user_id;
            if (YT.user.project_id === 10){
                //$scope.data.lpi_id_status_content = 717;
                $scope.data.lpi_id_recommendation_status = 924;
            }
        }else{
            if(window.location.href.includes('view')){
                $scope.getRequestListsDetails();         
            }
        }
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        $scope.mod = mod;
        //if(mod === 'add'){
            Utils.redirect('/elhanefesh/request/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('quickBrSave:postSuccess', function(e, mod, res){
        $scope.getRequestListsDetails();
    });
    
    // on new request when select סוג טיפול
   $scope.$watch('data.program_id', function(newValue, oldValue) {
       if (newValue && $scope.isInit && !window.location.href.includes('create')){
            $scope.isInit = false;
            return;
       }
        if( window.location.href.includes('view') || (!newValue && !oldValue) ){
            return;
        }
        let projectId = YT.user.project_id;
        if ( !newValue ) { // if program not selected
            if ( projectId == 10 ) {
                $scope.data.therapist_expected_quantity = null;
                $scope.data.team_expected_quantity = null;
            }else if (projectId == 5) {
                $scope.lpi_id_require_therapeutic_plan = null;
            }
        }
        if ( newValue && (oldValue != newValue) ) {
            var settingId = 28;
            if (projectId == 5){
                settingId = 29;
            }

            Ajax.get({
                module: 'common',
                url: 'settings/request-default-list',
                params: {id: settingId},
                notShowMessage: true,
                success: function(res) {
                    if (projectId == 10){
                        if (res[$scope.data.program_id]) {
                            $scope.data.therapist_expected_quantity = res[$scope.data.program_id]['therapist_expected_quantity'] ? res[$scope.data.program_id]['therapist_expected_quantity'] : null;
                            $scope.data.team_expected_quantity = res[$scope.data.program_id]['team_expected_quantity'] ? res[$scope.data.program_id]['team_expected_quantity'] : null;
                        }else { // if no default values for current program id
                            $scope.data.therapist_expected_quantity  = $scope.data.therapist_expected_quantity | null;
                            $scope.data.team_expected_quantity       = $scope.data.team_expected_quantity | null;
                        }
                    }else if (projectId == 5){
                        if (res[$scope.data.program_id]) {
                            $scope.data.lpi_id_require_therapeutic_plan = res[$scope.data.program_id]['lpi_id_require_therapeutic_plan'] ? res[$scope.data.program_id]['lpi_id_require_therapeutic_plan'] : null;
                            $scope.data.treatments_num_for_blocking = res[$scope.data.program_id]['treatments_num_for_blocking'];
                        }else{
                            $scope.data.lpi_id_require_therapeutic_plan = null;
                            $scope.data.treatments_num_for_blocking = null;
                        }
                    }
                }
            });
        }
   });
    
    $scope.getRequestListsDetails = function(){
        Ajax.get({
            module: 'elhanefesh',
            url: 'request/get-request-lists-details', 
            params: {
                programId: $scope.data.program_id,
                patientId: $scope.data.user_id,
                therapistId: $scope.data.user_id_therapist,
                orgId: ($scope.data.userPatient.organization_id) ? $scope.data.userPatient.organization_id : null,
                id: Utils.GET().id
            },
            success: function(res) { 
                $scope.supplierPricelist    = res.supplierPricelist;
                $scope.supplierHmo          = res.supplierHmo;
                $scope.budgetRequests       = res.budgetRequests;
                $scope.treatments           = res.treatments;
                $scope.patientRequests      = res.patientRequests;
                $scope.isLastRoundNotExist  =  $filter('filter')($scope.budgetRequests,{is_last_round:1}).length == 0;
            }
        });
    };
    
    $scope.addBudgetRequest = function(data, $modalInstance){
        if(!data.id && $scope.editBudgetData && angular.equals($scope.editBudgetData, data)){
            return; // to avoid from multiple saving
        }
               
        $scope.editBudgetData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/add-budget-request', 
            params: {id: Utils.GET().id},
            data: data,
            success: function(res) { 
                $scope.getRequestListsDetails();
                //$scope.$broadcast('quickBrSave:savedByPopup', 'view', data.id);
                if ($modalInstance){
                    $modalInstance.close();
                }
            }
        });
    };
        
    $scope.saveBudgetRequest = function(data, $modalInstance){
        if(!data.id && $scope.editBudgetData && angular.equals($scope.editBudgetData, data)){
            return; // to avoid from multiple saving
        }
               
        $scope.editBudgetData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/save-budget-request', 
            params: {id: Utils.GET().id, budgetRequestId: data.id},
            data: data,
            success: function(res) { 
                $scope.getRequestListsDetails();
                $scope.$broadcast('quickBrSave:savedByPopup', 'view', data.id);
                if ($modalInstance){
                    $modalInstance.close();
                }
            }
        });
    };
        
    $scope.openBudgetRequestPopup = function(br){
        
        if (!br && !$scope.data.userPatient.lpi_id_hmo){
            alert('כדי להמשיך ביצירת תקציב, צריך למלא את קופת החולים בכרטיס המוטב');
            return;
        }
        $scope.editBudgetData = null;
        
        br = br || {};
        br.isHmoPriceExist = $scope.supplierHmo != null;
        
        var watch = [];
        
        
        var fn = [];
        fn = {
            setSupplierHmoPrice: $scope.setSupplierHmoPrice,
            cancelSupplierHmoPrice: $scope.cancelSupplierHmoPrice,
            calculateCosts: (data) => {
                //br.treatment_cost = $scope.supplierPricelist.treatment_cost;
                if (data.treatment_cost && data.treatment_cost > 0){
                    data.patient_amount = $scope.getPercentPaticipation(data)/100*data.treatment_cost;
                    if (YT.user.project_id==5) {// round only in elhanefesh not in merchavim
                        //data.patient_amount = 5*(Math.round(data.patient_amount/5));
                        data.patient_amount = Math.round(data.patient_amount/10)*10;
                    }
                    data.amount = data.treatment_cost-data.patient_amount;
                }else{
                    alert('על מנת לבצע חישוב עלויות עליך להזין ערך בעלות מפגש ולוודא שהערך שונה מאפס')
                }
            },
            resetPatientCost: (data) => {
                data.amount = data.treatment_cost;
                if (data.actual_fund_amount){
                    data.actual_fund_amount = data.treatment_cost;
                }
                data.patient_amount = 0;
            }
        };
        $scope.blockListRes = {};
        Ajax.get({
            module: 'common',
            url: 'settings/budgetrequest-block-list',
            params: {id: YT.user.project_id==5?7:10},
            notShowMessage: true,
            success: function(res) {
               $scope.blockListRes = res; 
                watch = [
                    {
                        myVar: 'data.lpi_id_recommendation_to_committee',
                        myFun: function(newValue, oldValue){
                            if(newValue && newValue != oldValue){
                                Ajax.get({
                                    module: 'common',
                                    url: 'picklist/get-item-id-by-logic-id', 
                                    params: {logicId: 5}, // 5 == מוכן לקדם ועדה
                                    notShowMessage: true,
                                    success: function(res) {
                                        if (br.lpi_id_status_commitee_decision == res.data){
                                            Ajax.post({
                                                module: 'common',
                                                url: 'picklist/get-item-id-by-logic-id', 
                                                params: {logicId: 6}, // 6 = מוכן לועדה
                                                notShowMessage: true,
                                                success: function(res) { 
                                                    br.lpi_id_status_commitee_decision = res.data;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    },
                    {
                        myVar: 'data.lpi_id_status_commitee_decision',
                        myFun: function(newValue, oldValue){
                            if(newValue && newValue != oldValue){
                                Ajax.get({
                                    module: 'common',
                                    url: 'picklist/get-item-id-by-logic-id', 
                                    params: {logicId: (YT.user.project_id == 5 ? 6 : 5)},  // 6 = מוכן לועדה
                                    notShowMessage: true,
                                    success: function(res) {
                                        if (br.lpi_id_status_commitee_decision == res.data){    
                                            var idx = 0;
                                            idx = br.round <  $scope.blockListRes[$scope.data.program_id].length ? br.round : $scope.blockListRes[$scope.data.program_id].length-1;
                                            //idx = (br.round == 0)?0:1;
                                            
                                            if (!br.from_date) {
                                                var fromDateDefault = new Date();
                                                fromDateDefault.setMonth(fromDateDefault.getMonth() + $scope.blockListRes[$scope.data.program_id][idx].start_validation);
                                                br.from_date = fromDateDefault;
                                            }
                                            if (!br.to_date) {
                                                var toDateDefault = new Date();
                                                if ($scope.blockListRes[$scope.data.program_id][idx].end_validation_date){
                                                    br.to_date = $scope.blockListRes[$scope.data.program_id][idx].end_validation_date;
                                                }else{
                                                    toDateDefault.setMonth(toDateDefault.getMonth() + $scope.blockListRes[$scope.data.program_id][idx].end_validation);
                                                    br.to_date = toDateDefault;
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    },
                    {
                        myVar: 'data.is_last_round',
                        myFun: function(newValue, oldValue){
                            if(newValue && newValue != oldValue && !br.id){
                                var idx = 0;
                                if ($scope.budgetRequests){
                                    idx = br.round <  res[$scope.data.program_id].length ? br.round : res[$scope.data.program_id].length-1;
                                }
                                if (newValue == 1 && res[$scope.data.program_id][idx].last_round_therapist_form_id){
                                    br.therapist_form_id =  res[$scope.data.program_id][idx].last_round_therapist_form_id;
                                }else{
                                    br.therapist_form_id =  res[$scope.data.program_id][idx].therapist_form_id;
                                }
                                // digital feedback for contact
                                if (newValue == 1 && res[$scope.data.program_id][idx].last_round_contact_form_id){
                                    br.contact_form_id =  res[$scope.data.program_id][idx].last_round_contact_form_id;
                                }else{
                                    br.contact_form_id =  res[$scope.data.program_id][idx].contact_form_id;
                                }
                            }
                        }
                    }
                ];
                
                if(!br.id){ // if it's new budget request
                    br.request = $scope.data;
                    Ajax.get({
                        module: 'elhanefesh',
                        url: 'request/get-start-round', 
                        params: { programId: $scope.data.program_id, requestId: $scope.data.id},
                        success: function(result) { 
                            br.lpi_id_budget_classification = result.budgetClassification;
                            if ($scope.budgetRequests && $scope.budgetRequests.length == 0) {
                                br.round = result.startRound;
                            }else{
                                br.round = result.currentRound;
                            }
                            
                            var idx = 0;
                            if ($scope.budgetRequests){
                                idx = br.round <  res[$scope.data.program_id].length ? br.round : res[$scope.data.program_id].length-1;
                            }

                            // digital feedback for therapist
                            if (br.is_last_round==1 && res[$scope.data.program_id][idx].last_round_therapist_form_id){
                                br.therapist_form_id =  res[$scope.data.program_id][idx].last_round_therapist_form_id;
                            }else{
                                br.therapist_form_id =  res[$scope.data.program_id][idx].therapist_form_id;
                            }
                            // digital feedback for contact
                            if (br.is_last_round==1 && res[$scope.data.program_id][idx].last_round_contact_form_id){
                                br.contact_form_id =  res[$scope.data.program_id][idx].last_round_contact_form_id;
                            }else{
                                br.contact_form_id =  res[$scope.data.program_id][idx].contact_form_id;
                            }
                            br.treatments_num_for_alert = res[$scope.data.program_id][idx].treatments_num_for_alert;
                            br.treatments_num_for_blocking = res[$scope.data.program_id][idx].treatments_num_for_blocking;
                            br.lpi_id_therapist_assignment_submission_status = res[$scope.data.program_id][idx].lpi_id_therapist_assignment_submission_status;
                            br.lpi_id_contact_assignment_submission_status = res[$scope.data.program_id][idx].lpi_id_contact_assignment_submission_status;
                            br.lpi_id_patient_assignment_submission_status = res[$scope.data.program_id][idx].lpi_id_patient_assignment_submission_status;
                            br.quantity = res[$scope.data.program_id][idx].number_of_sessions;
                            

                            // when open new budget set the budget supplier id with therapist id from request as default
                            br.user_id_supplier = $scope.data.user_id_therapist;
                            // ** pass the therapist name for select 2 **
                            br.userIdSupplier = {};
                            br.userIdSupplier.supplierFullName = $scope.data.userIdTherapist.therapistFullName;
                            // *** end ***
                            if ($scope.supplierPricelist || $scope.supplierHmo){
                                br.treatment_cost = $scope.supplierHmo ? $scope.supplierHmo.patient_cost : $scope.supplierPricelist.treatment_cost;
                                br.patient_amount = $scope.getPercentPaticipation(br)/100*br.treatment_cost;
                                if (YT.user.project_id==5) {// round only in elhanefesh not in merchavim
                                    // br.patient_amount = 5*(Math.round(br.patient_amount/5));
                                    br.patient_amount = Math.round(br.patient_amount/10)*10;
                                }
                                
                                br.amount = br.treatment_cost-br.patient_amount;
                                br.pricelist = $scope.supplierHmo ? $scope.supplierHmo.patient_cost : $scope.supplierPricelist.treatment_cost;
                                //br.actual_fund_amount = $scope.supplierPricelist.treatment_cost;
                                
                                br.supplier_hmo_id  = $scope.supplierHmo ? $scope.supplierHmo.id : null;
                                br.lpi_id_hmo       = $scope.supplierHmo ? $scope.supplierHmo.lpi_id_hmo : null;
                            }else{
                                if (br.request.treatmentGroup){
                                    if (br.request.treatmentGroup.counter_request_id && br.request.treatmentGroup.counter_request_id > 0){
                                        br.amount = br.request.treatmentGroup.amount/br.request.treatmentGroup.counter_request_id;
                                    }
                                }
                            }
                            
                            if($scope.budgetRequests.length > 0){
                                // take some details from last budget request
                                var lastBr = $scope.budgetRequests[$scope.budgetRequests.length-1];
                                br.user_id_supplier = lastBr.user_id_supplier;
                                br.userIdSupplier.supplierFullName = lastBr.userIdSupplier.supplierFullName;
                                br.treatment_cost = lastBr.treatment_cost;
                                if (!br.percent_participtaion_source) {
                                    br.patient_amount = lastBr.patient_amount;
                                    br.amount = lastBr.amount;
                                }
                                
                                br.supplier_hmo_id  = lastBr.supplier_hmo_id;
                                br.lpi_id_hmo       = lastBr.lpi_id_hmo;
                                if (lastBr.is_canceled == 1) {
                                    br.round = lastBr.round;
                                    let idx = 0;
                                    idx = br.round <  $scope.blockListRes[$scope.data.program_id].length ? br.round : $scope.blockListRes[$scope.data.program_id].length-1;
                                    br.quantity = res[$scope.data.program_id][idx].number_of_sessions - lastBr.utilized_treatments_counter;
                                    br.treatments_num_for_alert = res[$scope.data.program_id][idx].treatments_num_for_alert  - lastBr.utilized_treatments_counter;
                                    br.treatments_num_for_blocking = res[$scope.data.program_id][idx].treatments_num_for_blocking  - lastBr.utilized_treatments_counter;
                                    br.from_date = lastBr.from_date;
                                    br.to_date = lastBr.to_date;
                                    br.lpi_id_therapist_assignment_submission_status = lastBr.lpi_id_therapist_assignment_submission_status;
                                    br.lpi_id_contact_assignment_submission_status = lastBr.lpi_id_contact_assignment_submission_status;
                                    br.lpi_id_patient_assignment_submission_status = lastBr.lpi_id_patient_assignment_submission_status;
                                }
                            }
                            if (parseInt(res[$scope.data.program_id][0].maxTreatments)) {
                                br.alertException = parseInt(res[$scope.data.program_id][0].maxTreatments) - parseInt(parseInt(br.request.maximum_treatments_counter) + parseInt(br.quantity));
                            }
                            Popup.open('elhanefesh/addBudgetRequest', $scope.addBudgetRequest, (br) ? br : {}, fn, watch);
                        }
                    });
                }else {
                    if ($scope.supplierPricelist || $scope.supplierHmo){
                        br.pricelist = $scope.supplierHmo ? $scope.supplierHmo.patient_cost : $scope.supplierPricelist.treatment_cost;
                    }

                    if (parseInt(res[$scope.data.program_id][0].maxTreatments)) {
                        br.alertException =  parseInt(res[$scope.data.program_id][0].maxTreatments) - parseInt(parseInt(br.request.maximum_treatments_counter) + parseInt(br.quantity));
                    }

                    if (br.committee_id){
                        br.lastBudgetRequestId = $scope.budgetRequests[$scope.budgetRequests.length-1].id;
                        Popup.open(((br.committee.lpiIdCommitteeStatus.logic_id!=15)? 'elhanefesh/addBudgetRequest' : 'elhanefesh/addBudgetRequestAfterApprove'), $scope.saveBudgetRequest, (br) ? br : {}, fn, watch);
                    }else{
                        Popup.open('elhanefesh/addBudgetRequest', $scope.saveBudgetRequest, (br) ? br : {}, fn, watch);
                    }
                }
            }
        });
    };
    
    $scope.cancelSupplierHmoPrice = function(data){
        if ($scope.supplierPricelist){
            data.treatment_cost = $scope.supplierPricelist.treatment_cost;
            data.patient_amount = $scope.getPercentPaticipation(data)/100*data.treatment_cost;
            if (YT.user.project_id==5) {// round only in elhanefesh not in merchavim
                //data.patient_amount = 5*(Math.round(data.patient_amount/5));
                data.patient_amount = Math.round(data.patient_amount/10)*10;
            }
            data.amount = data.treatment_cost-data.patient_amount;
            data.pricelist = $scope.supplierPricelist.treatment_cost;
            //br.actual_fund_amount = $scope.supplierPricelist.treatment_cost;
        }
        data.lpi_id_hmo = null;
        data.supplier_hmo_id = null;
    }
    
    $scope.getPercentPaticipation = (br) => {
        var budgetRequestsNotCanceled = $filter('filter')($scope.budgetRequests,{is_canceled:null}).length;
        if (budgetRequestsNotCanceled == 0 && $scope.data.userPatient.organization.students_participantation_percent) {
            br.percent_participtaion_source = 'organization';
            return $scope.data.userPatient.organization.students_participantation_percent;
        }
        return $scope.data.userPatient.percentage_participation;
    };

    $scope.setSupplierHmoPrice = function(data){
        if ($scope.supplierHmo){
            data.treatment_cost = $scope.supplierHmo.patient_cost;
            data.patient_amount = $scope.getPercentPaticipation(data)/100*data.treatment_cost;
            if (YT.user.project_id==5) {// round only in elhanefesh not in merchavim
                //data.patient_amount = 5*(Math.round(data.patient_amount/5));
                data.patient_amount = Math.round(data.patient_amount/10)*10;
            }
            data.amount = data.treatment_cost-data.patient_amount;
            data.pricelist = $scope.supplierHmo.patient_cost;
            //br.actual_fund_amount = $scope.supplierPricelist.treatment_cost;
            data.lpi_id_hmo = $scope.supplierHmo.lpi_id_hmo;
            data.supplier_hmo_id = $scope.supplierHmo.id;
        }
    }
    
    $scope.removeBudgetRequest = function($id){
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/remove-budget-request',
            params: {id: $id},
            success: function(res) {
                $scope.getRequestListsDetails();
            }
        });
    };
    
    $scope.saveTreatment = function(data, $modalInstance){
        if(!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)){
            return; // to avoid from multiple saving
        }
        
        if (!data.lpi_id_meeting_type){
            alert('אין אפשרות לשמור מפגש ללא צורת מפגש');
            return;
        }

        $scope.editTreatmentData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/save-treatment', 
            params: {budgetRequestId: data.budget_request_id, treatmentId: data.id},
            data: data,
            success: function(res) { 
                $scope.getRequestListsDetails();
                $modalInstance.close();
            }
        });
    };
    
    $scope.openTreatmentPopup = function(br, t){
        // I set the budget_request_id to the scope for send it to server on the ajax post at saveTreatment function
        var data = t || {};
        if(br && br.id){
            data.budget_request_id = br.id;
            data.amount = br.actual_fund_amount;
            data.treatment_date =  new Date();
            if(!t){
                data.userIdSupplier = {};
                data.userIdSupplier.supplierFullName = br.userIdSupplier.supplierFullName;
            }
        }
        $scope.editTreatmentData = null;
        //data.amount = 55.0;
        
        if (data.review_result)  {
            data.reviewResultArr = data.review_result.split(" | ");
        }
    
        var fn = [];
        fn = {
            removeTreatment: (id) => {
                if (confirm("האם אתה בטוח שברצונך למחוק את מפגש " + id)){
                    Ajax.post({
                        module: 'elhanefesh',
                        url: 'request/remove-treatment',
                        params: {id: id},
                        success: function(res) {
                            $scope.getRequestListsDetails();
                            $rootScope.$broadcast("popup:close", res);
                        }
                    });
                }
            },
            getJSFormatDate: (d) => {
                return new Date(d);
            }
        };
        
        

        Ajax.get({
            module: 'common',
            url: 'settings/default-by-type-list',
            params: {id: '29', programId: $scope.data.program_id},
            notShowMessage: true,
            success: function(res) {
                data.participantsOptions = res;
                var participantsArray = [];
                if (data.participants) {
                    participantsArray = data.participants.split("|");
                }
                angular.forEach(data.participantsOptions, function(item, key) {
                    if( participantsArray.includes(item.name) ) { 
                        item.ischecked = item.name;
                    }else{
                        item.ischecked = 'false';
                    }
                });
            
                if (t){
                    Popup.open((!t.lpi_id_approve_status || t.lpiIdApproveStatus.logic_id == 13 || t.lpi_id_approve_status == 1140 || t.lpi_id_approve_status == 1141) ? 'elhanefesh/addTreatment' : 'elhanefesh/addTreatmentAfterDecision', $scope.saveTreatment, data, fn);
                }else{
                    data.user_id_therapist = br.request.user_id_therapist;
                    data.user_id_supplier = br.user_id_supplier;
                    Popup.open('elhanefesh/addTreatment', $scope.saveTreatment, data, fn);
                }
            }
        });
    };
    
    
    $scope.showAddTreatment = function($index){
        return $scope.budgetRequests[$index].treatments_balance > 0 && $scope.budgetRequests[$index].lpiIdStatusCommiteeDecision.logic_id == 4 && ($scope.budgetRequests[$index].lpiIdBudgetRequestAccountingStatus && $scope.budgetRequests[$index].lpiIdBudgetRequestAccountingStatus.logic_id == 31);
    };
    
    $scope.openMailPopup = function(br){
        if (!br){
            mailMerge.openMailPopup($scope.data, 19); // 19 = Request logic id
        }else{
            mailMerge.openMailPopup(br,20); // 20 = BudgetRequest logic id
        }
    };
    
    $scope.getAge = function(birth_date){
        var birthYear = $filter('date')(birth_date, 'yyyy');
        var currentYear = $filter('date')(new Date(), 'yyyy');

        return currentYear - birthYear;   
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    }
    
    $scope.selectedBrId = null;
    $scope.uploadedObjId = null;
    $scope.addNewFeedbackFileClick = function(brId,uploadedObjId){
        $scope.selectedBrId     = brId;
        $scope.uploadedObjId    = uploadedObjId;
        $('#uploadNewFeedbackFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFeedbackFile")[0].files[0];
        
        if( file ){
            
            var ext = $("#uploadNewFeedbackFile").val().match(/\.(.+)$/)[1];
                        
            var fd      = new FormData();

            var param = {};
            param.upload_source = '(תוכנה)';
            param.is_sensitive = 1;

            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
//            var obj = {};
//            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-budget-request-file?id=' + $scope.selectedBrId + '&uploadedObjLogicId=' + $scope.uploadedObjLogicId,
                fd: fd,
                success: function(res){
                   $scope.getRequestListsDetails();
                }
            });
        }
    };




    // for therapist plan
    $scope.saveRequest = function(data, $modalInstance){
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/save', 
            data: data,
            success: function(res) {
            }
        });
    };

}]);