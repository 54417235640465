'use strict';

app.directive('log', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            logicid: '='
        },
        templateUrl: '/js/app/directives/log/logTpl.html',
        link: function(scope, element, attrs) {       
            //************************START*********************************
            // call to init function only when user select the log tab
            // the tab log's id must be equal to 'log-tab'
            $('a[aria-controls="log-tab"]').on('shown.bs.tab', function (e) {
                if( !scope.object){ // if data not upload already
                    scope.init(); 
                }
            });
            //**************************END**********************************
            
            $('#show-log-history').on('click', function (e) {
                if( !scope.object){ // if data not upload already
                    scope.init(); 
                }
            });
            
            scope.init = function(){
                Ajax.get({
                    module: 'common',
                    url: 'log/get-record',
                    params: {recordId: scope.recordid, logicId: scope.logicid},
                    success: function(res){
                        scope.object        = res.object;
                        scope.record        = res.record;
                        scope.creatorUser   = res.creatorUser;
                        scope.updateUser    = res.updateUser;
                        scope.logDetails    = res.logDetails;
                    }
                });
            };              
            
            scope.getJSFormatDate = function(d) {
                return Utils.getJSFormatDate(d);
            }
//            
//            scope.contains = function(orgStr, searchTxt){
//                return orgStr.includes(searchTxt);
//            }
        }
    };
}]);