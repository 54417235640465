'use strict';

app.controller('DafhCommitteeController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    
    $scope.getCommitteeDetails = function() {
        Ajax.get({
            module: 'dafchadash',
            url: 'committee/get-committee-details',
            params: {id: Utils.GET().id},
            success: function(res){
               
                $scope.donutChartDecision           = res.donutChartDecision;
                $scope.chartBudgetByRound           = res.chartBudgetByRound;
                $scope.committee                    = res.committee;
                $scope.editCommittee                = angular.copy($scope.committee);
                $scope.users                        = res.users;
                $scope.counterParams                = res.counterParams;                
                $scope.con                          = res.con;                
            }
        });
    };
    $scope.getCommitteeDetails();
    
    $scope.addUser = function(id){
        var user = $filter('filter')($scope.users, {id: id})[0];
        
        if ( !$filter('filter')($scope.committee.participants, {id: user.id})[0] ){ // chack if exsits
            $scope.committee.participants.push(user);
        }
        
        $scope.usersParticipants = "";
    };
    
    $scope.removeParticipants = function(user_id, index){
        $scope.committee.participants.splice(index, 1);
    };
    
    $scope.saveCommittee = function(){
        Ajax.post({
            module: 'dafchadash',
            url:    'committee/update-committee',
            params: {id: Utils.GET().id},
            data:   $scope.committee,
            //notShowMessage: true,
            success: function(res){
                if(res.data){
                    if( confirm("קיימות בקשות שלא התקבלו בהם החלטות האם בכל זאת תרצה לסגור את הועדה ?") ){
                        Ajax.post({
                            url:    'committee/update-committee',
                            params: {id: Utils.GET().id},
                            data:   angular.extend($scope.committee, {closeCommittee : true}),
                            success: function(res){
                                Utils.refresh();
                            }
                        });
                    }else{
                        $scope.getCommitteeDetails();    
                    }
                }else {
                    Ajax.post({
                        module: 'common',
                        url: 'picklist/get-item-id-by-logic-id', 
                        params: {logicId: 15}, // 15 = ועדה חתומה
                        notShowMessage: true,
                        success: function(res) {
                            if($scope.committee.lpi_id_committee_status == res.data){
                                Utils.refresh();
                            }
                        }
                    });
                }
            }
        });
    };
}]);