'use strict';

app.controller('PortalLeadController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){
        
    $scope.lead = {};
    
    Ajax.get({
        url: 'lead/lead-registration-details',
        success: function(res){
            $scope.regStudyYear = res.regStudyYear;
            $scope.picklists = res.picklists;
        }
    });

    $scope.validEmail = function(){
        if ($scope.lead.email != $scope.lead.emailConfirm){
            return false;
        }
        return true;
    };

    $scope.addLead = function(){
        
        if(!$scope.validEmail()){
            alert('אין התאמה בין כתובות המייל שסיפקת!');
            return;
        }          
        
        $scope.lead.lpi_id_status   = 67;
        $scope.lead.project_id      = 2;
        $scope.lead.program_id      = Utils.GET().program_id;
        
        Ajax.post({
            module: 'academy',
            url: 'lead/portal-create-lead',
            data: $scope.lead,
            success: function(res){
                Utils.redirect('/academyportal/lead/thank-you-page');
            }
        });
    };
    
    $scope.addMakamLead = function(){
        
        if(!$scope.validEmail()){
            alert('אין התאמה בין כתובות המייל שסיפקת!');
            return;
        }          
        
        $scope.lead.lpi_id_status   = 67;
        //$scope.lead.project_id      = 1;
        $scope.lead.program_id      = 77;
        
        Ajax.post({
            module: 'academy',
            url: 'lead/portal-create-cr21-lead',
            data: $scope.lead,
            success: function(res){
                Utils.redirect('/academyportal/lead/thank-you-page-cr21');
            }
        });
    };
}]);