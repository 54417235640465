'use strict';

app.directive('cru', ['$timeout','Ajax', 'Utils', 'PageContentOnresize', function ($timeout, Ajax, Utils, PageContentOnresize) {
    return{
        restrict: 'AEC',
        replace: true,
        scope: false,
        transclude: false,
        templateUrl: function(elem, attrs) {
            return '/js/app/partials/' + attrs.templateurl + '.html';
        },
        link: function(scope, element, attrs) { 
            scope.mod = attrs.mod;
            
            scope.$on('cru:getSuccess', function(e, mod, res){
                $("input[type=text]").keydown(function( event ) {
                    scope.preventCurlyBraces(event);
                });
                $("textarea").keydown(function( event ) {
                    scope.preventCurlyBraces(event);
                });
                Ajax.turnOnValidation();
            });

            scope.preventCurlyBraces = function(event){
                if ( event.shiftKey && (event.key === '{' || event.key === '}') ) {
                    event.preventDefault();
                }
            };
            
			scope.getParams = function(){
				var obj = {};
				if(attrs.identitykey){
					obj[attrs.identitykey] = Utils.GET()[attrs.identitykey];
				}else{
					obj = {id: Utils.GET().id};
				}
				
				return obj;
			};
			
            scope.getData = function(){
                if( attrs.mod === 'edit' ){
                    var param = {
                        url: attrs.geturl, 
						params: scope.getParams(),
                        success: function(res) { 
                            scope.data         = res.data;
                            scope.editData     = angular.copy(scope.data);

                            if( !angular.equals(scope.picklists, res.picklists) ){
                                scope.picklists = res.picklists;
                            }

                            scope.$emit('cru:getSuccess', scope.mod, res);
                        }
                    };
					
                    if(attrs.module){
                        param.module = attrs.module;
                    }
                    Ajax.get(param);
                }else if (attrs.mod === 'add'){
                    var param = {
                        url: attrs.geturl, 
						
                        success: function(res) { 
                            if( !angular.equals(scope.picklists, res.picklists) ){
                                scope.picklists = res.picklists;
                            }
                            scope.$emit('cru:getSuccess', scope.mod, res);
                        }
                    };
                    
                    if( Utils.GET() ){
                        param.params = scope.getParams();
                    }
                    
                    if(attrs.module){
                        param.module = attrs.module;
                    }
                    Ajax.get(param);
                }                
            };
            
            if(attrs.run){
                scope.getData();
            }
         
         
            scope.$on("cru:refresh", function(e, msg) {
                scope.getData();
            });
            
            scope.$on("docs:changeProfilePic", function(e, msg) {
                scope.getData();
            });
            
            scope.save = function(){
                // if($("#test") && $("#test").valid()){   // test for validity
                //     // do stuff if form is valid
                // } else {
                //     alert ('opppssss, something wrong')
                //     return;
                // }
                if( attrs.mod === 'edit' && !angular.equals(scope.editData, scope.data) ){   
                    var param = {
                        url: attrs.posturl,
                        params: scope.getParams(),
                        data: scope.data,
                        success: function(res){             
                            scope.data     = res.data;
                            scope.editData = angular.copy(scope.data);
                            
                            scope.$emit('cru:postSuccess', scope.mod, res);
                        }
                    };
                    
                    if( attrs.module ){
                        param.module = attrs.module;
                    }
                    Ajax.post(param);
                }else if(attrs.mod === 'add'){
                    var param = {
                        url: attrs.posturl,
                        params: scope.getParams(),
                        data: scope.data,
                        success: function(res){                             
                            scope.$emit('cru:postSuccess', scope.mod, res);
                        }
                    };   
                    
                    if( attrs.module ){
                        param.module = attrs.module;
                    }
                    Ajax.post(param);
                }
            };
            
            scope.$evalAsync(function() {
                PageContentOnresize.run();
                $timeout(function () {
                    scope.$emit('cru:finished');
                });
            });
        }
    };
}]);