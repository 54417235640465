'use strict';

app.controller('YtcrmSupplierController', ['$scope', '$filter', 'Ajax', 'mailMerge', 'Utils', 'Popup', function($scope, $filter, Ajax, mailMerge, Utils, Popup) {
    $scope.data = {};
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/ytcrm/supplier/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            $scope.profileSrc   = res.data.profileSrc;
        }
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getTreatmentsLength = function(arr){
        return arr.length;
    };
        
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data, 129); // 129 = supplier logic id
    };
	
	$scope.createUserLogin = function(){
		if( !$scope.data.userSupplier || !$scope.data.userSupplier.email){
			alert('חובה להזין כתובת אימייל לפני יצירת פרטי לוגין');
			return;
		}
		
        Ajax.post({
			module: 'ytcrm',
            url: 'supplier/create-user-object-login', 
            params: {user_id: Utils.GET().id, email: $scope.data.userSupplier.email},
            success: function(res) { 
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.resetPassword = function(){
        if(!confirm('האם אתה בטוח שברצונך לאפס את הסיסמה?')){
            return;
        }
        
        Ajax.post({
            url: 'supplier/reset-password',
            data: $scope.data,
            success: function(res){
                // do something
            },
            error: function(res){
                //alert('res');
            }
        });
    };
    
}]);