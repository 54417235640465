'use strict';

app.directive('timeLineEditTaskBlock', ['$timeout', 'Ajax', 'Utils', '$rootScope', function ($timeout, Ajax, Utils, $rootScope) {
    return {
        restrict: 'AEC',
        scope: {
            acId: '@'
        },
        templateUrl: '/js/app/directives/timeLineEditTaskBlock/timeLineEditTaskBlockTpl.html',
        link: function(scope, element, attrs) {
            scope.ac = {};
                
            scope.getJSFormatDate = function(d) {
                return new Date(d.replace(/-/g, '/'));
            };
            
            scope.initEditActivity = function(){
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-activity-connection',
                    //GetActivityConnection
                    params: {id: scope.acId},
                    success: function(res){
                        scope.ac = res.activityConnection;
                    }
                });
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-activity-picklists',
                    success: function(res){
                        scope.activityPicklists = res.picklists;
                        scope.objects   = res.objects;
                    }
                });
            };
            
            scope.initEditActivity();
                       
            scope.currentConnection     = {};
            scope.activityComment       = {};
            scope.currentDate           = new Date();
            scope.newRecordId           = null;
            scope.newObjectLogicId      = null;
//            scope.activitiesGroups      = {};
//            
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });

            scope.saveActivity = function(){
                var params;
                if (scope.ac.activity.id){
                    params =  {logicId: 54, id: scope.ac.activity.id};
                }else{
                    params = {logicId: 54, id: null, recordID: Utils.GET().id};
                }
                                
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity',
                    params: params,
                    data:   scope.ac.activity,
                    success: function(res){
                        $rootScope.$broadcast('activity:editsuccess'+res.activity.id, res.activity);
                    }
                });
            };
           
            
        }
    };
}]);