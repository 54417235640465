'use strict';

app.directive('formDependentFields', ['$timeout', '$filter', 'Ajax', 'Utils', '$sce', function ($timeout, $filter, Ajax, Utils, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            f: '=f',
        },
        templateUrl: '/js/app/directives/formDependentFields/formDependentFields.html',
        link: function(scope, element, attrs) {
            $timeout(function(){ 
                var allFields = scope.$parent.$parent.$parent.fields ? scope.$parent.$parent.$parent.fields : scope.$parent.$parent.fields ;   
                scope.dependentsFieldList = allFields.filter( function(i) {
                    return (i.field_type == 'radio' || i.field_type == 'select' || i.field_type == 'multiselect') && i.id != scope.f.id;
                });
                if (scope.f.dependent_fields){
                    scope.f.dependent_fields.forEach(function (item) {
                        //item.dependentAnswerOptions = item.fieldId ? scope.getFieldById(item.fieldId)[0].answer_options : {};
                    });
                    for (var i = 0; i < scope.f.dependent_fields.length; i++) {
                        scope.$watch('f.dependent_fields[' + i + '].fieldId', function(newValue, oldValue) {
                            if (newValue && newValue!=oldValue){
                                let currentIndex = this.exp.substring(
                                    this.exp.lastIndexOf("[") + 1, 
                                    this.exp.lastIndexOf("]")
                                );
                                if (newValue && newValue !== '-בחר שדה-'){
                                    let field = scope.getFieldById(newValue)[0];
                                    scope.f.dependent_fields[currentIndex].dependentAnswerOptions = field.answer_options;
                                }else if (newValue === '-בחר שדה-'){
                                    scope.f.dependent_fields[currentIndex].dependentAnswerOptions = {};
                                }
                            }
                        });
                    }
                }
            },1000);
            
            scope.getFieldById = (fieldId) => {
                return scope.$parent.getFieldById(fieldId);
            };

            
            scope.removeDependentField = function(f,$index){
                if (f){
                    f.dependent_fields.splice($index,1);
                }
            };

            scope.addDependentField = function(f){
                if (!f.dependent_fields) {
                    f.dependent_fields = [];
                }
                f.dependent_fields.push({fieldId: null, operator: null, answerOption: null});
                scope.$watch('f.dependent_fields[' + parseInt(f.dependent_fields.length-1) + '].fieldId', function(newValue, oldValue) {
                    let currentIndex = this.exp.substring(
                        this.exp.lastIndexOf("[") + 1, 
                        this.exp.lastIndexOf("]")
                    );
                    if (newValue && newValue !== '-בחר שדה-'){
                        let field = scope.getFieldById(newValue)[0];
                        f.dependent_fields[currentIndex].dependentAnswerOptions = field.answer_options;
                    }else if (newValue === '-בחר שדה-'){
                        f.dependent_fields[currentIndex].dependentAnswerOptions = {};
                    }
                });
                
            }
        }
    };
}]);