'use strict';

app.directive('docs', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            logicid: '@'
        },
        templateUrl: '/js/app/directives/docs/docsTpl.html',
        link: function(scope, element, attrs) {       
            scope.list              = true;
            scope.currentIndex      = null;
            scope.newRecordId       = null;
            scope.newObjectLogicId  = null;
            scope.docsGroups        = {};
            scope.fileData          = {access_action: 'private', is_required: 'no'};
            scope.globals           = YT;

            scope.$on("docs:refresh", function(e, msg) {
                scope.init();
            });
            
            scope.$watch('recordid', function(newData) {
                if(newData){
                    scope.init();
                }
            });
            
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.init = function(){
                // if object id not include with directive, set it do default 1 = student(not in use, I send logicid everywhere)
                scope.logicid = scope.logicid || 1;
                scope.newRecordId       = null;
                scope.newObjectLogicId  = null;
                scope.projectId        = YT.user.project_id;
                Ajax.get({
                    module: 'common',
                    url: 'file/get-record-docs',
                    params: {recordId: scope.recordid, logicId: scope.logicid},
                    success: function(res){
                        scope.docs      = res.docs;
                        scope.objects   = res.objects;
                        if (scope.currentIndex != null) {
                            scope.fileData = angular.copy(scope.docs[scope.currentIndex]);
                        }
                    }
                });
                Ajax.get({
                    module: 'common',
                    url: 'picklist/get-lookup',
                    params: {model: 'FileGroup', field: 'name_he'},
                    success: function(res){
                        scope.docsGroups = res;
                    }
                });
            };
            
            scope.dropzoneClick = function(){
                $("#add-doc-file").trigger('click');
            };
            
            
            
            scope.showUploadTab = function(index){
                scope.currentIndex = index;
                scope.fileData     = angular.copy(scope.docs[index]);
                scope.list         = false;
                
                $("#add-doc-file").change(function (){
                    $('.dropzone.dropzone-mini .dz-message').css({color: 'green'});
                });
            };

            scope.showList = function(){
                scope.currentIndex  = null;
                scope.fileData      = {access_action: 'private', is_required: 'no'};
                scope.list          = true;
                
                scope.newRecordId           = null;
                scope.newObjectLogicId      = null;
                scope.showaddfileconnection = false;
                                
                $('.dropzone.dropzone-mini .dz-message').css({color: '#656d78'});
                $("#add-doc-file").replaceWith($("#add-doc-file").clone()); 
            };

            scope.hideList = function(){
                $("#add-doc-file").off('change', "**");
                $("#add-doc-file").change(function (){
                    $('.dropzone.dropzone-mini .dz-message').css({color: 'green'});
                });
                scope.list = false;
            };
            
            scope.save = function(){
                if ( scope.fileData.file && scope.fileData.file.external_link ){
                    scope.saveExternalLink();
                    return;
                }

                var file    = $("#add-doc-file")[0].files[0];

                if( file ){ // user upload file
                    var isProfilePic    = false;
                    var fd              = new FormData();
                    
                    fd.append('file', file);
                    
                    if( scope.currentIndex !== null ){
                        scope.fileData.file_connection_id = scope.docs[scope.currentIndex].id;
                    }
                    if(scope.currentIndex !== null && scope.docs[scope.currentIndex].friendly_name == 'תמונת פספורט'){
                        scope.fileData.isProfilePic = true;
                        isProfilePic = true;
                        
                        var ext = $("#add-doc-file").val().match(/\.(.+)$/)[1];

                        if ( !ext.match(/(?:gif|GIF|jpg|JPG|jpeg|JPEG|png|PNG|bmp|BMP)$/) ) {
                            alert('פספורט חייב להיות מסוג תמונה');
                            return;
                        }
                    }
                    
                    if( !scope.fileData.friendly_name || scope.fileData.friendly_name == ''){
                        scope.fileData.friendly_name = file.name.replace(/\.[^.$]+$/, '');
                    }
                    if (scope.fileData.file && scope.fileData.file.is_sensitive) {
                        scope.fileData.is_sensitive = scope.fileData.file.is_sensitive;
                    }
                    fd.append('fileData',  JSON.stringify(scope.fileData));

                    
                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload?id=' + scope.recordid + '&logicId=' + scope.logicid,
                        fd: fd,
                        success: function(res){
                            $("#add-doc-file").val(''); // clear the file input
                            
                            scope.docs = res.docs;
                            scope.showList();
                            
                            if(isProfilePic){
                                scope.$parent.$broadcast("docs:changeProfilePic");
                            }
                        }
                    });
                }else if( scope.fileData.id ){ // user update file connection
                    if( angular.equals(scope.fileData, scope.docs[scope.currentIndex]) ){
                        return;
                    }
                    
                    if(!scope.fileData.friendly_name){
                        alert('אנא בחר שם קובץ');
                        return;
                    }
                    
                    Ajax.post({
                        module: 'common',
                        url:    'file/update-file-connection',
                        params: {id: scope.fileData.id, logicId: scope.logicid},
                        data:   scope.fileData,
                        success: function(res){
                            scope.docs = res.docs;
                            scope.showList();
                        }
                    });
                }else if(scope.fileData.friendly_name){ // user add new file connection without include a file
                    Ajax.post({
                        module: 'common',
                        url:    'file/add-file-connection',
                        params: {recordId: scope.recordid, logicId: scope.logicid},
                        data:   scope.fileData,
                        success: function(res){
                            scope.docs = res.docs;
                            scope.showList();
                        }
                    });
                }
            };
    
            scope.deleteFile = function(doc){
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-file',
                    params: {file_connection_id: doc.id},
                    success: function(res){
                        scope.docs = res.docs;
                        
                        if(doc.friendly_name === 'תמונת פספורט'){
                            scope.$parent.$broadcast("docs:changeProfilePic");
                        }
                        
                        scope.showList();                        
                    },
                    successMessage: 'הקובץ נמחק בהצלחה'
                });
            };
                        
            scope.deleteRecord = function(index){
                if(scope.docs[index].friendly_name === 'תמונת פספורט'){
                    return;
                }
                
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-record',
                    params: {fileConnectionId: scope.docs[index].id},
                    success: function(res){
                        scope.docs = res.docs;
                        scope.showList();                        
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };
            
            scope.getJSFormatDate = function(d){
                return Utils.getJSFormatDate(d);
            };
            
            scope.createDocsByFileGroupId = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'file/create-file-connections-file-group-id',
                    params: {fileGroupId: scope.docsGroups[$index].id, recordId: scope.recordid, logicId: scope.logicid},
                    success: function(res){
                        scope.docs = res.docs;
                        scope.showList();                        
                    },
                    successMessage: 'קבוצת המסמכים נוספה בהצלחה'
                });
            };
                        
            scope.addNewFileConnection = function(newRecordId, newObjectLogicId) {
                if (!newRecordId || !newObjectLogicId) {
                    alert('על מנת להוסיף קישור לקובץ, עליך לבחור אובייקט ויעד לקישור')
                    return;
                }
                
                if (newRecordId != scope.recordid || newObjectLogicId != scope.logicid) {
                    Ajax.post({
                        module: 'common',
                        url:    'file/add-file-connection',
                        params: {recordId: newRecordId, logicId: newObjectLogicId},
                        data:   scope.fileData,
                        success: function(res){
                            scope.init();
                            //scope.showList();
                        }
                    });
                }else {
                    alert('קישור המסמך קיים כבר');
                }
            };
            
            scope.deleteFileConnection = function(fileConnection){
//                if(fileConnection.friendly_name === 'תמונת פספורט'){
//                    return;
//                }
//                
                Ajax.post({
                    module: 'common',
                    url: 'file/delete-file-connection',
                    params: {fileConnectionId: fileConnection.id},
                    success: function(res){
                        scope.init();
                        //scope.showList();                        
                    },
                    successMessage: 'הרשומה נמחקה בהצלחה'
                });
            };

            scope.goToPreviewUrl = function(d) {
                Ajax.post({
                    module: 'common',
                    url: 'file/get-preview-url',
                    data: d,
                    success: function(res){
                        window.open('https://drive.google.com/gview?url='+res.previewUrl,'_blank');
                    }
                });
            };

            scope.saveExternalLink = function(){
                if( scope.fileData.file.external_link){ // user upload file
                    
                    if(!scope.fileData.friendly_name){
                        alert('אנא בחר שם קובץ');
                        return;
                    }

                    if( scope.currentIndex !== null ){
                        scope.fileData.file_connection_id = scope.docs[scope.currentIndex].id;
                    }
                    
                    if( !scope.fileData.friendly_name || scope.fileData.friendly_name == ''){
                        scope.fileData.friendly_name = file.name.replace(/\.[^.$]+$/, '');
                    }
                    if (scope.fileData.file && scope.fileData.file.is_sensitive) {
                        scope.fileData.is_sensitive = scope.fileData.file.is_sensitive;
                    }

                    Ajax.post({
                        module: 'common',
                        url: 'file/save-external-link?id=' + scope.recordid + '&logicId=' + scope.logicid,
                        data: scope.fileData,
                        success: function(res){
                            scope.docs = res.docs;
                            scope.showList();
                        }
                    });
                }
            };
        }
    };
}]);