'use strict';

app.directive('formViewHtml', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
        },
        templateUrl: '/js/app/directives/formViewHtml/formViewHtml.html',
        link: function(scope, element, attrs) {
            $timeout(function(){
                scope.data = angular.fromJson(scope.data);
                scope.globals = $rootScope.globals;
            },1000);
            
            
            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };
            
            scope.cancel = function() {
                $rootScope.$broadcast('popup:close',null);
            };
            
            scope.getJSFormatDate = function(d) {
                return new Date(d.replace(/-/g, '/'));
            };      
        }
    };
}]);