'use strict';

app.controller('PortalStudyHistoryCtrl', ['$scope', '$filter', 'Ajax', 'Utils', '$timeout', function($scope, $filter, Ajax, Utils, $timeout){
    $scope.filterAcademy = function(item){
        return item.parent_id === 18 || item.parent_id === 19; 
    };
    
    $scope.new_study_torani = {};
    $scope.new_study_academic = {};
    
    $scope.getStudys = function(refeshAcademicGeneralTypes){
        Ajax.get({
            module: 'common',
            url:    'student/academic-general',
            params: {id: $scope.globals.user.user_id},
            success: function(res){
                $scope.studies  = res.places;
                $scope.user     = res.user;
                
                if(refeshAcademicGeneralTypes){
                    $scope.academicGeneralTypes = res.academicGeneralTypes;
                }
            }
        });
    };
    
    $scope.getStudys(true);
    $scope.save = function(redirectUrl){
        if( !angular.equals($scope.user, $scope.editUser) ){
            Ajax.post({
                module: 'common',
                url: 'student/edit-student',
                params: {id: $scope.globals.user.user_id}, 
                data: $scope.user,
                success: function(res){
                    $scope.user     = res.data;
                    $scope.editUser = angular.copy($scope.user);
                }
            });
        }
    };
    
    $scope.addStudyTorani = function(){
        Ajax.post({
            module: 'common',
            url: 'student/add-academic-general',
            params: {id: $scope.globals.user.user_id},
            data: $scope.new_study_torani,
            success: function(res){
                $scope.getStudys();
                
                $scope.new_study_torani = {};
//                $timeout(function(){
//                    $('#torani-from-date').data("DateTimePicker").clear();
//                    $('#torani-to-date').data("DateTimePicker").clear();
//                }, 0);
            }
        });
    };
    
    $scope.addStudyAcademic = function(){
        Ajax.post({
            module: 'common',
            url: 'student/add-academic-general',
            params: {id: $scope.globals.user.user_id},
            data: $scope.new_study_academic,
            success: function(res){        
                $scope.getStudys();
                $scope.new_study_academic = {};
//                $timeout(function(){
//                    $('#academic-from-date').data("DateTimePicker").clear();
//                    $('#academic-to-date').data("DateTimePicker").clear();
//                }, 0);
            }
        });
    };
    
    $scope.removeStudy = function(id, arrayIndex){
        Ajax.post({
            module: 'common',
            url:    'student/remove-study',
            params: {study_id: id},
            success: function(data){
                $scope.getStudys();
            }
        });
    };
    
}]);