'use strict';

app.controller('EhPatientController', ['$scope', 'Ajax', 'Utils', 'Popup', '$sce', function($scope, Ajax, Utils, Popup, $sce) {
    $scope.tagsArr = [];
    $scope.organizationList = [];
    $scope.contactsList = [];
    $scope.data = {};
    $scope.data.userPatient = {};
        
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (!$scope.data.id){
            $scope.data.userPatient.process_start_date = new Date()
        }
        $scope.getOrganizationList();        
        if (mod == 'edit'){
            $scope.getPatientRequests();
            $scope.getContactsList($scope.data.userPatient.organization_id);
        }
        $scope.tagsToArr();
    });
    
    $scope.$on('ehpatient:getSuccess', function(e, mod, res){
        $scope.getPatientRequests();
        $scope.data.id = Utils.GET().id;
        //$scope.getOrganizationList();
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getOrganizationList = function(){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-organization', 
            //params: {model: 'Organization'},
            success: function(res) { 
                $scope.organizationList = res;
            }
        });
    };
    
    $scope.getContactsList = function(orgId){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-contact-by-org-id', 
            params: {orgId: orgId | null},
            success: function(res) { 
                $scope.contactsList = res;
            }
        });
    };
    
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(window.location.href.includes('view')){
            $scope.tagsToArr();
            return;
        }
        Utils.redirect('/elhanefesh/patient/view?id=' + res.data.id);
    });
    
    $scope.getPatientRequests = function(){
        Ajax.get({
            module: 'elhanefesh',
            url: 'patient/get-patient-requests', 
            params: {id: Utils.GET().id},
            success: function(res) {
                $scope.patientRequests  = res.requests;
            }
        });
    };

    $scope.savePatient = () => {
        let reason;
        if ($scope.data.userPatient.percentage_participation && $scope.data.userPatient.original_percentage_participation){
            if (parseFloat($scope.data.userPatient.percentage_participation) != parseFloat($scope.data.userPatient.original_percentage_participation)){
                $scope.data.userPatient.change_reason_percentage_participation = prompt("השתתפות המוטב שונתה, מה הסיבה?");
                if (!$scope.data.userPatient.change_reason_percentage_participation || $scope.data.userPatient.change_reason_percentage_participation == '') {
                    alert('אין אפשרות להמשיך בשמירה ללא הכנסת סיבה לשינוי השתתפות המוטב');
                    return;
                }
            }
        }
        $scope.save();
    }
    
    $scope.quickRequestCreate = (progId) => {
        Ajax.post({
            module: 'elhanefesh',
            url: 'patient/quick-request-create', 
            params: {userId: Utils.GET().id, progId: progId},
            success: function(res) {
                $scope.getPatientRequests();
            }
        });
    }

    $scope.getForms = function(){
        Ajax.get({
            module: 'common',
            url: 'form/get-open-forms',
            success: function(res) {
                $scope.forms = res.forms;
            }
        });
        Ajax.get({
            module: 'common',
            url: 'form/get-user-submissions',
            params: {userId: Utils.GET().id},
            success: function(res) {
                $scope.formSubmissions  = res.formSubmissions;
            }
        });
    };
       
    
    $scope.trustAsHtml = function(bindHtml){
        if (bindHtml){
            return $sce.trustAsHtml(bindHtml);
        }
    };
    
    $scope.getJSFormatDate = function(d) {
        return new Date(d);
    };
    
}]);