'use strict';

app.controller('EhTreatmentGroupLineController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tIsSelected              = [];
    $scope.members                  = [];
    $scope.treatments               = [];
    $scope.treatmentGroupLine       = {};
    $scope.treatmentGroupLine.treatment_group_id = $scope.treatmentGroupLine.treatment_group_id | Utils.GET().treatmentGroupId;
    
        
    $scope.init = function(mod){
        $scope.mod = mod;
        if (Utils.GET().id){
            $scope.getTreatmentGroupLineDetails();
        }else{
            $scope.getTreatementGroupDetails();
            $scope.getTreatmentMembers();
        }
    };
    
    $scope.getTreatementGroupDetails = function(){
        if (Utils.GET().treatmentGroupId){
            Ajax.get({
                url: 'treatment-group/get-treatment-group-details',
                params: {id: Utils.GET().treatmentGroupId},
                success: function(res){
                    $scope.treatmentGroupLine.treatmentGroup = res.treatmentGroup;
                },
            });
        }
    }
    
    $scope.$watch('treatmentGroupLine.treatment_date', function(newValue, oldValue) {
        if(newValue && newValue != oldValue && !$scope.treatmentGroupLine.id){
            $scope.getTreatmentMembers();
        }
    });
    
    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.save = function(){
        Ajax.post({
            url: 'treatment-group-line/save',
            data: {
                treatmentGroupLine: $scope.treatmentGroupLine,
                members: $scope.members,
                treatmentGroupId: $scope.treatmentGroup.id
            },
            success: function(res){
                $scope.treatmentGroup = res.data;
                if ( $scope.mod == 'add' ){
                   Utils.redirect('/elhanefesh/treatment-group-line/update?id=' + $scope.treatmentGroup.id);
                }
            },
        });
    };
    
    $scope.getTreatmentMembers = function(){
        if ($scope.treatmentGroupLine.treatment_date){
            Ajax.get({
                url: 'treatment-group-line/get-treatment-members',
                params: {
                    treatmentGroupId: ( ($scope.treatmentGroupLine.treatment_group_id) ? $scope.treatmentGroupLine.treatment_group_id : Utils.GET().treatmentGroupId ),
                    treatmentDate: $scope.treatmentGroupLine.treatment_date
                },
                success: function(res){
                    $scope.members = res.members;
                    for(let i=0;i<$scope.members.length;i++){
                        if (!$scope.members[i].request.lpi_id_absence_reason){
                            $scope.tIsSelected[i] = true;
                        }
                    }
                },
            });
        }
    };
    
    $scope.addRequestToGroup = function($index){
//        if(!$scope.treatmentGroup.counter_request_id){
//            $scope.treatmentGroup.counter_request_id=0;
//        }
        if ($scope.tIsSelected[$index] == true){
            $scope.members[$index].lpi_id_treatment_absence_reason = null;
        }
    };
    
    
    $scope.getTreatmentGroupLineDetails = function(){
        Ajax.get({
            url: 'treatment-group-line/get-treatment-group-line-details',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.members = res.members;
                for(let i=0;i<$scope.members.length;i++){
                    if (!$scope.members[i].lpi_id_treatment_absence_reason){
                        $scope.tIsSelected[i] = true;
                        $scope.members[i].lpi_id_treatment_absence_reason = null;
                    }
                }
                $scope.treatmentGroupLine = res.treatmentGroupLine;
            },
        });
    };
    
    
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param['upload_source'] = '(תוכנה)';
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));

            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-treatment-group-file?id=' + $scope.invoice.id,
                fd: fd,
                success: function(res){
                   $scope.init('edit');
                }
            });
        }
    };
    
    $scope.saveTreatment = function(data, $modalInstance){
        if(!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)){
            return; // to avoid from multiple saving
        }
        
        $scope.editTreatmentData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/save-treatment', 
            params: {budgetRequestId: data.budget_request_id, treatmentId: data.id},
            data: data,
            success: function(res) {
                $scope.getTreatmentGroupLineDetails();
                $modalInstance.close();
            }
        });
    };
    
    $scope.removeTreatment = function($id){
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/remove-treatment',
            params: {id: $id, requestId: data.request_id},
            success: function(res) {
                $scope.getTreatmentMembers();
            }
        });
    };
    
    $scope.openTreatmentPopup = function(br, t){
        // I set the budget_request_id to the scope for send it to server on the ajax post at saveTreatment function
        var data = t || {};
        if(br && br.id){
            data.request_id         = br.request_id;
            data.budget_request_id  = br.id;
            data.amount             = br.actual_fund_amount;
            data.treatment_date     = new Date();
            if(!t){
                data.userIdSupplier = {};
                data.userIdSupplier.supplierFullName = br.userIdSupplier.supplierFullName;
            }
        }
        $scope.editTreatmentData = null;
        //data.amount = 55.0;
        
        if (data.review_result)  {
            data.reviewResultArr = data.review_result.split(" | ");
        }
    
        var fn = [];
        fn = {
            removeTreatment: (id) => {
                if (confirm("האם אתה בטוח שברצונך למחוק את מפגש " + id)){
                    Ajax.post({
                        module: 'elhanefesh',
                        url: 'request/remove-treatment',
                        params: {id: id},
                        success: function(res) {
                            $scope.getRequestListsDetails();
                            $rootScope.$broadcast("popup:close", res);
                        }
                    });
                }
            },
            getJSFormatDate: (d) => {
                return new Date(d);
            }
        };
        
        if (t){
            Popup.open((t.lpiIdApproveStatus.logic_id == 13) ? 'elhanefesh/addTreatment' : 'elhanefesh/addTreatmentAfterDecision', $scope.saveTreatment, data, fn);
        }else{
            data.user_id_therapist = br.request.user_id_therapist;
            data.user_id_supplier = br.user_id_supplier;
            Popup.open('elhanefesh/addTreatment', $scope.saveTreatment, data, fn);
        }
    };
}]);