'use strict';

app.controller('AcademicRequestController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'mailMerge', 'Utils', function($scope, $http, $filter, $modal, Ajax, mailMerge, Utils) { 
        
    $scope.tabSelected = 1;
    $scope.mod;
    $scope.budgetRequests = null;
    $scope.budgetFinishLoading = false;

    $scope.init = function(mod){
        $scope.mod = mod;
        
        if ($scope.mod === 'edit'){
            Ajax.get({
                url: 'request/request-details', 
                params: {id: Utils.GET().id},
                success: function(data){
                    $scope.request      = data.request;
                    $scope.picklists    = data.picklists;
                    $scope.editRequest  = angular.copy($scope.request);

                    $scope.profileSrc   = $scope.request.user.profileSrc;

                    $( "#request-details-wrapper" ).find(".tab-content").addClass( "panel-body" );
                }
            });
        }else{
            Ajax.get({
                url: 'request/request-picklists', 
                params: {user_id: Utils.GET().user_id},
                success: function(data){
                    $scope.picklists = data.picklists;
                }
            });
        }
    };


    $scope.$on("docs:changeProfilePic", function(e, msg) {
        $scope.init('edit');
    });


    $scope.openChooseStatusPortalPopup = function(){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/chooseStatusCommiteeDecisionTpl.html',
            controller: function($scope, $modalInstance, request){
                $scope.data = {
                    portalStatus: request.user.userStudent.lpi_id_portal_status
                };
                
                $scope.ok = function () {
                    Ajax.post({
                        url: 'student/set-portal-status',
                        params: {id: request.user.id},
                        data: {portalStatus: $scope.data.portalStatus},
                        success: function(res){
                            $modalInstance.close();
                        }
                    });
                };

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                };
            },
            resolve: {
                request: function () {
                    return $scope.request;
                }
            }
        });

        modalInstance.result.then(function () {}, function () {});
    };

    $scope.save = function(){
        if( $scope.mod === 'edit' && !angular.equals($scope.editRequest, $scope.request) ){   
            
            var oldCommiteeDecision = $scope.editRequest.lpi_id_status_commitee_decision;
            var newCommiteeDecision = $scope.request.lpi_id_status_commitee_decision;
            var runChooseStatusPortalPopup = false;
            if(newCommiteeDecision == 107 || newCommiteeDecision == 108 && (oldCommiteeDecision !== 107 && oldCommiteeDecision !== 108) ){    
                runChooseStatusPortalPopup = true;
            }
            
            Ajax.post({
                url: 'request/edit-request',
                params: {id: Utils.GET().id},
                data: $scope.request,
                success: function(res){
                    $scope.request      = res.data;
                    $scope.editRequest  = angular.copy($scope.request);
                    if(runChooseStatusPortalPopup){
                        $scope.openChooseStatusPortalPopup();
                    }
                },
                error: function(data){}
            });
        }else if($scope.mod === 'add'){
            Ajax.post({
                url: 'request/add-request',
                params: {user_id: Utils.GET().user_id},
                data:   $scope.request,
                success: function(res){
                    Utils.redirect('/academy/request/update?id=' + res.data.request.id);
                }
            });
        }
    };
    
    $scope.getBudgetTab = function(){
        $scope.$broadcast("paymentGrid:load");
        
        Ajax.get({
            url: 'request/get-budget-request', 
            params: {id: Utils.GET().id},
            success: function(data) { 
                $scope.budgetRequests       = data.budgetRequests;
                $scope.picklist             = data.picklist; 
                
                $scope.budgetFinishLoading = true;
            }
        });
    };
    
    $scope.$watch('tabSelected', function(num) {
        if(num === 2 && !$scope.budgetRequest){
            $scope.getBudgetTab();
       }
    });
    
    $scope.addAutoBudget = function(){
        Ajax.post({
            url: 'request/add-auto-budget',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.getBudgetTab();
            }
        });
    };
    
    $scope.addBudget = function(){
        $scope.budgetRequests.push({edit: true});
    };
    
    $scope.saveBudgetRequest = function(index, br){  
        Ajax.post({
            url:        'request/save-budget-request',
            params:     {id: Utils.GET().id},
            data:       $scope.budgetRequests[index],
            success:    function(res){
                $scope.budgetRequests = res.data;
                br.edit = false;
            }
        });
    };
    
    $scope.cancelBudgetRequest = function(index, br){
        if(br.id){
            br.edit = false;
        }else{
            $scope.budgetRequests.splice(index, 1);
        }
    };
    
    $scope.deleteBudgetRequest = function(budget_request_id){
        Ajax.post({
            url:    'request/delete-budget-request',
            params: {id: Utils.GET().id, budget_request_id: budget_request_id},
            notShowMessage: true,
            success: function(res){
                $scope.getBudgetTab();
                Utils.showNoty('התקציב נמחק בהצלחה');
            },
            error: function(res){
                Utils.showNoty(res, 'error', 7000);
            }
        });
    };
    
    $scope.selectTab = function(num){
       $scope.tabSelected = num;
    };
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.request,3); // 3 = request(logic_id) in academy
    };
}]);