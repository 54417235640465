'use strict';

app.controller('CommitteeSummaryController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope,  $filter, Ajax, Utils) {
    $scope.approveFinance = function(){
        var selected = $('#w7').yiiGridView('getSelectedRows');

        if(selected[0]){
            Ajax.post({
                url: 'committee/approve-finance',
                //params: {id: Utils.GET().id},
                data: selected,
                success: function(res){
                    $.pjax.reload({container:'#w7-container'});
                }
            });
        }
    };
}]);