'use strict';

app.directive('tags', ['$timeout', '$filter', 'Ajax', 'Utils', function ($timeout, $filter, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: false,
        templateUrl: '/js/app/directives/tags/tagsTpl.html',
        link: function(scope, element, attrs) {
            scope.logicId       = attrs.ngLogicId ? attrs.ngLogicId : null;
            scope.nameSingle    = attrs.nameSingle ? attrs.nameSingle : 'תג';
            scope.namePlural    = attrs.namePlural ? attrs.namePlural : 'תגיות';
            
            scope.$on('cru:getSuccess', function(e, mod, res){
                scope.tagsArr = [];
                if (mod == 'edit'){
                    scope.setTags();
                }
            });

            scope.$on('cru:postSuccess', function(e, mod, res){
                scope.tagsArr = [];
                if (mod == 'edit'){
                    scope.setTags();
                }
            });
            
            scope.$watch('selected_tag', function(newValue, oldValue) {
                if (newValue) {
                    var newValueText = $( "#tags-list option:selected" ).text();
                    if( !scope.isTagExist(newValueText) && newValueText != '-בחר-' ) {
                        scope.tagsArr.push(newValueText);
                        scope.data.tags = scope.tagsArr.join("|");
                    }
                }
            });    

            scope.isTagExist = function(value){
                if ($filter('filter')(scope.tagsArr, value)[0]){
                    return true;
                }
                return false;
            };

            scope.removeTag = function($index){
                scope.tagsArr.splice($index, 1);
                scope.data.tags = scope.tagsArr.join("|");
            };
            
            scope.setTags = function(){
                if (scope.data.tags)  {
                    scope.tagsArr = scope.data.tags.split("|");
                }
            };
        }
    };
}]);