'use strict';

app.controller('SpDefaultController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    $scope.yearId = null;

    $scope.init = function(){
        Ajax.get({
            module: 'singleparent',
            url:    'default/get-orders-report', 
            params: {},
            success: function(res){
                $scope.ordersStautses   = res.ordersStautses;
                $scope.ordersActiveStatus = res.ordersActiveStatus;
                $scope.groups           = res.groups;
                $scope.programs         = res.programs;
                $scope.groupsAndActiveStatus = res.groupsAndActiveStatus,
                $scope.programsAndActiveStatus = res.programsAndActiveStatus
            }
        });
    };
        
    $scope.init();

}]);