'use strict';

app.controller('DafhSupplierController', ['$scope', '$filter', 'Ajax', 'mailMerge', 'Utils', 'Popup', function($scope, $filter, Ajax, mailMerge, Utils, Popup) {
    $scope.data = {};
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/dafchadash/supplier/view?id=' + res.data.id);
        //}
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            $scope.getSupplierInvoices();
            $scope.getRequestsCount();
            $scope.profileSrc   = res.data.profileSrc;
        }
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getRequestsCount = function(){
        Ajax.get({
            module: 'dafchadash',
            url:    'supplier/get-requests-count', 
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.requestsCount    = res.requestsCount;
                $scope.con              = res.con;
            }
        });
    };
            
    $scope.getSupplierInvoices = function(){
        Ajax.get({
            module: 'dafchadash',
            url: 'supplier/get-supplier-invoices', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.supplierInvoices = res.invoices;                
            }
        });
    };
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data, 78); // 78 = supplier logic id
    };
}]);