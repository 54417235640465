app.directive('dependentFields', ['$parse', function ($parse) {
    return {
        require:'^ngModel',
        restrict:'A',
        link:function (scope, elm, attrs, ctrl) {
            var dependModel = attrs.dependModel;
            scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                if((newValue != oldValue)){
                    // Get the model
                    var model = $parse(dependModel);
                    // Assigns a value to it
                    model.assign(scope, newValue);
                }
            });
        }
    };
}]);


