'use strict';

app.directive('formSettingsFields', ['$timeout', '$filter', 'Ajax', 'Utils', '$sce', function ($timeout, $filter, Ajax, Utils, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            title: '=title',
            f: '=f',
            form: '=form',
            fieldTypes: '=fieldTypes',
		},
        templateUrl: '/js/app/directives/formSettingsFields/formSettingsFields.html',
        link: function(scope, element, attrs) {
            $timeout(function(){    
                scope.f = angular.fromJson(scope.f);
                scope.form = angular.fromJson(scope.form);
                scope.fieldTypes = angular.fromJson(scope.fieldTypes);
                scope.titles = scope.$parent.titles;
            },1000);
            
            scope.getFieldById = (fieldId) => {
                return scope.$parent.fields.filter( function(i) {
                    return i.id == fieldId;
                });
            };

            scope.removeField = function(f){
                if (!f.sum_of_answers || f.sum_of_answers == 0){
                    f.delete = f.delete == 0 || f.delete == undefined ? 1 : 0;
                    f.visible = f.delete == 0 ? 1 : 0;
                    
                }else{
                    f.visible = f.visible == 0 ? 1 : 0;
                }
            };
            
            scope.addAnswer = function(f){
                if (f){
                    if (!f.answer_options){
                        f.answer_options = [];
                    }
                    f.answer_options.push({text: null, rank: null, isdefault: false});
                }
            };

            scope.removeAnswer = function(f,$index){
                if (f){
                    if (f.answer_options[$index].isdefault) {
                        f.default_answer = null;
                    }
                    f.answer_options.splice($index,1);
                }

            };

            scope.addValue = function(f){
                if (f){
                    if (!f.values){f.values=[]}
                    f.values.push({name: null});
                }
            };

            scope.removeValue = function(f,$index){
                if (f){
                    f.values.splice($index,1);
                }

            };

            scope.resetCheckboxes = function(f,$index){
                if (f){
                    f.default_answer = null;
                    if (!f.answer_options[$index].isdefault || f.answer_options[$index].isdefault == 'false') {
                        f.answer_options[$index].isdefault = 'true';
                        f.default_answer = f.answer_options[$index].text;
                        var i = 0;
                        for (i = 0; i < f.answer_options.length; i++) {
                            if (i != $index){
                                f.answer_options[i].isdefault = 'false';
                            }
                        }
                    }
                }
            };

            scope.resetRadio = function(f,$index){
                if (f){
                    f.default_answer = f.answer_options[$index].text;
                    f.answer_options[$index].isdefault = 'true';
                    var i = 0;
                    for (i = 0; i < f.answer_options.length; i++) {
                        if (i != $index){
                            f.answer_options[i].isdefault = 'false';
                        }
                    }
                }
            };

            scope.setDefaultAnswer = function(f,$index){
                if (f){
                    if (f.answer_options[$index].isdefault) {
                        f.default_answer = f.answer_options[$index].text;
                    }
                }
            };
            
            scope.copyField = function(f) {
                var copyField =  angular.copy(f);
                copyField.id = null;
                copyField.order = parseInt(scope.$parent.getMaxOrder()) + 1;
                scope.$parent.fields.push(copyField);
                scope.title.fields.push(copyField);
            };

            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };

            scope.changeFieldStep = function(f,pt){
                f.title_parent_id = pt.id;
                pt.fields.push(f);
                scope.title.fields.splice(f.index,1);
                //$('#tab-name-'+pt.id).trigger('click');
                $timeout(function(){
                    $('#tab-name-'+pt.id).trigger('click');
                })
            };

        }
    };
}]);