'use strict';

app.controller('EhpPatientController', ['$scope', '$rootScope', 'Ajax', 'Utils', 'Popup', function ($scope, $rootScope, Ajax, Utils, Popup) {
    $scope.requests = [];
    $scope.currentSupplier = {};

    $scope.getRequestDetails = function () {
        Ajax.get({
            module: 'elhanefeshportal',
            url: 'patient/get-request-details',
            params: { id: Utils.GET().id },
            success: function (res) {
                $scope.requests = res.requests;
                $scope.patient = res.patient
                $scope.treatments = res.treatments;
                $scope.budgetRequestsAlerts = res.budgetRequestsAlerts;
                $scope.patient.age = Utils.getAge($scope.patient.birth_date);
                $scope.isTreatmentDescriptionAvailable = res.isTreatmentDescriptionAvailable;
                $scope.programNames = res.programNames;
                $scope.currentSupplier = res.currentSupplier;
                if (res.treatmentRequireReason && !res.treatmentRequireReason.therapist_reason) {
                    var data = res.treatmentRequireReason;
                    data.request_id = res.treatmentRequireReason.budgetRequest.request.id;
                    data.errorMessage = res.errorMessage;
                    Popup.open('therapistReason', $scope.saveTreatment, data, {}, {}, 'static');
                }
            }
        });
    };

    $scope.init = function () {
        $scope.getRequestDetails();
        $scope.currentUser = YT.user;
    }

    $scope.init();

    $rootScope.$on('digitalData:saved', function (e) {
        $scope.getRequestDetails();
    });

    $scope.isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    $scope.saveTreatment = function (data, $modalInstance) {
        if (!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)) {
            return; // to avoid from multiple saving
        }

        if (data.participantsOptions && data.participantsOptions.length > 0 && !data.participants) {
            var bParticipantsChecked = false;
            angular.forEach(data.participantsOptions, function (item, key) {
                if (item.ischecked != "false") {
                    bParticipantsChecked = true;
                }
            });
            if (!bParticipantsChecked) {
                alert('חובה לסמן מי המשתתפים במפגש');
                return;
            }
        }

        if (!data.from_time) {
            if (!data.fromHour || !data.fromMinute) {
                alert('לא ניתן לשמור מפגש ללא שעת מפגש');
                return;
            } else {
                data.from_time = data.fromHour + ':' + data.fromMinute;
            }

            // if (!data.duration_time) {
            //     alert('חובה לבחור את משך המפגש');
            //         return;
            // }else{
            // בינתיים אין אפשרות לבחור משך פעילות והברירת מחדל שעה
            if (!data.duration_time) {
                data.duration_time = 60;
            }
            let toTimeMinutes = (parseInt(data.fromHour) * 60) + parseInt(data.fromMinute) + parseInt(data.duration_time);
            data.toHour = Math.floor(toTimeMinutes / 60);
            data.toMinute = Math.floor(toTimeMinutes % 60);
            if (data.toHour < 10) { data.toHour = '0' + data.toHour; }
            if (data.toMinute < 10) { data.toMinute = '0' + data.toMinute; }
            data.to_time = data.toHour + ':' + data.toMinute;
            // }

            if (!data.location) {
                alert('לא ניתן לשמור מפגש ללא מיקום המפגש');
                return;
            }
        }

        $scope.editTreatmentData = angular.copy(data);

        Ajax.post({
            module: 'elhanefeshportal',
            url: 'patient/save-treatment',
            params: { budgetRequestId: data.budget_request_id, treatmentId: data.id },
            data: data,
            success: function (res) {
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.setFutureTreatmentStatus = (data, status) => {
        data.lpi_id_approve_status = status;
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'patient/save-treatment',
            params: { budgetRequestId: data.budget_request_id, treatmentId: data.id },
            data: data,
            success: function (res) {
                $scope.init();
            }
        });
    }

    $scope.openTreatmentPopup = function (br, t) {
        // I set the budget_request_id to the scope for send it to server on the ajax post at saveTreatment function
        var data = t || {};
        if (br) {
            data.budgetRequest = br;
        }
        data.errorCounter = 0;

        data.currentDate = new Date();

        if (br && br.id && !data.id) {
            data.request_id = br.request_id;
            data.budget_request_id = br.id;
            data.amount = br.actual_fund_amount;
            data.treatment_date = data.currentDate;
            data.user_id_therapist = br.request.user_id_therapist;
            data.user_id_supplier = br.user_id_supplier;
        }

        data.remotePhoneAllow = data.budgetRequest.request.remote_approve_by_phone == 1 ? 1 : 0;
        data.remoteVideoAllow = data.budgetRequest.request.remote_approve_by_video == 1 ? 1 : 0;

        $scope.editTreatmentData = null;
        data.currentUser = YT.user.user_id;
        data.isTreatmentDescriptionAvailable = $scope.isTreatmentDescriptionAvailable;

        Ajax.get({
            module: 'elhanefeshportal',
            url: 'patient/default-by-type-list',
            params: { id: '29', programId: data.budgetRequest.request.program_id },
            notShowMessage: true,
            success: function (res) {
                data.participantsOptions = res;
                var participantsArray = [];
                if (data.participants) {
                    participantsArray = data.participants.split("|");
                }
                angular.forEach(data.participantsOptions, function (item, key) {
                    if (participantsArray.includes(item.name)) {
                        item.ischecked = item.name;
                    } else {
                        item.ischecked = 'false';
                    }
                });

                // set from time hour and minutes to the select lists
                if (data.from_time && data.from_time.includes(':')) {
                    data.fromHour = data.from_time.split(':')[0];
                    data.fromMinute = data.from_time.split(':')[1];
                }

                var fn = [];
                fn = {
                    setFutureTreatmentStatus: $scope.setFutureTreatmentStatus,
                };
                if (data.invoice_id) {
                    Popup.open('elhanefeshPortal/addTreatmentPortalView', $scope.saveTreatment, data, fn);
                } else if (data.lpi_id_gefen_status && data.daysRremainToTreatmentDate <= 4) {
                    Popup.open('elhanefeshPortal/addTreatmentPortalView', $scope.saveTreatment, data, fn);
                } else {
                    Popup.open('elhanefeshPortal/addTreatmentPortal', $scope.saveTreatment, data, fn);
                }
            }
        });

    };

    $scope.openViewHtmlForm = (br) => {
        // window.open('https://' + YT.project.baseHost + '/common/form-portal/view-html?token=' + br.therapistFormSubmission.token,'popup','width='+screen.availWidth+',height='+screen.availHeight);
        var data = {};
        data.formSubmission = br.therapistFormSubmission;
        Ajax.get({
            module: 'common',
            url: 'form/get-form-fields',
            params: {
                id: br.therapistFormSubmission.form_id,
                formSubmissionId: br.therapistFormSubmission.id
            },
            success: function (res) {
                data.form = res.form;
                data.titles = res.titles;
                data.fieldname = 'budget_request_id';
                Popup.open('common/formViewHtml', null, data, {}, {});
            }
        });
    };

    $scope.removeTreatment = function ($id) {
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'patient/remove-treatment',
            params: { id: $id },
            success: function (res) {
                $scope.init();
            }
        });
    };


    $scope.selectedBrId = null;
    $scope.uploadedObjLogicId = null;
    $scope.addNewFileClick = function (brId, uploadedObjLogicId) {
        $scope.selectedBrId = brId;
        $scope.uploadedObjLogicId = uploadedObjLogicId;
        $('#uploadNewFile').trigger('click');
    };

    $scope.uploadFile = function (elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];

        if (file) {
            var fd = new FormData();

            var param = {};
            param.upload_source = '(פורטל)';
            param.is_sensitive = 1;

            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
            //            var obj = {};
            //            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-budget-request-file?id=' + $scope.selectedBrId + '&uploadedObjLogicId=' + $scope.uploadedObjLogicId,
                fd: fd,
                success: function (res) {
                    $scope.init();
                }
            });
        }
    };

    $scope.showUploadBtn = function (br, req) {
        return parseInt(br.utilized_treatments_counter) >= parseInt(br.treatments_num_for_alert) && parseInt(br.treatments_num_for_alert) > 0 && br.lpiIdTherapistAssignmentSubmissionStatus.logic_id == 8;
    };

    $scope.showUploadDigitalBtn = (req) => {
        return req.lpiIdRequireTherapeuticPlan && req.lpiIdRequireTherapeuticPlan.logic_id == '201';// && !req.requestMoreDetails;
        //parseInt(br.utilized_treatments_counter) >= parseInt(br.treatments_num_for_alert) && parseInt(br.treatments_num_for_alert)>0 && br.lpiIdTherapistAssignmentSubmissionStatus.logic_id==8;
    };

    $scope.isUserAllowToAdd = function (req, br) {
        if (YT.user.user_id == br.user_id_supplier && req.user_id_therapist != br.user_id_supplier && $scope.currentSupplier.userSupplier.lpiIdPortalStatus.logic_id == '17') {
            return false;
        }
        return true;
    };

    $scope.createSubmission = (id) => {
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'patient/create-therapist-submission',
            params: { id: id },
            notShowMessage: true,
            success: function (res) {
                Utils.redirect('/common/form-portal?token=' + res.token, true);
            }
        });
    };

    $scope.exportTratmentsToExcel = () => {
        if (confirm('אתה עומד להוריד קובץ אקסל עם פירוט המפגשים של מוטב זה, להמשיך?')) {
            Utils.redirect('/elhanefeshportal/patient/export-treatments-to-excel?id=' + $scope.patient.id);
        }
    };
}]);