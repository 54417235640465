'use strict';

app.controller('SpGroupTrainingController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.data     = {};
    $scope.orderStatuses = {};

    $scope.$on('cru:getSuccess', function(e, mod, res){
         Ajax.get({
            module: 'singleparent',
            url:    'group-training/get-orders-report-by-group-id', 
            params: {groupId: Utils.GET().id},
            success: function(res){
                $scope.ordersStautses = res.ordersStautses;
            }
        });
    });
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(!window.location.href.includes('view')){
            Utils.redirect('/singleparent/group-training/view?id=' + res.data.id);
        }
        //}
    });

    $scope.saveGroupTrainingLine = (data, $modalInstance) => {
        Ajax.post({
            url: 'group-training/save-group-training-line',
            data: data,
            success: function(res){
                $modalInstance.close();
                $scope.getGroupTrainingDetails();
            }
        });
    }

    $scope.getGroupTrainingDetails = () => {
        Ajax.post({
            url: 'group-training/get-group-training-details',
            params: {id: $scope.data.id},
            success: function(res){
                $scope.data = res.data;
            }
        });
    }

    $scope.openGroupTrainingLinePopup = (data) => {
        Ajax.get({
            module: 'singleparent',
            url:    'group-training/get-attendance-list', 
            params: {
                groupTainingId: Utils.GET().id,
                lineId: data ? data.id : null
            },
            success: function(res){
                data = res.groupTrainingLine ? res.groupTrainingLine : {group_training_id: Utils.GET().id};
                data.attendanceList = res.attendanceList;
                Popup.open('singleparent/addGroupTrainingLine', $scope.saveGroupTrainingLine, data);
            }
        });
    };
    
}]);