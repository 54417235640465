'use strict';

app.directive('morrisWidget', ['Ajax', 'Utils', '$timeout', 'MorrisProvider', function (Ajax, Utils, $timeout, MorrisProvider) {
    return{
        restrict: 'AEC',
        scope: false,
        //transclude: false,
        template: function(elem, attrs){
            return '<div id="' + attrs.elemid + '" style="height: 250px;"></div>';
        },
        link: function(scope, element, attrs) { 
            scope.data = null;
            
            Ajax.get({
                url: attrs.geturl, 
                params: {id: Utils.GET().id},
                success: function(res) { 
                    scope.data = res.data;
                    Morris[attrs.type](scope.data);                    
                }
            });
        }
    };
}]);