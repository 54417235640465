'use strict';

app.controller('LookupPicklistItem', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.fileGroups = [];
    $scope.roles = ['','אדמין','כספים','מנהל תוכן','איש תוכן','מוטב'];

    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.init();
        }
    });
    
    $scope.init = function(){
        Ajax.get({
            url: 'lookup-picklist/get-lookup-picklist-items',
            params: {picklistId: Utils.GET().id},
            success: function(res){
                $scope.lookupPicklist = res.lookupPicklist;
                $scope.items = res.items;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        data.picklist_id = Utils.GET().id;
        Ajax.post({
            url: 'lookup-picklist/save-item',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };
        
    $scope.openLookupPicklistItemPopup = function(data){
        var fn = [];
        fn = {
            removeLine: $scope.removeLine
        };
        Popup.open('adminsettings/lookupPicklistItem', $scope.save, data, fn);
    };
}]);