'use strict';

app.controller('AcademicGeneralController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    $scope.places       = null;
    $scope.new_study    = null;
    
    
    $scope.init = function(){
        Ajax.get({
            module: 'common',
            url: 'student/academic-general', 
            params: {id: Utils.GET().id},
            success: function(data){
                $scope.places               = data.places;
                $scope.academicGeneralTypes = data.academicGeneralTypes;
            }
        });
    };
    // I add the init invoke here, because $watch not work???
    //$scope.init();
    $scope.$watch('tabSelected', function(num) {
        if(num === 2 && !$scope.places){
            $scope.init();
        }
    });

    $scope.addStudy = function(){
        if($scope.new_study) {
            Ajax.post({
                module: 'common',
                url:    'student/add-academic-general',
                params: {id: Utils.GET().id},
                data: $scope.new_study,
                success: function(res){
                    var type = $filter('filter')($scope.academicGeneralTypes, {id: $scope.new_study.lpi_id_Institution_type})[0];
                    $scope.new_study.type = {id: type.id, name: type.name};
                    if(  !$scope.places.hasOwnProperty(type.parent_id) ){
                        $scope.places[type.parent_id] = [];
                    }
                    
                    $scope.places = res.data.places;
                    $scope.new_study = null;
                    
                    //$scope.$$childHead.clearDate();
                    //$scope.$$childHead.$$nextSibling.clearDate();
                }
            });
        }
    };
    
    $scope.removeStudy = function(id, arrayIndex){
        Ajax.post({
            module: 'common',
            url:    'student/remove-study',
            params: {study_id: id},
            success: function(data){
                $scope.init();
            }
        });
    };
    
}]);