'use strict';

app.controller('EhpRequestController', ['$scope', '$rootScope', 'Ajax', 'Utils', 'Popup', function($scope, $rootScope, Ajax, Utils, Popup) {
    $scope.requests = [];
        
    $scope.getRequestDetails = function(){
        Ajax.get({
            module: 'elhanefeshportal',
            url: 'request/get-request-details', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.request              = res.request;
                $scope.budgetRequests       = res.budgetRequests;
                $scope.treatments           = res.treatments;
                $scope.budgetRequestsAlerts = res.budgetRequestsAlerts;
                $scope.request.patient.age = Utils.getAge($scope.request.patient.birth_date);
                $scope.isTreatmentDescriptionAvailable = res.isTreatmentDescriptionAvailable;
                if (res.treatmentRequireReason && !res.treatmentRequireReason.therapist_reason) {
                    var data = res.treatmentRequireReason;
                    data.errorMessage = res.errorMessage;
                    Popup.open('therapistReason', $scope.saveTreatment, data, {}, {}, 'static');
                }
            }
        });
    };
    
    $scope.init = function(){
        $scope.getRequestDetails();
        $scope.currentUser = YT.user;
    }
    
    $scope.saveTreatment = function(data, $modalInstance){
        if(!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)){
            return; // to avoid from multiple saving
        }

        if (!data.lpi_id_meeting_type){
            alert('אין אפשרות לשמור מפגש ללא צורת מפגש');
            return;
        }
        
        $scope.editTreatmentData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'request/save-treatment', 
            params: {id: $scope.request.id, treatmentId: data.id},
            data: data,
            success: function(res) {
                $scope.init();
                $modalInstance.close();
            }
        });
    };
    
    $scope.openTreatmentPopup = function(br, t){
        // I set the budget_request_id to the scope for send it to server on the ajax post at saveTreatment function
        var data = t || {};
        if (br){
            data.budgetRequest = br;
        }
        data.errorCounter = 0;
        
        data.currentDate = new Date()
        
        if(br && br.id && !data.id){
            data.budget_request_id = br.id;
            data.amount = br.actual_fund_amount;
            data.treatment_date = data.currentDate;
            data.user_id_therapist = br.request.user_id_therapist;
            data.user_id_supplier = br.user_id_supplier;
        }
        $scope.editTreatmentData = null;
        data.currentUser = YT.user.user_id;
        data.isTreatmentDescriptionAvailable = $scope.isTreatmentDescriptionAvailable;
        Popup.open('elhanefeshPortal/addTreatmentPortal', $scope.saveTreatment, data);
    };
    
    $scope.showAddTreatment = function($index){
        return $scope.budgetRequests[$index].treatments_balance > 0;
    };
    
    $scope.removeTreatment = function($id){
        Ajax.post({
            module: 'elhanefeshportal',
            url: 'request/remove-treatment',
            params: {id: $id, requestId: $scope.request.id},
            success: function(res) {
                $scope.init();
            }
        });
    };
    
    $scope.init();
}]);