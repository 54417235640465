'use strict';

app.controller('EhBudgetReviewLineController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    
    $scope.data = {};
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'edit'){
            
            //$scope.getBudgetReviewLinesCount();
        }
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        Utils.redirect('/common/list/view?id=517');
    });
    
    $scope.getBudgetReviewLinesCount = function(){
//        Ajax.get({
//            module: 'elhanefesh',
//            url:    'organization/get-number-of-patients', 
//            params: {id: Utils.GET().id},
//            success: function(res){
//                $scope.patientsCount    = res.patientsCount;
//                $scope.con              = res.con;
//            }
//        });
    };
    
}]);