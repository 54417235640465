'use strict';

app.directive('setNewPasswordWithToken', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: false,
        templateUrl: '/js/app/directives/setNewPasswordWithToken/setNewPasswordWithTokenTpl.html',
        link: function(scope, element, attrs) {
            scope.pass = {};
            scope.showPasswordStrength = false;
            scope.isValid = attrs.isValid;
            scope.changePassword = function(){
                if(!scope.pass.new_password || !scope.pass.retype_new_password){
                    alert('אנא מלא את כל השדות');
                    return;
                }

                if(scope.pass.new_password !== scope.pass.retype_new_password){
                    alert('סיסמה חדשה אינה תואמת בשני השדות');
                    return;
                }

                if (scope.pass.new_password.length < 8){
                    alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
                    return;
                }

                if (!scope.isValidPass(scope.pass.new_password)){
                    alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
                    return;
                }

                if (!scope.isStrengthPass(scope.pass.new_password)){
                    alert('הסיסמה צריכה לעמוד בדרישות הבאות: להכיל לפחות אות אחת ומספר אחד ולהכיל לפחות אות אחת גדולה');
                    return;
                }

                Ajax.post({
                    module: 'contactehportal',
                    url: 'login-manage/change-password-with-token',
                    params: {token: Utils.GET().token},
                    data: scope.pass, 
                    notShowMessage: true,
                    success: function(res){
                        Utils.redirect('/contactehportal/contact/home');

                    },
                });
            };

            scope.isValidPass = function(str) {
                return /^\w+$/.test(str);
            }

            scope.isStrengthPass = function(str) {
                return scope.pass.new_password.match(/[A-z]/) && scope.pass.new_password.match(/[A-Z]/) && scope.pass.new_password.match(/\d/);
            }

            scope.passwordStrength = function(){
                scope.showPasswordStrength = true;
                if(!scope.pass.new_password){
                    scope.validLength          = '';
                    scope.validLeastOneLetter  = '';
                    scope.validLeastOneCapital = '';
                    scope.validLeastOneNumber  = '';
                    return;
                }
                if ( scope.pass.new_password.length < 8 ) {
                    scope.validLength = 'invalid_pass';
                } else {
                    scope.validLength = 'valid_pass';
                }
                //validate letter
                if ( scope.pass.new_password.match(/[A-z]/) ) {
                    scope.validLeastOneLetter = 'valid_pass';
                } else {
                    scope.validLeastOneLetter = 'invalid_pass';
                }

                //validate capital letter
                if ( scope.pass.new_password.match(/[A-Z]/) ) {
                    scope.validLeastOneCapital = 'valid_pass';
                } else {
                    scope.validLeastOneCapital = 'invalid_pass';
                }

                //validate number
                if ( scope.pass.new_password.match(/\d/) ) {
                    scope.validLeastOneNumber = 'valid_pass';
                } else {
                    scope.validLeastOneNumber = 'invalid_pass';
                }
            };
        }
    };
}]);