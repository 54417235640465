'use strict';

app.controller('CommitteeController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    
    $scope.getCommitteeDetails = function() {
        Ajax.get({
            url: 'committee/get-committee-details',
            params: {id: Utils.GET().id},
            success: function(res){
               
                $scope.donutChartDecision           = res.donutChartDecision;
                $scope.chartBudgetByRound           = res.chartBudgetByRound;
                $scope.committee                    = res.committee;
                $scope.editCommittee                = angular.copy($scope.committee);
                $scope.users                        = res.users;
                $scope.counterParams                = res.counterParams;                
                $scope.con                          = res.con;
                $scope.setAccountingReviewUser();
            }
        });
    };
    $scope.getCommitteeDetails();
    
    $scope.setAccountingReviewUser = () => {
        $scope.acountigReviewUsers = $scope.committee.participants.filter( (user) => {
            return user.is_accounting_review_user == 1
        });
    };

    $scope.addUser = function(id,isAccountingReview){
        var user = $filter('filter')($scope.users, {id: id})[0];

        if (isAccountingReview) {
            user.is_accounting_review_user = 1;
        }

        if ( !$filter('filter')($scope.committee.participants, {id: user.id})[0] ){ // chack if exsits
            $scope.committee.participants.push(user);
        }
        
        $scope.setAccountingReviewUser();

        $scope.usersParticipants = "";
    };
    
    $scope.saveCommittee = function(){
        Ajax.post({
            url:    'committee/update-committee',
            params: {id: Utils.GET().id},
            data:   $scope.committee,
            //notShowMessage: true,
            success: function(res){
                $scope.getCommitteeDetails();
            }
        });
    };

    $scope.readyToSign = function() {
        Ajax.post({
            url:    'committee/ready-to-sign',
            params: {id: Utils.GET().id},
            data:   $scope.committee,
            //notShowMessage: true,
            success: function(res){
                if(res.data){
                    if( confirm("קיימות בקשות שלא התקבלו בהם החלטות האם בכל זאת תרצה לסגור את הועדה ?") ){
                        Ajax.post({
                            url:    'committee/ready-to-sign',
                            params: {id: Utils.GET().id},
                            data:   angular.extend($scope.committee, {closeCommittee : true}),
                            success: function(res){
                                Utils.refresh();
                            }
                        });
                    }else{
                        $scope.getCommitteeDetails();    
                    }
                }else {
                    Utils.refresh();
                }
            }
        });
    };

    $scope.cancelReadyToSign = function() {
        Ajax.post({
            url:    'committee/cancel-ready-to-sign',
            params: {id: Utils.GET().id},
            //notShowMessage: true,
            success: function(res){
                Utils.refresh();
            }
        });
    };

}]);