'use strict';

app.controller('EhcpPatientController', ['$scope', '$rootScope', '$timeout', 'Ajax', 'Utils', 'Popup', function($scope, $rootScope, $timeout, Ajax, Utils, Popup) {
    $scope.requests                     = [];
    $scope.patient                      = {};
    $scope.patient.userPatient          = {};
    $scope.organizations                = {};
    $scope.fileGroupLines               = {};
    $scope.tzError                      = false;
    $scope.data                         = {};
    $scope.data.files                   = {};
    $scope.filesToUploadCounter         = 0;
    $scope.uploadedFilesCounter         = 0;
    $scope.disabledSaveBtn              = false;
    $scope.isPlaceSelected = 0;
    $scope.copyCity = null;
    
    $scope.$on('autocomplete:getplace', function(e, place){
        $scope.isPlaceSelected = 3;
        
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        
        $scope.patient.adrs_city =  locality;
        
       
    });    
    
    $scope.isSelected = function(){
      if($scope.copyCity == $scope.patient.adrs_city){
          return;
      }
      $scope.isPlaceSelected = 2;
      $scope.patient.adrs_city = null;
    };
    
    $scope.getRequestDetails = function(){
        Ajax.get({
            module: 'contactehportal',
            url: 'patient/get-request-details', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.requests                 = res.requests;
                $scope.patient                  = res.patient;
                $scope.patient.age              = Utils.getAge($scope.patient.birth_date);
                $scope.programNames             = res.programNames;
                $scope.suppliers                = res.suppliers;
            }
        });
    };
    
    $scope.getPatientDetails = function(){
        Ajax.get({
            module: 'contactehportal',
            url: 'patient/get-patient-details', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.patient          = res.patient;
                $scope.copyCity                 = $scope.patient.adrs_city;
                $scope.organizations    = res.organizations;
                if ($scope.organizations.length == 1){ // if this contact has only one organization set it
                    $scope.patient.userPatient.organization_id = $scope.organizations[0].organization_id;
                }
                $scope.fileGroupLines   = res.fileGroupLines;
            }
        });
    };
    
    $scope.init = function(){
        if (Utils.GET().id){
            if(window.location.href.includes('view')){
                $scope.getRequestDetails();
            }else if (window.location.href.includes('update')){
                $scope.getPatientDetails();
            }
        }else{
            Ajax.get({
                module: 'contactehportal',
                url: 'patient/init-create-page', 
                success: function(res) {
                    $scope.organizations    = res.organizations;
                    if ($scope.organizations.length == 1){ // if this contact has only one organization set it
                        $scope.patient.userPatient.organization_id = $scope.organizations[0].organization_id;
                    }
                    $scope.fileGroupLines   = res.fileGroupLines;
                    angular.forEach($scope.fileGroupLines, function(item, key) {
                        item.group_line_id = item.id;
                        item.id = key;
                    });
                }
            });
            $scope.patient.userPatient.income_total = 0;
        }
        $scope.currentUser = YT.user;
    }    
    
    $scope.sendPatient = function(){
        $scope.requiredFilesName = undefined;
        $scope.showFilesValidateAlert = false;
        if (!$scope.validateRequiredFields('required')){
            //alert('יש להשלים את המידע בכל השדות המסומנים באדום וללחוץ שוב על הגשת בקשה');
            $scope.disabledSaveBtn = false;
            return;
        }
        
        if (!$scope.patient.id){
            angular.forEach($scope.fileGroupLines, function(item, key) {
                if (item.is_required == 'yes' && !item.fd){
                    //alert('חסר ' + item.name +  ' כדי להמשיך בהגשה יש להעלות את כל מסמכי החובה');
                    $scope.requiredFilesName = $scope.requiredFilesName ? $scope.requiredFilesName + ', ' + item.name : item.name;
                    $scope.showFilesValidateAlert = true;
                }
            });
        }else{
            angular.forEach($scope.fileGroupLines, function(item, key) {
                if (!item.file) {
                    if (item.is_required == 'yes' && !item.fd){
                        //alert('חסר ' + item.name +  ' כדי להמשיך בהגשה יש להעלות את כל מסמכי החובה');
                        $scope.requiredFilesName = $scope.requiredFilesName ? $scope.requiredFilesName + ', ' + item.name : item.name;
                        $scope.showFilesValidateAlert = true;
                    }
                }
            });
        }
        
        if ($scope.showFilesValidateAlert == true){
            $scope.disabledSaveBtn = false;
            return;
        }
        
        $scope.patient.processStatusLogicId = '175'; // הוגש
        $scope.save();
    };
    
    $scope.savePatient = function(){
        // clear all failed
        $( ".failed" ).each(function( index ) {
            $( this ).removeClass( "failed" );
        });
        if (!$scope.validateRequiredFields('mandatory')){
            //alert('השדות המסומנים באדום הינם שדות חובה, אין אפשרות להמשיך ללא מידע זה');
            $scope.disabledSaveBtn = false;
            return;
        }
        $scope.patient.processStatusLogicId = '174'; // בתהליך הגשה
        $scope.save();
    };
    
    $scope.save = function(){
        // set contact to current user on portal
        $scope.patient.userPatient.user_id_contact = YT.user.user_id;
        if ($scope.organizations.length == 1){ // if this contact has only one organization set it
            $scope.patient.userPatient.organization_id = $scope.organizations[0].organization_id;
        }
        $scope.patient.files = $scope.fileGroupLines;
        
        //********************************************************
        // before save check if employment status was changed and set data to null if needed
        if($scope.patient.lpi_id_employment_father!=399&&$scope.patient.lpi_id_employment_father!=401){
            $scope.patient.father_employment_place = null;
            $scope.patient.father_employment_title = null;
            $scope.patient.father_employment_income = null;
        }
        if($scope.patient.lpi_id_employment_mother!=402){
            $scope.patient.mother_employment_place = null;
            $scope.patient.mother_employment_title = null;
            $scope.patient.mother_employment_income = null;
        }
        if($scope.patient.lpi_id_employment!=405){
            $scope.patient.partner_employment_place = null;
            $scope.patient.partner_employment_title = null;
            $scope.patient.partner_employment_income = null;
        }
        $scope.calculateTotalInc();
        //**************
        Ajax.get({ // finaly before save check if TZ not exist
            module: 'contactehportal',
            url: 'patient/is-tz-exist', 
            params: {
                tz: $scope.patient.tz,
                orgId: $scope.patient.userPatient.organization_id,
                patientId: (($scope.patient.id) ? $scope.patient.id : null)
            },
            success: function(res) {
                if (res.errorCode !== '0'){
                    Popup.open('contactehportal/tzexist', $scope.closePopup, res, {}, {}, 'static');
                }else{
                    // if TZ not exist continue save
                    Ajax.post({
                        module: 'contactehportal',
                        url: 'patient/save',
                        //param: {files: $scope.fileGroupLines },
                        data: $scope.patient,
                        success: function(res){
                            $scope.patient = res.patient;
                            if ($scope.filesToUploadCounter==0){
                                Utils.redirect('/contactehportal/contact/home');
                            }
                            angular.forEach($scope.fileGroupLines, function(item, key) {
                                if(item.fd) {
                                    res.listFileConnections[key]['is_sensitive'] = 1;
                                    item.fd.append('fileData', JSON.stringify(res.listFileConnections[key]));
                                    Ajax.jqueryUploadFile({
                                        module: 'common',
                                        url: 'file/upload?id=' + $scope.patient.id + '&logicId=31',
                                        fd: item.fd,
                                        success: function(res){
                                            $scope.uploadedFilesCounter++;
                                            if ($scope.uploadedFilesCounter==$scope.filesToUploadCounter){
                                                Utils.redirect('/contactehportal/contact/home');
                                            }
                                        }
                                    });
                                }
                            });
                        },
                        error: function(data) {
                            if (data['birth_date']){
                                alert('תאריך הלידה אינו תקין, תאריך הלידה צריך להיות מורכב משתי ספרות עבור היום בחודש, שתי ספרות עבור החודש וארבע ספרות עבור השנה לדוגמה 01/07/2003');
                                $scope.disabledSaveBtn = false;
                            }
                        }
                    });
                }
            }
        });
        
    };
        
    $scope.uploadPatientFiles = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#"+elem.id)[0].files[0];
        if( file ){
            if (file.size > 12000000){
                alert('אין אפשרות להעלות קובץ גדול מ 12 מגה בייט')
                return;
            }
            $scope.fileGroupLines[elem.id].fd = new FormData();
            $scope.fileGroupLines[elem.id].fd.append('file', file);
            $scope.$apply(function ($scope) {
                $scope.fileGroupLines[elem.id].file = file;
                if (!$scope.fileGroupLines[elem.id].name){
                    $scope.fileGroupLines[elem.id].name = file.name.substring(0,file.name.indexOf("."));
                }
            });
            $scope.filesToUploadCounter++;
        }else{
            // how to catch cancel event???
        }
    };
    
    $scope.cancelPatientFileClick = function(index) {
        if ($scope.fileGroupLines[index].group_line_id){
            $scope.fileGroupLines[index].file = null;
            $scope.fileGroupLines[index].fd = null;
        }else{
            $scope.fileGroupLines.splice(index, 1);
        }
        $scope.filesToUploadCounter--;
    };
    
    $scope.addNewFile = function(){
        let $currentIndex = $scope.fileGroupLines.length+1
        $scope.fileGroupLines.push( {id: $scope.fileGroupLines.length+1,  file: null, access_action: 'edit', group_line_id: null, is_required: 'no'}  ); 
        $scope.addPatientFileClick($scope.fileGroupLines.length-1);
    };
    
    $scope.checkTz = function() {
        $scope.tzError = Utils.LegalTz($scope.patient.tz);
    };

    $scope.validateRequiredFields = function(className) {
        $scope.showValidateAlert = false;
        
        //$scope.isExistTz();
        
        var isValidate = true;
        $( "." + className ).each(function( index ) {
            if ($( this ).val()==''){
                $( this ).addClass( "failed" );
                isValidate = false;
            }else{
                $( this ).removeClass( "failed" );
            }
        });
        
        $( ".sel-" + className ).each(function( index ) {
            if ($( this ).find(".form-control").val()==''){
                $( this ).find(".form-control").addClass( "failed" );
                isValidate = false;
            }else{
                $( this ).find(".form-control").removeClass( "failed" );
            }
        });
        
        $( ".date-" + className ).each(function( index ) {
            if ($( this ).find(".form-control").val()==''){
                $( this ).find(".form-control").addClass( "failed" );
                isValidate = false;
            }else{
                $( this ).find(".form-control").removeClass( "failed" );
            }
        });
        
        $( ".select-" + className ).each(function( index ) {
            if ($( this ).find(":selected").text()=="-בחר-"){
                $( this ).addClass( "failed" );
                isValidate = false;
            }else{
                $( this ).removeClass( "failed" );
            }
        });
        
        if(!$scope.patient.gender){
            $( ".gender-" + className ).addClass( "failed" );
            isValidate = false;
        }else{
            $( ".gender-" + className ).removeClass( "failed" );
        }
        
        $scope.showValidateAlert = !isValidate;
        
        return isValidate;
    };

    $scope.isExistTz = function(){
        Ajax.get({
            module: 'contactehportal',
            url: 'patient/is-tz-exist', 
            params: {
                tz: $scope.patient.tz,
                orgId: $scope.patient.userPatient.organization_id,
                patientId: (($scope.patient.id) ? $scope.patient.id : null)
            },
            success: function(res) {
                if (res.errorCode !== '0'){
                    Popup.open('contactehportal/tzexist', $scope.closePopup, res, {}, {}, 'static');
                }
            }
        });
    };
        
    $scope.calculateTotalInc = function(){
        $scope.patient.userPatient.income_total = (
            parseInt($scope.patient.mother_employment_income | 0)  +
            parseInt($scope.patient.father_employment_income | 0)  +
            parseInt($scope.patient.partner_employment_income | 0) +
            parseInt($scope.patient.another_income | 0)
            
        );
    }
   
    $scope.updateBudgetRequest = function(data, $modalInstance){
        Ajax.post({
            module: 'contactehportal',
            url: 'patient/save-budget-request', 
            params: {id: data.req.br.id},
            data: data.req.br,
            success: function(res) { 
                
            }
        });
    };    
        
    $scope.openFeedbackPopup = function(reqIndex, brIndex, templateName){
        var data = {};
        data.req               = $scope.requests[reqIndex];
        data.req.br            = data.req.budgetRequests[brIndex];
        var fn = [];
        fn = {
            addNewFileClick:        $scope.addNewFileClick,
            uploadFile:             $scope.uploadFile,
            updateBudgetRequest:    $scope.updateBudgetRequest
        };

        Ajax.get({
            module: 'contactehportal',
            url: 'patient/get-feedback-page', 
            params: {name: templateName},
            success: function(res) { 
                data.descPage = res.feedbacDescPage;
                Popup.open('contactehportal/'+templateName, $scope.saveBudgetRequest, data, fn, {});
            }
        });
    };
    
    $scope.updateRequest = function(data, $modalInstance){
        if (data.note_to_contact) {
            data.note_to_contact += "\n"
        }
        
        var supplierName = data.supplier_name || $( "#therapistSelect option:selected" ).text();

        data.note_to_contact = data.note_to_contact + "שם מטפל: " + supplierName;
        data.note_to_contact = data.note_to_contact + "\n" + "תיאור הבקשה: " + data.service_description;
        data.team_expected_quantity         = data.expected_quantity;
        data.therapist_expected_quantity    = data.expected_quantity;
        
        Ajax.post({
            module: 'contactehportal',
            url: 'patient/update-request', 
            data: data,
            success: function(res) { 
                if ($modalInstance){
                    $scope.getRequestDetails();
                    $modalInstance.close();
                }
            }
        });
    };

    $scope.openRequestUpdatePopup = function(reqIndex, brIndex){
        var data = {};
        data = $scope.requests[reqIndex];
        data.suppliers = $scope.suppliers;
        Popup.open('contactehportal/requestUpdate', $scope.updateRequest, data, {}, {});
        
    };

    $scope.init();
    $scope.selectedBrId         = null;
    $scope.uploadedObjLogicId   = null;
    
    $scope.addNewFileClick = function(brId,inputfileid){
        $scope.selectedBrId         = brId;
        $timeout( () => {
            $('#'+inputfileid).trigger('click');
        });
    };
    
    $scope.addPatientFileClick = function(inputfileid){
        $timeout( () => {
            $('#'+inputfileid).trigger('click');
        });
    };
    
    $scope.saveBudgetRequest = function(data, $modalInstance){
        data = data.req.br;
        if(!data.id && $scope.editBudgetData && angular.equals($scope.editBudgetData, data)){
            if ($modalInstance){
                $modalInstance.close();
            }
            return; // to avoid from multiple saving
        }
               
        $scope.editBudgetData = angular.copy(data);
        
        Ajax.post({
            module: 'contactehportal',
            url: 'patient/save-budget-request', 
            params: {id: data.id},
            data: data,
            success: function(res) { 
                $scope.getRequestDetails();
                if ($modalInstance){
                    $modalInstance.close();
                }
            }
        });
    };
    
    $scope.uploadFile = function(elem,logicid,inputfileid) {
        //var file_connection_id  = this.doc.id;
        var file = $("#"+inputfileid)[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param.upload_source = '(פורטל)';
            param.is_sensitive = 1;
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));

            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-budget-request-file?id=' + $scope.selectedBrId + '&uploadedObjLogicId=' + logicid,
                fd: fd,
                success: function(res){
                    $scope.init();
                    $rootScope.$broadcast("popup:close", res);
                }
            });
        }
    };
    
    
    
    $scope.showFeedbackBtn = function(br){
        return ( parseInt(br.utilized_treatments_counter) >= parseInt(br.treatments_num_for_alert) ) && parseInt(br.treatments_num_for_alert)>0;
    }

    $scope.showUpdateRequestBtn = function(req){
        return (req.lpiIdStatusContent.logic_id=='136') && (req.lpiIdRecommendationStatus.logic_id=='198');// && () && ();
    }

    $scope.createSubmission = (id) => {
        Ajax.post({
            module: 'contactehportal',
            url: 'patient/create-contact-submission',
            params: {id: id},
            notShowMessage: true,
            success: function(res) {
                Utils.redirect('/common/form-portal?token=' + res.token, true);
            }
        });
    };
}]);