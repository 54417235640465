'use strict';

app.directive("contenteditable", function() {
	return {
		restrict: "A",
		require: "ngModel",
		link: function(scope, element, attrs, ngModel) {
			function read() {
				ngModel.$setViewValue( element.html() );
			}

			ngModel.$render = function() {
				element.html( $.parseHTML(ngModel.$viewValue) || "" );
			};

			scope.move = function(direction){
				var ele = (function(ele){
					return ele[direction]().find('div').get(0);
				})(element.parent());
				
				if( !ele ){
					return;
				}
				
				if( $(ele).html() ){
					var range	= document.createRange();
					var sel		= window.getSelection();

					range.setStart(ele, 1);
					range.collapse(true);

					sel.removeAllRanges();
					sel.addRange(range);
				}
				
				ele.focus();
			};

			element.bind("blur keyup change", function(event) {
				if(event.which === 13 && attrs.itemindex) {
					scope.move('next');
				}else if(event.which === 13 && !attrs.itemindex){
					scope.$emit('sectionList:add');
				} else if( !ngModel.$viewValue && (event.which == 8 || event.which == 46) ){
					scope.$emit('sectionList:removeItem', attrs.itemindex);
					scope.move('prev');
				}
				
				scope.$apply(read);	
			});
		}
	};
});

app.directive('sectionList', function () {
	return{
		scope: false,
		restrict: 'AE',
		templateUrl: '/js/app/directives/sectionList/tpl.html',
		link: function(scope, elem, attrs) { 
			scope.newItem = null;
			scope.lt = [
				{txt : 'moshe'},
				{txt : 'bender'}
			];

			scope.$on("sectionList:removeItem", function(e, index) {
				if(index){
					scope.lt.splice(index, 1);
				}
			});
			
			scope.$on("sectionList:add", function(e) {
				scope.$apply(function (){
					//scope.$eval(attrs.myEnter);
					scope.lt.push({txt: scope.newItem});
					scope.newItem = null;
				});
			});
			

			elem.bind("keydown keypress", function (event) {
				if(event.which === 13 ) {
					event.preventDefault();
				}
			});
		}
	};
});

