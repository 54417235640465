'use strict';

app.directive('forms', ['$timeout', 'Ajax', 'Utils', 'Popup', function ($timeout, Ajax, Utils, Popup) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: {
            recordid: '@',
            logicid: '@',
            fieldname: '@'
        },
        templateUrl: '/js/app/directives/forms/forms.html',
        link: function(scope, element, attrs) { 
            scope.globals = window.YT;
            
            scope.getForms = function(){
                
                Ajax.get({
                    module: 'common',
                    url: 'form/get-all-form-result-views',
                    params: {objectId: attrs.logicid},
                    success: function(res) {
                        scope.formResultViews = res.formResultViews;
                    }
                });

                Ajax.get({
                    module: 'common',
                    url: 'form/get-open-forms',
                    params: {objectId: attrs.logicid},
                    success: function(res) {
                        scope.forms = res.forms;
                    }
                });
                if (window.location.href.includes('contact')){
                    attrs.fieldname = 'user_id';
                }
                Ajax.get({
                    module: 'common',
                    url: 'form/get-record-submissions',
                    params: {recordId: attrs.recordid, fieldName: attrs.fieldname},
                    success: function(res) {
                        scope.formSubmissions  = res.formSubmissions;
                    }
                });                
            };
            
            scope.init = function(){
                scope.getForms();
            };

            scope.init();

            scope.openAddFormPopup = function(f,fs,$index){
                var data = {};
                data.formSubmission = {};
                Ajax.get({
                    module: 'common',
                    url: 'form/get-form-fields', 
                    params: {
                        id: f ? f.id : fs.form.id,
                        formSubmissionId: fs ? fs.id : null
                    },
                    success: function(res) {
                        data = res.form;
                        data.formSubmission = fs ? fs : {};
                        data.titles = res.titles;
                        scope.$on('formSubmission:submited', function(e, res){
                            scope.getForms();
                        });
                        data.fieldname = attrs.fieldname;
                        Popup.open('common/formInternal', null, data, {}, {}, 'static');
                    }
                });
                    
            };
            
            scope.openViewFormPopup = function(fs,$index) {
                var data = {};
                
                Ajax.get({
                    module: 'common',
                    url: 'form/get-form-answer', 
                    params: {
                        formId: fs.form.id,
                        recordId: Utils.GET().id,
                        fieldName: attrs.fieldname
                    },
                    success: function(res) {
                        data.form                   = res.form;
                        data.titles                 = res.titles;
                        data.submissions            = res.submissions;
                        data.categories             = res.categories;
                        data.currentSubmissionId    = fs.id;
                        Popup.open('common/formView', null, data, {});
                    }
                });
            };
        
            scope.copyTokenLink = function(fs) {
                var r = document.createRange();
                r.selectNode(document.getElementById("token-link-"+fs.id));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
            };

            scope.goToResultView = function(formResultViewId){
                Utils.redirect('/common/form/form-result-view?recordId='+ attrs.recordid + '&fieldName=' + attrs.fieldname + '&formResultViewId=' + formResultViewId, true);
            };
        }
    };
}]);