'use strict';

app.directive('studentOverView', ['Utils', function (Utils) {
    return {
        restrict: 'AEC',
        scope: { data: '=' },
        transclude: false,
        templateUrl: '/js/app/partials/studentOverViewTpl.html',
        link: function(scope, element, attrs) { 
            scope.Utils = Utils;
        }
    };
}]);