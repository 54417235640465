'use strict';

app.controller('MainPortalController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', 'Xnavigation' ,
	function($scope, $http, $filter, $modal, Ajax, Utils, Xnavigation) { 
                
                Xnavigation.run();
                
                    
        angular.element(document).ready(function () {  
			if (window.location.hostname == 'crm.ytoronto.org'){
				var url = '/ytcrm/default/index';
				Ajax.post({
					url: 'main-portal/set-project',
					notShowMessage: true,
					params: {projectId: 12},
					notValidateForm: true,
					success: function(res){
						window.open(url, '');
					}
				});
			};
        });
                
                
		$scope.goToProject = function(projectId){
			if(!projectId){
				return;
			}
			
			var url;
			if(projectId == 1){
				url = '/academy/default/index';
			}else if(projectId == 5 || projectId == 10){
				url = '/elhanefesh/default/index';
			}else if(projectId == 11){
				url = '/dafchadash/default/index';
			}else if(projectId == 12){
				url = '/ytcrm/default/index';
			}else if(projectId == 14){
				url = '/kidum/default/index';
			}else if (projectId == 13){
				url = '/general/default/index';
			}else if (projectId == 15){
				url = '/cr21/default/index';
			}else if (projectId == 16){
				url = '/mossad/default/index';
			}else if (projectId == 17){
				url = '/singleparent/default/index';
			}
			
			Ajax.post({
				url: 'main-portal/set-project',
                notShowMessage: true,
				params: {projectId: projectId},
				notValidateForm: true,
				success: function(res){
					window.open(url, '_blank');
				}
			});
		};
	
}]);