'use strict';

var ngRefresh = function() {
    var scope = angular.element('body').scope();
    var compile = angular.element('body').injector().get('$compile');

    compile($("body").contents())(scope); 
    scope.$apply();
};


$(document).on('pjax:complete', function() {
    ngRefresh();
});  


function x_navigation_minimize(action){
    
    if(action == 'open'){
        $(".page-container").removeClass("page-container-wide");
        $(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
        $(".x-navigation-minimize").find(".fa").removeClass("fa-indent").addClass("fa-dedent");
        $(".page-sidebar.scroll").mCustomScrollbar("update");
    }
    
    if(action == 'close'){
        $(".page-container").addClass("page-container-wide");
        $(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
        $(".x-navigation-minimize").find(".fa").removeClass("fa-dedent").addClass("fa-indent");
        $(".page-sidebar.scroll").mCustomScrollbar("disable",true);
    }
    
    $(".x-navigation li.active").removeClass("active");
    
}

function x_navigation_general(){
    
    $("html").on("click", function(){
        if(html_click_avail)
            $(".x-navigation-horizontal li,.x-navigation-minimized li,.x-features .x-features-search,.x-features .x-features-profile").removeClass('active');                
    });        
    
    $(".x-features-nav-open").on("click",function(e){
        $(".x-hnavigation").toggleClass("active");
    });
    
    $(".x-hnavigation .xn-openable > a").on("click",function(e){
        if($(this).parent("li").hasClass("active")){
           $(this).parent("li").removeClass("active");
        }else{
            $(".x-hnavigation .xn-openable").removeClass("active");
            $(this).parents("li").addClass("active");        
        }
    });
}

var app = angular.module('myApp', ['ui.bootstrap', 'ngAnimate', 'ngDraggable', 'angular.morris-chart', 'ngSanitize', 'angular-bind-html-compile'])
.config(['$httpProvider', '$modalProvider', '$controllerProvider', '$compileProvider', '$filterProvider', '$provide', 
	function($httpProvider, $modalProvider, $controllerProvider, $compileProvider, $filterProvider, $provide) {
        
    $httpProvider.defaults.headers.post['Content-Type']         = 'application/x-www-form-urlencoded';
    $httpProvider.defaults.headers.common["X-Requested-With"]   = 'XMLHttpRequest';

    app.controller  = $controllerProvider.register;
    app.directive   = $compileProvider.directive;
    app.filter      = $filterProvider.register;
    app.factory     = $provide.factory;
    app.service     = $provide.service;
    app.constant    = $provide.constant;
    app.value       = $provide.value;
    
}])
.run(['$rootScope', '$compile', '$filter', 'Utils', function($rootScope, $compile, $filter, Utils) { 
    
    $rootScope.globals = window.YT;
	$rootScope.checkAllListId = null;
	
	$rootScope.setGridCheck = function(all){
		$rootScope.checkAllListId =  all ? Utils.GET().id : null;
	};
	
    $rootScope.getNameById = function(picklist, id){
        if(picklist && id){
            return $filter('filter')(picklist, {id: id})[0].name;
        }
        return '';
    };
    
    $rootScope.getNameHeById = function(picklist, id){
        if(picklist && id){
            return $filter('filter')(picklist, {id: id})[0].name_he;
        }
        
        return '';
    };
}]);



