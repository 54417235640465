'use strict';

app.directive('addActivityConnection', ['$timeout', 'Ajax', 'Utils', '$rootScope', '$filter', function ($timeout, Ajax, Utils, $rootScope, $filter) {
    return {
        restrict: 'AEC',
        replace: true,
        scope: false,
        templateUrl: '/js/app/directives/addActivityConnection/tpl.html',
        link: function(scope, element, attrs) {
            //scope.activityId            = null;
            
            scope.current_user_id       = YT.user.user_id;
            scope.newRecordId           = null;
            scope.newObjectLogicId      = null;
            scope.exceptid              = attrs.exceptid.split(',');
            scope.$watch('newObjectLogicId', function(newValue, oldValue) {
                if(newValue && newValue != oldValue){
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = newValue;
                }else{
                    scope.newRecordId       = null;
                    scope.newObjectLogicId  = null;
                }
            });
            
            scope.initAddConnection = function(){ 
                Ajax.get({
                    module: 'common',
                    url: 'activity/get-objects',
                    params: {},
                    success: function(res){
                        scope.objects   = res.objects;
                    }
                });
            };
            
            scope.initAddConnection();
            
            scope.showObject = function(id){
                var res = $filter('filter')(scope.exceptid, id);
            
                if (res.length > 0) {
                    return false;
                }
            
                return true;
            };
            
            scope.addNewActivityConnection = function(newRecordId, newObjectLogicId) {
                if (!newRecordId || !newObjectLogicId) {
                    alert('על מנת להוסיף קישור למשימה, עליך לבחור אובייקט ויעד לקישור')
                    return;
                }
                
                Ajax.post({
                    module: 'common',
                    url:    'activity/add-new-connection',
                    params: {id: attrs.activityid, recordId: newRecordId, logicId: newObjectLogicId},
                    data:   scope.fileData,
                    success: function(res){
                        scope.showaddactivityconnection = false;
                        scope.getEventDetailsById(scope.data);
                        
                        scope.$on('parent:getEventDetailsById', function(event, res) {
                            scope.data = res;
                            scope.data.summary = res.activity.content;
                        });
                    }
                });
                scope.newRecordId         = null;
                scope.newObjectLogicId    = null
            };
        }
    };
}]);