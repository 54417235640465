'use strict';

app.controller('cr21UserController', ['$rootScope', '$scope', 'Ajax', '$filter', 'Utils', 'Popup', function($rootScope, $scope, Ajax, $filter, Utils, Popup) {
    $scope.tagsArr              = [];
    $scope.organizationList     = [];
    $scope.contactsList         = [];
    $scope.organizationList     = [];
    $scope.data                 = {};
    $scope.ihptServices         = {};
        
    $scope.$on('cr21User:getSuccess', function(e, mod, res){
        $scope.data = res.data;
        
        $scope.getProgramList();
        $scope.getOrders();
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getOrders = () => {
        Ajax.get({
            module: 'cr21',
            url: 'order/get-record-orders',
            params: {id: Utils.GET().id, fieldName: 'customer'},
            success: function(res) { 
                $scope.data.orders = res.orders;
            }
        });
    }

    $scope.getOrganizationList = function(){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-organization', 
            success: function(res) { 
                $scope.organizationList = res;
            }
        });
    };
    
    $scope.getProgramList = function(){
        if (!$scope.data.ihpt_client_id){
            return;
        }
        Ajax.get({
            module: 'common',
            url: 'picklist/get-program-list-for-current-ihpt', 
            params: {ihptClientId: $scope.data.ihpt_client_id},
            success: function(res) { 
                $scope.programList = res;
            }
        });
    };
        
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/cr21/cr21-user/view?id=' + res.data.id);
        //}
    });
	
    $scope.getAge = function(d){
        return Utils.getAge(d);
    };
}]);