'use strict';

app.controller('KdmFamilyController', ['$rootScope', '$scope', 'Ajax', '$filter', 'Utils', 'Popup', function($rootScope, $scope, Ajax, $filter, Utils, Popup) {
    $scope.tagsArr              = [];
    $scope.organizationList     = [];
    $scope.contactsList         = [];
    $scope.organizationList     = [];
    $scope.data                 = {};
    $scope.ihptServices         = {};
        
    $scope.$on('cru:getSuccess', function(e, mod, res){
        $scope.getOrganizationList();
        $scope.getProgramList();
        if (mod == 'edit'){
            $scope.getFamilyBudgetRequests();
			if($scope.data.userMoreDetails){
				$scope.getContactsList($scope.data.userMoreDetails.organization_id);
			}
            if($scope.data.userMoreDetails.process_start_date) {
                let dateArray = $scope.data.userMoreDetails.process_start_date.split('-');
                let oneMonth = 24*60*60*1000*30; // hours*minutes*seconds*milliseconds
                let firstDate = new Date(dateArray[0],dateArray[1],dateArray[2]);
                let currentDate = new Date();
                $scope.number_of_month = Math.round(Math.abs((firstDate.getTime() - currentDate.getTime())/(oneMonth)));
            }
        }
        
        $scope.tagsToArr();
    });
    
    $scope.$on('family:getSuccess', function(e, mod, res){
        $scope.getFamilyBudgetRequests();
        $scope.data = res.data;
        res.leads.forEach( (lead) => {
            if (lead.more_details) {
                lead.moreDetailsArray = JSON.parse(lead.more_details);
            }
        });
        $scope.data.leads = res.leads;
        $scope.getFamilyContacts();
        $scope.getProgramList();
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_apartement = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.getOrganizationList = function(){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-organization', 
            success: function(res) { 
                $scope.organizationList = res;
            }
        });
    };
    
    $scope.getProgramList = function(){
        if (!$scope.data.ihpt_client_id){
            return;
        }
        Ajax.get({
            module: 'common',
            url: 'picklist/get-program-list-for-current-ihpt', 
            params: {ihptClientId: $scope.data.ihpt_client_id},
            success: function(res) { 
                $scope.programList = res;
            }
        });
    };
       
    $scope.getContactsList = function(orgId){
        Ajax.get({
            module: 'common',
            url: 'picklist/get-contact-by-org-id', 
            params: {orgId: orgId | null},
            success: function(res) { 
                $scope.contactsList = res;
            }
        });
    };
        
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod === 'add'){
            Utils.redirect('/kidum/family/view?id=' + res.data.id);
        //}
    });
	
    $scope.getFamilyBudgetRequests = function(){
        Ajax.get({
            module: 'kidum',
            url: 'family/get-family-budget-requests', 
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.familyBudgetRequests = res.budgetRequests;
                for (var i = 0; i < $scope.familyBudgetRequests.length; i++) {
                    $scope.$watch('familyBudgetRequests[' + i + '].payment_reciver', function (newValue, oldValue) {
                        if (oldValue && newValue && newValue != oldValue){
                            //let currentBr = $filter('filter')($scope.familyBudgetRequests, {id: newValue.id})[0];
                            if (newValue == 'other'){
                                let currentIndex = this.exp.substring(
                                    this.exp.lastIndexOf("[") + 1, 
                                    this.exp.lastIndexOf("]")
                                );
                                $scope.familyBudgetRequests[currentIndex].bank_id                    = null;
                                $scope.familyBudgetRequests[currentIndex].bank_branch                = null;
                                $scope.familyBudgetRequests[currentIndex].bank_account_number        = null;
                                $scope.familyBudgetRequests[currentIndex].bank_account_name          = null;
                                $scope.familyBudgetRequests[currentIndex].bank_account_owner_proof   = null;
                            }
                        }
                    }, true);
                }
            }
        });
    };

    $scope.getFamilyContacts = function(){
        Ajax.get({
            module: 'kidum',
            url: 'family/get-family-contacts', 
            params: {id: Utils.GET().id},
            success: function(res) {
                $scope.familyContactsList = res.familyContactsList;
            }
        });
    };
    
    $scope.saveContact = function(data, $modalInstance){
        Ajax.post({
            module: 'kidum',
            url: 'family/save-contact', 
            params: {familyId: Utils.GET().id},
            data: data,
            success: function(res) { 
                $scope.getFamilyContacts();
                $modalInstance.close();
            }
        });
    };
    
    $scope.removeContact = function($id){
		Ajax.post({
			module: 'kidum',
            url: 'family/remove-contact',
            params: {id: $id},
            success: function(res){
				$scope.getFamilyContacts();
                $rootScope.$broadcast("popup:close", res);
            },
            successMessage: 'הרשומה נמחקה בהצלחה'
        });
	};
    
    $scope.openContactPopup = function(fc, contactTypeLogicId){
        var fn = [];
        fn = {
            removeContact:     $scope.removeContact
        };
        if(!fc && contactTypeLogicId){
            fc = {};
            Ajax.post({
                module: 'common',
                url: 'picklist/get-item-id-by-logic-id', 
                params: {logicId: contactTypeLogicId},
                notShowMessage: true,
                success: function(res) { 
                    fc.lpi_id_contact_type = res.data;
                    Popup.open('kidum/addFamilyContact', $scope.saveContact, fc);
                }
            });
        }else{
            var fn = [];
            fn = {
                removeContact:     $scope.removeContact
            };
            Popup.open('kidum/addFamilyContact', $scope.saveContact, fc, fn);
        }
    };
    
    $scope.calculateTotalInc = function(data){
        
        data.salary_amount = (
            parseInt(data.main_work_salary | 0)         +
            parseInt(data.addition_work_salary | 0)
        );
        
        data.total_income = (
            parseInt(data.salary_amount | 0)            +
            parseInt(data.inc_btl_children | 0)         +
            parseInt(data.inc_btl_havtachat | 0)        +
            parseInt(data.inc_btl_hashlamat | 0)        +
            parseInt(data.inc_btl_mezonot | 0)          +
            parseInt(data.inc_btl_sherim | 0)           +
            parseInt(data.inc_btl_avtala | 0)           +
            parseInt(data.inc_btl_special_needs | 0)    +
            parseInt(data.inc_btl_nayadut | 0)          +
            parseInt(data.inc_btl_other | 0)
        );
    }
    
    $scope.calculateTotalOutcome = function(data){
        data.total_outcome = (
            parseInt(data.outcome_rent | 0)             +
            parseInt(data.outcome_other | 0)
        );
    }
    
    
    $scope.getAge = function(d){
        return Utils.getAge(d);
    };
    
    $scope.saveNewBudgetRequest = function(prog){
        Ajax.post({
            module: 'kidum',
            url: 'family/save-new-budget-request', 
            params: {familyId: Utils.GET().id, progId: prog.id},
            success: function(res) { 
                $scope.newBudgetRequest = res.newBudgetRequest;
                $scope.getFamilyBudgetRequests();
                $scope.$broadcast('family:budgetRequestsUpdate');
            }
        });
    }
    
    $scope.saveKidumBudgetRequest = function(br,$index){
        Ajax.post({
            module: 'kidum',
            url: 'family/save-budget-request', 
            data: br,
            success: function(res) { 
                $scope.getBudgetRequestById($index);
            },
            error: function(data) {
                alert (data);
            }
        });
    }
    
    $scope.getBudgetRequestById = function($index){
        Ajax.post({
            module: 'kidum',
            url: 'family/get-budget-request-by-id', 
            params: {id: $scope.familyBudgetRequests[$index].id},
            success: function(res) { 
                $scope.familyBudgetRequests[$index] = res.budgetRequest;
            }
        });
    };
    
    $scope.savePayment = function(payment,$pIndex,$brIndex){
        Ajax.post({
            module: 'kidum',
            url: 'family/save-payment', 
            data: payment,
            success: function(res) { 
                $scope.getBudgetRequestById($brIndex);
            },
            error: function(res){
                alert('אירעה שגיאה בשמירת התשלום בדוק שלא חרגת מהסכום הכולל של התקציב ');
                $scope.getBudgetRequestById($brIndex);
            }
        });
    }
    
    $scope.addPayment = function($index) {
        $scope.familyBudgetRequests[$index].payments.push({
            paymentEditMode: true,
            budget_request_id: $scope.familyBudgetRequests[$index].id,
            payment_reciver: $scope.familyBudgetRequests[$index].payment_reciver,
        });
    };

    $scope.cancelBudgetRequest = function($index) {
        Ajax.post({
            module: 'kidum',
            url: 'family/cancel-budget-request', 
            params: {id: $scope.familyBudgetRequests[$index].id},
            success: function(res) { 
                $scope.getBudgetRequestById($index);
            }
        });
    };

    
}]);