'use strict';

app.controller('ActivityTemplateController', ['$scope', '$rootScope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $rootScope, $filter, Ajax, Utils, Popup) {
    $scope.data = {};
    $scope.selectedTarget = null;
    $scope.activityProjectTypes = [];

    $scope.init = function() {
        Ajax.get({
            module: 'adminsettings',
            url:  'settings/data-list', 
            params: {id: null, name: 'MilestoneTemplates', projectId: YT.user.project_id },
            success: function(res) {
                $scope.data = res.values;
                if ($scope.data.length && !$scope.selectedTarget){
                    $scope.setSelectedTarget($scope.data[0]);
                }
                $scope.pushNewEmptyTask();
                $scope.description = res.description;
                $scope.project = res.project;
                $scope.activityPicklists = res.activityPicklists;
            }
        });

        Ajax.get({
            module: 'adminsettings',
            url: 'activity-project-type/get-activity-project-types',
            success: function(res){
                $scope.activityProjectTypes = res.activityProjectTypes;
            },
        });
    };
    
    $scope.init();

    $scope.pushNewEmptyTask = function(){
        angular.forEach($scope.data, function(target) {
            angular.forEach(target.milestones, function(milestone) {
                if (!milestone.tasks){
                    milestone.tasks = [];
                }
                milestone.tasks.push({
                    id: 'new',
                    lpi_id_type: null,
                    userId: null,
                    subject: null,
                    content: null,
                    dueDate: null,
                    done: 0,
                    executing_source: 'internal',
                    progress_value: null, 
                    order: milestone.tasks.length   
                });        
            });
        });
    };

    $scope.save = function(inside){

        angular.forEach($scope.data, function(target) {
            var milestoneIndex = 0;
            angular.forEach(target.milestones, function(milestone) {
                milestone.order = milestoneIndex;
                milestoneIndex++;
                if (milestone.tasks){
                    var taskIndex = 0;
                    milestone.tasks.forEach(function (item, index, object) {
                        if (item.id ){
                            object.splice(index, 1);
                        }
                        item.order = taskIndex;
                        taskIndex++;
                    });
                }
            });
        });
        Ajax.post({
            module: 'adminsettings',
            url:  'settings/edit-data-list', 
            params: {id: null, name: 'MilestoneTemplates', projectId: YT.user.project_id },
            data: {dataList: $scope.data, description: $scope.description},
            success: function(res) {
                $scope.pushNewEmptyTask();
                $rootScope.$broadcast("popup:close", res);
                
            }
        });
    };

    $scope.openMilestonePopup = function(target,milestone){
        
        if (!target.milestones){
            target.milestones = [];
        }

        if (!milestone){
            milestone = {};
            milestone.tasks = [];
            target.milestones.push(milestone);
        }
        var fn = {
            // addActivitiesToMilestone: $scope.addActivitiesToMilestone
        };
        milestone.projectName = $scope.project.name;

        milestone.activityProjectType = $scope.activityProjectTypes.filter((t) => {
            return t.id == $scope.selectedTarget.activity_project_type_id;
        })[0];

        Ajax.get({
            module: 'common',
            url: 'form/get-open-forms',
            params: {objectId: null, availableInActivities: 1},
            success: function(res) {
                milestone.forms= res.forms;
                Popup.open('common/activityMilestone', $scope.save, milestone, fn, {}, 'static');
            }
        });
        
    };

    $scope.keyUp = function($event,milestone,data){
        if ($event.which == 13){ // only if "Enter" key was pressed
            $scope.saveActivity(milestone,data);
        }
    }

    $scope.saveActivity = function(milestone,activity){
        if (activity.id){
            delete activity.id;
        }
        $scope.save();
    }

    $scope.openActivityPopup = function(data){
        var fn = {};
        data.activityPicklists = $scope.activityPicklists;
        Popup.open('adminsettings/activityJson', $scope.saveActivity, data, fn, {}, 'static');
    };
    
    $scope.setSelectedTarget = function(target){
        $scope.selectedTarget = target;
    }

    $scope.addTarget = function(data){
        if (!$scope.data){
            $scope.data = [];
        }
        $scope.data.push({name: data.name, activity_project_type_id: data.activity_project_type_id});
        $scope.setSelectedTarget($scope.data[$scope.data.length-1]);
        $scope.save();
    }

    $scope.copyTemplate = () => {
        let newTemplate = angular.copy($scope.selectedTarget);
        newTemplate.name = 'copy of ' + newTemplate.name;
        $scope.data.push(newTemplate);
        $scope.setSelectedTarget($scope.data[$scope.data.length-1]);
        $scope.save();
    };

    $scope.openAddTargetPopup = function(){
        var data = {};
        data.activityProjectTypes = $scope.activityProjectTypes;
        $scope.modalInstance = Popup.open('adminsettings/addTarget', $scope.addTarget, data, {});
    };

    $scope.removeActivity = function($index,tasks){
        tasks.splice($index,1);
    };

    $scope.removeMilestone = function($index,tasks){
        $scope.selectedTarget.milestones.splice($index,1);
    };

    $scope.removeTemplate = function($index){
        $scope.data.splice($index,1);
    };

    $scope.cloneTemplate = ($index) => {
        
    };

    $scope.onDropTaskComplete = function(index, obj, evt, m){
        var milestoneIndex  = $scope.selectedTarget.milestones.indexOf(m);
        var tasks = $scope.selectedTarget.milestones[milestoneIndex].tasks;
        var otherObj    = tasks[index];
        var otherIndex  = tasks.indexOf(obj);
        
        if (otherIndex < 0){
            return;
        }

        if (otherIndex < index){
            tasks.splice(index+1, 0, obj);
            tasks.splice(otherIndex, 1);
        }else{
            tasks.splice(index, 0, obj);
            tasks.splice(otherIndex+1, 1);
        }
    };

    $scope.onDropMilestoneComplete = function(index, obj, evt ){
        var milestones = $scope.selectedTarget.milestones;
        var otherObj    = milestones[index];
        var otherIndex  = milestones.indexOf(obj);
        
        if (otherIndex < 0){
            return;
        }

        if (otherIndex < index){
            milestones.splice(index+1, 0, obj);
            milestones.splice(otherIndex, 1);
        }else{
            milestones.splice(index, 0, obj);
            milestones.splice(otherIndex+1, 1);
        }
    };
}]);