'use strict';

app.controller('ActivityProjectController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', '$rootScope', '$window',
    function($scope, $http, $filter, $modal, Ajax, Utils, $rootScope, $window) { 
    
    $scope.activityProjects = {};
    
       
    $scope.init = function(firstTime){
        Ajax.get({
            module: 'common',
            url: 'activity-milestone/get-all-activity-projects',
            success: function(res){
                $scope.activityProjects = res.activityProjects;
            },
        });
    };
    
    $scope.init();
    
    
}]);