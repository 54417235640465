'use strict';

app.directive('digitalTherapeuticPlan', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', 'Popup', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce, Popup) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
            mode: '=mode'
        },
        templateUrl: '/js/app/directives/digitalTherapeuticPlan/digitalTherapeuticPlan.html',
        link: function(scope, element, attrs) {
            scope.failedToSave = false;
            scope.noGoals = false;

            scope.init = function() {
                $timeout(function(){
                    scope.data = angular.fromJson(scope.data);
                    Ajax.get({
                        module: 'common',
                        url: 'request/get-symptom-categories', 
                        success: function(res) { 
                            scope.symptomCategories = res.symptomCategories;
                        }
                    });
                    Ajax.get({
                        module: 'common',
                        url: 'request/get-symptoms', 
                        params: {reqId: scope.data.id},
                        success: function(res) { 
                            scope.data.symptoms = res.symptoms;
                        }
                    });
                },100);
                
            }
            
            scope.init();

            scope.saveTherapeuticPlan = function(data, $modalInstance){
                if (scope.mode=='view') {
                    $modalInstance.close();
                    return;
                }

                scope.failedToSave = false;
                
                $('#planSaveBtn').prop('disabled', true);
                
                $rootScope.$on('ajax:notValid', function(e,data){
                    $('#planSaveBtn').prop('disabled', false); //  if form not valid enable button again
                    scope.failedToSave = true;
                });
        
                if (!scope.validateData(data)){
                    $('#planSaveBtn').prop('disabled', false);
                    return;
                }
        
                Ajax.post({
                    module: 'common',
                    url: 'request/save-therapeutic-plan', 
                    data: data,
                    success: function(res) {
                        data = res.data;
                        //scope.getRequestDetails();
                        $rootScope.$broadcast("digitalData:saved");
                        $modalInstance.close();
                    }
                });
            };
        
            scope.validateData = (data) => {
                var isValidate = true;
                $( ".mandatory__field" ).each(function( index ) {
                    let inputValue = undefined;
                    if ($( this ).find('radio-buttons').length > 0){
                        inputValue = $( this ).find('radio-buttons').find('input[type="radio"]:checked').val();
                    }else if($( this ).find('textarea').length > 0) {
                        inputValue = $( this ).find('textarea').val();
                    }else if($( this ).find('input[type="text"]').length > 0){
                        inputValue = $( this ).find('input').val();
                    }else if($( this ).find('input[type="checkbox"]').length > 0){
                        $( this ).find('input[type="checkbox"]').each(function( index ) {
                            if ($( this )[0].checked){
                                inputValue = true;
                                return;
                            }
                        });
                    }
                    
                    
                    if (inputValue==undefined || inputValue==''){
                        if ($(this).siblings('.card__item__label').length > 0) {
                            $(this).siblings('.card__item__label').addClass( "failed" );
                        }else if($(this).siblings('.symptom_label').length > 0){
                            $(this).siblings('.symptom_label').addClass( "failed" );
                        }
                        isValidate = false;
                    }else{
                        if ($(this).siblings('.card__item__label').length > 0) {
                            $(this).siblings('.card__item__label').removeClass( "failed" );
                        }else if($(this).siblings('.symptom_label').length > 0){
                            $(this).siblings('.symptom_label').removeClass( "failed" );
                        }
                    }
                });
                scope.failedToSave = !isValidate;
                scope.noGoals = data.goals.length == 0;
                return isValidate && !scope.noGoals;
            };
        
            scope.addSymptom = function(req){
                req.newSymptom.request_id = req.id;
                req.symptoms.push(req.newSymptom);
                req.newSymptom = {};
            };
        
            scope.addGoal = function(req){
                req.goals.push({
                    request_id: req.id,
                    goal_details: req.newGoal.goal_details,
                    source: 'תוכנית טיפולית',
                });
                req.newGoal = {};
            };
        
            scope.deleteGoal = function($index,data){
                if (data.goals[$index].id){
                    data.deletedGoals.push(data.goals[$index]);
                }
                data.goals.splice($index,1);
            };
        
            scope.OpenTherapeuticPlanPopup = function(req){
                var data = req;
                data.deletedGoals     = [];
                data.deletedSymptoms  = [];
                
                data.symptomLevel = [
                    {id: '0', name: 'אין קושי'},
                    {id: '1', name: 'נמוך'},
                    {id: '2', name: 'בינוני'},
                    {id: '3', name: 'גבוה'},
                    {id: '4', name: 'גבוה מאוד'},
                ]
        
                data.futureTreatmentTypes = [];
                data.futureTreatmentTypes.push(
                    {ischecked: 'false', name: 'אבחון פסיכו-דידקטי'},
                    {ischecked: 'false', name: 'אבחון בריאותי'},
                    {ischecked: 'false', name: 'אבחון נוירולוגי'},
                    {ischecked: 'false', name: 'הוראה מתקנת'},
                    {ischecked: 'false', name: 'אבחון פסיכיאטרי'},
                    {ischecked: 'false', name: 'תרפיה'},
                    {ischecked: 'false', name: 'לא נדרשת התערבות נוספת'},
                    {ischecked: 'false', name: 'אחר', other: '1'},
                );

                data.personalSymptoms = [];
                data.personalSymptoms.push(
                    {ischecked: 'false', name: 'כעסים'},
                    {ischecked: 'false', name: 'חרדות'},
                    {ischecked: 'false', name: 'עצבות'},
                    {ischecked: 'false', name: 'תסכול'},
                    {ischecked: 'false', name: 'קושי במתן אמון'},
                    {ischecked: 'false', name: 'אילמות סלקטיבית'},
                    {ischecked: 'false', name: 'ניתוקים'},
                    {ischecked: 'false', name: 'פגיעה עצמית'},
                    {ischecked: 'false', name: 'הפרעות אכילה'},
                    {ischecked: 'false', name: 'חוסר שליטה ביציאות'},
                    {ischecked: 'false', name: 'הרטבת לילה'},
                    {ischecked: 'false', name: 'קשיי שינה'},
                    {ischecked: 'false', name: 'גמגום'},
                    {ischecked: 'false', name: 'כפייתיות'},
                );
                
                if (data.requestMoreDetails && data.requestMoreDetails.future_treatment_types) {
                    var tempArray = data.requestMoreDetails.future_treatment_types.split("|");
                    angular.forEach(data.futureTreatmentTypes, function(item, key) {
                        item.ischecked = 'false';
                        if( tempArray.includes(item.name) ) { 
                            item.ischecked = 'true';
                        }
                    });
                }
                
                data.symptomCategory = scope.symptomCategories;
                if (data.symptoms.length == 0){
                    data.symptoms = [];
                    data.symptoms.push(
                        // קשיים ביחסיו עם דמויות סמכות ובלמידה
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'התנגדות לכללי המסגרת הלימודית', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'חוסר הצלחה בלימוד מסיבות רגשיות', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[0]['id'], description: 'קשר לקוי עם ההורים', request_id: data.id, level: null},
                        // קשיים ביחסיו עם קבוצת השווים
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'התנהגות אלימה בחברה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'מופנמות יתר בחברה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'יצירת קשרים לא בריאים', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[1]['id'], description: 'דחיה על ידי החברה', request_id: data.id, level: null},
                        // קשיים אישיים
                        // {lpi_id_type: data.symptomCategory[2]['id'], description: 'מחשבות הפוגעות בתפקוד', request_id: data.id, level: null},
                        // {lpi_id_type: data.symptomCategory[2]['id'], description: 'תסמינים הפוגעים בתפקוד (קושי להירדם, הרטבות לילה, פחדים מוגזמים, גמגום)', request_id: data.id, level: null},
                        // {lpi_id_type: data.symptomCategory[2]['id'], description: 'התנהגויות הפוגעות בתפקוד (התנהגות כפייתית, פגיעה עצמית, הפרעות אכילה)', request_id: data.id, level: null},
                        // קשיים לימודיים
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בקריאה וכתיבה', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בהישגים לימודיים', request_id: data.id, level: null},
                        {lpi_id_type: data.symptomCategory[3]['id'], description: 'קושי בכישרון לימודי', request_id: data.id, level: null},
                        // אחר
                    );
                    
                }
                if (!data.goals){
                    data.goals = [];
                }
                
                var fn = {
                    addSymptom: scope.addSymptom,
                    addGoal: scope.addGoal,
                    deleteGoal: scope.deleteGoal,
                    isFailedToSave: scope.isFailedToSave,
                    isNoGoals: scope.isNoGoals,
                    updatePlan: scope.updatePlan,
                };
                if (scope.mode == 'view'){
                    if (data.requestMoreDetails.submited_time) {
                        data.requestMoreDetails.submited_time = scope.getJSFormatDate(data.requestMoreDetails.submited_time);
                    }
                    data.yesNoOptions = ['לא', 'כן', 'לא רלוונטי', 'חלקית'];
                    Popup.open('common/therapeuticPlanView', scope.saveTherapeuticPlan, data, fn, {});
                }else{
                    Popup.open('common/therapeuticPlan', scope.saveTherapeuticPlan, data, fn, {}, 'static');
                }
            };
        
            scope.updatePlan = function(data){
                var popupData = data;
                Ajax.post({
                    module: 'common',
                    url: 'request/auto-therapeutic-plan-saving', 
                    data: data,
                    notShowMessage: true,
                    success: function(res) {
                        for (var i = 0; i < popupData.goals.length; i++){
                            if (!popupData.goals[i].id){
                                popupData.goals[i] = res.data.goals[i];
                            }
                        }
                        popupData.id = res.data.id;
                    }
                });
            }

            scope.saveOnSpace = function($event,data){
                if ($event.which == 32){ // only if "Space" key was pressed
                    scope.updatePlan(data);
                }
            }

            scope.isFailedToSave = () => {
                return scope.failedToSave;
            };
        
            scope.isNoGoals = () => {
                return scope.noGoals;
            };

            scope.getJSFormatDate = function(d) {
                console.log('getJSFormatDate');
                return new Date(d);
            }
        }
    };
}]);