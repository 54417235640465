'use strict';

app.directive('msdOrganizationCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl:'/js/app/directives/msdOrganizationCard/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
            
            scope.init = function(){
                Ajax.get({
                    //module: 'mossad',
                    url: 'organization/get-organization-details',
                    params: {id: scope.objectid},
                    success: function(res) { 
                        scope.data = res.data;
                        //scope.$emit('cr21User:getSuccess', 'view', res);
                    }
                });
            };
            scope.init();
            
            
            
            scope.openMailPopup = function(br){
                // if (!br){
                //     mailMerge.openMailPopup(scope.data, 102); // 102 = cr21 user logic id
                // }
            };
		}
    };
}]);
