'use strict';

app.controller('YtcrmProgramOrgController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    $scope.tagsArr = [];
    $scope.communityTags = [];
    $scope.data = {};    
    
    $scope.$on('organization:getSuccess', function(e, mod, res){
        if (mod == 'add') {
            $scope.data.organization_id = Utils.GET().organization_id;
        }
        $scope.getProgramOrgDetais();
    });
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (mod == 'add') {
            $scope.data.organization_id = Utils.GET().organization_id;
        }
        
    });

    $scope.$on('cru:postSuccess', function(e, mod, res){
        Utils.redirect('/ytcrm/program-org/view?id=' + res.data.id);
    });

    $scope.getProgramOrgDetais = function(){
        Ajax.get({
            module: 'ytcrm',
            url: 'program-org/get-program-org-details',
            params: {id: Utils.GET().id},
            success: function(res) { 
                $scope.data = res.data;
            }
        });
    };

    $scope.saveProgramOrgPeriod = function(data, $modalInstance) {
        data.program_org_id = Utils.GET().id;
        Ajax.post({
            module: 'ytcrm',
            url: 'program-org/save-program-org-period', 
            data: data,
            success: function(res) { 
                $scope.getProgramOrgDetais();
                $modalInstance.close();
            }
        });
    };

    $scope.openProgramOrgPeriodPopup = function(data){
        var data = data ? data : {};
        Popup.open('ytcrm/addProgramOrgPeriod', $scope.saveProgramOrgPeriod, data, {});
    };
}]);