'use strict';

app.controller('cr21LeadController', ['$scope', '$filter', 'Ajax', 'Utils', 'mailMerge', 'Popup', function($scope, $filter, Ajax, Utils, mailMerge, Popup){
    $scope.profileSrc       =  '/img/profile.png';
    $scope.avilableAction   = false;
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (!res.data){
            return;
        }
        $scope.existsUsers = res.existsUsers;
        $scope.existsLeads = res.existsLeads;
        
        var status = $scope.data.lpi_id_status;
        
        if(status == 67 || status == 134 || status == 135){
            $scope.avilableAction = true;
        }else{
            $scope.avilableAction = false;
        }
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(window.location.href.includes('create')){
            Utils.redirect('/cr21/lead/update?id=' + res.data.id);
        }
    });
    
    $scope.linkAccount = function(user_id){
        Ajax.post({
            url:     'lead/link-account',
            params: {id: Utils.GET().id, user_id: user_id},
            success: function(res){
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.createAccount = function(/*data, $modalInstance*/){
        $scope.data.userMoreDetails = {
            email: $scope.data.email 
        };
        
//        $scope.data.sendWellcomeMail        = data.sendWellcomeMail;
//        $scope.data.createFileConnections   = data.createFileConnections;
//        $scope.data.createRequest           = data.createRequest;
        $scope.data.lpi_id_status = '69';
        
        Ajax.post({
            module: 'cr21',
            url:     'cr21-user/add-cr21-user',
            data:    $scope.data,
            params: {id: Utils.GET().id},
            success: function(res1){
                Ajax.post({
                    url: 'lead/save-lead',
                    params: {id: Utils.GET().id},
                    data: {lpi_id_status: 69, user_id: res1.data.id},
                    notShowMessage: true,
                    success: function(res2){
                        $scope.data     = res2.data;
                        $scope.editData = angular.copy($scope.data);
                        
                        $scope.$broadcast("cru:refresh");
                    }
                });
            }
        });
    };
    
    $scope.rejectLead = function(/*data, $modalInstance*/){
        $scope.data.lpi_id_status = 70;// 70 = נדחה
        Ajax.post({
            url: 'lead/save-lead',
            params: {id: Utils.GET().id},
            data:   $scope.data,
            success: function(res){
                Ajax.post({
                    url: 'lead/save-lead',
                    params: {id: Utils.GET().id},
                    data: {lpi_id_status: 70, sendRejectMail: true},
                    success: function(res){
                        $scope.data     = res.data;
                        $scope.editData = angular.copy($scope.data);
                        $scope.$broadcast("cru:refresh");
                    }
                });
            
            }
        });
    };

    $scope.$on("activitiesUserCount:newCount", function(e, res) {
        $scope.userActivitiesCount();
    });
    
    // get student's activities that they are not done, and date past already
    $scope.userActivitiesCount = function(){
        Ajax.get({
            module: 'common',
            url: 'activity/get-activities-count-by-record-id',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.leadActivitiesCount = res.activities_count;
            }
        });
    };
    
    $scope.userActivitiesCount();
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,116); // 116 = lead
    };

    // get lead's activities that they are not done, and date past already
    
}]);