'use strict';

app.directive('timeLineNewItem', ['$timeout', 'Ajax', 'Utils', '$rootScope', 'mailMerge', function ($timeout, Ajax, Utils, $rootScope, mailMerge) {
    return {
        restrict: 'AEC',
        scope: {
            objectlogicid: '=',
            recordid: '@',
            fieldname: '=',
            //forms: '=',
            workplan: '='
        },
        templateUrl: '/js/app/directives/timeLineNewItem/timeLineNewItemTpl.html',
        link: function(scope, element, attrs) {
            scope.data = {};
            scope.picklist = {};
            scope.activity = {};
            scope.recordId = Utils.GET().id;
            scope.setActivityDefault = function(){
                scope.activity = {};
                scope.activity.user_id = YT.user.user_id;
                scope.activity.due_date = new  Date();
            };
            
            scope.init = function(){
                scope.setActivityDefault();
                if (YT.user.project_id == 12 && scope.fieldname == 'organization_id'){
                    Ajax.get({
                        module: 'ytcrm',
                        url:    'organization/get-organization-details',
                        params: {id: scope.recordid},
                        success: function(res){
                            scope.data = res.data;
                        }
                    });
                }
                Ajax.get({
                    module: 'common',
                    url:    'activity/get-activity-picklists',
                    success: function(res){
                        scope.picklist = res.picklists;
                    }
                });
                Ajax.get({
                    module: 'common',
                    url: 'picklist/get-json-lookup',
                    params: {settingName: 'ActivitiesTemplates'},
                    success: function(res){
                        scope.activitiesGroups = res;
                    }
                });
            };
            
            scope.init();
                        
            scope.saveActivity = function(){
                var params = {logicId: scope.objectlogicid, id: null, recordID: Utils.GET().id};
                scope.activity.typeLogicId = 112; // 112 = משימה
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-activity',
                    params: params,
                    data:   scope.activity,
                    success: function(res){
                        $rootScope.$broadcast('item:createdsuccess', res.activity);
                        scope.setActivityDefault();
                    }
                });
            };
            
            scope.saveNoteActivity = function(){
                var params = {logicId: scope.objectlogicid, id: null, recordID: Utils.GET().id};
                Ajax.post({
                    module: 'common',
                    url:    'activity/save-note-activity',
                    params: params,
                    data:   scope.activity,
                    success: function(res){
                        $rootScope.$broadcast('item:createdsuccess', res.activity);
                        scope.setActivityDefault();
                    }
                });
            };
            
            scope.openMailPopup = function(){
                scope.contact = {};
                scope.contact.id = Utils.GET().id;
                mailMerge.openMailPopup(scope.contact, scope.objectlogicid); // scope.objectlogicid = Request logic id
            };
            
            scope.createActivitiesByGroupName = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'activity/create-activities-by-group',
                    params: {groupIndex: $index, recordId: Utils.GET().id, logicId: scope.objectlogicid},
                    success: function(res){
                        $rootScope.$broadcast('activity:groupadded',res);                   
                    },
                    successMessage: 'קבוצת המשימות נוספה בהצלחה'
                });
            };
        }
    };
}]);