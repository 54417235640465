'use strict';

app.controller('AutomationController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.automations = [];
    $scope.automation = {};
    
    // $scope.form.categories = [];
    
    $scope.init = function(){
        
        if (Utils.GET().id){
            // single automation
            Ajax.get({
                url: 'automation/get-automation',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.automation = res.automation;
                    $scope.getLists($scope.automation.object_id);                    
                    Ajax.get({
                        module: 'common',
                        url: 'mail/get-fields-label-map',
                        notShowMessage: true,
                        params: {logicId: $scope.automation.object_id},
                        success: function(res) {
                            $scope.fieldsMaping =  res.data;
                        }
                    });
                },
            });
        }else{
            // all automations
            Ajax.get({
                url: 'automation/get-automations',
                success: function(res){
                    $scope.automations = res.automations;
                },
            });
        }    
        Ajax.get({
            module: 'common',
            url: 'form/get-objects',
            success: function(res){
                $scope.objects  = res.objects;
            },
        });

        $scope.$watch('automation.object_id', function(newValue, oldValue) {
            if(newValue && newValue != oldValue){
                $scope.getLists(newValue);
            }
        });
    };
    
    $scope.init();
        
    $scope.save = function(){
        Ajax.post({
            url: 'automation/save',
            data: $scope.automation,
            success: function(res){
                $scope.init();
            }
        });
    };

    $scope.removeAutomation = function($id){
        Ajax.post({
            url: 'automation/remove-automation',
            params: {id: $id},
            success: function(res){
                $scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
    };    
        
    $scope.addAction = function(){
        if (!$scope.automation.actions){
            $scope.automation.actions = [];
        }
        $scope.automation.actions.push({type: null, email_template_id: null});
    }
    
    $scope.removeAction = function($index){
        $scope.automation.actions.splice($index,1);
    };

    $scope.getLists = (objectId) => {
        Ajax.get({
            url: 'automation/get-lists',
            params: {objectId: objectId},
            success: function(res){
                $scope.systemLists  = res.systemLists;
                $scope.emailTemplates = res.emailTemplates;
            },
        });
    }
    
    $scope.mergeData = (action) => {
        action.webhook.object_id = $scope.automation.object_id;
        Ajax.post({
            module: 'common',
            url: 'mail/merge',
            notShowMessage: true,
            params: {logicId: action.webhook.object_id},
            data: {emailTemplate: action.webhook, ids: [12220]},
            success: function(res) {
                action.webhook.sampleData = res.data.content;
            
            }
        });
    }
}]);