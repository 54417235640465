'use strict';

app.directive('formInternalFieldsReadOnly', ['$timeout', '$filter', 'Ajax', 'Utils', '$sce', function ($timeout, $filter, Ajax, Utils, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            f: '=f',
        },
        templateUrl: '/js/app/directives/formInternalFieldsReadOnly/formInternalFieldsReadOnly.html',
        link: function(scope, element, attrs) {
            $timeout(function(){    
                scope.f = angular.fromJson(scope.f);
            },1000);
            
            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };

            scope.validPoints = function(currentField) {
                if (currentField.points && parseInt(currentField.points) > parseInt(currentField.max_points)) {
                    alert('חרגת מהציון המקסימלי הציון שישמר הוא המקסימלי ' + currentField.max_points);
                    currentField.points = currentField.max_points;
                    currentField.style = 'color:red';
                }else{
                    currentField.style = '';
                }

            }
        }
    };
}]);