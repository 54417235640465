'use strict';

app.controller('SpOrderController', ['$rootScope', '$scope', 'Ajax', '$filter', 'Utils', 'Popup', 'mailMerge', function($rootScope, $scope, Ajax, $filter, Utils, Popup, mailMerge) {
    $scope.data     = {};
    $scope.tagsArr  = [];

    $scope.$on('cru:getSuccess', function(e, mod, res){
        //$scope.getFamilyBudgetRequests();
        $scope.data = res.data ? res.data : {};
        if(mod == 'add'){
            $scope.data.customer_user_id = Utils.GET().customer_user_id;
        }
        $scope.tagsToArr();

        if (!$scope.data.year_id) {
            Ajax.get({
                module: 'common',
                url: 'settings/get-current-academic-year',
                success: function(res) {
                    $scope.data.year_id = res.currentAcademicYear.value;
                }
            });
        }
    });
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(mod == 'add'){
            $scope.data.customer_user_id = Utils.GET().customer_user_id;
            
        }else{
            $scope.tagsToArr();
            //$scope.editTags=!$scope.editTags;
        }
        if(!window.location.href.includes('view')){
            Utils.redirect('/singleparent/order/view?id=' + res.data.id);
        }
        //}
    });

    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,166); // 166 = order(logic_id) in cr21
    };

    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };

    $scope.createContinuedOrder = (programId) =>{
        Ajax.post({
            url: 'order/create-continued-order',
            params: {programId: programId, parentOrderId: $scope.data.id},
            success: function(res){
                $scope.getOrder();         
            }
        });
    };  

    $scope.getOrder = () => {
        Ajax.get({
            url: 'order/get-order',
            params: {id: $scope.data.id},
            success: function(res) {
                $scope.data = res.data;
                $scope.picklists = res.picklists;
            }
        });
    };

}]);