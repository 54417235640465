'use strict';

app.directive('formSignature', ['$timeout', '$filter', 'Ajax', 'Utils', '$rootScope', '$sce', function ($timeout, $filter, Ajax, Utils, $rootScope, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            data: '=data',
        },
        templateUrl: '/js/app/directives/formSignature/formSignature.html',
        link: function(scope, element, attrs) {
            
            scope.signaturePad = null;
            scope.saveButton = null;
            scope.cancelButton = null;
            scope.signatureNotDedected = false;
            scope.init = function() {
                scope.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    penColor: 'blue',
                    
                });
                scope.clearSignature();
                scope.userMessages = window.ytUserMessages;
                scope.language = window.ytFormLanguage;
            };
            
            $timeout(function(){
                scope.init();
            },1000);
            
            scope.saveSignature = function () {
                var imgg = scope.signaturePad.toDataURL();
                if( scope.signaturePad.isEmpty()){
                    scope.signatureNotDedected = true;
                    return;
                }
                scope.signatureNotDedected = false;                  
                var blobBin = atob(imgg.split(',')[1]);
                var array = [];
                for(var i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i));
                }
                var file=new Blob([new Uint8Array(array)], {type: 'image/png'});

                var formdata = new FormData();
                formdata.append("file", file);
                scope.uploadFile(formdata, scope.data.id);
            };
            
            scope.clearSignature = function() {
                scope.signatureNotDedected = false;
                scope.signaturePad.clear();
            };

            scope.uploadFile = function(fd) {
                Ajax.jqueryUploadFile({
                    module: 'common',
                    url: 'file/upload-form-submission-signature?formSubmissionId=' + scope.data.formSubmission.id,
                    fd: fd,
                    success: function(res){
                        $rootScope.$broadcast("form:signed", res.data);
                    }
                });
            };

            scope.cancel = function() {
                scope.signatureNotDedected = false;
                $rootScope.$broadcast("form:willsignlater", null);
            };
        }
    };
}]);