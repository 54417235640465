'use strict';

app.controller('PortalRequestDetailsCtrl', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){
    Ajax.get({
        module: 'academyportal',
        url:    'initial/get-request-portal',
        params: {user_id: $scope.globals.user.user_id}, 
        success: function(res){
            $scope.request      = res.request;
            $scope.picklists    = res.picklists;
            $scope.editRequest  = angular.copy($scope.request);
        }
    });
    
    $scope.save = function(){
        if( !angular.equals($scope.request, $scope.editRequest) ){
            Ajax.post({
                module: 'academyportal',
                url:    'default/save-request-portal',
                params: {user_id: $scope.globals.user.user_id}, 
                data:   $scope.request,
                success: function(res){
                    $scope.request      = res.data;
                    $scope.editRequest  = angular.copy($scope.request);
                }
            });
        }
    };
    
}]);