'use strict';

app.controller('FileGroupLine', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.fileGroups = [];
    
    $rootScope.$on('popup:cancel', function(e,data){
        if(data){// if data, it's edit mode
            $scope.init();
        }
    });
    
    $scope.init = function(){
        Ajax.get({
            url: 'file-group/get-file-group-lines',
            params: {groupId: Utils.GET().id},
            success: function(res){
                $scope.group = res.group;
                $scope.lines = res.lines;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        data.group_id = Utils.GET().id;
        Ajax.post({
            url: 'file-group/save-line',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.removeLine = function($id){
		Ajax.post({
			url: 'file-group/remove-line',
            params: {id: $id},
            success: function(res){
				$scope.init();
                $rootScope.$broadcast("popup:close", res);
            }
        });
	};    
        
    $scope.openFileGroupLinePopup = function(data){
        var fn = [];
        fn = {
            removeLine: $scope.removeLine
        };
        Popup.open('adminsettings/fileGroupLine', $scope.save, data, fn);
    };
}]);