'use strict';

app.directive('searchRow', ['$timeout', '$compile', '$parse', 'Ajax', 'Utils', function ($timeout, $compile, $parse, Ajax, Utils) {
    return {
        restrict: 'AE',
        scope: {
            searchdata: '='
        },
        templateUrl: function(elem, attrs) {
            return path + '/searchRow/tpl.html'  + window.YT.project.version;
        },
        link: function(scope, element, attrs) {
            scope.ngIndex = attrs.ngIndex;
            scope.search = {};
            scope.search.text = '';
            
            
            scope.search = function(){
                scope.$emit('datattable:search', scope.ngIndex, scope.search.text);
            };
        }
    };
}]);
