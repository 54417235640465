'use strict';

app.controller('EhcpHomeController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    $scope.init = function(){
        Ajax.get({
            url: 'contact/get-alert-details',
            params: {id: YT.user.user_id},
            success: function(res){
                $scope.alerts                       = res.alerts;
                $scope.requestsSummary              = res.requestsSummary;
                $scope.budgetReqeustInProcess       = res.budgetReqeustInProcess;
                $scope.patientsInProcess            = res.patientsInProcess;
                $scope.organizationQuota            = res.organizationQuota;                
            },
        });
        //}
    };
        
    $scope.init();
    
    $scope.setProjectId = function(id) {
        Ajax.post({
            module: 'common',
            url: 'default/set-project-id', 
            params: {id: id},
            success: function(res) { 
                Utils.redirect('/contactehportal/contact/home');
            },
            notShowMessage: true
        });
    };
    
    $scope.goToUpdate = function(id){
        Utils.redirect('/contactehportal/patient/update?id='+id);
    }
}]);