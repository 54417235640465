'use strict';

app.controller('SetRequestsStatusController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.continueaction = true;
    $scope.message = 'המתן לתחילת הפעולה...';
    $scope.offset = 0;
    $scope.doneCount = 0;
    $scope.finishaction = false;
    $scope.totalCount = 0;
    $scope.init = function(){
        Ajax.post({
            module: 'common',
            url: 'settings/995eb3b350b711a3ec10f9576a859c6c',
            params: {offset: $scope.offset, limit: 1000, doneCount: $scope.doneCount},
            success: function(res){
                $scope.message = 'נבדקו ' + ( (res.offset<res.totalCount) ? res.offset : res.totalCount ) +  ' מתוך ' + res.totalCount + ', עודכנו ' + res.doneCount;
                $scope.offset = res.offset;
                $scope.doneCount = res.doneCount;
                $scope.totalCount = res.totalCount;
                if ($scope.continueaction && res.offset < res.totalCount){
                    $scope.init();
                }else{
                    $scope.message = 'הפעולה הסתיימה נבדקו ' + ( (res.offset<res.totalCount) ? res.offset : res.totalCount ) +  ' מתוך ' + res.totalCount + ', עודכנו ' + res.doneCount;
                    $scope.finishaction = true;
                    $scope.continueaction = false;
//                    Ajax.post({
//                        module: 'common',
//                        url: 'settings/set-last-request-update',
//                        params: {value: $scope.message},
//                        success: function(res){
//                        },
//                        notShowMessage:true,
//                    });
                }
            },
            notShowMessage:true,
        });
    };
    
    $scope.init();
        
    $scope.stop = function(){
        $scope.continueaction = false;
    };
}]);