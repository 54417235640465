app.directive('measureSelect', ['$parse', function ($parse) {
    return {
        require:'?ngModel',
        restrict: 'AEC',
        scope: {
            ngModel: '='
        },
        templateUrl: '/js/app/partials/measureSelectTpl.html',
        link:function (scope, elm, attrs, ngModel) {}
    };
}]);


