app.directive('listWizard', ['$filter', 'Ajax', 'Utils', '$timeout',  
	function ($filter, Ajax, Utils, $timeout) {
    return{
        restrict: 'AEC',
        //replace: true,
        scope: false,
        transclude: false,
        templateUrl: '/js/app/partials/list/wizardTpl.html',
        link: function(scope, element, attrs) { 
			scope.loading						= false;
            scope.fieldsMap                     = [];
            scope.objects                       = [];
            
            scope.selectedFields                = [];
            scope.selectedWhere                 = [];
            scope.selectedSort                  = [];
            scope.selectedActions               = [];
            scope.selectedFreeTextFields        = [];
            scope.selectedMassiveUpdateFields   = [];
            scope.selectedAddTo                 = [];
            scope.data                          = {};
            scope.fieldsSelected                = {};
            
            scope.gridId                        = Utils.GET().id;
            scope.role                          = YT.user.role;
            scope.data.is_active                = 'yes';
            
            scope.data.add_button               = null;
            scope.data.user_id_access_limit     = scope.globals.user.user_id;
            
            scope.startObject                   = null;
            scope.showChooseModel               = attrs.mod === 'add' ? true : false;
            scope.adminUsers					= null;
            scope.adminSharing					= null;


            // for all projects
            if (YT && YT.user && YT.user.project_id) {
                if (!scope.addToPicklist){
                    scope.addToPicklist = [];
                }
                scope.addToPicklist.push(
                    {
                        label: 'ביצוע משימה', 
                        object: 'activityDone',
                        requiredMainFieldFullName: ['tbl_activity.id'],
                        requiredErrorMessage:		'בהוסף אל משימה העמודה הראשית חייבת להיות זיהוי משימה',
                    },
                    {
                        label: 'פריסת תוכנית עבודה',
                        object: 'activityProject',
                        requiredMainFieldFullName: ['tbl_user.id','tbl_organization.id', 'tbl_order.id'],
                        requiredErrorMessage: 'בהוסף אל פריסת תוכנית עבודה העמודה הראשית חייבת להיות זיהוי משתמש, זיהוי ארגון/מוסד או זיהוי הזמנה',
                    },
                    {
                        label: 'טופס',
                        object: 'form',
                        requiredMainFieldFullName: ['tbl_user.id','tbl_organization.id','tbl_order.id'],
                        requiredErrorMessage: 'בהוסף אל טופס העמודה הראשית חייבת להיות זיהוי הזמנה או זיהוי משתמש או זיהוי ארגון/מוסד',
                    },
                );
            }
            
            if (YT.user.project_id == 1){ // 1 = academy
                scope.addToPicklist.push(
                    {
                        label: 'מטלה', 
                        object: 'assignment',
                        requiredMainFieldFullName: ['tbl_user.id'],
                        requiredErrorMessage:		'בהוספת סטודנט למטלה עמודה ראשית חייבת להיות זיהוי סטודנט',
                    },
                    {
                        label: 'ביצוע מטלה', 
                        object: 'assignmentdone',
                        requiredMainFieldFullName: ['tbl_asignment_connection.id'],
                        requiredErrorMessage:		'בהוספת סטודנט לביצוע עמודה ראשית חייבת להיות זיהוי קישור סטודנט ומטלה',
                    },
                    {
                        label: 'שנה', 
                        object: 'year',
                        requiredMainFieldFullName: ['tbl_academic_study_user.id'],
                        requiredErrorMessage:		'בהוספת שנה עמודה ראשית חייבת להיות זיהוי לימודים',
                    },
                    {
                        label: 'סמסטר', 
                        object: 'semester', 
                        requiredMainFieldFullName: ['tbl_academic_study_year.id'],
                        requiredErrorMessage:		'בהוספת סמסטר עמודה ראשית חייבת להיות זיהוי שנת לימוד',
                    },
                    {
                        label: 'בדיקת זכאות', 
                        object: 'eligibility',
                        requiredMainFieldFullName: ['tbl_user.id'],
                        requiredErrorMessage:		'בבדיקת זכאות עמודה ראשית חייבת להיות זיהוי סטודנט',
                    },
                    {
                        label: 'שליחת מייל מותאם אישית', 
                        object: 'sendcustomemail',
                        requiredMainFieldFullName: ['tbl_user.id','tbl_request.id'],
                        requiredErrorMessage: 'בהוסף אל שליחת הודעה לאחר תשלום העמודה הראשית חייבת להיות זיהוי משתמש/בקשה',
                    },
                );
            }
            
            if (YT.user.project_id == 5){ // 5 = elhanefesh
                scope.addToPicklist.push(
                    {
                        label: 'שליחת מייל בקשה', 
                        object: 'sendemail',
                        requiredMainFieldFullName: ['tbl_request.id'],
                        requiredErrorMessage:		'בשליחת מייל העמודה הראשית חייבת להיות זיהוי בקשה',
                    },
                    {
                        label: 'שליחת מייל תקציב בבקשה', 
                        object: 'sendemailbudgetrequest',
                        requiredMainFieldFullName: ['tbl_budget_request.id'],
                        requiredErrorMessage:		'בשליחת מייל מתקציב בבקשה העמודה הראשית חייבת להיות זיהוי תקציב',
                    },
                    {
                        label: 'שליחת מייל ספק', 
                        object: 'sendemailsupplier',
                        requiredMainFieldFullName: ['tbl_user.id'],
                        requiredErrorMessage:		'בשליחת מייל לספקים העמודה הראשית חייבת להיות זיהוי ספק',
                    },
                    {
                        label: 'החלטה לאישור/דחיה מפגשים', 
                        object: 'treatmentdecision',
                        requiredMainFieldFullName: ['tbl_treatment.id'],
                        requiredErrorMessage:		'בהוסף אל החלטה העמודה הראשית חייבת להיות זיהוי מפגש',
                    },
                    {
                        label: 'סקירה', 
                        object: 'treatmentreview',
                        requiredMainFieldFullName: ['tbl_treatment.id'],
                        requiredErrorMessage:		'בהוסף אל סקירה העמודה הראשית חייבת להיות זיהוי מפגש',
                    },
                    {
                        label: 'יצירת חשבונית', 
                        object: 'invoicecreation',
                        requiredMainFieldFullName: ['tbl_treatment.id'],
                        requiredErrorMessage:		'בהוסף אל יצירת חשבונית העמודה הראשית חייבת להיות זיהוי מפגש',
                    },
                    {
                        label: 'שליחת הודעה לאחר תשלום', 
                        object: 'sendemailinvoice',
                        requiredMainFieldFullName: ['tbl_invoice.id'],
                        requiredErrorMessage:		'בהוסף אל שליחת הודעה לאחר תשלום העמודה הראשית חייבת להיות זיהוי חשבונית',
                    },
                    {
                        label: 'שליחת מייל מותאם אישית',
                        object: 'sendcustomemail',
                        requiredMainFieldFullName: ['tbl_user.id','tbl_request.id','tbl_budget_request.id'],
                        requiredErrorMessage: 'בהוסף אל שליחת הודעה לאחר תשלום העמודה הראשית חייבת להיות זיהוי משתמש/בקשה/תקציב',
                    },
                );
            }
            
            if (YT.user.project_id == 10 || YT.user.project_id == 5){ // 10 = merchavim
                scope.addToPicklist.push(
                    {
                        label: 'ועדה', 
                        object: 'committee',
                        requiredMainFieldFullName: ['tbl_budget_request.id'],
                        requiredErrorMessage:		'בהוסף אל ועדה העמודה הראשית חייבת להיות זיהוי תקציב',
                    },
                    {
                        label: 'שינוי/הסרת ועדה', 
                        object: 'changeCommittee',
                        requiredMainFieldFullName: ['tbl_budget_request.id'],
                        requiredErrorMessage:		'בהוסף אל שינוי/הסרת ועדה העמודה הראשית חייבת להיות זיהוי תקציב',
                    },
                    {
                        label: 'טופס',
                        object: 'form',
                        requiredMainFieldFullName: ['tbl_user.id','tbl_organization.id'],
                        requiredErrorMessage: 'בהוסף אל טופס העמודה הראשית חייבת להיות זיהוי משתמש או זיהוי ארגון/מוסד',
                    },
                    {
                        label: 'סגירת בקשות',
                        object: 'closeRequests',
                        requiredMainFieldFullName: ['tbl_request.id'],
                        requiredErrorMessage: 'בהוסף אל סגירת בקשות העמודה הראשית חייבת להיות זיהוי בקשה',
                    },
                    {
                        label: 'ועדת קבוצות', 
                        object: 'groupsCommittee',
                        requiredMainFieldFullName: ['tbl_treatment_group.id'],
                        requiredErrorMessage:		'בהוסף אל ועדת קבוצות העמודה הראשית חייבת להיות זיהוי קבוצה',
                    },
                    {
                        label: 'יצירת חשבונית למפגשים קבוצתיים', 
                        object: 'treatmentGroupLinesInvoiceCreation',
                        requiredMainFieldFullName: ['tbl_treatment_group_line.id'],
                        requiredErrorMessage:		'בהוסף אל יצירת חשבונית למפגשים קבוצתיים העמודה הראשית חייבת להיות זיהוי מפגש קבוצתי',
                    },
                    {
                        label: 'שליחת מייל החלטת ועדה', 
                        object: 'senddecisionemail',
                        requiredMainFieldFullName: ['tbl_budget_request.id','tbl_treatment_group.id'],
                        requiredErrorMessage:		'בשליחת הודעה על החלטת ועדה העמודה הראשית חייבת להיות זיהוי תקציב או זיהוי קבוצה',
                    },
                    {
                        label: 'בדיקת הקצאות פרטניות', 
                        object: 'showPrivateQuotas',
                        requiredMainFieldFullName: ['tbl_request.id'],
                        requiredErrorMessage:		'בבדיקת הקצאות פרטניות העמודה הראשית חייבת להיות מזהה בקשה',
                    },
                );
            }
            
            if (YT.user.project_id == 14){ // 14 = kidum
                scope.addToPicklist.push(
                    
                    {
                        label: 'שליחת מייל החלטת ועדה', 
                        object: 'kdmsenddecisionemail',
                        requiredMainFieldFullName: ['tbl_budget_request.id'],
                        requiredErrorMessage:		'בשליחת הודעה על החלטת ועדה העמודה הראשית חייבת להיות זיהוי מענק',
                    },
                    
                    {
                        label: 'ועדה', 
                        object: 'committee',
                        requiredMainFieldFullName: ['tbl_budget_request.id'],
                        requiredErrorMessage:		'בהוספת ועדה העמודה הראשית חייבת להיות זיהוי תקציב',
                    },
                    
                    
                    
                );
            }

            if (YT.user.project_id == 17){ // 17 = singleparent
                scope.addToPicklist.push(
                    {
                        label: 'ועדה',
                        object: 'orderToCommittee',
                        requiredMainFieldFullName: ['tbl_order.id'],
                        requiredErrorMessage:		'בהוספת ועדה העמודה הראשית חייבת להיות זיהוי הזמנה',
                    },
                );
            }
            
            scope.loadObject = function(item){
                if( scope.loading ){
                        return;
                }

                scope.loading = true;
                scope.objects.push(item);   
                scope.getFieldsMap(item.modelClass);
            };
            
            scope.back = function(){
                if( scope.loading || scope.objects.length <= 1){
                        return;
                }

                scope.loading = true;

                scope.objects.pop();
                scope.getFieldsMap(scope.objects[scope.objects.length-1].modelClass);
            };
            
            scope.selectedRow = function(item, objects){  
                scope.fieldsSelected = angular.copy(item);
                
                //var label = scope.fieldsMap.attributeLabels[scope.fieldsSelected.field] ? scope.fieldsMap.attributeLabels[scope.fieldsSelected.field] : scope.fieldsMap.attributeLabels[scope.fieldsSelected.fullNameOriginal];
                var label = scope.fieldsSelected.label;
                if(objects.length > 1){   
                    var objStr      = Utils.replaceObejctToStringOrm(objects);

                    scope.fieldsSelected.joinWith       = scope.fieldsSelected.joinWith ? (objStr.joinWith + "." + scope.fieldsSelected.joinWith) : objStr.joinWith;
                    scope.fieldsSelected.objStrJoinWith = objStr.joinWith;

                    scope.fieldsSelected.listLabel  = objStr.listLabel ? (objStr.listLabel + "." + label) : label;
                }else{
                    scope.fieldsSelected.listLabel = objects[0].label + "." + label;
                }

                //scope.fieldsSelected.userLabel = label;
                scope.fieldsSelected.userLabel = scope.fieldsSelected.label;
            };
            
            scope.getFieldsMap = function(model){
                Ajax.get({
                    module: 'common',
                    url: 'model/fields-map', 
                    params: {model: model},
                    success: function(res) {
                        scope.fieldsMap = res;
						scope.loading = false;
                    }
                });
            };
            
            scope.setFirstModel = function(){
                scope.objects.push({
                    modelClass: scope.objectsList[scope.startObject].model,
                    joinWith:   false,
                    label:      scope.objectsList[scope.startObject].name_yachid
                });
                
                scope.selectedModel();
            };
            
            scope.selectedModel = function(){
                scope.getFieldsMap(scope.objects[0].modelClass);            
                scope.showChooseModel = false;
            };
            
            if(attrs.mod === 'add'){
                Ajax.get({
                    url: 'list/get-objects', 
                    success: function(res) { 
                        scope.objectsList = res.objects;
                        scope.data.is_pdf_allowed = 'yes'; // default on creation use to be yes
                    }
                });
            }else if(attrs.mod === 'edit'){
                Ajax.get({
                    url: 'list/get', 
                    params: {id: Utils.GET().id},
                    success: function(res) { 
                        scope.data.name                 = res.systemList.name;
                        scope.data.description          = res.systemList.description;
                        scope.data.is_active            = res.systemList.is_active;
                        scope.data.is_excel_allowed     = res.systemList.is_excel_allowed;
                        scope.data.is_pdf_allowed       = res.systemList.is_pdf_allowed;
                        scope.data.is_archived_allowed  = res.systemList.is_archived_allowed;
                        scope.data.user_id_access_limit = res.systemList.user_id_access_limit;
                        scope.data.add_button           = res.systemList.add_button;
                        scope.data.creator_user_id       = res.systemList.creator_user_id;
                        
                        scope.selectedFields                = res.systemList.fields ? res.systemList.fields : [];
                        scope.selectedWhere                 = res.systemList.where ? res.systemList.where : [];
                        scope.selectedSort                  = res.systemList.sort ? res.systemList.sort : [];
                        scope.selectedActions               = res.systemList.actions ? res.systemList.actions : [];
                        scope.selectedMassiveUpdateFields   = res.systemList.massive_updates ? res.systemList.massive_updates : [];
                        scope.selectedAddTo                 = res.systemList.add_to ? res.systemList.add_to : [];
                        scope.selectedFreeTextFields        = res.systemList.free_text_fields ? res.systemList.free_text_fields : [];
                        scope.data.node                     = res.systemList.node;
                        
						scope.adminSharing					= res.adminSharing;
						scope.adminUsers					= res.adminUsers;
						
                        scope.objects.push({
                            modelClass: res.systemList.object.model,
                            joinWith:   false,
                            label:      res.systemList.object.name_yachid
                        });
                        
                        scope.selectedModel();
                    }
                });
            };
			
            scope.add = function(objects, type){
                if( !angular.equals({}, scope.fieldsSelected) ){
                    var key = "selected" + Utils.capitalizeFirstLetter(type);
                    
                    scope[key].push( angular.copy(scope.fieldsSelected) );
                }
            };
            
            scope.remove = function(item, type, actionIndex){
                if(item.action && item.action === 'mainField' && scope.selectedAddTo.length > 0){
                        alert('חובה להסיר את כל "הוספה אל" לפני מחיקה של עמודה ראשית');
                        return;
                }
				
                if(type === 'actions'){
                    for (var i = 0; i < scope.selectedFields.length; i++){
                        if(scope.selectedFields[i].action == actionIndex){
                            scope.selectedFields[i].action = null;
                        }
                    }
                }
                
                var key = "selected" + Utils.capitalizeFirstLetter(type);
                scope[key].splice(scope[key].indexOf(item), 1);       
            };
            
            scope.save = function(){
                var data = {
                    name:               scope.data.name,
                    description:        scope.data.description,
                    is_active:          scope.data.is_active,
                    is_excel_allowed:   scope.data.is_excel_allowed,
                    is_pdf_allowed:     scope.data.is_pdf_allowed,
                    is_archived_allowed: scope.data.is_archived_allowed,
                    user_id_access_limit: scope.data.user_id_access_limit,
                    add_button:         scope.data.add_button,
                    fields:             scope.selectedFields, 
                    where:              scope.selectedWhere, 
                    sort:               scope.selectedSort,
                    actions:            scope.selectedActions,
                    free_text_fields:   scope.selectedFreeTextFields,
                    add_to:             scope.selectedAddTo,
                    massive_updates:    scope.selectedMassiveUpdateFields,
                    node:               scope.selectedWhere.length > 1 ? scope.data.node : '',
					adminSharing:		scope.adminSharing
                };
                
                if(attrs.mod === 'add'){
                    data['object_id'] = scope.objectsList[scope.startObject].id;
                }
                
                Ajax.post({
                    url: 'list/save',
                    params: (attrs.mod === 'edit') ? {id: Utils.GET().id} : {id: null},
                    data: data,
                    success: function(res){
                        if(attrs.mod === 'add'){
                            Utils.redirect('/common/list/update?id=' + res.data.id);
                        }
                    }
                });
            };
            
            scope.addToClickOnAdd = function(addToIndex){
                if( !addToIndex ){ return; }
                var found = $filter('filter')(scope.selectedAddTo, scope.addToPicklist[addToIndex], true);
                if(found.length){ return; }
			
			
				var mainField = $filter('filter')(scope.selectedFields, {action: 'mainField'})[0];
				
				if(!mainField || scope.addToPicklist[addToIndex].requiredMainFieldFullName.includes(mainField.fullName) == false ){
					alert(scope.addToPicklist[addToIndex].requiredErrorMessage);
					return;
				}
				
				scope.selectedAddTo.push( scope.addToPicklist[addToIndex] );
            };
            
            scope.onDropComplete = function(index, obj, evt, type){
                var key = "selected" + Utils.capitalizeFirstLetter(type);
                
                var otherObj    = scope[key][index];
                var otherIndex  = scope[key].indexOf(obj);
                
                
                
                if (otherIndex < index){
                    //scope[key].splice(otherIndex, 0, otherObj);
                    scope[key].splice(index+1, 0, obj);
                    scope[key].splice(otherIndex, 1);
                }else{
                    scope[key].splice(index, 0, obj);
                    scope[key].splice(otherIndex+1, 1);
                }
                //scope[key][index]       = obj;
                //scope[key][otherIndex]  = otherObj;
            };
        }
    };
}]);