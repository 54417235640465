'use strict';

app.controller('EmailAccountController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tags = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'email-account/get-email-accounts',
            success: function(res){
                $scope.emailAccounts = res.emailAccounts;
            },
        });
    };
    
    $scope.init();
        
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'email-account/save-email-account',
            data:   data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            }
        });
    };

    $scope.openEmailAccountPopup = function(data){
        data = data ? data : {};
        
        data.objects = $scope.objects;
        Popup.open('adminsettings/emailAccount', $scope.save, data, {});
    };
}]);