'use strict';

app.factory('Utils', ['$rootScope', '$log', '$filter', function($rootScope, $log, $filter) {
    var dataFactory = {};   
    
    dataFactory.GET = function(){
        var paramsObj = {};
        var input = window.location.search;

        if (input.length > 1) {
            var param = input.slice(1).replace(/\+/g, ' ').split('&');
            
            var plength = param.length;
            var tmp, p;

            for (p = 0; p < plength; p += 1) {
                tmp = param[p].split('=');
                paramsObj[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp[1]);
            }
        }

        return paramsObj;
    };
    
    dataFactory.getParameterByName = function(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
    
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
        
    dataFactory.log = function(data){
        console.log(data);
    };
    
    dataFactory.getAge = function(birth_date){
        var diff = new Date() - new Date(birth_date); // This is the difference in milliseconds
        var age  = (diff/31536000000).toFixed(1);
        //var age = Math.floor( (diff/31536000000) ); // Divide by 1000*60*60*24*365
        
        return (age == parseInt(age)) ? parseInt(age) : age;
    };
    
    dataFactory.LegalTz = function(num) {
        var tot = 0;
        var tz = new String(num);
        for (var i=0; i<8; i++)
            {
                var x = (((i%2)+1)*tz.charAt(i));
                if (x > 9)
                    {
                    x =x.toString();
                    x=parseInt(x.charAt(0))+parseInt(x.charAt(1))
                    }
            tot += x;
            }
        
        if ((tot+parseInt(tz.charAt(8)))%10 == 0) {
            return false;
        } else {
            return true;
        }
    }
    
    dataFactory.redirect = function(url, blank){
        if (blank) {
            window.open(url, "_blank")
        }else {
             window.location = url;
        }
    };
    
    dataFactory.refresh = function(){
        location.reload();
    };
    
    dataFactory.getUrl = function(obj){
        if( obj.hasOwnProperty('module') ){
            return "/" + obj.module + "/" + obj.url;
        }

        return "/" + $rootScope.globals.mvc.module + "/" + obj.url;
    };

    dataFactory.showNoty =  function(message, type, timeout){
        noty({
            text:           message, 
            layout:         'topLeft', 
            type:           type || 'success',
            timeout:        timeout || 1000,
            dismissQueue:   true
        });
    };
    
    dataFactory.replaceObejctToStringOrm = function(objects){
        var joinWith    = '';
        var listLabel   = '';

        angular.forEach(objects, function(obj, key) {
            if(obj.joinWith){
                joinWith +=  ( joinWith == '' ? obj.joinWith : ("." + obj.joinWith) );
            }

            if(obj.label){
                listLabel += ( listLabel == '' ? obj.label : ("." + obj.label) );
            }
        });

        return {
            joinWith: joinWith,
            listLabel: listLabel
        };
    };
    
    dataFactory.capitalizeFirstLetter = function(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    dataFactory.getDateForDisplay = function(iDate){
        var today = new Date();
        var commentDate = new Date(iDate);
        if (iDate == null || commentDate == 'Invalid Date'){
            return 'Invalid Date';
        }
        var strCommentDate4Compare = commentDate.getDate().toString()+commentDate.getMonth().toString()+commentDate.getFullYear().toString();
        var strToday4Compare = today.getDate().toString()+today.getMonth().toString()+today.getFullYear().toString();
        if (strCommentDate4Compare === strToday4Compare){
            var hour = commentDate.getHours()<10?"0"+commentDate.getHours():commentDate.getHours();
            var minute = commentDate.getMinutes()<10?"0"+commentDate.getMinutes():commentDate.getMinutes();
            var secound = commentDate.getSeconds()<10?"0"+commentDate.getSeconds():commentDate.getSeconds();
            return "היום ב " + hour + ":" + minute + ":" + secound;
        }
        var iMonth = commentDate.getMonth()+1;
        var day = commentDate.getDate()<10?"0"+commentDate.getDate():commentDate.getDate();
        var month = iMonth<10?"0"+iMonth:iMonth;
        return  day + "/" + month + "/" + commentDate.getFullYear();
    };
    
    dataFactory.getTimeForDisplay = function(iDate){
        var commentDate = new Date(iDate);
        if (iDate == null || commentDate == 'Invalid Date'){
            return 'Invalid Time';
        }
        var hour = commentDate.getHours()<10?"0"+commentDate.getHours().toString():commentDate.getHours().toString();
        var minute = commentDate.getMinutes()<10?"0"+commentDate.getMinutes().toString():commentDate.getMinutes().toString();
        var secound = commentDate.getSeconds()<10?"0"+commentDate.getSeconds().toString():commentDate.getSeconds().toString();
        return hour + ":" + minute;
    };
    
    dataFactory.getWeekDayForDisplay = function(iDate){
        var currentDate = new Date(iDate);
        if (iDate == null || currentDate == 'Invalid Date'){
            return 'Invalid Date';
        }
        var weekDay = currentDate.getDay();
        var strWeekDay = '';
        switch(weekDay) {
            case 0:
                strWeekDay = 'יום ראשון';
                break;
            case 1:
                strWeekDay = 'יום שני';
                break;
            case 2:
                strWeekDay = 'יום שלישי';
                break;
            case 3:
                strWeekDay = 'יום רביעי';
                break;
             case 4:
                strWeekDay = 'יום חמישי';
                break;
            case 5:
                strWeekDay = 'יום שישי';
                break;
            case 6:
                strWeekDay = 'שבת';
                break;
            default:
                //default code block
        }
        return strWeekDay;
    };
    
    dataFactory.validateEmail = function(email){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    
    dataFactory.getJSFormatDate = function(d) {
        return new Date(d);
    }
    
    return dataFactory;
}]);



app.factory('Popup', ['$rootScope', '$modal', '$timeout', 'Ajax', function($rootScope, $modal, $timeout, Ajax) {
	
	var option = {};
	return {
		setOption: function(key, val){
			option[key] = val;
		},
		open: function(tpl, okCalback, data, fn, watch, backdrop, size){
			var modalInstance = $modal.open({
                templateUrl: '/js/app/partials/popup/' + tpl + '.html',
				controller: function($scope, $modalInstance, option){
					$scope.option       = option	|| {};
					$scope.data         = data		|| {};
					$scope.editData     = data		|| {};
					$scope.fn           = fn		|| {};
					$scope.watch        = watch		|| {};
					$scope.errorMsg     = '';
                    $scope.showErrorMsg = false;
					
                    $scope.$on('ajax:error', function(event, args) {
                        $('#main-alert-error').hide();
                        $scope.errorMsg = '';
                        angular.forEach(args, function(msg) {
                            $scope.errorMsg = $scope.errorMsg + msg + ' ';
                        });
                        $scope.showErrorMsg = true;
                    });
                    
                    $scope.$on('popup:close', function(event, args) {
                        $modalInstance.close();
                    });
					
					if($scope.fn){
						angular.forEach($scope.fn, function(cb, key) {
							if( key.includes('_popupVar') ){
								$scope[ key.replace("_popupVar", "") ] = function(){
									cb($scope.data, $modalInstance);
								};
							}else{
								$scope[key] = cb;
							}
						});
					}
					
					if($scope.watch){
						angular.forEach($scope.watch, function(item) {
							$scope.$watch(item.myVar, item.myFun);
						});
					}
					
					$scope.show = false;
					$timeout(function(){
                        $scope.show = true;
                        $("input[type=text]").keydown(function( event ) {
                            $scope.preventCurlyBraces(event);
                        });
                        $("textarea").keydown(function( event ) {
                            $scope.preventCurlyBraces(event);
                        });
                        Ajax.turnOnValidation();
                        
					}, 1000);
                    
                    $scope.preventCurlyBraces = function(event){
                        if ( event.shiftKey && (event.key === '{' || event.key === '}') ) {
                            event.preventDefault();
                        }
                    };

					$scope.ok = function(){
                        $scope.errorMsg = '';
                        $scope.showErrorMsg = false;
                        okCalback($scope.data, $modalInstance);
					};
					
					$scope.cancel = function () {
                        //$rootScope.$broadcast('popup:cancel',data); // Yechiel - I add it, need to see if it's OK
						$modalInstance.dismiss('cancel');
					};
				},
				size: size ? size : 'lg',
				resolve: {
					option: function () {
						return option;
					}
				},
                backdrop  : (backdrop == 'static') ? backdrop : true
            });

			modalInstance.result.then(function (data) {
				
			}, function () {
				$rootScope.$broadcast('popup:cancel', data); 
            });
            return modalInstance;
		}
	};
}]);

app.factory('Xnavigation', ['$rootScope', 'PageContentOnresize', function($rootScope, PageContentOnresize) {
    return {
        run: function(){
            $(".x-navigation-control").click(function(){
                $(this).parents(".x-navigation").toggleClass("x-navigation-open");

                PageContentOnresize.run();

                return false;
            });

            if($(".page-navigation-toggled").length > 0){
                x_navigation_minimize("close");
            }    

            $(".x-navigation-minimize").click(function(){

                if($(".page-sidebar .x-navigation").hasClass("x-navigation-minimized")){
                    $(".page-container").removeClass("page-navigation-toggled");
                    x_navigation_minimize("open");
                }else{            
                    $(".page-container").addClass("page-navigation-toggled");
                    x_navigation_minimize("close");            
                }

                PageContentOnresize.run();

                return false;        
            });

            $(".x-navigation  li > a").click(function(){

                var li = $(this).parent('li');        
                var ul = li.parent("ul");

                ul.find(" > li").not(li).removeClass("active");    

            });

            $(".x-navigation li").click(function(event){
                event.stopPropagation();

                var li = $(this);

                    if(li.children("ul").length > 0 || li.children(".panel").length > 0 || $(this).hasClass("xn-profile") > 0){
                        if(li.hasClass("active")){
                            li.removeClass("active");
                            li.find("li.active").removeClass("active");
                        }else
                            li.addClass("active");

                        PageContentOnresize.run();

                        if($(this).hasClass("xn-profile") > 0)
                            return true;
                        else
                            return false;
                    }                                     
            });
            
            

            /* XN-SEARCH */
            $(".xn-search").on("click",function(){
                $(this).find("input").focus();
            });
            /* END XN-SEARCH */
            
            
            /* Moshe Start */
            
            $('.x-navigation ul.navbar-right li a').removeClass('active');
            var url = "/" + $rootScope.globals.mvc.module + "/" + $rootScope.globals.mvc.controller + "/" + $rootScope.globals.mvc.action;
            
            $('.x-navigation ul.navbar-right li a').each(function(){    
                if( url == $(this).attr('href') ){
                    $(this).parent('li').addClass('active');

                    if( $(this).closest('li[class^="xn-openable"]').hasClass('xn-openable') ){
                        $(this).closest('li[class^="xn-openable"]').addClass('active');
                    }
                }
            });
            
            /* Moshe end */
        }
    };
}]);


app.factory('PortalActiveNavigation', function($rootScope) {
    return {
        run: function(){
            $('#sidebar-wrapper .sidebar-nav li ul.nav-menu li a').removeClass('active');
            var url = "/" + $rootScope.globals.mvc.module + "/" + $rootScope.globals.mvc.controller + "/" + $rootScope.globals.mvc.action;

            $('#sidebar-wrapper .sidebar-nav li ul.nav-menu li a').each(function(){    
                if( url == $(this).attr('href') ){
                    $(this).addClass('active');
                }
            });
        }
    };
});

app.factory('PageContentOnresize', function() {
    return {
        run: function() {
            $(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width", "").css("height","");

            var content_minus = 0;
            content_minus = ($(".page-container-boxed").length > 0) ? 40 : content_minus;
            content_minus += ($(".page-navigation-top-fixed").length > 0) ? 50 : 0;

            var content = $(".page-content");
            var sidebar = $(".page-sidebar");

            if(content.height() < $(document).height() - content_minus){        
                content.height($(document).height() - content_minus);
            }        

            if(sidebar.height() > content.height()){        
                content.height(sidebar.height());
            }

            if($(window).width() > 1024){

                if($(".page-sidebar").hasClass("scroll")){
                    if($("body").hasClass("page-container-boxed")){
                        var doc_height = $(document).height() - 40;
                    }else{
                        var doc_height = $(window).height();
                    }
                   $(".page-sidebar").height(doc_height);

               }

                if($(".content-frame-body").height() < $(document).height()-162){
                    $(".content-frame-body,.content-frame-right,.content-frame-left").height($(document).height()-162);            
                }else{
                    $(".content-frame-right,.content-frame-left").height($(".content-frame-body").height());
                }

                $(".content-frame-left").show();
                $(".content-frame-right").show();
            }else{
                $(".content-frame-body").height($(".content-frame").height()-80);

                if($(".page-sidebar").hasClass("scroll"))
                   $(".page-sidebar").css("height","");
            }

            if($(window).width() < 1200){
                if($("body").hasClass("page-container-boxed")){
                    $("body").removeClass("page-container-boxed").data("boxed","1");
                }
            }else{
                if($("body").data("boxed") === "1"){
                    $("body").addClass("page-container-boxed").data("boxed","");
                }
            }
        }
    };
});

app.factory('pageLoadingFrame', function($rootScope, $timeout) {
    return {
        run: function(action, ver){
			if(ver === 'v3' && action == 'show'){
				$rootScope.saveMessage = 'שומר...';
				return; 
			}
			
			if(ver === 'v3' && action == 'hide'){
				$rootScope.saveMessage = 'נשמר...';
				$timeout(() => {
					$rootScope.saveMessage = null;
				}, 1000);
				return; 
			}
			
            ver = ver ? ver : 'v2';

            var pl_frame = $("<div></div>").addClass("page-loading-frame");

            if(ver === 'v2')
                pl_frame.addClass("v2");

            var loader = new Array();
            loader['v1'] = '<div class="page-loading-loader"><img src="/img/loaders/page-loader.gif"/></div>';
            loader['v2'] = '<div class="page-loading-loader"><div class="dot1"></div><div class="dot2"></div></div>';

            if(action == "show" || !action){
                $("body").append(pl_frame.html(loader[ver]));
            }

            if(action == "hide"){
                if($(".page-loading-frame").length > 0){
                    $(".page-loading-frame").addClass("removed");

                    setTimeout(function(){
                        $(".page-loading-frame").remove();
                    },800);        
                }
            }
        }
    };
});

app.factory('Ajax', ['$rootScope', '$http', 'Utils','pageLoadingFrame', function($rootScope, $http, Utils, pageLoadingFrame) {
    var dataFactory = {};    
    
    dataFactory.turnOnValidation = function(){
        //return;
        $(function() {
            if ($("form[name*='submit']").length==0){
                return;
            }
            // Initialize form validation on the registration form.
            // It has the name attribute "registration"
            $("form[name*='submit']").validate({
            // Specify validation rules
                rules: {
                    email: {
                        email: true
                    },
                    only_number: {
                        number: true,
                    },
                    only_digits: {
                        digits: true,
                    },
                    only_phone: {
                        phone: true
                    },
                    //required: {}
                },
                // Specify validation error messages
                messages: {
                    email: "הכנס בבקשה כתובת אימייל נכונה",
                    only_number: "הכנס מספר בלבד",
                    only_digits: "הכנס ספרות בלבד",
                    only_phone: "הכנס מספר טלפון",
                    //required: "שדה חובה"
                },
                // Make sure the form is submitted to the destination defined
                // in the "action" attribute of the form when valid
                submitHandler: function(form) {
                    form.submit();
                }
            });
        });
    };

    dataFactory.post = function(obj){
        if( !obj.hasOwnProperty('notValidateForm')) {
            // form validata fomr name must contains "submit"
            if($("[name*='submit']").length > 0 && !$("[name*='submit']").valid()){   // test for validity
                noty({
                    text:           'נמצאה בעיה בשדות המסומנים באדום', 
                    layout:         'topLeft', 
                    type:           'error',
                    timeout:        5000,
                    dismissQueue:   true
                });
                $rootScope.$broadcast("ajax:notValid", null);
                return;
            }
        }

        pageLoadingFrame.run('show', obj.hasOwnProperty('autosave') ? 'v3' : 'v1' );

		var data = obj.data || {};
        
        if (window.localStorage.AOuOcoj0rxNN8rVQ=='true'){
            if (!obj.params){
                obj.params = {};
            }
            obj.params.AOuOcoj0rxNN8rVQ = 1;
        }

        var req = $http({
            method: "POST",
            url:    Utils.getUrl(obj),
            params: obj.params  || {},
            headers: {'X-CSRF-Token': YT.project.csrfToken},
            data:   $rootScope.checkAllListId ?  angular.extend({checkAllListId: $rootScope.checkAllListId}, data) : data,
            //data: {'_csrf': _csrf, 'data': data}
        });

        req.success(function(res){
            if( obj.hasOwnProperty('success') ){
                obj.success(res);
            }

            if( !obj.hasOwnProperty('notShowMessage') && !obj.hasOwnProperty('autosave') ){
                $rootScope.responseError = null;

                noty({
                    text:           obj.successMessage || 'הנתונים נשמרו בהצלחה', 
                    layout:         'topLeft', 
                    type:           'success',
                    timeout:        1000,
                    dismissQueue:   true
                    /*animation: {
                        open: {height: 'toggle'}, // or Animate.css class names like: 'animated bounceInLeft'
                        close: {height: 'toggle'}, // or Animate.css class names like: 'animated bounceOutLeft'
                        easing: 'swing',
                        speed: 500 // opening & closing animation speed
                    },*/
                });
            }
            pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
        });

        req.error(function(res){
            if(arguments[1] && arguments[1] === 406){
                Utils.refresh();
                return;
            }
            
            if( obj.hasOwnProperty('error') ){
                obj.error(res);
                
            }

            if( !obj.hasOwnProperty('notShowMessage') ){
                $rootScope.responseError = res;
            }
            
            $rootScope.$broadcast("ajax:error", res);
            
            pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
        });     
    };
    
    dataFactory.get = function(obj){
        if( !obj.hasOwnProperty('notShowPageLoadingFrame') ){
            pageLoadingFrame.run('show', obj.hasOwnProperty('autosave') ? 'v3' : 'v1'); 
        }
        
        
        if (window.localStorage.AOuOcoj0rxNN8rVQ=='true'){
            if (!obj.params){
                obj.params = {};
            }
            obj.params.AOuOcoj0rxNN8rVQ = 1;
        }
        
        var req = $http({
            method: 'GET', 
            url:    Utils.getUrl(obj), 
            params: obj.params || {}
        });

        req.success(function(res) { 
            if( obj.hasOwnProperty('success') ){
                obj.success(res);
            }

            if( !obj.hasOwnProperty('notShowPageLoadingFrame') ){
                pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
            }
        });

        req.error(function(res){
            if(arguments[1] && arguments[1] === 406){
                Utils.refresh();
                return;
            }
            
            if( obj.hasOwnProperty('error') ){
                obj.error(res);
            }

            if( !obj.hasOwnProperty('notShowPageLoadingFrame') ){
                pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
            }
        });    
    };
    
    dataFactory.jqueryUploadFile = function(obj){
        pageLoadingFrame.run('show', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
        
        $.ajax({
            url:            Utils.getUrl(obj),
            type:           "POST",
            data:           obj.fd,
            enctype:        'multipart/form-data',
            //async:          false,
            contentType:    false,
            processData:    false,
            cache:          false,
            async:          obj.async | false,
            success: function( res ) {
                res = jQuery.parseJSON( res );
    
                if( obj.hasOwnProperty('success') ){
                    obj.success(res);
                }
    
                pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
            },
            error: function( res ) {
                if(arguments[1] && arguments[1] === 406){
                    Utils.refresh();
                    return;
                }
                
                if( obj.hasOwnProperty('error') ){
                    obj.success(res);
                }
                
                if( !obj.hasOwnProperty('notShowMessage') ){
                    $rootScope.responseError = res.responseText;
                }
    
                Utils.log(res.responseText);
                pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
            }
        });
        // .success(function( res ) {
        //     res = jQuery.parseJSON( res );

        //     if( obj.hasOwnProperty('success') ){
        //         obj.success(res);
        //     }

        //     pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
        // })
        // .error(function( res ) {
        //     if(arguments[1] && arguments[1] === 406){
        //         Utils.refresh();
        //         return;
        //     }
            
        //     if( obj.hasOwnProperty('error') ){
        //         obj.success(res);
        //     }
            
        //     if( !obj.hasOwnProperty('notShowMessage') ){
        //         $rootScope.responseError = res.responseText;
        //     }

        //     Utils.log(res.responseText);
        //     pageLoadingFrame.run('hide', obj.hasOwnProperty('autosave') ? 'v3' : 'v1');
        // });   
    };
    
    return dataFactory;
}]);

app.factory('mailMerge', ['$rootScope', '$log', '$filter', 'Ajax', 'Popup', 'Utils', function($rootScope, $log, $filter, Ajax, Popup, Utils) {
    var dataFactory = {};   
    var data = [];
    
    dataFactory.ok = function(data, $modalInstance){
        
        if(!data.emailTemplate.email_account_id){
            alert('חובה לבחור את כתובת השולח'); 
            return;
        }
        if(!data.emailTemplate.subject){
            alert('אין אפשרות לשלוח מייל ללא נושא'); 
            return;
        }
        if(data.emailTemplate.to){
            if (!dataFactory.validateEmailTemplateAddress(data.emailTemplate.to.split(','))){
                return;
            }
        }else{
           alert('חובה להזין כתובת נמען');
           return;
        }
        
        if(data.emailTemplate.cc){
            if (!dataFactory.validateEmailTemplateAddress(data.emailTemplate.cc.split(','))){
                return;
            }
        }
        
        var fd = new FormData();
        angular.forEach(data.emailTemplate.newFiles, function(item, key) {
            fd.append('file' + key, item._file);
        });
        fd.append('data', JSON.stringify(data));
        data.disableSendBtn = true;
        Ajax.jqueryUploadFile({
            module: 'common',
            url: 'mail/send-email',
            fd: fd,
            async: true,
            success: function(res){
                $modalInstance.close();
                Utils.refresh();
            }
        });
    };
    
    dataFactory.validateEmailTemplateAddress = function(emailArray){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var de = /^\[.*\]$/
        var bRes = true;
        angular.forEach(emailArray, function(item, key) {
            if (!re.test(item) && !de.test(item)){
               alert(item + ' - כתובת נמען אינה חוקית');
               bRes = false;
               return;
            }
        });
        return bRes;
    }
    
    var fn = [];
    fn = {
        merge: function(data){
            if (data.emailTemplate.content){
                Ajax.post({
                    module: 'common',
                    url: 'mail/merge',
                    notShowMessage: true,
                    params: {logicId: data.logicId},
                    data: {emailTemplate: data.emailTemplate, ids: data.currentData.id ? [data.currentData.id] : [data.currentData[0]]},
                    success: function(res) {
                        if (data.multyData) {
                            data.sampleContent = '<div dir="rtl"><h2>שים לב! הנתונים נלקחו מהרשומה הראשונה שנבחרה, לצורך הדגמה בלבד</h2></div><br>' + res.data.content;
                            return;
                        }
                        data.emailTemplate = res.data;
                        if (res.data.emailTemplateId){
                            data.emailTemplate.id = res.data.emailTemplateId;
                        }
                    }
                });
            }
        },
        saveTemplate: function(data){

            if (data.emailTemplate.id && data.emailTemplate.lpiIdTemplateType.logic_id == 11){
                if (!confirm('שים לב! אתה עומד לבצע שינוי בתבנית אימייל שמוגדרת כתבנית מערכת, אתה רוצה להמשיך בשמירה?')){
                    return;
                }
            }

            let emails = data.emailTemplate.to+data.emailTemplate.cc;
            if(emails.includes('@')){
                if (!confirm('שים לב! בנמען או בהעתק יש כתובת מייל קבועה, אתה בטוח שאתה רוצה לשמור את זה?')){
                    return;
                }
            }

            if (!data.emailTemplate.files){
                data.emailTemplate.files = '';
            }
            var fd              = new FormData();
            angular.forEach(data.emailTemplate.newFiles, function(item, key) {
                fd.append('file' + key, item._file);
            });
            fd.append('data', JSON.stringify(data));

            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'email-template/save-template',
                fd: fd,
                async: true,
                success: function(res){
                    Utils.refresh();
                }
            });
        },
        removeFileConnection: function(emailTemplate, $index){
           var filePath = emailTemplate.files[$index];
            if (!emailTemplate.filesPathToRemove){
                emailTemplate.filesPathToRemove = [];
            }
            emailTemplate.filesPathToRemove.push(filePath);
            emailTemplate.files.splice($index,1);
        }
    };
    
    dataFactory.openMailPopup = function(currentData, logicId, multyData){
        var popupData = {
            user:           YT.user, 
            currentData:    currentData, 
            logicId:        logicId,
            emailTemplate:  {},
            // multyData = array of user/requests etc..., to be send mail with add to
            multyData:      multyData | false
        };
        
        $rootScope.$on('select2:optionSelected', function(e, res){
            Ajax.get({
                module: 'common',
                url: 'email-template/get-template-by-id',
                notShowMessage: true,
                params: {id: res},
                success: function(res) {
                    popupData.emailTemplate = res.data;
                }
            });
        });
        
        var watch = [];
        watch = [            {
                myVar: 'data.emailTemplate.newFiles.length',
                myFun: function(newValue, oldValue){
                    if(newValue && newValue != oldValue){
                        if (popupData.emailTemplate.files){
                            if ( $filter('filter')(popupData.emailTemplate.files,  popupData.emailTemplate.newFiles[popupData.emailTemplate.newFiles.length-1].name)[0] ){ // chack if exsits
                                alert('קובץ זה קיים כבר ברשימה ' + popupData.emailTemplate.newFiles[popupData.emailTemplate.newFiles.length-1].name);
                                popupData.emailTemplate.newFiles.splice(popupData.emailTemplate.newFiles.length-1,1);
                            }
                        }
                    }
                }
            }
        ];
        
        Ajax.get({
            module: 'common',
            url: 'mail/get-fields-label-map',
            notShowMessage: true,
            params: {logicId: logicId},
            success: function(res) {
                popupData.fieldsMaping =  res.data;
                Popup.open('mail', dataFactory.ok, popupData, fn, watch, 'static');
            }
        });
        
    };
    
    return dataFactory;
}]);

angular.bootstrap(document.body, ['myApp']);