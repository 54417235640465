'use strict';

app.directive('viewTags', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngModel',
        restrict: 'AE',
        replace: false,
        scope: false,
        templateUrl: '/js/app/directives/viewTags/tpl.html',
        link: function(scope, element, attrs) {
            scope.logicId       = attrs.logicId;
            scope.nameSingle    = attrs.nameSingle ? attrs.nameSingle : 'קבוצה';
            scope.namePlural    = attrs.namePlural ? attrs.namePlural : 'קבוצות';
        }
    };
}]);