'use strict';

app.controller('EhpInvoiceController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tIsSelected          = [];
    $scope.treatmentIdsToAdd    = [];
    $scope.invoice              = {};
    $scope.invoice.due_amount   = Number(0);
    $scope.invoice.quantity     = Number(0);
        
    $scope.checkAll = function(checked){
        $scope.invoice.due_amount   = Number(0);
        $scope.invoice.quantity     = Number(0);
        $("input[name=treatmentCheck]").each(function(i) {
             $(this).prop('checked', checked);
             $scope.tIsSelected[i] = checked;
             if (checked == true){
                 $scope.addTreatmentToInvoice(i);
             }
         });
    };
    
    $scope.init = function(mod){
        $scope.mod = mod;
        //if ($scope.mod == 'edit'){
            Ajax.get({
                url: 'invoice/get-invoice-details',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.invoice = res.invoice;
                    $scope.treatments = res.invoice.treatments;
                },
            });
        //}
    };
        
    $scope.init();
    
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param['upload_source']  = '(פורטל)';
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
//            var obj = {};
//            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-invoice-file?id=' + $scope.invoice.id,
                fd: fd,
                success: function(res){
                   $scope.init();
                }
            });
        }
    };
}]);