'use strict';

app.controller('SpLeadController', ['$scope', '$filter', 'Ajax', 'Utils', 'mailMerge', 'Popup', function($scope, $filter, Ajax, Utils, mailMerge, Popup){
    $scope.profileSrc       =  '/img/profile.png';
    $scope.avilableAction   = false;
    
    $scope.$on('cru:getSuccess', function(e, mod, res){
        if (!res.data){
            return;
        }
        $scope.existsUsers = res.existsUsers;
        $scope.existsLeads = res.existsLeads;
        
        var status = $scope.data.lpi_id_status;
        
        if(status == 67 || status == 134 || status == 135){
            $scope.avilableAction = true;
        }else{
            $scope.avilableAction = false;
        }
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(window.location.href.includes('create')){
            Utils.redirect('/singleparent/lead/update?id=' + res.data.id);
        }
    });
    
    $scope.linkAccount = function(user_id){
        Ajax.post({
            url:     'lead/link-account',
            params: {id: Utils.GET().id, user_id: user_id, createOrder: $scope.data.createOrder},
            success: function(res){
                $scope.$broadcast("cru:refresh");
            }
        });
    };
    
    $scope.createAccount = function(existUserId/*data, $modalInstance*/){
        
        if ($scope.data.createOrder == 1 && (!$scope.data.lpi_id_order_status || !$scope.data.program_id) ){
            alert('סטטוס הזמנה ותוכנית הם שדות חובה כדי ליצור הזמנה בהפיכת הליד לחשבון');
            return;
        }

        $scope.data.userMoreDetails = {
            email: $scope.data.email 
        };
        
//        $scope.data.sendWellcomeMail        = data.sendWellcomeMail;
//        $scope.data.createFileConnections   = data.createFileConnections;
//        $scope.data.createRequest           = data.createRequest;

        $scope.data.lpi_id_status = '69';
        
        Ajax.post({
            url:     'single-parent-user/add-single-parent-user',
            data:    $scope.data,
            params: {id: Utils.GET().id, existUserId: existUserId},
            success: function(res1){
                Ajax.post({
                    url: 'lead/save-lead',
                    params: {id: Utils.GET().id},
                    data: {lpi_id_status: 69, user_id: res1.data.id},
                    notShowMessage: true,
                    success: function(res2){
                        $scope.data     = res2.data;
                        $scope.editData = angular.copy($scope.data);
                        
                        $scope.$broadcast("cru:refresh");
                    }
                });
//                if ($modalInstance){
//                    $modalInstance.close();
//                } 
            }
        });
    };
    
    $scope.rejectLead = function(/*data, $modalInstance*/){
        $scope.data.lpi_id_status = 70;// 70 = נדחה
        
        var sendRejectMail;
        if( confirm('לחיצה על אישור תשלח מייל לפונה ותדחה את הפניה, לחיצה על ביטול תדחה את הפניה בלי לשלוח מייל לפונה') ){
            sendRejectMail = 1;
        }

        Ajax.post({
            url: 'lead/save-lead',
            params: {id: Utils.GET().id, sendRejectMail: sendRejectMail ? sendRejectMail : null},
            data:   $scope.data,
            success: function(res){
                Ajax.post({
                    url: 'lead/save-lead',
                    params: {id: Utils.GET().id},
                    data: {lpi_id_status: 70},
                    success: function(res){
                        $scope.data     = res.data;
                        $scope.editData = angular.copy($scope.data);
                        $scope.$broadcast("cru:refresh");
                    }
                });
            
            }
        });
    };

    $scope.$on("activitiesUserCount:newCount", function(e, res) {
        $scope.userActivitiesCount();
    });
    
    // get student's activities that they are not done, and date past already
    $scope.userActivitiesCount = function(){
        Ajax.get({
            module: 'common',
            url: 'activity/get-activities-count-by-record-id',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.leadActivitiesCount = res.activities_count;
            }
        });
    };
    
    $scope.userActivitiesCount();
    
    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,153); // 1 = student
    };

    // get lead's activities that they are not done, and date past already
    
}]);