'use strict';

app.directive('committeeComment', function($compile){
    return {
        restrict: 'AEC',
        replace: false,
        templateUrl: 'js/app/partials/committeeCommentTpl.html',
        link: function(scope, elem, attrs) {
            scope.active = false;
            
            elem.prev().bind('click', function() {
                scope.$apply(function () {
                    scope.active = !scope.active;            
                });
            });
            
            elem.prev().bind('mouseover', function() {
                elem.prev().css('cursor', 'pointer');
            });
        }
    };
});