'use strict';

// changeStatusPopupController use in both controller - AcademicYearController & AcademicSemesterController
var changeStatusPopupController = function($scope, $modalInstance, $modal, Ajax, selected, controllerName){
    $scope.data = {
        status: null
    };
    
    Ajax.get({
        url: 'academic-study-year/get-file-check-status',
        success: function(res){
            $scope.picklist = res.picklist;
        }
    });
    
    
    $scope.ok = function(){
        if( !$scope.data.status ){
            return;
        }
        
        Ajax.post({
            url: controllerName  + '/set-file-status',
            data:   {ids: selected},
            params: {status_id: $scope.data.status},
            success: function(res){
                $modalInstance.close(res);
            }
        });
    };
    
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};


app.controller('AcademicSemesterController', ['$scope', 'Ajax', '$modal', 'Utils', function($scope, Ajax, $modal, Utils) {
        
    $scope.changeStatus = function(){
        var selected = $('#w7').yiiGridView('getSelectedRows');
        if( selected.length <= 0 ){
            alert('בחר רשומות לפני לחיצה על שינוי סטטוס');
            return;
        }
        
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/gridStudyChangeStatusTpl.html' + window.YT.project.version,
            controller: changeStatusPopupController,
            size: 'sm',
            resolve: {
                selected: function () {
                    return selected;
                },
                controllerName: function(){
                    return 'academic-study-semester';
                }
            }
        });
        
        modalInstance.result.then(function (res) {
            $.pjax.reload({container:'#w7-container'});
        }, function () {

        });
    };
    
}]);
