'use strict';

app.directive('selectTag', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngModel',
        restrict: 'AE',
        replace: false,
        scope: false,
        templateUrl:'/js/app/directives/selectTag/tpl.html',
        link: (scope, element, attrs) => {
			scope.listid = attrs.listid ? attrs.listid : 'select_tag_list';
			scope.items = [];
			var sel2 = null;

			// scope.init can not be use because scope = false and parent has scope.init
			scope.initSelectTag = () => {
				scope.listid = scope.listid + '_' + attrs.logicId;
				Ajax.get({
					module: 'common',
					url: 'tag/get-tags', 
					params: {logicId: attrs.logicId ? attrs.logicId : null},
					success: (res) => {
						scope.initSelect2(res);
					}
				});    
			};

			$timeout(function() {
                scope.initSelectTag();
            });

			scope.changeTag = (e) => {
				if ($('#'+scope.listid).val()){
					var selectionArr = $('#'+scope.listid).val();
					Ajax.post({
						module: 'common',
						url: 'tag/save-tag-if-not-exist', 
						params: {name: selectionArr[selectionArr.length-1], logicId: attrs.logicId ? attrs.logicId : null},
						success: function(res){
							if (res.data == 'not_allow'){
								selectionArr.splice(selectionArr.length-1,1);
								$('#'+scope.listid).val(selectionArr);
								sel2.trigger('change');
								alert('אינך רשאי להוסיף תגיות/קבוצות חדשות, אנא פנה למנהל המערכת');
							}
						},
						notShowMessage: true
					});
				}
				
				scope.data.tags = $('#'+scope.listid).val() ? $('#'+scope.listid).val().join('|') : null;
			};

			scope.initSelect2 = (res) => {
				sel2 = element.find('select').select2({
					data: res.items,
					minimumInputLength: 0,
					placeholder: "בחר " + ((attrs.tagName)?attrs.tagName:'קבוצה') + " להוספה...",
					multiple: 'multiple',
					tags: true,
					selectOnBlur: true,
					width: attrs.selWidth ? attrs.selWidth : 'resolve',
				}).on("change", scope.changeTag);

				var listener = scope.$watch("data.tags", (newValue, oldValue) => {	
					if(newValue){
						$('#'+scope.listid).val(scope.data.tags.split('|'));
						sel2.trigger('change');
						listener();
					}
				});
			};            
		}
    };
}]);