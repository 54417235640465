'use strict';

app.controller('g-committee', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    $scope.unsignCommittees = {};
    
    $scope.getCommitteeDetails = function() {
        Ajax.get({
            module: 'general',
            url: 'committee/get-committee-details',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.committee = res.committee;
            }
        });
    };

    $scope.init = function() {
        Ajax.get({
            module: 'general',
            url: 'committee/get-my-unsign-committees',
            success: function(res){
                //console.log(res);
                $scope.unsignCommittees = res.unsignCommittees;
            }
        });
        if (Utils.GET().id) {
            $scope.getCommitteeDetails();
        }
    };
    
    $scope.init();
    
    
    
    $scope.saveCommittee = function(){
        Ajax.post({
            module: 'general',
            url:    'committee/update-committee',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.getCommitteeDetails();
            }
        });
    };

    $scope.openSignaturePopup = function(user) {
        var fn = [];
        fn = {
            sign: $scope.sign
        };
        user.project_id = $scope.committee.project_id;
        Popup.open('common/signature', {}, user, fn, {}, 'static', 'md');
    };

    $scope.sign = function(user) {
        if (YT.mvc.module=='academy') {
            if ($scope.committee.lpi_id_committee_status != 47) {
                $scope.committee.lpi_id_committee_status = 47;
            }
            user.sign = 'yes';
            $scope.saveCommittee();
        }else{
            Ajax.post({
                module: 'common',
                url: 'picklist/get-item-id-by-logic-id', 
                params: {logicId: 15}, // 15 = ועדה חתומה
                notShowMessage: true,
                success: function(res) {
                    if ($scope.committee.lpi_id_committee_status != res.data) {
                        $scope.committee.lpi_id_committee_status = res.data;
                    }
                    user.sign = 'yes';
                    $scope.saveCommittee();
                }
            });
        }
    };
}]);