'use strict';

app.controller('AcademicAllRequestController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils) {
    $scope.requests = null;
    $scope.user_id  = Utils.GET().id;
    
    $scope.$watch('tabSelected', function(num) {
        if(num === 4 && !$scope.requests){
            Ajax.get({
                url: 'request/get-all-requests', 
                params: {user_id: $scope.user_id},
                success : function(res){
                    $scope.requests         = res.requests;
                    $scope.budgetRequests   = res.budgetRequests;
                    $scope.mainRequest      = $scope.$parent.data.userStudent.request_id_main;
                }   
            });
       }
    });
    
    $scope.setMainRequest = function(id, $index){
        Ajax.post({
            url: 'request/set-main-request', 
            params: {id: id, userId: $scope.user_id},
            success : function(res){
                $scope.mainRequest = res.data.mainRequestId;
                for (var i = 0; i < $scope.requests.length; i++){
                    $scope.requests[i].is_main_request = 0;
                }
                $scope.requests[$index].is_main_request = 1;
            }
        });
    };
}]);