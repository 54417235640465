'use strict';

app.controller('StudentDiscussedCtrl', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils) {
        
    $scope.init = function(){
        Ajax.get({
            url: 'committee/get-student-request',
            params: {id: Utils.GET().id},
            success: function(res){
                $scope.students         = res.students;
                $scope.amount           = res.amount;
                $scope.requestHistory   = res.requestHistory;


            }
        });
    };    
     
    $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
        $(".knob").knob();
    });
    
    $scope.init();
}]);