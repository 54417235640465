'use strict';

app.controller('PortalActiveStudiesSemestersController', ['$scope', 'Ajax', '$modal', function($scope, Ajax, $modal){

    $scope.$on("fileViewUpload:uploadSuccess", function(e, file_connection_id) {
        $scope.$broadcast("viewData:refresh");
    });
    
    $scope.$on("fileViewUpload:deleteSuccess", function(e, res){
        $scope.$broadcast("viewData:refresh");
    });
    
    $scope.submitSemesterFile = function(doc){
        Ajax.post({
            url: 'active/submit-semester-file',
            params: {file_connection_id: doc.id}, 
            success: function(res){
                $scope.data = res.data;
            }
        });
    };
    
    $scope.openGradePopup = function(sem){
        
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/portalActiveGradesTpl.html',
            controller: function($scope, $modalInstance, $modal, $filter, Ajax, sem){
                $scope.mod = (!sem.doc || sem.doc.access_action === 'edit' || sem.doc.access_action === null)  ? 'edit' : 'view';
                
                $scope.grades       = sem.grades;
                $scope.editGrades   = angular.copy($scope.grades);
                
                $scope.addGrade = function(){
                    if( !$scope.grades )  {
                        $scope.grades = [];
                    }

                    $scope.grades.push( {name: '', grade: '', academic_credit: '', status: '', course_number: '', course_cost: '', edit: true}  );
                };
    
                $scope.saveGrade = function(key, grade){
                    Ajax.post({
                        url: 'active/grade',
                        data: angular.extend({academic_study_semester_id: sem.semester_id}, grade),
                        success: function(res){
                            $scope.grades      = res.data;
                            $scope.editGrades  = angular.copy($scope.grades);
                            grade.edit = false;
                        }
                    });
                };
                
                $scope.deleteGrade = function(key, grade){
                    Ajax.post({
                        url: 'active/delete-grade',
                        params: {grade_id: grade.id}, 
                        success: function(res){
                            var index = $scope.grades.indexOf(grade);
                            $scope.grades.splice(index, 1);
                            $scope.editGrades  = angular.copy($scope.grades);
                        }
                    });
                };

                $scope.cancelGrade = function(key, g){
                    if( !g.id ){
                        $scope.grades.splice(key, 1);
                    }else{
                        $scope.grades[key] = angular.copy($scope.editGrades[key]);
                    }
                };
    
                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                };
            },
            size: 'lg',
            resolve: {
                sem: function () {
                    return sem;
                }
            }
        });
        
        modalInstance.opened.then(function() {
        });
        
        modalInstance.result.then(function (/*committee*/) {
            $scope.$broadcast("viewData:refresh");
        }, function () {
            $scope.$broadcast("viewData:refresh");
        });
    };
        
}]);