'use strict';

app.controller('EhDefaultController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    //$scope.ordersReport = {};
    $scope.yearId = null;

    $scope.init = function(){
        Ajax.get({
            module: 'common',
            url: 'settings/get-current-academic-year',
            success: function(res) {
                $scope.yearId = res.currentAcademicYear.value;
                // $scope.getReport();
            }
        });
    };
        
    if(!window.location.href.includes('yearId')){
        $scope.init();
    }else{
        $scope.yearId = Utils.GET().yearId;
    }

    

    $scope.$watch('yearId', function(newValue, oldValue) {
        if (oldValue && newValue && newValue != oldValue){
            Utils.redirect('/elhanefesh/default/index?yearId=' + newValue);
        }
    });
}]);