'use strict';

app.directive('select2Picklist', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngModel',
        restrict: 'AE',
        replace: false,
        scope: {picklist: '@'},
        templateUrl:'/js/app/directives/select2Picklist/tpl.html',
        link: (scope, element, attrs) => {
			scope.listid = attrs.listid ? attrs.listid : 'select_tag_list';
			//scope.items = [];
			// scope.selectedItems = 
			var sel2 = null;

			// scope.init can not be use because scope = false and parent has scope.init
			scope.initSelectTag = () => {
				scope.listid = scope.picklist;
				Ajax.get({
					module: 'common',
					url:	'picklist/get', 
					params: {name: scope.picklist},
					success: function(res) { 
						res.items = [];
						res.forEach((item) => {
							res.items.push({id: item.id, text: item.name});
						})
						scope.initSelect2(res);
					}
				});
			};

			$timeout(function() {
                scope.initSelectTag();
            });

			scope.changeTag = (e) => {
				var d = e;
			};

			scope.initSelect2 = (res) => {
				sel2 = element.find('select').select2({
					data: res.items,
					minimumInputLength: 0,
					placeholder: "בחר " + ((attrs.tagName)?attrs.tagName:'קבוצה') + " להוספה...",
					multiple: 'multiple',
					tags: true,
					selectOnBlur: true,
					width: attrs.selWidth ? attrs.selWidth : 'resolve',
				}).on("change", scope.changeTag);

				var listener = scope.$watch("data.tags", (newValue, oldValue) => {	
					if(newValue){
						$('#'+scope.listid).val(scope.data.tags.split('|'));
						sel2.trigger('change');
						listener();
					}
				});
			};            
		}
    };
}]);