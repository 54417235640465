'use strict';

app.controller('PortalSendRequestController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){    
    $scope.mod = 'beforeLoad';    
    
    $scope.validationRequest = function(){
        Ajax.post({
            module: 'academyportal',
            url:    'initial/portal-validation-request',
            notShowMessage: true,
            params: {user_id: $scope.globals.user.user_id},
            success: function(res){
                $scope.mod =  'success';
            },
            error: function(res){
                $scope.mod =  'error';
                $scope.errors = res;
            }
        });
    };
    
    $scope.sendRequest = function(){
        Ajax.post({
            module: 'academyportal',
            url:    'initial/portal-send-request',
            params: {user_id: $scope.globals.user.user_id},
            success: function(res){
                window.location.reload(true);
            }
        });
    };
}]);