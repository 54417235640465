'use strict';

app.directive('cr21UserCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl:'/js/app/directives/cr21UserCard/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
            
            // scope.$on('ihpt:getIhptClientSuccess', function(e, res){
            //     scope.serviceException = res.serviceException;
            // });
            
            // scope.$on('ihpt:ihptClientChanged', function(e, res){
            //     scope.init();
            // }); 
            
            scope.init = function(){
                Ajax.get({
                    module: 'cr21',
                    url: 'cr21-user/get-cr21-user-details',
                    params: {id: scope.objectid},
                    success: function(res) { 
                        scope.data = res.data;
                        scope.budgetRequests = res.budgetRequests;
                        scope.$emit('cr21User:getSuccess', 'view', res);

                        if(scope.data){
                            if(scope.data.birth_date){
                                scope.data.age = Utils.getAge(scope.data.birth_date);
                            }
                            if (scope.data.tags)  {
                                scope.tagsArr = scope.data.tags.split("|");
                            }
                        }
                    }
                });
            };
            scope.init();
            
            scope.save = function(){
                // this save function for saving tags in view mode
                Ajax.post({
                    module: 'cr21',
                    url: 'cr21-user/save', 
                    params: {id: scope.data.id},
                    data: scope.data,
                    success: function(res) { 
                        if(res.data.tags!=null){
                            scope.tagsArr = res.data.tags.split("|");
                            if(scope.tagsArr[scope.tagsArr.length-1]==''){
                                scope.tagsArr.splice(0,1);
                            }
                        }
                    }
                });
            };
            
            scope.openMailPopup = function(br){
                if (!br){
                    mailMerge.openMailPopup(scope.data, 102); // 102 = cr21 user logic id
                }else{
                    mailMerge.openMailPopup(br,112); // 112 = BudgetRequest logic id
                }
            };
		}
    };
}]);
