'use strict';

app.directive('yearMask', ['$filter', '$timeout', function ($filter, $timeout) {
    return{
        require: '?ngModel',
        restrict: 'AEC',
		scope: {
			model:	'=ngModel'
        },
        link: function(scope, elem, attrs) { 
			scope.checkMinMax = (year) => {
				if(attrs.linkedtoid && $('#' + attrs.linkedtoid).val().length != 0 ){
					return ( year > $('#' + attrs.linkedtoid).val() ) ? false : true;
				}else if(attrs.linkedfromid && $('#' + attrs.linkedfromid).val().length != 0 ){
					return ( year < $('#' + attrs.linkedfromid).val() ) ? false : true;
				}
				
				return true;
			};
			
			elem.css({direction: 'ltr'});
			
			elem.mask("0000", {
				placeholder: "____",
				onKeyPress: (cep, event, currentField, options) => {
					if( cep.length === 4 && !scope.checkMinMax(cep) ){
						let contentTr = angular.element('<span class="help-block">תאריך התחלה גדול מתאריך סיום</span>');
						contentTr.insertAfter(elem);

						$timeout( () => {
							contentTr.css('display', 'none');
						}, 2000);

						scope.$apply(() => {    
							scope.model = null;
						});
					}
				}
			});
        }
    };
}]);

