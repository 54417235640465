'use strict';

app.controller('PageController', ['$scope', '$http', '$filter', '$modal', 'Ajax', 'Utils', function($scope, $http, $filter, $modal, Ajax, Utils) { 
    
    $scope.mod;
    
    $scope.init = function(mod){
        $scope.mod = mod;
        $scope.page = {};
        if ($scope.mod === 'edit'){
            Ajax.get({
                url: 'page/page-details',
                params: {id: Utils.GET().id},
                success: function(data){
                    $scope.page      = data.page;
                    $scope.editPage  = angular.copy($scope.page);
                }
            });
        }else{
            $scope.page.is_active = 'yes';
            $scope.page.is_portal_available = 'no';
        }
    };
    $scope.savePage = function(){
        if( $scope.mod === 'edit' && !angular.equals($scope.editPage, $scope.page) ){   
            Ajax.post({
                url: 'page/edit-page',
                params: {id: Utils.GET().id},
                data: $scope.page,
                success: function(res){
                    $scope.page      = res.data;
                    //$scope.editPage  = angular.copy($scope.page);
                    Utils.redirect('/adminsettings/page/view?id=' + Utils.GET().id);
                },
            });
        }else if($scope.mod === 'add'){
            Ajax.post({
                url: 'page/add-page',
                data:   $scope.page,
                success: function(res){
                    $scope.page = res;
                    Utils.redirect('/adminsettings/page/view?id=' + res.data.page.id);
                }
            });
        }
    };
    $scope.getDate = function(iDate){
        var today = new Date();
        var commentDate = new Date(iDate);
        if (iDate == null || commentDate == 'Invalid Date'){
            return 'Invalid Date';
        }
        var strCommentDate4Compare = commentDate.getDate().toString()+commentDate.getMonth().toString()+commentDate.getFullYear().toString();
        var strToday4Compare = today.getDate().toString()+today.getMonth().toString()+today.getFullYear().toString();
        if (strCommentDate4Compare === strToday4Compare){
            var hour = commentDate.getHours()<10?"0"+commentDate.getHours():commentDate.getHours();
            var minute = commentDate.getMinutes()<10?"0"+commentDate.getMinutes():commentDate.getMinutes();
            var secound = commentDate.getSeconds()<10?"0"+commentDate.getSeconds():commentDate.getSeconds();
            return "היום ב " + hour + ":" + minute + ":" + secound;
        }
        var iMonth = commentDate.getMonth()+1;
        var day = commentDate.getDate()<10?"0"+commentDate.getDate():commentDate.getDate();
        var month = iMonth<10?"0"+iMonth:iMonth;
        return  "ב " + day + "/" + month + "/" + commentDate.getFullYear();
    };
}]);