'use strict';

app.controller('EhTreatmentGroupController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', 'mailMerge', function($scope, $timeout, $filter, Ajax, Utils, Popup, mailMerge) {
    $scope.tIsSelected              = [];
    $scope.treatmentIsSelected      = [];
    $scope.requestsIdsToAdd         = [];
    $scope.showMembers              = [];
    $scope.data           = {};
    $scope.data.therapist = {};
    $scope.disabledSaveBtn          = false;
    $scope.tagsArr = [];
        
    $scope.init = function(mod){
        $scope.mod = mod;
        if (Utils.GET().id){
            Ajax.get({
                url: 'treatment-group/get-treatment-group-details',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.data = res.data;
                    $scope.tagsToArr();
                },
            });
            $scope.getRequests();
            $scope.getTreatmentGroupLines();
        }else {
            Ajax.get({
                module: 'common',
                url: 'settings/get-current-academic-year',
                success: function(res) {
                    $scope.data.year_id = res.currentAcademicYear.value;
                }
            });
        }

        
    };
    
    $scope.$watch('data.program_id', function(newValue, oldValue) {
        if(newValue && newValue != oldValue && $scope.data.user_id_therapist){
            $scope.getRequests();
        }
    });
    
    $scope.$watch('data.user_id_therapist', function(newValue, oldValue) {
        if(newValue && newValue != oldValue && $scope.data.program_id){
            $scope.getRequests();
        }
    });
    
    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.save = function(){
        
        // empty the id's array before save
        $scope.requestsIdsToAdd = [];
        $("input[name=requestCheck]:checked").each(function() {
            $scope.requestsIdsToAdd.push( $(this).val() );
        });
        
        Ajax.post({
            url: 'treatment-group/save',
            params: {id: Utils.GET().id},
            data: {
                data: $scope.data,
                requestsIdsToAdd: $scope.requestsIdsToAdd
            },
            success: function(res){
                $scope.data = res.data;
                if ( $scope.mod == 'add' ){
                    Utils.redirect('/elhanefesh/treatment-group/update?id=' + $scope.data.id);
                }
            },
        });
    };
    
    $scope.getRequests = function(){
        if ($scope.data.user_id_therapist && $scope.data.program_id){
            Ajax.get({
                url: 'treatment-group/get-treatment-group-requests',
                params: {
                    therapistId: $scope.data.user_id_therapist,
                    programId: $scope.data.program_id,
                    treatmentGroupId: $scope.data.id ? $scope.data.id : null,
                },
                success: function(res){
                    $scope.requests         = res.requests;
                    $scope.groupRequests    = res.groupRequests;
                    $scope.picklists        = res.picklists;
                },
            });
        }
    };
    
    $scope.getTreatmentGroupLines = function(){
        if (Utils.GET().id){
            Ajax.get({
                url: 'treatment-group/get-treatment-group-lines',
                params: {treatmentGroupId: Utils.GET().id},
                success: function(res){
                    $scope.treatmentGroupLines = res.treatmentGroupLines;
                    $scope.lineIds = $scope.treatmentGroupLines.map(a => a.id);
                },
            });
        }
    };
    
    $scope.addRequestToGroup = function($index){
        if(!$scope.data.counter_request_id){
            $scope.data.counter_request_id=0;
        }
        if ($scope.tIsSelected[$index] == true){
            $scope.data.counter_request_id++;
        }else{
            $scope.data.counter_request_id--;
        }
    };
    
    $scope.getShowFilter = function(t,supplierIdForFilter,brIdForFilter){
        if (!supplierIdForFilter && !brIdForFilter){ // if no filter choosen
            return true;
        }
        
        if (!supplierIdForFilter && brIdForFilter){
            return t.budget_request_id == brIdForFilter;
        }
        if (supplierIdForFilter && !brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter;
        }
        if (supplierIdForFilter && brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter && t.budget_request_id == brIdForFilter;
        }
    };
    
    $scope.checkAll = function(checked){
        $scope.data.counter_request_id     = Number(0);
        $("input[name=requestCheck]").each(function(i) {
             $(this).prop('checked', checked);
             $scope.tIsSelected[i] = checked;
             if (checked == true){
                 $scope.addRequestToGroup(i);
             }
         });
    };
    
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param['upload_source'] = '(תוכנה)';
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
//            var obj = {};
//            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-treatment-group-file?id=' + $scope.invoice.id,
                fd: fd,
                success: function(res){
                   $scope.init('edit');
                }
            });
        }
    };
    
    $scope.$watch('newTreatmentGroupLine.treatment_date', function(newValue, oldValue) {
        if(newValue && newValue != oldValue){
            if ($scope.data.group_type == 'private'){
                $scope.getTreatmentMembers();
            }
        }
    });
    
    $scope.getTreatmentMembers = function(){
        //if ($scope.treatmentGroupLine.treatment_date){
            Ajax.get({
                url: 'treatment-group-line/get-treatment-members',
                params: {
                    treatmentGroupId: $scope.data.id,
                    treatmentDate: $scope.newTreatmentGroupLine.treatment_date
                },
                success: function(res){
                    $scope.members = res.members;
                    for(let i=0;i<$scope.members.length;i++){
                        if (!$scope.members[i].request.lpi_id_absence_reason){
                            $scope.treatmentIsSelected[i] = true;
                        }
                    }
                },
            });
        //}
    };
    
    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.saveNewTreatmentGroupLine = function(){
        if (!$scope.newTreatmentGroupLine.treatment_date){
            $scope.disabledSaveBtn=false;
            alert('לא ניתן לשמור מפגש ללא תאריך');
            return;
        }
        
        $scope.disabledSaveBtn=true; //  to avoid double click disable save button 
        
        $scope.newTreatmentGroupLine.treatment_group_id = $scope.data.id;
        Ajax.post({
            url: 'treatment-group-line/save',
            data: {
                treatmentGroupLine: $scope.newTreatmentGroupLine,
                members: $scope.members,
                treatmentGroupId: $scope.data.id
            },
            success: function(res){
                Utils.redirect('/elhanefesh/treatment-group/update?id=' + $scope.data.id);
            },
        });
    };
    
    $scope.cancel = function(){
        $scope.newGroupLine             = false;
        $scope.newTreatmentGroupLine    = {};
        $scope.members                  = {};
        // empty the datepicker
        $(".display-date").val('');
    };
    
    
    $scope.getTreatmentGroupLineDetails = function($index, refresh){
        if ( refresh || ($scope.treatmentGroupLines[$index].showMembers && !$scope.treatmentGroupLines[$index].members) ) {
            Ajax.get({
                url: 'treatment-group-line/get-treatment-group-line-details',
                params: {id: $scope.treatmentGroupLines[$index].id},
                success: function(res){
                    $scope.treatmentGroupLines[$index] = res.treatmentGroupLine;
                    $scope.treatmentGroupLines[$index].members = res.members;
                    for(let i=0;i<$scope.treatmentGroupLines[$index].members.length;i++){
                        if (!$scope.treatmentGroupLines[$index].members[i].lpi_id_treatment_absence_reason){
                            $scope.treatmentGroupLines[$index].members[i].is_selected = true;
                            $scope.treatmentGroupLines[$index].members[i].lpi_id_treatment_absence_reason = null;
                        }
                    }
                    if ($scope.data.group_type == 'private'){
                        $scope.treatmentGroupLines[$index].showMembers = true;
                    }
                },
            });
        }
    };
    
    $scope.saveTreatment = function(data, $modalInstance){
        if(!data.id && $scope.editTreatmentData && angular.equals($scope.editTreatmentData, data)){
            return; // to avoid from multiple saving
        }
        
        $scope.editTreatmentData = angular.copy(data);
        
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/save-treatment', 
            params: {budgetRequestId: data.budget_request_id, treatmentId: data.id},
            data: data,
            success: function(res) {
                $scope.getTreatmentGroupLineDetails(data.index, true);
                $modalInstance.close();
            }
        });
    };
    
    $scope.removeTreatment = function($id){
        Ajax.post({
            module: 'elhanefesh',
            url: 'request/remove-treatment',
            params: {id: $id, requestId: data.request_id},
            success: function(res) {
                $scope.getTreatmentMembers();
            }
        });
    };
    
    $scope.openTreatmentPopup = function(br, t, $index){
        // I set the budget_request_id to the scope for send it to server on the ajax post at saveTreatment function
        var data = t || {};
        
        $scope.editTreatmentData = null;
        data.index = $index;
        
        if (data.review_result)  {
            data.reviewResultArr = data.review_result.split(" | ");
        }
    
        var fn = [];
        fn = {
            removeTreatment: (id) => {
                if (confirm("האם אתה בטוח שברצונך למחוק את מפגש " + id)){
                    Ajax.post({
                        module: 'elhanefesh',
                        url: 'request/remove-treatment',
                        params: {id: id},
                        success: function(res) {
                            $scope.getRequestListsDetails();
                            $rootScope.$broadcast("popup:close", res);
                        }
                    });
                }
            },
            getJSFormatDate: (d) => {
                return new Date(d);
            }
        };
        
        if (t){
            Popup.open((t.lpiIdApproveStatus.logic_id == 13) ? 'elhanefesh/addTreatment' : 'elhanefesh/addTreatmentAfterDecision', $scope.saveTreatment, data, fn);
        }else{
            data.user_id_therapist = br.request.user_id_therapist;
            data.user_id_supplier = br.user_id_supplier;
            Popup.open('elhanefesh/addTreatment', $scope.saveTreatment, data, fn);
        }
    };

    $scope.updateTreatmentGroupLine = function(data){
        Ajax.post({
            module: 'elhanefesh',
            url: 'treatment-group-line/update-line',
            data: data,
            success: function(res){
                Utils.refresh();
            }
        });
    };

    $scope.openTreatmentGroupLinePopup = function(data){
        
        var data = data ? data : {treatment_group_id: Utils.GET().id};

        var fn = [];
        fn = {
            removeTreatment: (id) => {
                if (confirm("האם אתה בטוח שברצונך למחוק את מפגש " + id)){
                    Ajax.post({
                        module: 'elhanefesh',
                        url: 'treatment-group/remove-treatment-group-line',
                        params: {id: id},
                        success: function(res) {
                            Utils.refresh();

                        }
                    });
                }
            },
            getJSFormatDate: (d) => {
                return new Date(d);
            }
        };
        
        Popup.open('elhanefesh/addGroupTreatment', $scope.updateTreatmentGroupLine, data, fn);
    };

    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data, 171); // 171 = treatment group
    };

    $scope.openGroupLineMailPopup = function(data){
        mailMerge.openMailPopup(data, 172); // 172 = treatment group line
    };

    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
}]);