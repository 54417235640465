'use strict';

app.controller('PivotController', ['$scope', '$timeout', '$http', '$filter', '$modal', 'Ajax', 'Utils', '$rootScope', '$window',
    function($scope, $timeout, $http, $filter, $modal, Ajax, Utils, $rootScope, $window) { 
    
    $scope.pivot = {};
    
       
    $scope.init = function(firstTime){
        Ajax.post({
            module: 'common',
            url: 'list/json',
            params: {id: Utils.GET().id},
            notShowMessage: true,
            data: {
                sft: Utils.GET().sft ? Utils.GET().sft : null,
                node: Utils.GET().node ? Utils.GET().node : null,
                con: Utils.GET().con ? Utils.GET().con : null,
            },
            success: function(res){
                $scope.pivot = new WebDataRocks({
                    container: "#wdr-component",
                    toolbar: true,
                    report: {
                        dataSource: {
                            data: res.data
                        }
                    }
                });

                $("#wdr-component").on('click', function(event){
                    // alert('Element with class "myClass" clicked!');
                    if ($('#wdr-fields-view').is(':visible')) {
                        console.log('Div is now visible.');
                        console.log($(".wdr-ui-btn-dark").text());
                        //$('#wdr-fields-view').on('click', '.wdr-ui-btn-dark', function(event) {
                            $(".wdr-ui-btn-dark").on('click', function(event){
                            // Your event handling code here
                            alert('Element with class "myClass" clicked!');
                          });
                    } else {
                        console.log('Div is now hidden.');
                    }
                });
                
                
                // $('#wdr-fields-view').on('click', '.wdr-ui-btn-dark', function() {
                //     // Your event handling code here
                //     alert('Element with class "myClass" clicked!');
                //   });
            },
        });
    };
    
    
    $timeout(function(){
        $scope.init();
    }, 1000);

    
    
    
}]);