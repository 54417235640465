'use strict';

app.controller('DefaultController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', function($scope, $timeout, $filter, Ajax, Utils) {
    $scope.init = function(){
        Ajax.get({
            module: 'dafchadash',
            url: 'default/get-info',
            success: function(res){
                $scope.socialWorkers            = res.socialWorkers;
                $scope.statuses                 = res.statuses;
                $scope.requestsSummary          = res.requestsSummary;
                $scope.resNotDoneActivityInfo   = res.resNotDoneActivityInfo;
                $scope.resDoneActivityInfo      = res.resDoneActivityInfo;
                $scope.globalWomansCounter      = res.globalWomansCounter;
                $scope.counterWomansWithoutSw   = res.counterWomansWithoutSw;
                $scope.datesDoneFilter          = res.datesDoneFilter;
                $scope.datesNotDoneFilter       = res.datesNotDoneFilter;
            },
        });
        //}
    };
        
    $scope.init();
}]);