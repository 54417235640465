'use strict';

app.directive('profilePicture', ['$timeout', 'Ajax', 'Utils',  function ($timeout, Ajax, Utils) {
    return{
        restrict: 'AEC',
        replace: false,
        scope: false,
        transclude: false,
        template:   '<input type="file" onchange="angular.element(this).scope().uploadAndShowImg(this)"  id="choose-profile-pic" style="display: none;" />'
                  + '<img ng-click="changePic()"  id="profile-img" ng-src="{{profileSrc}}" style="width: 118px;">',
        link: function(scope, element, attrs) {   
            scope.changePic = function(){     
                if( !attrs.edit ){
                    return;
                }
                
                $("#choose-profile-pic").trigger('click');
            };
    
            scope.uploadAndShowImg = function(element){
                if( !attrs.edit ){
                    return;
                }
                
                var file    = $("#choose-profile-pic")[0].files[0];
                
                if( file ){
                    var ext = $("#choose-profile-pic").val().match(/\.(.+)$/)[1];

                    if ( !ext.match(/(?:gif|GIF|jpg|JPG|jpeg|JPEG|png|PNG|bmp|BMP)$/) ) {

                        alert('פספורט חייב להיות מסוג תמונה');
                        return;
                    }
                    
                    
                    var fd      = new FormData();

                    fd.append('file', file);
                    fd.append("label", "WEBUPLOAD");

                    Ajax.jqueryUploadFile({
                        module: 'common',
                        url: 'file/upload-profile-img?id=' + Utils.GET().id + '&logicId=' + attrs.logicId,
                        fd: fd,
                        success: function(res){
                            var photofile = element.files[0];
                            var reader = new FileReader();

                            reader.onload = function(e) {
                                scope.$apply(function() {
                                    scope.profileSrc = res.data.picSrc;
                                    $('#profile-img').attr('src', res.data.picSrc);
                                    scope.$parent.$broadcast("cru:refresh");
                                    scope.$parent.$broadcast("docs:refresh");
                                });
                            };

                            reader.readAsDataURL(photofile);
                        }
                    });
                }
            };
        }
    };
}]);