'use strict';

app.directive('ytcrmOrderCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			recordid: '@'
		},
        templateUrl:'/js/app/directives/ytcrmOrderCard/tpl.html',
        link: function(scope, element, attrs) {
            scope.tagsArr = [];
            
            scope.init = function(){
                Ajax.get({
                    //module: 'mossad',
                    url: 'order/get-order',
                    params: {id: Utils.GET().id},
                    success: function(res) { 
                        scope.data = res.data;
                        scope.tagsToArr();
                        //scope.$emit('cr21User:getSuccess', 'view', res);
                    }
                });
            };
            
            scope.init();
            
            scope.openMailPopup = function(br){
                // if (!br){
                    mailMerge.openMailPopup(scope.data, 181); // 102 = cr21 user logic id
                // }
            };

            scope.tagsToArr = function(){
                if (scope.data.tags!=null)  {
                    scope.tagsArr = scope.data.tags.split("|");
                    if(scope.tagsArr[scope.tagsArr.length-1]==''){
                        scope.tagsArr.splice(0,1);
                    }
                }
            };

            scope.save = function(){
                Ajax.post({
                    //module: 'mossad',
                    url: 'order/save',
                    data: scope.data,
                    success: function(res) { 
                        scope.data = res.data;
                        scope.tagsToArr();
                        //scope.$emit('cr21User:getSuccess', 'view', res);
                    }
                });
                
            };
		}
    };
}]);
