'use strict';

app.controller('AdminRegisterController', ['$scope', 'Ajax', function($scope, Ajax){
    $scope.admin = {};
    
    $scope.save = function(){
        Ajax.post({
            url: 'test/create-admin-user',
            data:   $scope.admin,
            success: function(res){
                $scope.admin = {};
            }
        });
    };
}]);