'use strict';

app.directive('editWarnings', ['Utils', 'Ajax', function (Utils, Ajax) {
    return{
        restrict: 'AEC',
       // replace: true,
        scope: false,
        transclude: false,
        templateUrl: '/js/app/directives/editWarnings/tpl.html',
        link: function(scope, element, attrs) {
            scope.id    = Utils.GET().id;
            scope.name  = Utils.GET().name;
            scope.params = {id: scope.id};
            
            if (attrs.name){
                scope.params = {id: null, name: attrs.name, projectId: attrs.projectId};
            }
            
            Ajax.get({
                module: 'adminsettings',
                url:  ( (attrs.urlCtrl) ? attrs.urlCtrl : 'settings' ) + '/data-list', 
                params: scope.params,
                success: function(res) {
                    if (attrs.urlCtrl != 'settings') {
                        scope.dataList = res;         
                    }else{
                        scope.dataList = res.values;
                        scope.description = res.description;
                    }
                }
            });
                                    
            scope.save = function(){
                Ajax.post({
                    module: 'adminsettings',
                    url: ( (attrs.urlCtrl) ? attrs.urlCtrl : 'settings' ) + '/edit-data-list',
                    params: scope.params,
                    data: {dataList: scope.dataList, description: scope.description},
                    success: function(res) { 
                        //scope.dataList = res.data.map;
                    }
                });
            };
            
        }
    };
}]);