'use strict';

app.controller('IhptClientController', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.ihptClients          = [];
    $scope.ihptServices         = [];
    $scope.tIsSelected          = [];
    $scope.data                 = {};
    $scope.data.clientsToCopy  = [];
    $scope.modalInstance = null;
    
    $scope.init = function(){
        Ajax.get({
            url: 'ihpt-client/get-ihpt-clients',
            params: {yearId: Utils.GET().yearId},
            success: function(res){
                $scope.years        = res.years,
                $scope.lookupYear   = res.lookupYear,   
                $scope.ihptClients  = res.ihptClients;
            },
        });
    };
    
    $scope.init();
    
    $scope.getIhptServices = function(clientId){
        Ajax.get({
            url: 'ihpt-client/get-ihpt-client-details',
            params: {id: clientId},
            success: function(res){
                $scope.ihptClient   = res.ihptClient;
                $scope.ihptServices = res.ihptServices;
            }
        });
    };
    
    $scope.addService = function(service){
        Ajax.post({
            url: 'ihpt-client/add-service',
            data: $scope.ihptServices[service],
            params: {clientId: $scope.ihptClient.id},
            success: function(res){
                //$scope.init();
            }
        });
    };
    
    $scope.checkAll = function(checked){
        $("input[name=treatmentCheck]").each(function(i) {
            $(this).prop('checked', checked);
            $scope.tIsSelected[i] = checked;
        });
    };
    
    $scope.singleSelected = function(){
        $scope.checkAllTreatments = false;
        $('#checkAllTreatments').prop('checked', false);
    };
    
    $scope.editClientService = function(service){
        $scope.copyAmountMaxService = service.amount_max_service;
    };
    
    $scope.cancelEditClientService = function(service){
        service.amount_max_service = $scope.copyAmountMaxService;
    };
    
    $scope.copyClients = function(yearId){
        $scope.data.clientsToCopy = [];
        var arrayIndex = 0;
        angular.forEach($scope.tIsSelected, function(item, key) {
            if(item) { 
                $scope.data.clientsToCopy.push($scope.ihptClients[arrayIndex]);
            }
            arrayIndex++;
        });
         
        if ($scope.data.clientsToCopy.length == 0){
            alert('אין אפשרות להמשיך בפעולה ללא בחירת סיווגים להעתקה!');
            return;
        }
        
        Ajax.post({
            url: 'ihpt-client/copy-ihpt-clients',
            data: $scope.data,
            params: {yearId: yearId},
            success: function(res){
                $scope.modalInstance.close();
                Utils.redirect('/adminsettings/ihpt-client?yearId='+yearId);
            }
        });
    };
    
    $scope.openCopyClientsPopup = function(){
        var data = {};
        data.years = $scope.years;
        var fn = [];
        fn = {
            copyClients: $scope.copyClients
        };
        $scope.modalInstance = Popup.open('adminsettings/copyIhptClients', $scope.copyClients, data, fn);
    };
        
    $scope.saveClient = function(data){
        Ajax.post({
            url: 'ihpt-client/save-client',
            data: data ? data : $scope.ihptClient,
            success: function(res){
                $scope.init();
                if ($scope.modalInstance){
                    $scope.modalInstance.close();
                }
            }
        });
    };
    
    $scope.openSaveClientPopup = function(){
        var data = {};
        data.lookupYear = $scope.lookupYear;
        data.academic_year_id = $scope.lookupYear.id;
        var fn = [];
        fn = {
            saveClient: $scope.saveClient
        };
        $scope.modalInstance = Popup.open('adminsettings/addIhptClient', $scope.saveClient, data, fn);
    };
}]);
