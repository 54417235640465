'use strict';

app.directive('ytcrmOrganizationCard', ['$timeout', 'Ajax', 'Utils', 'mailMerge', function ($timeout, Ajax, Utils, mailMerge) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl:'/js/app/directives/ytcrmOrganizationCard/tpl.html',
        link: function(scope, element, attrs) { 
            scope.tagsArr = [];
            
            scope.init = function(){
                Ajax.get({
                    //module: 'mossad',
                    url: 'organization/get-organization-details',
                    params: {id: scope.objectid},
                    success: function(res) { 
                        scope.data = res.data;
                        scope.tagsToArr();
                        //scope.$emit('cr21User:getSuccess', 'view', res);
                    }
                });
                
            };
            scope.init();
            
            scope.openMailPopup = function(br){
                // if (!br){
                //     mailMerge.openMailPopup(scope.data, 102); // 102 = cr21 user logic id
                // }
            };

            scope.tagsToArr = function(){
                if (scope.data.tags!=null)  {
                    scope.tagsArr = scope.data.tags.split("|");
                    if(scope.tagsArr[scope.tagsArr.length-1]==''){
                        scope.tagsArr.splice(0,1);
                    }
                }
            };

            scope.save = function(){
                Ajax.post({
                    //module: 'mossad',
                    url: 'organization/save-organization',
                    data: scope.data,
                    success: function(res) { 
                        scope.data = res.data;
                        scope.tagsToArr();
                        //scope.$emit('cr21User:getSuccess', 'view', res);
                    }
                });
                
            };

            scope.openOrgPage = () => {
                Utils.redirect('../organization/view?id=' + scope.data.id)
            };
		}
    };
}]);
