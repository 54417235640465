'use strict';

app.controller('CommitteeDetailsController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    
    $scope.getCommitteeDetails = function() {
        Ajax.get({
            url: 'committee/get-committee-details',
            params: {id: Utils.GET().id},
            success: function(res){
               
                $scope.donutChartDecision           = res.donutChartDecision;
                $scope.chartBudgetByYear            = res.chartBudgetByYear;
                $scope.committee                    = res.committee;
                $scope.editCommittee                = angular.copy($scope.committee);
                $scope.users                        = res.users;
                $scope.picklists                    = res.picklists;
                $scope.counterParams                = res.counterParams;                
                
            }
        });
    };
    $scope.getCommitteeDetails();
    
    $scope.addUser = function(id){
        var user = $filter('filter')($scope.users, {id: id})[0];
        
        if ( !$filter('filter')($scope.committee.participants, {id: user.id})[0] ){ // chack if exsits
            $scope.committee.participants.push(user);
        }
        
        $scope.usersParticipants = "";
    };
    
    $scope.saveCommittee = function(){
        Ajax.post({
            url:    'committee/save-committee',
            params: {id: Utils.GET().id},
            data:   $scope.committee,
            //notShowMessage: true,
            success: function(res){
                if(res.data){
                    if( confirm("קיימות בקשות שלא התקבלו בהם החלטות האם בכל זאת תרצה לסגור את הועדה ?") ){
                        Ajax.post({
                            url:    'committee/save-committee',
                            params: {id: Utils.GET().id},
                            data:   angular.extend($scope.committee, {closeCommittee : true}),
                            success: function(){
                                Utils.refresh();
                            }
                        });
                    }else{
                        $scope.getCommitteeDetails();    
                    }
                }else if($scope.committee.lpi_id_committee_status == 47){
                    Utils.refresh();
                }else{
                    $scope.getCommitteeDetails();
                }
            }
        });
    };
}]);