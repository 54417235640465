'use strict';

app.controller('MsdOrganizationController', ['$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $filter, Ajax, Utils, Popup) {
    $scope.tagsArr = [];
    $scope.data = {};

    $scope.$on('cru:getSuccess', function(e, mod, res){
        $scope.tagsToArr();
        $scope.getOrders();
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        //if(mod == 'add'){
            Utils.redirect('/mossad/organization/view?id=' + res.data.id);
            $scope.tagsToArr();
        //}
    });

    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_house_num = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };

    $scope.getOrders = () => {
        Ajax.get({
            module: 'mossad',
            url: 'order/get-record-orders',
            params: {id: Utils.GET().id, fieldName: 'organization'},
            success: function(res) { 
                $scope.data.orders = res.orders;
            }
        });
    }
}]);