'use strict';

app.controller('CashflowYearController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    $scope.cashflowYear = {};
    $scope.statusesList = null;
    $scope.getCashflowYearsDetails = function($yearId) {
        Ajax.post({
            module: 'common',
            url: 'cashflow-year/get-cashflow-years-details',
            params: {lookupYearId: Utils.GET().lookupYearId},
            data: {list:$scope.statusesList},
            success: function(res){
                $scope.currentYearView      = res.yearId;
                $scope.resCashflowYears     = res.resCashflowYears;
                $scope.cashflowYear         = $scope.resCashflowYears[$scope.currentYearView];
            }
        });
        
    };
    
    $scope.getStatusesList = function() {
        Ajax.get({
            module: 'common',
            url: 'cashflow-year/get-statuses-list',
            params: {lookupYearId: Utils.GET().lookupYearId},
            success: function(res){
                $scope.years                = res.years;
                $scope.weekList             = res.weekList;
                $scope.statusesList         = res.statusesList;
                $scope.getCashflowYearsDetails();
            }
        });
    };
    
    $scope.getStatusesList();
        
    $scope.goForward = function() {
        $scope.currentYearView++;
        if ($scope.currentYearView>$scope.size($scope.years)){
            $scope.currentYearView--;
            return;
        }
        Utils.redirect('/common/cashflow-year?lookupYearId=' + $scope.currentYearView);
    }
    
    $scope.goBackward = function() {
        $scope.currentYearView--;
        if ($scope.currentYearView<1){
            $scope.currentYearView++;
            return;
        }
        Utils.redirect('/common/cashflow-year?lookupYearId=' + $scope.currentYearView);
    }
    
    $scope.size = function(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };
    
    $scope.removeStatus = function($index){
        $scope.statusesList.splice($index, 1);
    };
}]);