'use strict';

app.directive('greeting', ['$timeout', 'Ajax', 'Utils', function ($timeout, Ajax, Utils) {
    return {
        restrict: 'AEC',
        scope: { 
			objectid: '='
		},
        templateUrl: '/js/app/directives/greeting/tpl.html',
        link: function(scope, element, attrs) {
            scope.greeting = 'ללא';
            let d = new Date();
            if (d.getDay() < 5 ) {
                if (d.getHours() >= 3 && d.getHours() < 12) {
                    scope.greeting = 'בוקר טוב';
                }
                if (d.getHours() >= 12 && d.getHours() < 14) {
                    scope.greeting = 'צהריים טובים';
                }
                if (d.getHours() >= 14 && d.getHours() < 17) {
                    scope.greeting = 'אחר צהריים טובים';
                }
                if (d.getHours() >= 17 && d.getHours() < 20) {
                    scope.greeting = 'ערב טוב';
                }
                if (d.getHours() >= 20 || d.getHours() < 3) {
                    scope.greeting = 'לילה טוב';
                }
            }else if (d.getDay() == 5) {
                scope.greeting = 'שבת שלום';
            }else if (d.getDay() == 6) {
                scope.greeting = 'שבוע טוב';
            }
		}
    };
}]);
