'use strict';

app.controller('PortalUserDetailsCtrl', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils){
        
    Ajax.get({
        module: 'common',
        url: 'student/student-details',
        params: {id: $scope.globals.user.user_id}, 
        success: function(res){
            $scope.user      = res.data;
            $scope.picklists = res.picklists;
            $scope.editUser  = angular.copy($scope.user);
        }
    });
    
    $scope.pass = {};    
    $scope.changePassword = function(){
        if(!$scope.pass.password || !$scope.pass.new_password || !$scope.pass.retype_new_password){
            alert('אנא מלא את כל השדות');
            return;
        }
        
        if($scope.pass.new_password !== $scope.pass.retype_new_password){
            alert('סיסמה חדשה אינה תואמת בשני השדות');
            return;
        }
        
        if ($scope.pass.new_password.length < 8){
            alert('אורך הסיסמא חייב להכיל לפחות שמונה תווים (מספרים או אותיות באנגלית)');
            return;
        }
        
        if (!$scope.isValidPass($scope.pass.new_password)){
            alert('סיסמה יכולה להכיל מספרים ואותיות באנגלית בלבד');
            return;
        }
        
        Ajax.post({
            url: 'active/change-password',
            data: $scope.pass, 
            notShowMessage: true,
            success: function(res){
                $scope.pass = {};
                Utils.showNoty('הסיסמה נשמרה בהצלחה');
            },
            error: function(res){
                if (typeof res === 'string' || res instanceof String){
                    alert(res);
                }
            }
        });
    }; 
        
        
    $scope.save = function(){
        if( !angular.equals($scope.user, $scope.editUser) ){
            Ajax.post({
                module: 'common',
                url: 'student/edit-student',
                params: {id: $scope.globals.user.user_id}, 
                data: $scope.user,
                success: function(res){
                    $scope.user     = res.data;
                    $scope.editUser = angular.copy($scope.user);
                }
            });
        }
    };
    
    $scope.isValidPass = function(str) {
        return /^\w+$/.test(str);
    }
}]);