'use strict';

app.controller('EhInvoiceController', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.tIsSelected              = [];
    $scope.treatmentIdsToAdd        = [];
    $scope.invoice                  = {};
    $scope.invoice.userIdSupplier   = {};
    $scope.invoice.due_amount   = Number(0);
    $scope.invoice.quantity     = Number(0);
        
    $scope.init = function(mod){
        $scope.mod = mod;
        if ($scope.mod == 'edit'){
            Ajax.get({
                url: 'invoice/get-invoice-details',
                params: {id: Utils.GET().id},
                success: function(res){
                    $scope.data = res.data;
                    $scope.invoice = res.data;
                },
            });
        }else if ($scope.mod == 'add'){
            Ajax.get({
                url: 'supplier/get-supplier-details',
                params: {id: Utils.GET().user_id_supplier},
                success: function(res){
                    $scope.invoice.userIdSupplier = res.data;
                },
            });
        }
        
        $scope.getTreatments();
    };
    
    $scope.$watch('data.reference', function(newValue, oldValue) {
        if((newValue != oldValue) && !$scope.data.receipt_entry_date){
            $scope.data.receipt_entry_date = new Date();
        }
    });
    
    // this save using only on add mode on edit mode I'm using the "cru" save  
    $scope.save = function(){
        // empty the id's array before save
        $scope.treatmentIdsToAdd = [];
        $("input[name=treatmentCheck]:checked").each(function() {
            $scope.treatmentIdsToAdd.push( $(this).val() );
        });
        if ($scope.mod == 'add'){ // if $scope.mod == 'add'
            $scope.invoice.user_id_supplier = Utils.GET().user_id_supplier;
        }
        Ajax.post({
            url: 'invoice/save',
            params: {$id: (Utils.GET().id) ? Utils.GET().id : null},
            data: {
                invoice: $scope.invoice,
                treatmentIdsToAdd: $scope.treatmentIdsToAdd
            },
            success: function(res){
                $scope.invoice = res.data;
                if ( $scope.mod == 'add' ){
                    Utils.redirect('/elhanefesh/invoice/update?id=' + $scope.invoice.id);
                }
            },
        });
    };
    
    $scope.getTreatments = function(){
        if ($scope.mod == 'add'){
            Ajax.get({
                module: 'elhanefesh',
                url: 'invoice/get-treatments', 
                params: {userIdSupplier: Utils.GET().user_id_supplier},
                success: function(res) { 
                    $scope.treatments = res.treatments;
                    $scope.picklists =   res.picklists;
                }
            });
        }else{
            Ajax.get({
                module: 'elhanefesh',
                url: 'invoice/get-invoice-treatments', 
                params: {id: Utils.GET().id},
                success: function(res) { 
                    $scope.treatments = res.treatments;
                    $scope.picklists =   res.picklists;
                }
            });
        }
    };
    
    $scope.addTreatmentToInvoice = function($index,patientSearchText,budgetRequestsearchText){
        var tArray = [];
        tArray = $filter('filter')($scope.treatments,{budget_request_id:budgetRequestsearchText, budgetRequest: patientSearchText});
        if ($scope.tIsSelected[$index] == true){
            $scope.invoice.due_amount += Number(tArray[$index].amount);
            $scope.invoice.quantity++;
        }else{
            $scope.invoice.due_amount -= Number(tArray[$index].amount);
            $scope.invoice.quantity--;
        }
    };
    
    $scope.getShowFilter = function(t,supplierIdForFilter,brIdForFilter){
        if (!supplierIdForFilter && !brIdForFilter){ // if no filter choosen
            return true;
        }
        
        if (!supplierIdForFilter && brIdForFilter){
            return t.budget_request_id == brIdForFilter;
        }
        if (supplierIdForFilter && !brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter;
        }
        if (supplierIdForFilter && brIdForFilter){
            return t.budgetRequest.request.user.id == supplierIdForFilter && t.budget_request_id == brIdForFilter;
        }
    };
    
    $scope.checkAll = function(checked){
        $scope.invoice.due_amount   = Number(0);
        $scope.invoice.quantity     = Number(0);
        $("input[name=treatmentCheck]").each(function(i) {
             $(this).prop('checked', checked);
             $scope.tIsSelected[i] = checked;
             if (checked == true){
                 $scope.addTreatmentToInvoice(i);
             }
         });
    };
    
    $scope.onChange = function(t) {
        if(t.$id === "008" || t.$id === "005"){
            $scope.tIsSelected = [];
            $scope.invoice.due_amount = 0;
            $scope.invoice.quantity = 0;
        }
    };
    
    $scope.addNewFileClick = function(){
        $('#uploadNewFile').trigger('click');
    };
    
    $scope.uploadFile = function(elem) {
        //var file_connection_id  = this.doc.id;
        var file = $("#uploadNewFile")[0].files[0];
        
        if( file ){
            var fd      = new FormData();

            var param = {};
            param['upload_source'] = '(תוכנה)';
            
            fd.append('file', file);
            fd.append('fileData', JSON.stringify(param));
//            var obj = {};
//            obj = elem.dataset.record;
            Ajax.jqueryUploadFile({
                module: 'common',
                url: 'file/upload-invoice-file?id=' + $scope.invoice.id,
                fd: fd,
                success: function(res){
                   $scope.init('edit');
                }
            });
        }
    };
}]);