'use strict';

app.directive('timeLine', ['$timeout', 'Ajax', 'Utils', '$rootScope', 'mailMerge', function ($timeout, Ajax, Utils, $rootScope, mailMerge) {
    return {
        restrict: 'AEC',
        scope: {
            logicid: '@',
            recordid: '@',
            fieldname: '@'
        },
        templateUrl: '/js/app/directives/timeLine/timeLineTpl.html',
        link: function(scope, element, attrs) {
            scope.userItems = {};

            scope.$on('item:createdsuccess', function(e, res){
                scope.filterDate = res.due_date;
                scope.getItems('anydirection',true);
            });
            
            scope.$on('item:fileupload', function(e, res){
                var currentDate = new Date();
                var year=currentDate.getFullYear(),month=currentDate.getMonth()+1,day=1;
                scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
                scope.getItems('anydirection',true);
            });
            
            scope.$on('activity:groupadded', function(e, res){
                scope.filterDate = null;
                scope.getItems('anydirection',true);
            });
            
            scope.$on('item:fileupdated', function(e, res){
                var resDate = res.filterDate.replace(/-/g, ''); // remove all '-' dashs from date string
                var year=res.substring(0,4),month=res.substring(4,2),day=1;
                scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
                scope.getItems('anydirection',true);
            });

            scope.getItems = (direction, updateItems) => {
                if (!scope.filterDate){
                    var currentDate = new Date();
                    var year=currentDate.getFullYear(),month=currentDate.getMonth()+1,day=1;
                    scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-' + day);
                }
                Ajax.get({
                    module: 'ytcrm',
                    url: 'organization/get-items-by-record-id',
                    params: {recordId: scope.recordid, objectId: scope.logicid, filterDate: scope.filterDate, direction: direction},
                    success: function(res) {
                        if (scope.userItems && scope.userItems.length > 0){
                            //let updateItems = false;// dont forget do something here
                            if (updateItems){
                                var keyMonth    = scope.filterDate.replace(/-/g, ''); // remove all '-' dashs from date string
                                keyMonth        = keyMonth.substring(0,6); // get the year and month only like '201811'
                                var itemIndex = scope.getIndexByMonthNumber(keyMonth);
                                if (itemIndex == -1){
                                    if (parseInt(keyMonth) > parseInt(scope.userItems[0].monthNumber)){
                                        scope.userItems = res.items.concat(scope.userItems);
                                    }else if (parseInt(keyMonth) < parseInt(scope.userItems[scope.userItems.length-1].monthNumber)){
                                        scope.userItems = scope.userItems.concat(res.items);
                                    }else{
                                        var spliceIndex = scope.getIndexForSplice(keyMonth);
                                         scope.userItems.splice(spliceIndex,0,res.items[0]);
                                    }
                                }else{
                                    scope.userItems[itemIndex] = res.items[0];
                                }
                            }else{
                                if (scope.back == true) {
                                    scope.userItems = scope.userItems.concat(res.items);
                                    if (res.items[0] && scope.getTotalItems() < 15){
                                        scope.goMonthBack();
                                    }
                                }else{
                                    scope.userItems = res.items.concat(scope.userItems);
                                }
                            }
                        }else{
                            scope.userItems = res.items;
                            if (res.totalCount < 15){
                                scope.goMonthBack();
                            }
                        }
                        scope.itemsCount          = res.itemsCount;
                        // scope.calendarAuthLevel   = res.calendarAuthLevel;
                        scope.disableForwardBtn        = false;
                        scope.disableBackBtn       = false;   
                        scope.showLoadFrame = false;
                        scope.showNoDataFrame = scope.userItems && scope.userItems.length == 0;
                    },
                    notShowMessage: true
                });
            };
            
            scope.init = function(direction){
                var direction = direction ? direction : 'anydirection';
                scope.getItems(direction);
            };
            
            scope.init();
            
            scope.goMonthBack = function(){
                if (scope.userItems.length==0){
                    return;
                }
                var monthNumber = scope.userItems[scope.userItems.length-1].monthNumber;
                var jsDate = new Date(monthNumber.substring(0,4), monthNumber.substring(4,6)-1, 1); // day of month always 1st day to avoid day that not exist like 2018-02-30 etc...
                jsDate.setMonth(jsDate.getMonth() - 1);
                var year=jsDate.getFullYear(),month=jsDate.getMonth()+1,day=jsDate.getDate();
                scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-01');
                scope.pastFilterDate = scope.filterDate;
                scope.back = true;
                scope.init('back');
            };

            scope.getIndexByMonthNumber = function(monthNumber){
                var index=0;
                var keepgoing = true;
                angular.forEach(scope.userItems, function(item, key) {
                    if(keepgoing && item.monthNumber == monthNumber) { 
                        keepgoing = false;
                    }else if (keepgoing){
                        index++;
                    }
                });
                return keepgoing ? -1 : index;
            };

            scope.getTotalItems = function(){
                var totalItems = 0;
                angular.forEach(scope.userItems, function(item, key) {
                    totalItems += item.itemsList.length;
                });
                return totalItems;
            }
        }
    };
}]);