'use strict';

app.controller('TherapistSearchingController', ['$scope', '$filter', 'Ajax', 'Utils', function($scope, $filter, Ajax, Utils) {
    $scope.mandatoryAttributes  = [];
    $scope.optionalAttributes   = [];

    $scope.init = function(){
        $scope.attributesKeys = [];
        Ajax.get({
            module: 'elhanefesh',
            url: 'therapist-attributes/get-attributes-keys', 
            success: function(res) { 
                $scope.attributesKeys = res.data;
            }
        });
    };
    $scope.init();

    $scope.$watch('key', function(newValue, oldValue) {
        if(!newValue){
            return;
        }

        var keyName = ($scope.key) ? $( "#key-selected option:selected" ).text() : null;
        $scope.attributesValue = [];
        Ajax.get({
            module: 'elhanefesh',
            url: 'therapist-attributes/get-attributes-value-by-key', 
            params: {key: keyName},
            success: function(res) { 
                $scope.attributesValue = res.data;
            }
        });
    });

    $scope.addMandatoryAttribute = function(){
        var keyName     = ($scope.key) ? $( "#key-selected option:selected" ).text() : '';
        var valueName   = ($scope.value) ? $( "#value-selected option:selected" ).text() : '';
        if(keyName == '' || valueName == ''){
            alert('חובה לבחור מאפיין וערך');
            return;
        }
        if ($scope.isAttributeExist(keyName,valueName)){
            alert('כבר נבחר ערך עבור מאפיין זה');
            return;
        }
        $scope.mandatoryAttributes.push( {attr_key: keyName, attr_value: valueName} );
    };

    $scope.addOptionalAttribute = function(){
        var keyName     = ($scope.key) ? $( "#key-selected option:selected" ).text() : '';
        var valueName   = ($scope.value) ? $( "#value-selected option:selected" ).text() : '';
        if(keyName == '' || valueName == ''){
            alert('חובה לבחור מאפיין וערך');
            return;
        }
        if ($scope.isAttributeExist(keyName,valueName)){
            alert('כבר נבחר ערך עבור מאפיין זה');
            return;
        }
        $scope.optionalAttributes.push( {attr_key: keyName, attr_value: valueName} );  
    };

    $scope.isAttributeExist = function(keyName,valueName){
        if ($filter('filter')($scope.mandatoryAttributes,{attr_key:keyName,attr_value:valueName})[0] ||
                $filter('filter')($scope.optionalAttributes,{attr_key:keyName,attr_value:valueName})[0]){
            return true;
        }
        return false;
    };

    $scope.cancelMandatoryAttribute = function($index){
        $scope.mandatoryAttributes.splice($index, 1);
    };

    $scope.cancelOptionalAttribute = function($index){
        $scope.optionalAttributes.splice($index, 1);
    };

    $scope.apply = function(){
        if($scope.mandatoryAttributes.length == 0 && $scope.optionalAttributes.length == 0){
            alert('כדי להתחיל בחיפוש, עליך לבחור לפחות מאפיין אחד');
            return;
        }
        Ajax.post({
            module: 'elhanefesh',
            url: 'therapist-attributes/apply', 
            notShowMessage: true,
            data: {
                mandatoryAttributes: ($scope.mandatoryAttributes) ? $scope.mandatoryAttributes : [],
                optionalAttributes: ($scope.optionalAttributes) ? $scope.optionalAttributes : []
            },
            success: function(res) { 
                $scope.results = res.resultArray;
            }
        });
    };
}]);