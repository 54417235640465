'use strict';

app.controller('EhcpAllRequestsController', ['$scope', 'Ajax', 'Utils', function($scope, Ajax, Utils) {
    $scope.requests = [];
    
    $scope.getAllTherapistRequests = function(){
        Ajax.get({
            module: 'contactehportal',
            url: 'request/get-all-therapist-requests', 
            params: {therapistId: $scope.globals.user.user_id},
            success: function(res) { 
                $scope.requests = res.requests;
            }
        });
    };
    
    $scope.init = function(){
        $scope.getAllTherapistRequests();
    }
    
    $scope.init();
}]);