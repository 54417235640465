'use strict';

app.directive('lookupEagerLoad', ['$timeout', '$filter', '$parse', 'Ajax', 'Utils', function ($timeout, $filter, $parse, Ajax, Utils) {
    return {
        require: '?ngModel',
        restrict: 'AE',
        replace: false,
        scope: {
            ngModel             :   '=',
            ngData              :   '=',
            ngModelName         :   '=',
        },
        templateUrl: '/js/app/directives/lookup/lookupEagerLoad/tpl.html',
        link: function(scope, element, attrs) {
            
			var model = $parse(attrs.ngModel);
			scope.modelSetter = model.assign;

			scope.items = [];
			scope.init = function() {
				Ajax.get({
					module: 'common',
					url: 'lookup/lookup-get-data', 
					params: {
						like: '',
						modelName       : attrs.ngModelName,
						fieldName       : attrs.ngFieldName,
						logicId      : (attrs.ngLogicId) ? attrs.ngLogicId : null,
					},
					success: function(res) {
						scope.items = res.items;
						element.find('select').select2({
							data: res.items,
							minimumInputLength: 0,
							placeholder: "בחר...",
							language: {
								noResults: function() {
									return "לא נמצאו נתונים התואמים את החיפוש";
								},
								 inputTooShort: function(args) {
									var remainingChars = args.minimum - args.input.length;
									return "עליך להזין עוד " + remainingChars + " תוים";
								},
								searching: function () {
									return 'מחפש…';
								},
								errorLoading: function () {
									return 'אירעה שגיאה בעת החיפוש';
								},
						   }
						}).on("change", function(e) {
							scope.$parent.$emit('select2:optionSelected', $('#template_select_list').val());
						});
					}
				});
			};

			scope.init();            
        }
    };
}]);