'use strict';

app.controller('SystemList', ['$rootScope', '$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $timeout, $filter, Ajax, Utils, Popup) {
    $scope.systemList = [];

    $scope.init = function(){
        Ajax.get({
            url: 'system-list/get-system-list',
            success: function(res){
                $scope.systemList = res.systemList;
            },
        });
    };
    
    $scope.init();
}]);
