'use strict';

app.directive('alertError', ['$timeout', function ($timeout) {
    return {
        scope:{
            responseError:'=ngModel'
        },
        restrict: 'AEC',
        replace: false,
        templateUrl: '/js/app/partials/alertErrorTpl.html',
        link: function(scope, elem, attrs, ngModel) {
            scope.showAlert = false;
            scope.timer     = null;
            
            scope.$watch('responseError', function(newValue) {
                if(!newValue){
                    scope.close();
                }else{
                    scope.responseError = newValue;
                    scope.showAlert = true;
                    
                    if( scope.timer ){
                        $timeout.cancel( scope.timer );
                    }
                    
                    scope.timer = $timeout(function() {
                        scope.showAlert = false;
                    }, 15000);
                }
            });
            
            scope.close = function(){
                if( scope.timer ){
                    $timeout.cancel( scope.timer );
                }
        
                scope.showAlert = false;
            };
            
            scope.formatErrorMesage = function(messages){
                if( angular.isString(messages) ){
                    return messages;
                }
                
                var arr = [];
                $.each(messages, function(k, v) {
                    arr.push(v);
                });
                
                return arr.join(" ");
            };
        }
    };
}]);