'use strict';

var newCommitteeModalInstanceCtrl = function($scope, $modalInstance, $modal, $filter, Ajax/*, users*/){
    Ajax.get({
        url: 'committee/get-committee-users',
        success: function(res){
            $scope.users = res.users;
        }
    });
        
    $scope.committee = {selectedUser: []};
    
    $scope.addUser = function(id){
        var user = $filter('filter')($scope.users, {id: id})[0];
        
        if ( !$filter('filter')($scope.committee.selectedUser, {id: user.id})[0] ){ // chack if exsits
            $scope.committee.selectedUser.push(user);
        }
    };
    
    $scope.removeParticipants = function(user_id, index){
        $scope.committee.selectedUser.splice(index, 1);
    };
    
    $scope.ok = function() {
        Ajax.post({
            url: 'committee/new-committee',
            data: $scope.committee,
            success: function(res){
                $modalInstance.close(res.data);
            }
        });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
};

app.controller('NewCommitteeCtrl', ['$scope', '$http', '$filter', '$modal', '$log', 'Utils', function($scope, $http, $filter, $modal, $log, Utils) {

    $scope.openNewCommitteePopup = function($event){
        var modalInstance = $modal.open({
            templateUrl: '/js/app/partials/new_committee_popup.html' + window.YT.project.version,
            controller: newCommitteeModalInstanceCtrl,
            size: 'lg'
            /*resolve: {
                users: function () {
                    return $scope.users;
                }
            }*/
        });
        
        modalInstance.result.then(function (committee) {
            Utils.redirect('/academy/committee/update?id=' + committee.id);
        }, function () {
            Utils.redirect('/common/list/view?id=13');
        });
        
       // $event.stopPropagation();
    };
    
    $scope.openNewCommitteePopup();
    
}]);