'use strict';

app.controller('YtcrmOrganizationController', ['$rootScope', '$scope', '$filter', 'Ajax', 'Utils', 'Popup', function($rootScope, $scope, $filter, Ajax, Utils, Popup) {
    
    $scope.tagsArr = [];
    $scope.data = {};
    
    $scope.init = (direction) => {
        
    };

    $scope.init();

    $scope.$on('cru:getSuccess', function(e, mod, res){
        $scope.tagsToArr();
        $scope.getOrders();
    });
    
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(window.location.href.includes('view')){
            $scope.tagsToArr();
            return;
        }
        Utils.redirect('/ytcrm/organization/view?id=' + res.data.id);
    });
    
    $scope.$on('autocomplete:getplace', function(e, place){
        var street_number = '';
        var route = '';
        var locality = '';
        var country = '';
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == 'street_number') {
                street_number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'route') {
                route = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'locality') {
                locality = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[0] == 'country') {
                country = place.address_components[i].long_name;
            }
        }
        $scope.data.adrs_house_num = street_number;
        $scope.data.adrs_street =  route;
        $scope.data.adrs_city =  locality;
        $scope.data.adrs_country =  country;
    });    
    
    
    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };
    
    $scope.removeOrganization = function($id){
		Ajax.post({
			module: 'ytcrm',
            url: 'organization/remove',
            params: {id: $id},
            success: function(res){
				Utils.redirect('/ytcrm');
            }
        });
	};

    $scope.getOrders = () => {
        Ajax.get({
            module: 'ytcrm',
            url: 'order/get-record-orders',
            params: {id: Utils.GET().id, fieldName: 'organization'},
            success: function(res) { 
                $scope.data.orders = res.orders;
            }
        });
    };

    $scope.goMonthBack = function(){
        if ($scope.data.userItems.length==0){
            return;
        }
        var monthNumber = $scope.data.userItems[$scope.data.userItems.length-1].monthNumber;
        var jsDate = new Date(monthNumber.substring(0,4), monthNumber.substring(4,6)-1, 1); // day of month always 1st day to avoid day that not exist like 2018-02-30 etc...
        jsDate.setMonth(jsDate.getMonth() - 1);
        var year=jsDate.getFullYear(),month=jsDate.getMonth()+1,day=jsDate.getDate();
        $scope.filterDate = (year + '-' + ('0' + (month)).slice(-2) + '-01');
        $scope.pastFilterDate = $scope.filterDate;
        $scope.back = true;
        $scope.init('back');
    };
    
}]);