'use strict';

app.controller('UserProject', ['$scope', '$timeout', '$filter', 'Ajax', 'Utils', 'Popup', function($scope, $timeout, $filter, Ajax, Utils, Popup) {
    
    $scope.projectUsers = [];
    
    $scope.init = function(){
        Ajax.get({
            url: 'user-project/get-project-users',
            success: function(res){
                $scope.projectUsers = res.projectUsers;
                $scope.projectname = Utils.GET().projectname;
            },
        });
        
    };
    
    $scope.init();
    
    $scope.save = function(data, $modalInstance){
        Ajax.post({
            url: 'user-project/save',
            data: data,
            success: function(res){
                $scope.init();
                $modalInstance.close();
            },
        });
    }
    
        
    $scope.openUserProjectPopup = function(data){
        Popup.open('adminsettings/userProject', $scope.save, data);
    };
}]);