'use strict';

app.controller('cr21OrderController', ['$rootScope', '$scope', 'Ajax', '$filter', 'Utils', 'Popup', 'mailMerge', function($rootScope, $scope, Ajax, $filter, Utils, Popup, mailMerge) {
    $scope.data     = {};
    $scope.tagsArr  = [];

    $scope.$on('cru:getSuccess', function(e, mod, res){
        //$scope.getFamilyBudgetRequests();
        $scope.data = res.data ? res.data : {};
        if(mod == 'add'){
            $scope.data.customer_user_id = Utils.GET().customer_user_id;
        }
        $scope.tagsToArr();
    });
        
    $scope.$on('cru:postSuccess', function(e, mod, res){
        if(mod == 'add'){
            $scope.data.customer_user_id = Utils.GET().customer_user_id;
            
        }else{
            $scope.tagsToArr();
            //$scope.editTags=!$scope.editTags;
        }
        if(!window.location.href.includes('view')){
            Utils.redirect('/cr21/order/view?id=' + res.data.id);
        }
        //}
    });

    $scope.openMailPopup = function(){
        mailMerge.openMailPopup($scope.data,145); // 145 = order(logic_id) in cr21
    };

    $scope.tagsToArr = function(){
        if ($scope.data.tags!=null)  {
            $scope.tagsArr = $scope.data.tags.split("|");
            if($scope.tagsArr[$scope.tagsArr.length-1]==''){
                $scope.tagsArr.splice(0,1);
            }
        }
    };

}]);