'use strict';

app.directive('formExternalFields', ['$timeout', '$filter', 'Ajax', 'Utils', '$sce', function ($timeout, $filter, Ajax, Utils, $sce) {
    return {
        restrict: 'AEC',
        scope: {
            f: '=f',
        },
        templateUrl: '/js/app/directives/formExternalFields/formExternalFields.html',
        link: function(scope, element, attrs) {
            scope.setDateArrays = function(){
                    scope.f.yearsArray = [];
                    scope.monthsArray = [];
                    scope.daysArray = [];
                    var y = (scope.f.attributes && scope.f.attributes.year && scope.f.attributes.year.to) ? scope.f.attributes.year.to : 2030;
                    var from = (scope.f.attributes && scope.f.attributes.year && scope.f.attributes.year.from) ? scope.f.attributes.year.from : 1900;
                    for (; y >= from; y--) {
                        scope.f.yearsArray.push({value: y, id: y});
                    }
                    var months = ['ינואר','פברואר','מרץ','אפריל','מאי','יוני','יולי','אוגוסט','ספטמבר','אוקטובר','נובמבר','דצמבר'];
                    var m = 1;
                    for (m = 1; m <= 12; m++) {
                        scope.monthsArray.push({value: months[m-1], id: m});
                    }
                    var d = 1;
                    for (d = 1; d <= 31; d++) {
                        scope.daysArray.push({value: d, id: d});
                    }
            };
            
            $timeout(function(){    
                scope.f = angular.fromJson(scope.f);
                if (scope.f.field_type=='date'){
                    scope.setDateArrays();
                    if (scope.f.myanswer){
                        var myAnswerArray = scope.f.myanswer.split("-");
                        var dCurrentYear = new Date();
                        scope.f.year = myAnswerArray[0] ? myAnswerArray[0] : dCurrentYear.getFullYear();
                        scope.f.month = myAnswerArray[1];
                        scope.f.day = myAnswerArray[2];
                    }
                }
                if (scope.f.field_type == 'fileupload'){
                    if(!scope.f.myanswer){
                        scope.f.myanswer=[];
                    }
                }
                
                scope.userMessages = window.ytUserMessages;
                scope.language = window.ytFormLanguage;
                

            },1000);
            
            scope.trustAsHtml = function(bindHtml){
                if (bindHtml){
                    return $sce.trustAsHtml(bindHtml);
                }
            };

            scope.setDate = function() {
                if (scope.f.day && scope.f.year && scope.f.month) {
                    var month = parseInt(scope.f.month)<10 ? '0'+scope.f.month : scope.f.month;
                    var day = parseInt(scope.f.day)<10 ? '0'+scope.f.day : scope.f.day;
                    scope.f.myanswer = scope.f.year + '-' + month + '-' + day;
                }else{
                    scope.f.myanswer = null;
                }
            }

            scope.onlyNumbers = function($event){
                if ( !isNaN(scope.f.myanswer) && !isNaN( parseFloat(scope.f.myanswer) ) ) {
                    scope.numbersMessage=false;
                }else{
                    scope.numbersMessage=true;
                    //$event.preventDefault();
                    scope.f.myanswer = scope.f.myanswer.substring(0, scope.f.myanswer.length-1);
                }
            };

            scope.addNewFileClick = function(){
                $('#'+scope.f.id+'uploadNewFile').trigger('click');
            };

            scope.uploadFile = function(elem) {
                scope.f.failed_format_class = '';
                //var file_connection_id  = this.doc.id;
                var file = $('#'+scope.f.id+'uploadNewFile')[0].files[0];
                
                if( file ){

                    var ext = $('#'+scope.f.id+'uploadNewFile').val().match(/\.(.+)$/)[1];
                    
                    
                    if (!scope.isValidFileFormat(ext)){
                        scope.f.uploadProgress = false;
                        Ajax.post({ // for refresh page data
                            url: 'form-portal/empty',
                            notShowMessage: true,
                            success: function(res) {
                            }
                        });
                        return;
                    }
                    

                    var fd      = new FormData();
        
                    var param = {};
                    param.friendly_name = '(' + parseInt(scope.f.myanswer.length + 1) + ') ' + scope.f.field_text;
                    param.formats = scope.f.settings.fileFormat;
                    fd.append('file', file);
                    fd.append('fileData', JSON.stringify(param));
                    scope.f.uploadProgress=true;

                    Ajax.post({ // for refresh page data
                        url: 'form-portal/empty',
                        notShowMessage: true,
                        success: function(res) {
                            Ajax.post({ // for refresh page data
                            url: 'form-portal/empty',
                            notShowMessage: true,
                            success: function(res) {

                            
                        Ajax.jqueryUploadFile({ 
                            module: 'common',
                            url: 'form-portal/upload-form-file?token=' + Utils.GET().token + '&fieldId=' + scope.f.id,
                            fd: fd,
                            success: function(res){
                                //scope.init();
                                if (!res.fileConnection.file_id){
                                    scope.f.uploadProgress = false;
                                    scope.f.failed_format_class = 'is-invalid-fileformat';
                                    scope.f.failed_format_message = res.data;
                                    return;
                                }
                                scope.f.myanswer.push({
                                    id:             res.fileConnection.file_id,
                                    name:           res.file.name,
                                    friendlyName:   res.file.friendly_name,
                                    ext:            res.file.ext  
                                });
                                Ajax.post({
                                    module: 'common',
                                    url: 'form-portal/save-fileupload-answer', 
                                    data: scope.f.myanswer,
                                    params: {token: Utils.GET().token, fieldId: scope.f.id},
                                    notShowMessage: true,
                                    success: function(res) {
                                        scope.f.uploadProgress = false;
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
                }
            };

            scope.deleteFile = function($index){
                Ajax.post({
                    module: 'common',
                    url: 'form-portal/delete-form-file', 
                    params: {fileId: scope.f.myanswer[$index].id},
                    notShowMessage: true,
                    success: function(res) {
                        scope.f.myanswer.splice($index,1);
                        Ajax.post({
                            module: 'common',
                            url: 'form-portal/save-fileupload-answer', 
                            data: scope.f.myanswer,
                            params: {token: Utils.GET().token, fieldId: scope.f.id},
                            notShowMessage: true,
                            success: function(res) {
                            }
                        });
                    }
                });
            };

            scope.isValidFileFormat = function(ext){
                var isValid = false;
                var extLowerCase = ext.toLowerCase();
                var formatsList = [
                    {name: 'PDF', regx: /(?:pdf$)/, check: scope.f.settings.fileFormat.pdf},
                    {name: 'תמונה', regx: /(?:gif|jpg|jpeg|png|bmp$)/, check: scope.f.settings.fileFormat.image},
                    {name: 'מסמך', regx: /(?:doc|docx|txt|trf$)/, check: scope.f.settings.fileFormat.doc},
                    {name: 'אקסל', regx: /(?:csv|xml|xls|xlsx$)/, check: scope.f.settings.fileFormat.excel}
                ];
                scope.f.failed_format_message = 'ניתן להעלות קבצים רק מסוג ';
                formatsList.forEach(function(format) {
                    if ( isValid == false && format.check == 'true' && !extLowerCase.match(format.regx) ) {
                        scope.f.failed_format_class = 'is-invalid-fileformat';
                        scope.f.failed_format_message = scope.f.failed_format_message + format.name + ' | ';
                        
                    }else if(format.check == 'true'){
                        scope.f.failed_format_class = '';
                        scope.f.failed_format_message = '';
                        isValid = true;
                    }
                });
                return isValid;
            }
        }
    };
}]);